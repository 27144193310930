import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { makeStyles } from "@material-ui/core";
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, KeaLoader } from 'components';
import { FaTimes } from 'react-icons/fa';
import SuccessImg from 'assets/images/success.gif';


const ConfirmModalGif = props => {
  const classes = usetyles();

  const [isSuccess, setIsSuccess] = useState(false);

  const handleConfirm = (status = false) => {
    setIsSuccess(status);
    if (status) {
      setTimeout(() => {
        props.onHide(status);
      }, 1500);
    } else {
      props.onHide(status);
    }
  };

  useEffect(() => {
    if (props?.visible) {
      setIsSuccess(false);
    }
  }, [props?.visible]);

  return (
    <Modal
      centered
      backdrop="static"
      contentClassName={classes.modalContainer}
      show={props.visible}
      onHide={props.onHide.bind(this, false)}
    >
      <div className="h-auto w-100 d-flex flex-column p-3">
        <div
          style={{ height: "300px" }}
          className="bg-white border-10 w-100 d-flex flex-column align-items-center justify-content-center"
        >
          {
            isSuccess ?
              <img src={SuccessImg} style={{ height: "150px", objectFit: "contain", width: "80%", marginBottom: "30px" }} />
              :
              <KeaLoader />
          }
          <Message style={props.messageStyle} className={`mt-4 ${props.messageClassName ? props.messageClassName : ''}`}>
            {props.message}
          </Message>
        </div>
        <div className="d-flex align-items-center justify-content-center mt-3">
          <Button disabled={isSuccess} onClick={handleConfirm.bind(this, true)} value={props.successLabel} className={`w-40 mr-4 ${props.successClassName ? props.successClassName : ''}`} style={{ ...props.successStyle }} />
          <Button disabled={isSuccess} variant="gray" onClick={handleConfirm.bind(this, false)} value={props.cancelLabel} className={`w-40 ${props.cancelClassName ? props.cancelClassName : ''}`} style={{ ...props.cancelStyle }} />
        </div>
      </div>
    </Modal>
  );
};

const usetyles = makeStyles(() => ({
  modalContainer: {
    boxShadow: "0px 8px 30px rgba(0, 0, 0, 0.12)",
    borderRadius: "5px",
    borderWidth: 0,
    backgroundColor: "#F2F6F9",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start"
  },
}));

ConfirmModalGif.propTypes = {
  successLabel: PropTypes.string,
  successStyle: PropTypes.object,
  successClassName: PropTypes.string,
  cancelStyle: PropTypes.object,
  cancelLabel: PropTypes.string,
  cancelClassName: PropTypes.string,
  message: PropTypes.string,
  messageStyle: PropTypes.object,
  messageClassName: PropTypes.string,
  visible: PropTypes.bool,
  onHide: PropTypes.func,
};

ConfirmModalGif.defaultProps = {
  visible: false,
  message: "Voulez-vous vraiment continuer ?",
  successLabel: "Continer",
  cancelLabel: "Annuler",
  cancelStyle: {},
  successStyle: {},
  messageStyle: {},
  onHide: () => console.log("Please attach an onHide function to [ConfirmModalGif]"),
};


const CloseIcon = styled(FaTimes)`
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  color: #52575C;
`;

const Message = styled.div`
  width: 100%;
  text-align: center;
  color: #52575C;
`;

export default ConfirmModalGif;