import React from "react";
import styled from 'styled-components';
import PropTypes from 'prop-types';

const SwiftInput = ({
	component,
	name,
	value,
	onChange,
	error,
	errorStyle,
	style,
	containerStyle,
	containerClassName,
	theme,
	children,
	...rest
}) => {
	const InputComponent = component ? component : Input;

	return (
		<div
			className={containerClassName}
			style={{
				margin: "5px 0",
				padding: '5px',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'flex-start',
				justifyContent: 'flex-start',
				...containerStyle
			}}
		>
			<InputComponent
				name={name}
				value={value}
				onChange={onChange}
				style={{
					color: !!theme && !!theme.text ? theme.text : "black",
					borderColor: !!theme && !!theme.border ? theme.border : "#888",
					backgroundColor: !!theme && !!theme.background ? theme.background : "white",
					...style
				}}
				{...rest}
			>{children}</InputComponent>
			{!!error && <ErrorFeedback style={{ ...errorStyle }}>{error}</ErrorFeedback>}
		</div>
	);
};

SwiftInput.propTypes = {
	component: PropTypes.node.isRequired,
	name: PropTypes.string,
	value: PropTypes.string,
	onChange: PropTypes.func,
	error: PropTypes.string,
	errorStyle: PropTypes.object,
	style: PropTypes.object,
	containerStyle: PropTypes.object,
	containerClassName: PropTypes.string,
	theme: PropTypes.object
};

SwiftInput.defaultProps = {
	name: '',
	value: '',
	onChange: () => console.log('no onChange method attached...'),
	isInvalid: false,
	style: {},
	errorStyle: {},
	containerStyle: {},
	containerClassName: "",
};

const Input = styled.input`
	border: 1px solid #888;
	padding: 10px;
	width: 100%;
	border-radius: 5px;
	&:focus {
		outline: none;
	}
`;

const ErrorFeedback = styled.span`
	width: 100%;
	margin-top: .25rem;
	font-size: 80%;
	color: red;
	text-align: right;
`;

export default SwiftInput;
