import React from 'react';
import PropTypes from 'prop-types';
import Pill from '../pill';
import styled from 'styled-components';
import Button from '../button';
import { FaCertificate } from 'react-icons/fa';

const CardAssurance = props => {
  return (
    <Pill
      variant={props.active ? `principal` : `gray`}
      {...props}
      className={`p-2 position-relative h-auto flex-column ${props.className ? props.className : ''}`}
    >
      {props.certified && <CertifiedIcon size={10} color={props.active ? '#7B61FF' : '#A0A4A8'} />}
      <ImageContainer>
        {props.picture && <img src={props.picture} />}
      </ImageContainer>
      <Button
        variant={props.active ? `principal` : `gray`}
        size="sm"
        className="border-5 font-80 mt-2"
        value={props.title}
        onClick={()=> props.onButtonClick(props.card)}
        style={{ minWidth: "100px" }}
      />
    </Pill>
  );
};

CardAssurance.propTypes = {
  title: PropTypes.string,
  picture: PropTypes.any,
  active: PropTypes.bool,
  certified: PropTypes.bool,
  onButtonClick: PropTypes.func,
};

CardAssurance.defaultProps = {
  title: "",
  picture: null,
  active: false,
  certified: false,
  onButtonClick: () => {},
};

const CertifiedIcon = styled(FaCertificate)`
  position: absolute;
  top: 5px;
  right: 5px;
`;

const ImageContainer = styled.div`
  height: 40px;
  width: 100px;
  overflow: hidden;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
`;

export default CardAssurance;