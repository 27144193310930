import React from "react";
import {useStore} from "context/store";
import {AGENT_SUFFIX, API_BASE_URL, OPERATIONS_SUFFIX, PATIENT_SUFFIX} from "../http/crud";
import UserImg from "assets/icons/user-placeholder.png";
import moment from "moment";
import {BACKEND_DATE_FORMAT} from "../configs";


export const getTypeCentreLabel = (comparedString = "hopital",
                                   validReturnString = "hospitalisations",
                                   unvalidReturnString = "mises en observations") => {
    const {state: {configs}} = useStore();
    return configs?.centreInfo?.typeCentre === String(comparedString).toUpperCase() ? validReturnString : unvalidReturnString
}

export const getAgentPhotoAbsoluteURL = (photo, defaultImg = UserImg) => {
    return !!photo ? `${API_BASE_URL}/${AGENT_SUFFIX}/uploads/${photo}` : defaultImg
}
export const getPatientPhotoAbsoluteURL = (photo, defaultImg = UserImg) => {
    return !!photo ? `${API_BASE_URL}/${PATIENT_SUFFIX}/${photo}` : defaultImg
}

export const sortArrayOfObjectByArray = (array, order, key, elementFormatter = null) => {

    const elementFormatterIsFunc = !!elementFormatter && typeof elementFormatter === "function"
    array.sort(function (a, b) {
        var A = elementFormatterIsFunc ? elementFormatter(a[key]) : a[key],
            B = elementFormatterIsFunc ? elementFormatter(b[key]) : b[key];

        if (order.indexOf(A) > order.indexOf(B)) {
            return 1;
        } else {
            return -1;
        }

    });

    return array;
};

export const calculateIMC = (taille, poids, defaultValue=0) => {
    if (!!!taille || !!!poids) {
        return defaultValue
    }
    return (parseFloat(poids) / ((parseFloat(taille) * 0.01) ** 2)).toFixed(4)
}

export const termeGrossesseCalculator = (ddr, withSuffix = false) => {
    let dt = new Date(),
        forma = dt.getFullYear() + "-" + (dt.getMonth() + 1) + "-" + dt.getDate(),
        today = moment(forma, "YYYY-MM-DD"),
        last_regle = moment(ddr, BACKEND_DATE_FORMAT),
        deltaWeek = Math.abs(today.diff(last_regle, "days"));

    let pos = 0,
        increment = 0,
        amenh = "",
        term = [{}],
        tab = [],
        temp = 0;
    if (deltaWeek != 0 && deltaWeek != "NaN") {
        for (let index = 1; index <= deltaWeek; index++) {
            if (pos < 7) {
                // before 7 day
                tab.push(index);
                pos += 1;
            }
            if (pos === 7) {
                // to 7 days
                increment += 1;
                term[increment] = tab;
                temp = index;
                tab = []; //reinitialisation
                pos = 0;
            }
        }
        if (Math.abs(temp - deltaWeek) != 0) {
            amenh = !withSuffix ? term.length - 1 : term.length - 1 + 'SA + ' + Math.abs(temp - deltaWeek) + ' jour(s)'; //;
        } else {
            amenh = !withSuffix ? term.length - 1 : term.length - 1 + 'SA'; // + 'SA';
        }
    }
    return amenh;
};

export const getDataFullName = (data, firstNameLabel = "firstName", lastNameLabel = "lastName", defaultValue = "-") => {
    return `${!!data?.[firstNameLabel] ? data?.[firstNameLabel] : defaultValue} ${!!data?.[lastNameLabel] ? data?.[lastNameLabel] : defaultValue} `
}