import React from 'react';
import "./styles.scss";
import PropTypes from 'prop-types';

const FocusedInput = ({
  type,
  name,
  value,
  error,
  onChange,
  className,
  ...rest
}) => {
  const [active, setActive] = React.useState(true);

  return (
    <input
      type={type}
      name={name}
      value={value}
      readOnly={!active}
      onChange={onChange}
      // onDoubleClick={() => setActive(true)}
      // onBlur={() => setActive(false)}
      {...rest}
      className={`kea-input__field h-auto p-1 border-0 ${className ? String(className) : ""}`}
    />
  );
};

FocusedInput.propTypes = {
  type: PropTypes.oneOf(["text", "number", "date"]),
  name: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string
};

FocusedInput.defaultProps = {
  type: "text",
  name: '',
  value: '',
  onChange: () => console.log('no onChange method attached to Input...'),
};

export default FocusedInput;