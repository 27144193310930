import React from 'react';
import PropTypes from 'prop-types';
import { FaQuestion } from 'react-icons/fa';
import "./styles.scss";

const CardOption = props => {
  return (
    <div
      {...props}
      className={`kea-card kea-card-option ${props.className} text-center`}
    >
      {
        !!props?.icon ?
          props.icon
          :
          <FaQuestion size={50} className="mt-5" />
      }
      <span className="my-2 w-100 text-center font-weight-bold">
        {props.title}
      </span>
      {!!props.subtitle &&
        <span className="w-100 text-center text-disabled font-90">
          {props.subtitle}
        </span>
      }
    </div>
  );
};

CardOption.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.any
};

CardOption.defaultProps = {
  title: "",
};

export default CardOption;