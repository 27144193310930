import React, { useState } from 'react';
import styled from 'styled-components';
import { Input, Textarea } from 'components';
import { PatientType, ResultatImgPelvienneType, ImagerieExam } from 'types';
import { useDebounce } from 'hooks';
import moment from 'moment';
import { BACKEND_DATE_FORMAT } from 'configs';
import { minValOrNullable, resetObject, validate } from 'context/swift-inputs';
import _ from 'lodash';


interface FieldsType {
  result_date: string;
  medecin_demandeur: string;
  ddr: string;
  indication: string;
  resultat: ResultatImgPelvienneType;
  comment: string;
};

interface FicheEchoPelvienneProps {
  exam: ImagerieExam;
  className: string;
  patient: PatientType;
  fields: FieldsType;
  onChange: (fields: FieldsType, isValid: boolean) => void;
  readOnly: boolean;
};

const FicheEchoPelvienne = (props: FicheEchoPelvienneProps) => {
  const [fields, setFields] = useState(props?.fields);

  const [errors, setErrors] = useState({
    medecin_demandeur: "",
    ddr: "",
    indication: "",
    comment: "",

    vessie: "",
    uterus: "",
    position: "",
    echostructure: "",
    contours: "",
    long: "",
    dap: "",
    dt: "",
    vacuite: "",
    endometre_aspect: "",
    endometre_mesure: "",
    myome: "",
    autres: "",
    ov_droit_taille: "",
    ov_droit_structure: "",
    ov_gauche_taille: "",
    ov_gauche_structure: "",
    douglas: "",
  });

  // FOR RESULTATS INPUTS
  const handleInputChange = e => {
    setFields(old => ({
      ...old,
      resultat: {
        ...old?.resultat,
        [e?.target?.name]: e?.target?.value
      }
    }));
  };

  useDebounce(() => {
    !props?.readOnly && props?.onChange(fields, checkForm({
      ..._.omit(fields, 'resultat'),
      ...fields?.resultat
    }));
  }, 100, [fields]);

  const checkForm = (fieldsData: FieldsType) => {
    const validation = validate(fieldsData, {
      medecin_demandeur: "required|name",
      ddr: "required|date",
      indication: "required",
      comment: "required",

      vessie: "nullable",
      uterus: "nullable",
      position: "nullable",
      echostructure: "nullable",
      contours: "nullable",
      long: minValOrNullable.bind(this, 0),
      dap: minValOrNullable.bind(this, 0),
      dt: minValOrNullable.bind(this, 0),
      vacuite: "nullable",
      endometre_aspect: "nullable",
      endometre_mesure: "nullable",
      myome: "nullable",
      autres: "nullable",
      ov_droit_taille: "nullable",
      ov_droit_structure: "nullable",
      ov_gauche_taille: "nullable",
      ov_gauche_structure: "nullable",
      douglas: "nullable",
    });

    if (validation?.isFormValid()) {
      setErrors(resetObject(errors));
      return true;
    } else {
      setErrors(old => ({
        ...old,
        medecin_demandeur: validation?.response?.medecin_demandeur?.error,
        ddr: validation?.response?.ddr?.error,
        indication: validation?.response?.indication?.error,
        comment: validation?.response?.comment?.error,
        vessie: validation?.response?.vessie?.error,
        uterus: validation?.response?.uterus?.error,
        position: validation?.response?.position?.error,
        echostructure: validation?.response?.echostructure?.error,
        contours: validation?.response?.contours?.error,
        long: validation?.response?.long?.error,
        dap: validation?.response?.dap?.error,
        dt: validation?.response?.dt?.error,
        vacuite: validation?.response?.vacuite?.error,
        endometre_aspect: validation?.response?.endometre_aspect?.error,
        endometre_mesure: validation?.response?.endometre_mesure?.error,
        myome: validation?.response?.myome?.error,
        autres: validation?.response?.autres?.error,
        ov_droit_taille: validation?.response?.ov_droit_taille?.error,
        ov_droit_structure: validation?.response?.ov_droit_structure?.error,
        ov_gauche_taille: validation?.response?.ov_gauche_taille?.error,
        ov_gauche_structure: validation?.response?.ov_gauche_structure?.error,
        douglas: validation?.response?.douglas?.error,
      }));
      return false;
    }
  };

  const makeInputClassName = err => {
    return `px-0 py-1 h-auto font-weight-bold ${!!err ? 'border-principal-bottom' : ''}`;
  };

  return (
    <Container className={`w-100 ${props.className || ""}`}>
      <div className="w-100 p-3 border-bottom font-weight-bold bg-principal-pills text-center ">
        {props?.exam?.libelle || 'ECHOGRAPHIE PELVIENNE'}
      </div>
      <div className="p-3 d-flex flex-column w-100">
        <div className="align-self-end d-flex align-items-center">
          <span className="mr-3">Date :</span>
          <Input
            wrapperStyle={{ width: "120px" }}
            containerClassName="border-left-0 border-top-0 border-right-0"
            className="px-0 py-1 h-auto"
            type="date"
            value={props?.fields?.result_date}
          />
        </div>
        <div className="w-100 d-flex flex-wrap">
          <div className="d-flex align-items-center mr-5 mt-4 w-40">
            <span className="mr-3 white-space-nowrap">Nom et Prénoms :</span>
            <Input
              containerClassName="border-left-0 border-top-0 border-right-0"
              className="px-0 py-1 h-auto"
              readOnly
              value={`${props?.patient?.lastName || '-'}${!!props?.patient?.firstName ? ' ' + props?.patient?.firstName : ''}`}
            />
          </div>
          <div className="d-flex align-items-center mr-5 mt-4">
            <span className="mr-3 white-space-nowrap">Age :</span>
            <Input
              containerClassName="border-left-0 border-top-0 border-right-0"
              className="px-0 py-1 h-auto"
              wrapperStyle={{ width: "150px" }}
              readOnly
              value={`${!!props?.patient?.birthDate ? `${moment().diff(moment(props?.patient?.birthDate, BACKEND_DATE_FORMAT), 'years')} ans` : "-"}`}
            />
          </div>
          <div className="d-flex align-items-center mr-5 mt-4 w-40">
            <span className="mr-3 white-space-nowrap">Demandeur :</span>
            <Input
              containerClassName="border-left-0 border-top-0 border-right-0"
              className={`px-0 py-1 h-auto font-weight-bold ${!!errors?.medecin_demandeur ? 'border-principal-bottom' : ''}`}
              value={fields?.medecin_demandeur}
              onChange={e => setFields(old => ({
                ...old,
                medecin_demandeur: e?.target?.value
              }))}
              readOnly={props?.readOnly}
            />
          </div>
          <div className="d-flex align-items-center mr-5 mt-4">
            <span className="mr-3 white-space-nowrap">DDR :</span>
            <Input
              containerClassName="border-left-0 border-top-0 border-right-0"
              className={`px-0 py-1 h-auto font-weight-bold ${!!errors?.ddr ? 'border-principal-bottom' : ''}`}
              type="date"
              wrapperStyle={{ width: "150px" }}
              value={fields?.ddr}
              onChange={e => setFields(old => ({
                ...old,
                ddr: e?.target?.value
              }))}
              max={moment().subtract(2, "weeks").format(BACKEND_DATE_FORMAT)}
              readOnly={props?.readOnly}
            />
          </div>
          <div className="d-flex align-items-center mt-4 w-100">
            <span className="mr-3 white-space-nowrap">Indications :</span>
            <Input
              containerClassName="border-left-0 border-top-0 border-right-0"
              className={`px-0 py-1 h-auto font-weight-bold ${!!errors?.indication ? 'border-principal-bottom' : ''}`}
              value={fields?.indication}
              onChange={e => setFields(old => ({
                ...old,
                indication: e?.target?.value
              }))}
              readOnly={props?.readOnly}
            />
          </div>
        </div>
      </div>
      <div className="w-100 p-3 font-weight-bold text-principal text-center mt-4">
        RÉSULTATS
      </div>
      <div className="p-3 d-flex flex-wrap w-100 border-top mt-3">
        <div className="d-flex align-items-center mt-3 w-100">
          <span className="mr-2 white-space-nowrap">Vessie :</span>
          <Input
            containerClassName="border-left-0 border-top-0 border-right-0"
            className={makeInputClassName(errors?.vessie)}
            name="vessie"
            value={fields?.resultat?.vessie}
            onChange={handleInputChange}
            readOnly={props?.readOnly}
          />
        </div>
        <div className="d-flex align-items-center mt-3 w-100">
          <span className="mr-2 white-space-nowrap">Utérus: :</span>
          <Input
            containerClassName="border-left-0 border-top-0 border-right-0"
            className={makeInputClassName(errors?.uterus)}
            name="uterus"
            value={fields?.resultat?.uterus}
            onChange={handleInputChange}
            readOnly={props?.readOnly}
          />
        </div>
        <div className="d-flex flex-wrap w-100 pl-4">
          <div className="d-flex align-items-center mt-3 w-100">
            <span className="mr-2 white-space-nowrap">- Position :</span>
            <Input
              containerClassName="border-left-0 border-top-0 border-right-0"
              className={makeInputClassName(errors?.position)}
              name="position"
              value={fields?.resultat?.position}
              onChange={handleInputChange}
              readOnly={props?.readOnly}
            />
          </div>
        </div>
        <div className="d-flex flex-wrap w-100 pl-4">
          <div className="d-flex align-items-center mt-3 w-60">
            <span className="mr-2 white-space-nowrap">- Echostructure :</span>
            <Input
              containerClassName="border-left-0 border-top-0 border-right-0"
              className={makeInputClassName(errors?.echostructure)}
              name="echostructure"
              value={fields?.resultat?.echostructure}
              onChange={handleInputChange}
              readOnly={props?.readOnly}
            />
          </div>
          <div className="d-flex align-items-center mt-3 w-40 pl-4">
            <span className="mr-2 white-space-nowrap">Contours :</span>
            <Input
              containerClassName="border-left-0 border-top-0 border-right-0"
              className={makeInputClassName(errors?.contours)}
              name="contours"
              value={fields?.resultat?.contours}
              onChange={handleInputChange}
              readOnly={props?.readOnly}
            />
          </div>
        </div>
        <div className="d-flex flex-wrap w-100 pl-4">
          <div className="d-flex align-items-center mt-3">
            <span className="mr-2 white-space-nowrap">- Dimensions : Long</span>
            <Input
              containerClassName="border-left-0 border-top-0 border-right-0"
              className={makeInputClassName(errors?.long)}
              name="long"
              value={fields?.resultat?.long}
              onChange={handleInputChange}
              wrapperStyle={{ width: "150px" }}
              min="0" type="number"
              readOnly={props?.readOnly}
            />
            <span className="ml-1">mm</span>
          </div>
          <div className="d-flex align-items-center mt-3 pl-4">
            <span className="mr-2 white-space-nowrap">DAP :</span>
            <Input
              containerClassName="border-left-0 border-top-0 border-right-0"
              className={makeInputClassName(errors?.dap)}
              name="dap"
              value={fields?.resultat?.dap}
              onChange={handleInputChange}
              wrapperStyle={{ width: "150px" }}
              min="0" type="number"
              readOnly={props?.readOnly}
            />
            <span className="ml-1">mm</span>
          </div>
          <div className="d-flex align-items-center mt-3 pl-4">
            <span className="mr-2 white-space-nowrap">DT :</span>
            <Input
              containerClassName="border-left-0 border-top-0 border-right-0"
              className={makeInputClassName(errors?.dt)}
              name="dt"
              value={fields?.resultat?.dt}
              onChange={handleInputChange}
              wrapperStyle={{ width: "150px" }}
              min="0" type="number"
              readOnly={props?.readOnly}
            />
            <span className="ml-1">mm</span>
          </div>
        </div>
        <div className="d-flex flex-wrap w-100 pl-4">
          <div className="d-flex align-items-center mt-3 w-100">
            <span className="mr-2 white-space-nowrap">- Ligne de vacuité :</span>
            <Input
              containerClassName="border-left-0 border-top-0 border-right-0"
              className={makeInputClassName(errors?.vacuite)}
              name="vacuite"
              value={fields?.resultat?.vacuite}
              onChange={handleInputChange}
              readOnly={props?.readOnly}
            />
          </div>
        </div>
        <div className="d-flex flex-wrap w-100 pl-4">
          <div className="d-flex align-items-center mt-3 w-60">
            <span className="mr-2 white-space-nowrap">- Muqueuse de l'endomètre : Aspect</span>
            <Input
              containerClassName="border-left-0 border-top-0 border-right-0"
              className={makeInputClassName(errors?.endometre_aspect)}
              name="endometre_aspect"
              value={fields?.resultat?.endometre_aspect}
              onChange={handleInputChange}
            />
          </div>
          <div className="d-flex align-items-center mt-3 pl-4 w-40">
            <span className="mr-2 white-space-nowrap">Mesures :</span>
            <Input
              containerClassName="border-left-0 border-top-0 border-right-0"
              className={makeInputClassName(errors?.endometre_mesure)}
              name="endometre_mesure"
              value={fields?.resultat?.endometre_mesure}
              onChange={handleInputChange}
              readOnly={props?.readOnly}
            />
          </div>
        </div>
        <div className="d-flex flex-wrap w-100 pl-4">
          <div className="d-flex align-items-center mt-3 w-100">
            <span className="mr-2 white-space-nowrap">- Myome :</span>
            <Input
              containerClassName="border-left-0 border-top-0 border-right-0"
              className={makeInputClassName(errors?.myome)}
              name="myome"
              value={fields?.resultat?.myome}
              onChange={handleInputChange}
              readOnly={props?.readOnly}
            />
          </div>
        </div>
        <div className="d-flex flex-wrap w-100 pl-4">
          <div className="d-flex align-items-center mt-3 w-100">
            <span className="mr-2 white-space-nowrap">- Autres :</span>
            <Input
              containerClassName="border-left-0 border-top-0 border-right-0"
              className={makeInputClassName(errors?.autres)}
              name="autres"
              value={fields?.resultat?.autres}
              onChange={handleInputChange}
              readOnly={props?.readOnly}
            />
          </div>
        </div>
      </div>
      <div className="p-3 d-flex flex-column w-100 border-top mt-3">
        <div className="font-weight-bold w-100">Annexe</div>
        <div className="mt-3 w-100 d-flex align-items-center justify-content-between">
          <span className="w-20">Ovaire Droit</span>
          <div className="d-flex align-items-center w-35">
            <span className="mr-2 white-space-nowrap">Taille :</span>
            <Input
              containerClassName="border-left-0 border-top-0 border-right-0"
              className={makeInputClassName(errors?.ov_droit_taille)}
              name="ov_droit_taille"
              value={fields?.resultat?.ov_droit_taille}
              onChange={handleInputChange}
              min="0" type="number"
              readOnly={props?.readOnly}
            />
          </div>
          <div className="d-flex align-items-center w-35">
            <span className="mr-2 white-space-nowrap">Structure :</span>
            <Input
              containerClassName="border-left-0 border-top-0 border-right-0"
              className={makeInputClassName(errors?.ov_droit_structure)}
              name="ov_droit_structure"
              value={fields?.resultat?.ov_droit_structure}
              onChange={handleInputChange}
              readOnly={props?.readOnly}
            />
          </div>
        </div>
        <div className="mt-3 w-100 d-flex align-items-center justify-content-between">
          <span className="w-20">Ovaire Gauche</span>
          <div className="d-flex align-items-center w-35">
            <span className="mr-2 white-space-nowrap">Taille :</span>
            <Input
              containerClassName="border-left-0 border-top-0 border-right-0"
              className={makeInputClassName(errors?.ov_gauche_taille)}
              name="ov_gauche_taille"
              value={fields?.resultat?.ov_gauche_taille}
              onChange={handleInputChange}
              min="0" type="number"
              readOnly={props?.readOnly}
            />
          </div>
          <div className="d-flex align-items-center w-35">
            <span className="mr-2 white-space-nowrap">Structure :</span>
            <Input
              containerClassName="border-left-0 border-top-0 border-right-0"
              className={makeInputClassName(errors?.ov_gauche_structure)}
              name="ov_gauche_structure"
              value={fields?.resultat?.ov_gauche_structure}
              onChange={handleInputChange}
              readOnly={props?.readOnly}
            />
          </div>
        </div>
        <div className="d-flex align-items-center w-100 mt-3">
          <span className="mr-2 white-space-nowrap">Douglas :</span>
          <Input
            containerClassName="border-left-0 border-top-0 border-right-0"
            className={makeInputClassName(errors?.douglas)}
            name="douglas"
            value={fields?.resultat?.douglas}
            onChange={handleInputChange}
            readOnly={props?.readOnly}
          />
        </div>
      </div>
      <div className="p-3 d-flex flex-wrap w-100 border-top mt-3">
        <Textarea
          label="CONCLUSION"
          labelClassName="font-weight-bold text-principal"
          containerClassName="border-0"
          className={`px-0 py-1 ${!!errors?.comment ? 'border-principal-bottom' : ''}`}
          placeholder="Saisir ici"
          rows={3}
          value={fields?.comment}
          onChange={e => setFields(old => ({
            ...old,
            comment: e?.target?.value
          }))}
          readOnly={props?.readOnly}
        />
      </div>
    </Container>
  );
};

const Container = styled.div`
  border: 0.5px solid #ABABAB;
`;

export default FicheEchoPelvienne;