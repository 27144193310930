import React, { useContext } from "react";
import classnames from "classnames";
import { FaTimes } from "react-icons/fa";
import { FiCalendar, FiSettings } from "react-icons/fi";
import styled from "styled-components";
import routes from "configs/routes";
import {
  makeStyles,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@material-ui/core";
import { useLocation, Link } from "react-router-dom";
import Logo from "assets/images/logo.png";
import { checkPermission } from "helpers";

import { ReactComponent as AccueilIcon } from "assets/icons/accueil.svg";
import { ReactComponent as PharmacieIcon } from "assets/icons/pharmacie.svg";
import { ReactComponent as SoinsIcon } from "assets/icons/soins.svg";
import { ReactComponent as DossierIcon } from "assets/icons/dossier.svg";
import { ReactComponent as InterventionChirugieIcon } from "assets/icons/intervention-chirurgie.svg";
import { ReactComponent as ChambreLitIcon } from "assets/icons/chambreLit.svg";
import { ReactComponent as LaboratoireIcon } from "assets/icons/laboratoire.svg";
import { ReactComponent as ImagerieIcon } from "assets/icons/imagerie.svg";
import { ReactComponent as MaterniteIcon } from "assets/icons/maternite.svg";
import { ReactComponent as DoctorIcon } from "assets/icons/doctor.svg";
import { ReactComponent as ArretsIcon } from "assets/icons/arrets.svg";
import { ReactComponent as FacturationIcon } from "assets/icons/facturation.svg";
import { ReactComponent as ConfigsIcon } from "assets/icons/configs.svg";
import { ReactComponent as StatsIcon } from "assets/icons/statistiques.svg";
import { ReactComponent as ArchiveIcon } from "assets/icons/archives-new.svg";
import { APP_PAGES } from "configs";
import {getTypeCentreLabel} from "../../../helpers/utils";
import { useStore } from "context/store";
import { AppContext } from "context/app-context/AppContext";

const CURRENT_MENU_COLOR = "#E91E63";
const DEFAULT_MENU_COLOR = "#526487";

const useStyles = makeStyles((theme) => ({
  link: {
    textDecoration: "none",
    "&:hover": {
      textDecoration: "none",
    },
    CustomListItemIcon: {
      width: 16,
    },
  },
  listItem: {
    MuiTouchRipple: {
      child: {
        backgroundColor: "red",
      },
    },
  },
}));

function SideBar(props) {
  const location = useLocation();
  const classes = useStyles();

  const {
    state: { configs },
  } = useStore();

  function toggleSidebar() {
    props.toggleSidebar();
    props.toggleGrid();
  }

  function ifCurrent(url) {
    return url !== "/"
      ? location && location.pathname.includes(url)
      : location && location.pathname == url;
  }

  const { showAgenda } = useContext(AppContext);
  return (
    <aside
      className={classnames("sidenav", {
        "sidenav--active": props.sidenavState,
      })}
      style={{ zIndex: 1000 }}
    >
      <div className="sidenav__brand">
        <FaTimes
          size={20}
          className="sidenav__brand-close"
          onClick={toggleSidebar}
        />
      </div>

      <ListWrapper className="mt-5">
        <List className="pt-0">
          <Link className={classes.link} to={routes["ROOT"]}>
            <ListItem disableRipple button>
              <CustomListItemIcon>
                <AccueilIcon className="text-danger" />
              </CustomListItemIcon>
              <ListItemText
                style={{
                  color: ifCurrent(routes["ROOT"])
                    ? CURRENT_MENU_COLOR
                    : DEFAULT_MENU_COLOR,
                }}
                primary="Menu principal"
              />
            </ListItem>
          </Link>
          {/* {false && (
            <Link className={classes.link} to={routes["PHARMACIE.INDEX"]}>
              <ListItem disableRipple button>
                <CustomListItemIcon>
                  <PharmacieIcon />
                </CustomListItemIcon>
                <ListItemText
                  style={{
                    color: ifCurrent(routes["PHARMACIE.INDEX"])
                      ? CURRENT_MENU_COLOR
                      : DEFAULT_MENU_COLOR,
                  }}
                  primary="Pharmacie"
                />
              </ListItem>
            </Link>
          )} */}
          {checkPermission(APP_PAGES.PAGE_SOINS_AMBULATOIRES) && (
            <Link className={classes.link} to={routes["SOINS.LIST"]}>
              <ListItem disableRipple button>
                <CustomListItemIcon>
                  <SoinsIcon />
                </CustomListItemIcon>
                <ListItemText
                  style={{
                    color: ifCurrent(routes["SOINS.LIST"])
                      ? CURRENT_MENU_COLOR
                      : DEFAULT_MENU_COLOR,
                  }}
                  primary="Soins ambulatoires"
                />
              </ListItem>
            </Link>
          )}

          {checkPermission(APP_PAGES.PAGE_AFFECTATION_CHAMBRE) &&
            !!configs?.fonctionnalite?.affectToChambreHospi && (
              <Link
                className={classes.link}
                to={routes["AFFECTATION.CHAMBRE.INDEX"]}
              >
                <ListItem disableRipple button>
                  <CustomListItemIcon>
                    <ChambreLitIcon />
                  </CustomListItemIcon>
                  <ListItemText
                    style={{
                      color: ifCurrent(routes["AFFECTATION.CHAMBRE.INDEX"])
                        ? CURRENT_MENU_COLOR
                        : DEFAULT_MENU_COLOR,
                    }}
                    primary="Affectation de chambres et lits"
                  />
                </ListItem>
              </Link>
            )}

          {checkPermission(APP_PAGES.PAGE_DOSSIERS_HOSPI) && (
            <Link className={classes.link} to={routes["DOSSIERS.INDEX"]}>
              <ListItem disableRipple button>
                <CustomListItemIcon>
                  <DossierIcon />
                </CustomListItemIcon>
                <ListItemText
                  style={{
                    color: ifCurrent(routes["DOSSIERS.INDEX"])
                      ? CURRENT_MENU_COLOR
                      : DEFAULT_MENU_COLOR,
                  }}
                  primary={getTypeCentreLabel(
                    "HOPITAL",
                    "Hospitalisations",
                    "Mises en observation"
                  )}
                />
              </ListItem>
            </Link>
          )}
          {checkPermission(APP_PAGES.PAGE_PRE_ANESTHESIE) && (
            <Link
              className={classes.link}
              to={routes["INTERVENTIONCHIRUGICALE"]}
            >
              <ListItem disableRipple button>
                <CustomListItemIcon>
                  <InterventionChirugieIcon />
                </CustomListItemIcon>
                <ListItemText
                  style={{
                    color: ifCurrent(routes["INTERVENTIONCHIRUGICALE"])
                      ? CURRENT_MENU_COLOR
                      : DEFAULT_MENU_COLOR,
                  }}
                  primary="Interventions chirugicales"
                />
              </ListItem>
            </Link>
          )}
          {checkPermission(APP_PAGES.PAGE_LABO) && (
            <Link className={classes.link} to={routes["LABORATOIRE.INDEX"]}>
              <ListItem disableRipple button>
                <CustomListItemIcon>
                  <LaboratoireIcon />
                </CustomListItemIcon>
                <ListItemText
                  style={{
                    color: ifCurrent(routes["LABORATOIRE.INDEX"])
                      ? CURRENT_MENU_COLOR
                      : DEFAULT_MENU_COLOR,
                  }}
                  primary="Laboratoire"
                />
              </ListItem>
            </Link>
          )}
          {checkPermission(APP_PAGES.PAGE_IMAGERIE) && (
            <Link className={classes.link} to={routes["IMAGERIE.INDEX"]}>
              <ListItem disableRipple button>
                <CustomListItemIcon>
                  <ImagerieIcon />
                </CustomListItemIcon>
                <ListItemText
                  style={{
                    color: ifCurrent(routes["IMAGERIE.INDEX"])
                      ? CURRENT_MENU_COLOR
                      : DEFAULT_MENU_COLOR,
                  }}
                  primary="Imagerie"
                />
              </ListItem>
            </Link>
          )}
          {checkPermission(APP_PAGES.PAGE_MATERNITE) && (
            <Link className={classes.link} to={routes["MATERNITE.INDEX"]}>
              <ListItem disableRipple button>
                <CustomListItemIcon>
                  <MaterniteIcon />
                </CustomListItemIcon>
                <ListItemText
                  style={{
                    color: ifCurrent("/maternite")
                      ? CURRENT_MENU_COLOR
                      : DEFAULT_MENU_COLOR,
                  }}
                  primary="Maternité"
                />
              </ListItem>
            </Link>
          )}
          {checkPermission(APP_PAGES.PAGE_LIST_CONSULTATION) && (
            <Link className={classes.link} to={routes["MENU.CONSULTATION"]}>
              <ListItem disableRipple button>
                <CustomListItemIcon>
                  <DoctorIcon />
                </CustomListItemIcon>
                <ListItemText
                  style={{
                    color: ifCurrent(routes["MENU.CONSULTATION"])
                      ? CURRENT_MENU_COLOR
                      : DEFAULT_MENU_COLOR,
                  }}
                  primary="Mes consultations"
                />
              </ListItem>
            </Link>
          )}
          {checkPermission(APP_PAGES.PAGE_ARCHIVE_CONSULTATION) && (
            <Link className={classes.link} to={routes["ARCHIVES.INDEX"]}>
              <ListItem disableRipple button>
                <CustomListItemIcon>
                  <ArchiveIcon />
                </CustomListItemIcon>
                <ListItemText
                  style={{
                    color: ifCurrent(routes["ARCHIVES.INDEX"])
                      ? CURRENT_MENU_COLOR
                      : DEFAULT_MENU_COLOR,
                  }}
                  primary="Archives"
                />
              </ListItem>
            </Link>
          )}
          {false && (
            <Link className={classes.link} to={routes["ARRETS.INDEX"]}>
              <ListItem disableRipple button>
                <CustomListItemIcon>
                  <ArretsIcon />
                </CustomListItemIcon>
                <ListItemText
                  style={{
                    color: ifCurrent(routes["ARRETS.INDEX"])
                      ? CURRENT_MENU_COLOR
                      : DEFAULT_MENU_COLOR,
                  }}
                  primary="Arrêts Maladie et accidents de travail"
                />
              </ListItem>
            </Link>
          )}
          {checkPermission(APP_PAGES.PAGE_FACTURATION) && (
            <Link className={classes.link} to={routes["FACTURATIONS.INDEX"]}>
              <ListItem disableRipple button>
                <CustomListItemIcon>
                  <FacturationIcon />
                </CustomListItemIcon>
                <ListItemText
                  style={{
                    color: ifCurrent(routes["FACTURATIONS.INDEX"])
                      ? CURRENT_MENU_COLOR
                      : DEFAULT_MENU_COLOR,
                  }}
                  primary="Facturations"
                />
              </ListItem>
            </Link>
          )}
          {checkPermission(APP_PAGES.PAGE_ACCES_CONFIG_CENTRES) && (
            <Link className={classes.link} to={routes["CONFIGURATIONS.INDEX"]}>
              <ListItem disableRipple button>
                <CustomListItemIcon>
                  <ConfigsIcon />
                </CustomListItemIcon>
                <ListItemText
                  style={{
                    color: ifCurrent(routes["CONFIGURATIONS.INDEX"])
                      ? CURRENT_MENU_COLOR
                      : DEFAULT_MENU_COLOR,
                  }}
                  primary="Accès et configuration du centre"
                />
              </ListItem>
            </Link>
          )}
          {checkPermission(APP_PAGES.PAGE_STATISTIQUES) && (
            <Link className={classes.link} to={routes["STATS.INDEX"]}>
              <ListItem disableRipple button>
                <CustomListItemIcon>
                  <StatsIcon />
                </CustomListItemIcon>
                <ListItemText
                  style={{
                    color: ifCurrent(routes["STATS.INDEX"])
                      ? CURRENT_MENU_COLOR
                      : DEFAULT_MENU_COLOR,
                  }}
                  primary="Statistiques"
                />
              </ListItem>
            </Link>
          )}

          {showAgenda && checkPermission(APP_PAGES.PAGE_AGENDA) && (
            <Link className={classes.link} to={routes["AGENDA"]}>
              <ListItem disableRipple button>
                <CustomListItemIcon>
                  <FiCalendar size={20} />
                </CustomListItemIcon>
                <ListItemText
                  style={{
                    color: ifCurrent(routes["AGENDA"])
                      ? CURRENT_MENU_COLOR
                      : DEFAULT_MENU_COLOR,
                  }}
                  primary="Agenda"
                />
              </ListItem>
            </Link>
          )}

        </List>
      </ListWrapper>
    </aside>
  );
}

export default SideBar;

const ListWrapper = styled.div`
  margin-top: 0px;
  width: 100%;
  fontsize: 13px;
  color: #ffffff;
  letter-spacing: 0.01em;
  font-weight: 500;
  line-height: 19px;
`;

const CustomListItemIcon = styled(ListItemIcon)`
  min-width: 25px !important;
  margin-right: 10px;
`;
