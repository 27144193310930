import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './styles.scss';
import { FaHome, FaChartLine, FaUserCircle } from 'react-icons/fa';

const Sidebar = props => {
  const [wrapStates, setWrapStates] = useState([]);

  React.useEffect(() => {
    setWrapStates(props?.elements?.map(el => false));
  }, [props.elements]);

  const handleUnWrap = (index) => {
    setWrapStates(wrapStates.map((el, ind) => ind === index ? !el : el));
  };

  return (
    <aside
      className={`kea-sidebar ${!!props.className ? props.className : ""}`}
    >
      <div className="kea-sidebar__brand">
        <a className="kea-sidebar__brand-link" href={"/"}>
          LOGO LOGO
        </a>
      </div>
      <div className={`kea-sidebar__menu ${!!props.menuClassName ? props.menuClassName : ""}`}>
        {props?.elements?.map((menu, ind) => {
          return (
            <div
              key={ind.toString()}
              className={`kea-sidebar__menu-item ${menu?.isActive ? 'active' : ''} ${!!menu?.className ? menu.className : ""}`}
            >
              <div
                onClick={
                  !!menu?.subs &&
                    Array.isArray(menu?.subs) ?
                    handleUnWrap.bind(this, ind)
                    :
                    (!!menu?.onClick ?
                      menu?.onClick :
                      () => { }
                    )
                }
                className="d-flex align-items-center w-100 h-auto p-3"
              >
                {!!menu?.icon &&
                  <div className="kea-sidebar__menu-item__icon">
                    {menu?.icon}
                  </div>
                }
                <div className="kea-sidebar__menu-item__label">
                  {menu?.label}
                </div>
              </div>
              <div className={`kea-sidebar__submenu ${wrapStates[ind] ? '' : 'active'}`}>
                {
                  Array.isArray(menu?.subs) &&
                  menu.subs.map((sub, index) => (
                    <div key={index.toString()} className={`kea-sidebar__submenu-item p-3 pl-5 ${!!sub?.className ? sub.className : ""}`}>
                      {sub?.label}
                    </div>
                  ))
                }
              </div>
            </div>
          );
        })}
      </div>
    </aside>
  );
};

Sidebar.propTypes = {
  menuClassName: PropTypes.string,
  elements: PropTypes.array
};

Sidebar.defaultProps = {
  className: "",
  elements: [
    {
      label: "Accueil",
      className: "",
      isActive: true,
      icon: <FaHome size={18} />,
      onClick: () => { }
    },
    {
      label: "Second",
      className: "",
      isActive: false,
      icon: <FaChartLine size={18} />,
      onClick: () => { },
      subs: [
        {
          label: "Second-First",
          className: "",
          onClick: () => { }
        },
        {
          label: "Second-Second",
          className: "",
          onClick: () => { }
        }
      ]
    },
    {
      label: "Third",
      className: "",
      isActive: false,
      icon: <FaUserCircle size={18} />,
      onClick: () => { }
    },
  ]
};

export default Sidebar;