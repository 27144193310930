import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FaTimesCircle, FaCaretDown } from "react-icons/fa";
import {
  FocusedInput,
  SelectSearchable,
  RemoteSelectCreatable,
} from "components";
import { searchMedocs } from "http/crud";

const DEFAULT_CODE = "ENABELPRODPH";

const OrdonnanceInput = (props) => {
  const { unites } = props;

  return (
    <Container {...props}>
      {props.closable && <CloseIcon onClick={props.onClose} />}
      <tr>
        <th className="text-left pl-4">Nom du produit</th>
        <th>Quantité</th>
        <th>Unité</th>
        <th>Posologie</th>
      </tr>
      <tr>
        <td
          className="text-left"
          style={{
            minWidth: props.produitNameWidth ? props.produitNameWidth : "250px",
          }}
        >
          <RemoteSelectCreatable
            valueName="code"
            labelName="libelle"
            containerClassName="border-0"
            placeholder="Choisir"
            name="medicament"
            value={
              !!props?.fields?.medicament
                ? {
                    label: props?.fields?.medicament,
                    value: props?.fields?.code,
                  }
                : null
            }
            onChange={props.handleInputChange}
            onSearch={searchMedocs}
            getNewOptionData={(text) => ({
              label: text,
              value: `${DEFAULT_CODE}_${Date.now()}`,
            })}
            noIndicators
            allowCreateWhileLoading={false}
            inputStyle={{ fontSize: "80%" }}
          />
        </td>
        <td>
          <FocusedInput
            placeholder="0"
            className="text-center"
            type="number"
            name="quantite"
            value={props?.fields?.quantite}
            onChange={props.handleInputChange}
          />
        </td>
        <td style={{ minWidth: "150px" }}>
          <SelectSearchable
            containerClassName="border-0"
            placeholder="Choisir"
            name="unite"
            value={
              unites.findIndex((el) => el?.id === props.fields.unite) !== -1
                ? {
                    value: props.fields.unite,
                    label: unites.find(
                      (element) => element?.id === props.fields.unite
                    )?.text,
                  }
                : null
            }
            onChange={props.handleInputChange}
            options={unites.map((el) => ({ label: el?.text, value: el?.id }))}
            noIndicators
            inputStyle={{ fontSize: "80%" }}
            className="text-center"
          />
        </td>
        <td>
          <FocusedInput
            placeholder="Saisir ici"
            className="text-center"
            name="posologie"
            value={props?.fields?.posologie}
            onChange={props.handleInputChange}
          />
        </td>
      </tr>
    </Container>
  );
};

OrdonnanceInput.propTypes = {
  fields: PropTypes.object,
  unites: PropTypes.array,
  handleInputChange: PropTypes.func,
  closable: PropTypes.bool,
  onClose: PropTypes.func,
};

OrdonnanceInput.defaultProps = {
  fields: {
    medicament: "",
    code: "",
    posologie: "",
    quantite: "",
    unite: "",
    modalite: "",
  },
  unites: [],
  closable: false,
  handleInputChange: () => console.log("Attach handleInputChange method"),
  onClose: () => console.log("Attach onClose method"),
};

const Container = styled.table`
  width: 100%;
  position: relative;
  border: 0px solid #e8357955;
  border-collapse: collapse;
  box-shadow: 0px 0px 3.7146px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  th {
    border-radius: 3px;
    padding: 10px 5px;
    font-weight: bold;
    color: #9f9f9f;
    text-align: center;
    font-size: 11px;
  }
  td {
    padding: 10px;
    text-align: center;
    border: 0px solid #e8357955;
    border-collapse: collapse;
    font-size: 11px;
  }
`;

const CloseIcon = styled(FaTimesCircle)`
  position: absolute;
  top: -5px;
  right: -5px;
  color: #e71a65;
  cursor: pointer;
`;

export default OrdonnanceInput;
