import React from 'react';
import "./styles.scss";
import PropTypes from 'prop-types';
import { Button } from 'components';
import { FaTimes } from 'react-icons/fa';

const SearchInputType2 = ({
  type,
  name,
  label,
  value,
  error,
  onChange,
  onSearch,
  onEraseInput,
  iconLeft,
  style,
  buttonSearchBg,
  buttonIsDisabled,
  hasStartedTyping,
  className,
  labelClassName,
  errorClassName,
  containerClassName,
  containerStyle,
  wrapperClassName,
  wrapperStyle,
  ...rest
}) => {
  return (
    <div style={{ width: "100%", ...wrapperStyle }} className="w-100 m-0 p-0 d-flex flex-column">
      {!!label && <div className={`w-100 py-2 ${labelClassName}`}>{label}</div>}
      <div style={{ ...containerStyle }} className={`kea-input type-search-2 ${containerClassName}`}>
        <div className="d-flex align-items-center w-100 h-100">
          {iconLeft &&
            <div className="kea-input__left-icon">
              {iconLeft}
            </div>
          }
          <input
            type={type}
            name={name}
            value={value}
            onChange={onChange}
            {...rest}
            className={`kea-input__field ${className} ${iconLeft ? 'ml-0' : ''}`}
          />
          <div className="kea-input__right-icon-2" style={{position:'relative'}}>
            {
                hasStartedTyping && (
                <div style={{position:"absolute", left:"-25px"}}>
                  <FaTimes size={10} onClick={onEraseInput} style={{cursor:"pointer", color:"#BEC6DA"}} />
                </div>
              )
            }
            <Button
              value="Rechercher"
              variant="gray"
              className="h-75 border-5"
              style={{ backgroundColor: buttonSearchBg || "#BEC6DA" }}
              onClick={onSearch}
              disabled={buttonIsDisabled}
            />
          </div>
        </div>
        {!!error && <div className={`kea-input__feedback ${errorClassName}`}>{error}</div>}
      </div>
    </div>
  );
};

SearchInputType2.propTypes = {
  type: PropTypes.oneOf(["text", "date", "time", "number", "password", "email", "tel"]),
  name: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func,
  iconLeft: PropTypes.any,
  labelClassName: PropTypes.string,
  errorClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  containerStyle: PropTypes.object,
  wrapperClassName: PropTypes.string,
  wrapperStyle: PropTypes.object,
  placeholder: PropTypes.string,
  onSearch: PropTypes.func,
};

SearchInputType2.defaultProps = {
  type: 'text',
  name: '',
  value: '',
  onChange: () => console.log('no onChange method attached to Input...'),
  className: "",
  labelClassName: "",
  errorClassName: "",
  containerClassName: "",
  containerStyle: {},
  wrapperClassName: "",
  wrapperStyle: {},
  onSearch: () => console.log('no onSearch method attached to Input...')
};

export default SearchInputType2;