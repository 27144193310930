import React from "react";
import "./styles.scss";
import styled from "styled-components";

interface SwitchThreeStepProps {
  state: 'left' | 'center' | 'right';
  leftText: string;
  leftColor: string;
  rightText: string;
  rightColor: string;
  centerColor: string;
  onChange: (status: 'left' | 'center' | 'right') => void;
};

const SwitchThreeStep = (props: SwitchThreeStepProps) => {
  const handleClick = status => {
    props.onChange(status);
  };

  return (
    <div className="d-flex align-items-center">
      {!!props.leftText && <div className="mr-3 font-90">{props.leftText}</div>}
      <Container
        leftColor={props.leftColor}
        centerColor={props.centerColor}
        rightColor={props.rightColor}
        className={`
      is-active-${props.state}
      ${props.className ? props.className : ''}
      `}
      >
        <div className="indicator"></div>
        <div onClick={handleClick.bind(this, 'left')} className="left-button" />
        <div onClick={handleClick.bind(this, 'center')} className="center-button" />
        <div onClick={handleClick.bind(this, 'right')} className="right-button" />
      </Container>
      {!!props.rightText && <div className="ml-3 font-90">{props.rightText}</div>}
    </div>
  );
};

const Container = styled.div`
  height: 30px;
  width: 80px;
  background-color: #E9E9EA;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1%;
  transition: all 0.2s ease-in-out;
  border-radius: 20px;
  position: relative;
  
  .indicator {
    height: 26px;
    width: 26px;
    background-color: ${({ centerColor }) => centerColor || 'white'};
    border-radius: 50%;
    box-shadow: 0px 2.75px 6.41667px rgba(0, 0, 0, 0.12);
    position: absolute;
    transition: all 0.2s ease-in-out;
  }

  &.is-active-left {
    background-color: ${({ leftColor }) => `${leftColor}33` || '#E9E9EA'};
    transition: all 0.2s ease-in-out;
  }
  &.is-active-left .indicator {
    background-color: ${({ leftColor }) => leftColor || '#2AC769'};
    transform: translateX(-25px);
    transition: all 0.2s ease-in-out;
  }

  &.is-active-right {
    background-color: ${({ rightColor }) => `${rightColor}33` || '#E9E9EA'};
    transition: all 0.2s ease-in-out;
  }
  &.is-active-right .indicator {
    background-color: ${({ rightColor }) => rightColor || '#FB4E4E'};
    transform: translateX(25px);
    transition: all 0.2s ease-in-out;
  }

  .left-button, .center-button, .right-button {
    height: 26px;
    width: 33%;
    cursor: pointer;
  }
`;

SwitchThreeStep.defaultProps = {
  onChange: () => console.log("Please attach an onChange function to [Switch]"),
  state: 'center',
  leftColor: "#2AC769",
  centerColor: "white",
  rightColor: "#FB4E4E",
};

export default SwitchThreeStep;