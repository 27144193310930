import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { makeStyles } from "@material-ui/core";
import styled from 'styled-components';
import { FaTimes } from 'react-icons/fa';
import { BandeletteFieldsType } from 'types';
import { useDebounce } from "hooks";


interface BandeletteUrinaireProps {
  fields: BandeletteFieldsType;
  visible: boolean;
  onHide: (fields: BandeletteFieldsType) => void;
  onChange: (fields: BandeletteFieldsType) => void;
  readOnly: boolean;
};

const BandeletteUrinaire = (props: BandeletteUrinaireProps) => {
  const classes = usetyles();

  const [fields, setFields] = useState({
    leucocyte: "",
    nitrite: "",
    urobilinogene: "",
    proteine: "",
    ph: "",
    sang: "",
    densite: "",
    cetone: "",
    bilirubine: "",
    glucose: "",
  });

  useEffect(() => {
    setFields({
      leucocyte: props.fields?.leucocyte || "",
      nitrite: props.fields?.nitrite || "",
      urobilinogene: props.fields?.urobilinogene || "",
      proteine: props.fields?.proteine || "",
      ph: props.fields?.ph || "",
      sang: props.fields?.sang || "",
      densite: props.fields?.densite || "",
      cetone: props.fields?.cetone || "",
      bilirubine: props.fields?.bilirubine || "",
      glucose: props.fields?.glucose || "",
    });
  }, [props.fields]);

  const handlePickTest = (name, value) => {
    setFields(old => ({
      ...old,
      [name]: old[name] !== value ? value : ""
    }));
  };
  useDebounce(() => {
    props.visible && props.onChange(fields);
  }, 100, [fields]);

  const [tests, setTests] = useState([
    {
      name: 'leucocyte',
      data: [
        { index: 'neg-', color: '#FEEBD7' },
        { index: 'trace', color: '#F5CFD9' },
        { index: '+70', color: '#FEEBD7' },
        { index: '++125', color: '#DEB0C9' },
        { index: '+++500', color: '#C583A3' },
        { index: 'WBC/μL', color: '#FFFFFF' },
      ],
    },
    {
      name: 'nitrite',
      data: [
        { index: 'neg-', color: '#FFF0CF' },
        { index: 'trace', color: '#FFE1EA' },
        { index: 'pos.', color: '#D84885', textColor: "white" },
      ],
    },
    {
      name: 'urobilinogene',
      data: [
        { index: '0.1', color: '#FFD3BD' },
        { index: '1(16)', color: '#FEC7C9' },
        { index: '2(33)', color: '#FEADA8' },
        { index: '4(66)', color: '#FF8A7B' },
        { index: '8(131)', color: '#FF7E90' },
        { index: 'mg/dl(μmol/L)', color: '#FFFFFF' },
      ],
    },
    {
      name: 'proteine',
      data: [
        { index: 'neg-', color: '#D9FCB6' },
        { index: 'trace', color: '#C0E9B4' },
        { index: '+30(0,3)', color: '#AAE5AE' },
        { index: '++100(1,0)', color: '#A1DA9B' },
        { index: '+++300(3,0)', color: '#88CC98' },
        { index: '++++1000(10)', color: '#75B084' },
        { index: 'mg/dl(g/l)', color: '#FFFFFF' },
      ],
    },
    {
      name: 'ph',
      data: [
        { index: '5', color: '#FFCF9A' },
        { index: '6', color: '#FCD27E' },
        { index: '6,5', color: '#CCB65C' },
        { index: '7', color: '#A4CC6E' },
        { index: '7,5', color: '#79AA6C' },
        { index: '8', color: '#3D7B22', textColor: "white" },
        { index: '8,5', color: '#0D6D60', textColor: "white" },
      ],
    },
    {
      name: 'sang',
      data: [
        { index: 'neg-', color: '#FEBE5F' },
        { index: 'Hemolysis trace', color: '#DED591' },
        { index: '+25', color: '#A6B688' },
        { index: '++80', color: '#87AD86' },
        { index: '+++200', color: '#297252', textColor: "white" },
        { index: 'Non Hemolysis+10', color: '#FDDB93' }, //FDDB93
        { index: '++80', color: '#FBDD92' },
        { index: 'RBC/μL', color: '#FFFFFF' },
      ],
    },
    {
      name: 'densite',
      data: [
        { index: '1,000', color: '#386061', textColor: "white" },
        { index: '1,005', color: '#577A58', textColor: "white" },
        { index: '1,010', color: '#778C6B', textColor: "white" },
        { index: '1,015', color: '#7D9E5F' },
        { index: '1,020', color: '#959E58' },
        { index: '1,025', color: '#B2AB64' },
        { index: '1,030', color: '#E3AC74' },
      ],
    },
    {
      name: 'cetone',
      data: [
        { index: 'neg-', color: '#FFE2DC' },
        { index: '±5(0,5)', color: '#F1C4C7' },
        { index: '±15(1.5)', color: '#DFA8B9' },
        { index: '++40(3,9)', color: '#AB7893', textColor: "white" },
        { index: '+++80(8)', color: '#944A6E', textColor: "white" },
        { index: '++++160(16)', color: '#640C36', textColor: "white" },
        { index: 'mg/dl(mmol/L)', color: '#FFFFFF' },
      ],
    },
    {
      name: 'bilirubine',
      data: [
        { index: 'neg-', color: '#FFEBCD' },
        // { index: '', color: '#FFFFFF' },
        { index: '+', color: '#F2DFC8' },
        { index: '++', color: '#DFC3AC' },
        { index: '+++', color: '#CCA59E' },
      ],
    },
    {
      name: 'glucose',
      data: [
        { index: 'neg-', color: '#82E3C4' },
        { index: '±100(5.5)', color: '#93E4B4' },
        { index: '+250(1.4)', color: '#84CB85' },
        { index: '++500(28)', color: '#9AA776' },
        { index: '+++1000(55)', color: '#9C7C5A' },
        { index: '++++2000(111)', color: '#825235', textColor: "white" },
        { index: 'mg/dl(mmol/L)', color: '#FFFFFF' },
      ]
    },
  ]);

  return (
    <Modal
      centered
      style={{ background: "#0005" }}
      backdrop="static" size="lg"
      contentClassName={classes.modalContainer}
      show={props.visible}
      onHide={props.onHide.bind(this, false)}
    >
      <CloseIcon onClick={props.onHide.bind(this, false)} />
      <div className="h-100 w-100 d-flex align-items-center flex-column px-3 overflow-auto">
        <Table>
          <tr className="text-center">
            <th className="bg-principal text-white text-uppercase" colSpan={2}>Bandelette urinaire</th>
          </tr>
          <tr>
            <th style={{ width: "150px" }}>Tests</th>
            <th>Résultats</th>
          </tr>
          {tests.map((el, key) => {
            return (
              <tr key={key} className={key % 2 === 0 ? 'active' : ''}>
                <td style={{ width: "150px" }}>
                  {el?.name}
                </td>
                <td>
                  <div className="w-100 d-flex align-items-center flex-wrap">
                    {el?.data?.map((valeur, ind) => {
                      return (
                        <div key={ind} className="d-flex flex-column align-items-center mr-3">
                          <ColorOption
                            className="shadow-sm font-weight-bold"
                            color={valeur?.color}
                            textColor={valeur?.textColor || "black"}
                            onClick={() => props.readOnly ? {} : handlePickTest(el?.name, valeur?.index)}
                          >
                            {fields[el?.name] === valeur?.index ? "X" : ""}
                          </ColorOption>
                          <span style={{ heigh: "40px" }}>
                            {valeur?.index || "-"}
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </td>
              </tr>
            );
          })}
        </Table>
      </div>
    </Modal>
  );
};

const usetyles = makeStyles(() => ({
  modalContainer: {
    boxShadow: "0px 8px 30px rgba(0, 0, 0, 0.12)",
    borderRadius: "5px",
    borderWidth: 0,
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    position: "relative",
    left: "-8vw",
    minWidth: "70vw",
    maxWidth: "70vw",
    minHeight: "80vh",
    maxHeight: "80vh",
    padding: "50px 15px 15px 15px",
    "@media only screen and (max-width: 768px)": {
      width: "80vw",
      marginLeft: "7vw"
    }
  },
}));

BandeletteUrinaire.defaultProps = {
  visible: false,
  onHide: () => console.log("Please attach an onHide function to [BandeletteUrinaire]"),
  onChange: () => console.log("Please attach an onChange function to [BandeletteUrinaire]"),
  readOnly: false,
};

const CloseIcon = styled(FaTimes)`
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  color: #52575C;
`;

const Table = styled.table`
  width: 100%;
  position: relative;
  border: 0px solid #E8357955;
  border-collapse: collapse;
  border-radius: 5px;
  tr {
    &.active {
      background-color: rgb(227, 230, 232, 0.4);
    }
  }
  th {
    padding: 15px;
    font-weight: bold;
  }
  td {
    padding: 15px;
  }
`;

const ColorOption = styled.div`
  background-color: ${({ color }) => color || "#777"};
  color: ${({ textColor }) => textColor || "black"};
  height: 35px;
  width: 70px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default BandeletteUrinaire;