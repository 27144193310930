import { validate } from "context/swift-inputs";
import moment from "moment";

export const verifyInitData = data => {
  if (
    Array.isArray(data?.ExamensPhysiquesTypes) &&
    Array.isArray(data?.PrisesDeDecisionTypes) &&
    Array.isArray(data?.TypeCertificat) &&
    Array.isArray(data?.UnitesTypes) &&
    Array.isArray(data?.VoieAdministrationtypes)
  ) {
    return true;
  } else {
    return false;
  }
};

interface ExamenType {
  examens: string[];
  resultat: string;
}

export const validateExamensPhysiques = examens => validate({ examens }, {
  examens: arr => {
    if (Array.isArray(arr)) {
      let isValid = true;
      for (let i = 0; i < arr.length; i++) {
        const exam: ExamenType = arr[i];
        isValid = isValid && exam?.examens?.length > 0 && !!exam?.resultat;
      }
      return {
        valid: isValid,
        error: isValid ? "" : "Les champs d'examens physiques sont incorrectements remplis"
      };
    } else {
      return {
        valid: false,
        error: "Les champs d'examens physiques sont incorrectements remplis"
      };
    }
  },
});

export const validateConstantes = (data, age = 0, pickOrOmitList=[], isPickOrOmit="pick") => {
  const rules = {
    temperature: "required|minVal:34|maxVal:42",
    poids: "required|minVal:0",
    taille: "required|minVal:0",
    pouls: value => ({
      valid: !!value ? (parseFloat(value) >= 0) : true,
      error: !!value ? (parseFloat(value) >= 0 ? "" : "Supérieur à 0") : "",
    }),
    icmDroit: "nullable",
    icmGauche: age >= 18 ? "required" : "nullable",
    freqResp: value => ({
      valid: !!value ? (parseFloat(value) >= 0 && parseFloat(value) <= 30) : true,
      error: !!value ? (parseFloat(value) >= 0 && parseFloat(value) <= 30 ? "" : "Entre 0 - 30") : "",
    }),
    satO2: value => ({
      valid: !!value ? (parseFloat(value) >= 0 && parseFloat(value) <= 100) : true,
      error: !!value ? (parseFloat(value) >= 0 && parseFloat(value) <= 100 ? "" : "Entre 0 - 100") : "",
    }),
    imc: "required|minVal:0",
    ...(age >= 0.5 && age <= 5 ? {
      perimetreBrachial: value => ({
        valid: !!value ? (parseFloat(value) >= 0) : true,
        error: !!value ? (parseFloat(value) >= 0 ? "" : "Supérieur à 0") : "",
      })
    } : {}),
  }
  return validate(data, Array.isArray(pickOrOmitList) && pickOrOmitList.length > 0 ? (isPickOrOmit === "omit" ? _.omit(rules, pickOrOmitList) :_.pick(rules, pickOrOmitList)) : rules)
};

export const validateCertificatMedical = fields => validate(fields, {
  type: (!!fields?.dateDebut || !!fields?.dateFin || !!fields?.nom_entreprise || (Array.isArray(fields?.fileUrls) && fields?.fileUrls?.length > 0)) ? "required" : "nullable",
  dateDebut: (!!fields?.type || !!fields?.dateFin || !!fields?.nom_entreprise || (Array.isArray(fields?.fileUrls) && fields?.fileUrls?.length > 0)) ? "required|date" : "nullable",
  dateFin: (!!fields?.type || !!fields?.dateDebut || !!fields?.nom_entreprise || (Array.isArray(fields?.fileUrls) && fields?.fileUrls?.length > 0)) ? value => {
    if (!!value) {
      if (!!fields?.dateDebut) {
        return {
          valid: moment(value).toDate().getTime() > moment(fields?.dateDebut).toDate().getTime(),
          error: moment(value).toDate().getTime() > moment(fields?.dateDebut).toDate().getTime() ? "" : `Doit être après le ${moment(fields?.dateDebut).format("DD MMM YYYY")}`
        };
      } else {
        return {
          valid: false,
          error: "Date de début requise",
        };
      }
    } else {
      return {
        valid: false,
        error: "Champ requis"
      };
    }
  } : "nullable",
  nom_entreprise: (!!fields?.type || !!fields?.dateDebut || !!fields?.dateFin || (Array.isArray(fields?.fileUrls) && fields?.fileUrls?.length > 0)) ? "required|string|max:150" : "nullable",
});