import React from 'react';
import Skeleton from "@material-ui/lab/Skeleton";

export default props => {

    const SkeletonConstruct = () => {
        let skeletons = [];
        for(let i = 0; i < (props?.number || 4) ; i++) {
            skeletons.push(<SkeletonElement key={i}/>)
        }
        return skeletons;
    }

    return (
        <div style={props?.containerStyle} className={props?.containerClassName || "mt-3"}>
            {
                SkeletonConstruct()
            }
        </div>
    );
};

const SkeletonElement = (props) => {
    return (<Skeleton variant="rectangular" width="100%" style={{marginBottom:"10px"}}
                  height={20}/>)
}