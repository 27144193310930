import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Input, Textarea } from 'components';
import { validate, resetObject } from 'context/swift-inputs';
import { useDebounce } from 'hooks';
import _ from 'lodash';

interface FicheHematologieProps {
  data:object;
  toBeSubmittedData:object;
  setToBeSubmittedData:() => void;
  patientData:object;
}

const FicheHematologie = (props: FicheHematologieProps) => {
  const isDisabled = (acteCode)=>{
    if(props?.subCategoryCode==="NFS"){
      return false
    }
    return !(!! props?.data && !!props?.data[acteCode])
  }

  const isNormalValueField = (value, normalValues={})=>{
    const gender = props?.patientData?.gender
    if(!_.isEmpty(normalValues) && !!value && value!==""){
      const convertedValue = parseFloat(value)
      if(!!normalValues?.[gender] && !_.isEmpty(normalValues?.[gender])){
        if(convertedValue < normalValues?.[gender]?.min || convertedValue > normalValues?.[gender]?.max){
          return false
        }
      }else{
        if(convertedValue < normalValues?.min || convertedValue > normalValues?.max){
          return false
        }
      }
    }
    return true
  }
  
  const getActeAttributes = (acteName, isCommentField=false, 
    type="number", min="0", max=null, placeholder="", 
    normalValues={})=>{
    placeholder = !!placeholder ? placeholder : "Saisir"
    const value = isCommentField ?  props?.toBeSubmittedData[acteName] : props?.toBeSubmittedData[acteName]?.value
    const isNormalValue=isNormalValueField(value, normalValues);
    return {
      disabled: !isCommentField && isDisabled(acteName),
      value: value,
      onChange:handleInputChange,
      name:acteName,
      type:type,
      min:min,
      max:max,
      placeholder: !isCommentField && isDisabled(acteName) ? "": placeholder,
      className:!isCommentField && isDisabled(acteName) ? "fiche-disabled-input" : "",
      containerClassName:`border-0 ${isNormalValue ? "bg-light" : "fiche-bg-red"}`
    }
  }

  const handleInputChange = el =>{
    if(!el){
      return
    }
    props?.setToBeSubmittedData(old=>({
      ...old,
      //[el?.target?.name]:el?.target?.name=="comment" ? el?.target?.value : parseFloat(el?.target?.value)
      [el?.target?.name]:el?.target?.name=="comment"? el?.target?.value : {value:el?.target?.value}
    }))
  }
/* interface FicheHematologieProps {
  fields: { [key: string]: string };
  onChange: (fields: { [key: string]: string }, isValid: boolean) => void;
};

const FicheHematologie = (props: FicheHematologieProps) => {
  const [fields, setFields] = useState({
    NGR: "", HB: "", HTE: "", VGM: "", CCMH: "", TCMH: "", NRETI: "", VS1H: "", NGB: "",
    PN1: "", PN2: "",
    PE1: "", PE2: "",
    PB1: "", PB2: "",
    LY1: "", LY2: "",
    MO1: "", MO2: "",
    NPLAQ: "", GEDP: "",
    COMMENT: "",
  });

  const [errors, setErrors] = useState({
    NGR: "", HB: "", HTE: "", VGM: "", CCMH: "", TCMH: "", NRETI: "", VS1H: "", NGB: "",
    PN1: "", PN2: "",
    PE1: "", PE2: "",
    PB1: "", PB2: "",
    LY1: "", LY2: "",
    MO1: "", MO2: "",
    NPLAQ: "", GEDP: "",
    COMMENT: "",
  });

  const handleInputChange = e => {
    setFields(old => ({
      ...old,
      [e?.target?.name]: e?.target?.value,
    }));
  };

  const numberFieldValidator = value => {
    if (!!value) {
      return {
        valid: parseFloat(value) >= 0,
        error: parseFloat(value) >= 0 ? "" : "Supérieur à 0",
      };
    } else {
      return {
        valid: true,
        error: "",
      };
    }
  };

  useDebounce(() => {
    const validation = validate(fields, {
      NGR: numberFieldValidator, HB: numberFieldValidator, HTE: numberFieldValidator, VGM: numberFieldValidator, CCMH: numberFieldValidator, TCMH: numberFieldValidator, NRETI: numberFieldValidator, VS1H: numberFieldValidator, NGB: numberFieldValidator,
      PN1: numberFieldValidator, PN2: numberFieldValidator,
      PE1: numberFieldValidator, PE2: numberFieldValidator,
      PB1: numberFieldValidator, PB2: numberFieldValidator,
      LY1: numberFieldValidator, LY2: numberFieldValidator,
      MO1: numberFieldValidator, MO2: numberFieldValidator,
      NPLAQ: numberFieldValidator, GEDP: "nullable",
      COMMENT: "nullable",
    });

    if (!validation.isFormValid()) {
      const { response } = validation;
      setErrors(old => ({
        ...old,
        NGR: response?.NGR?.error,
        HB: response?.HB?.error,
        HTE: response?.HTE?.error,
        VGM: response?.VGM?.error,
        CCMH: response?.CCMH?.error,
        TCMH: response?.TCMH?.error,
        NRETI: response?.NRETI?.error,
        VS1H: response?.VS1H?.error,
        NGB: response?.NGB?.error,
        PN1: response?.PN1?.error, PN2: response?.PN2?.error,
        PE1: response?.PE1?.error, PE2: response?.PE2?.error,
        PB1: response?.PB1?.error, PB2: response?.PB2?.error,
        LY1: response?.LY1?.error, LY2: response?.LY2?.error,
        MO1: response?.MO1?.error, MO2: response?.MO2?.error,
        NPLAQ: response?.NPLAQ?.error, GEDP: response?.GEDP?.error,
        COMMENT: response?.COMMENT?.error,
      }));
    } else {
      setErrors(resetObject(errors));
    }

    props.onChange(fields, validation.isFormValid() && Object.values(fields).some(el => !!el));
  }, 200, [fields]);

  useEffect(() => {
    setFields(old => ({
      ...old,
      NGR: props?.fields?.NGR || "",
      HB: props?.fields?.HB || "",
      HTE: props?.fields?.HTE || "",
      VGM: props?.fields?.VGM || "",
      CCMH: props?.fields?.CCMH || "",
      TCMH: props?.fields?.TCMH || "",
      NRETI: props?.fields?.NRETI || "",
      VS1H: props?.fields?.VS1H || "",
      NGB: props?.fields?.NGB || "",
      PN1: props?.fields?.PN1 || "", PN2: props?.fields?.PN2 || "",
      PE1: props?.fields?.PE1 || "", PE2: props?.fields?.PE2 || "",
      PB1: props?.fields?.PB1 || "", PB2: props?.fields?.PB2 || "",
      LY1: props?.fields?.LY1 || "", LY2: props?.fields?.LY2 || "",
      MO1: props?.fields?.MO1 || "", MO2: props?.fields?.MO2 || "",
      NPLAQ: props?.fields?.NPLAQ || "", GEDP: props?.fields?.GEDP || "",
      COMMENT: props?.fields?.COMMENT || ""
    }));
    setErrors(resetObject(errors));
  }, []); */

  return (
    <Table>
      <tr>
        <th colSpan={8} className="bg-principal-pills">COMPTE RENDU D'EXAMEN D'HÉMATOLOGIE</th>
      </tr>
      <tr>
        <td colSpan={4}>ERYTROCYTOMETRIE</td>
        <td colSpan={4}>LEUCOCYTOMETRIE</td>
      </tr>
      <tr>
        <th rowSpan={2}>PARAMETRES</th>
        <th rowSpan={2}>RESULTATS</th>
        <th colSpan={2}>Valeurs normales</th>
        <th rowSpan={2}>PARAMETRES</th>
        <th rowSpan={2} colSpan={2}>RESULTATS</th>
        <th rowSpan={2}>Valeurs normales</th>
      </tr>
      <tr>
        <th>Hommes</th>
        <th>Femmes</th>
      </tr>
      <tr>
        <td>NGR</td>
        <td className={`p-0`}>
          <Input {...getActeAttributes("NGR", false, "number", 0, null, "", {M:{min:4, max:6}, F:{min:3.5, max:5}})}
            
            style={{ fontSize: "12px" }}
          />
        </td>
        <td>4 à 6 T/l</td>
        <td>3,5 à 5 T/l</td>
        <td>NGB</td>
        <td colSpan={2} className={`p-0`}>
          <Input {...getActeAttributes("FD01010", false, "number", 0, null, "", {min:3, max:8})}
            
            style={{ fontSize: "12px" }}
          />
        </td>
        <td>3 à 8 G/L</td>
      </tr>
      <tr>
        <td>Hb</td>
        <td className={`p-0`}>
          <Input {...getActeAttributes("HEMOG", false, "number", 0, null, "", {M:{min:12, max:16}, F:{min:11.5, max:14}})}
            
            style={{ fontSize: "12px" }}
          />
        </td>
        <td>12 à 16 g/dl</td>
        <td>11,5 à 14 g/dl</td>
        <td>PN</td>
        <td colSpan={2} className={`p-0`}>
          <Input {...getActeAttributes("PN", false, "number", 0, null, "", {min:1.5, max:6})}
            
            style={{ fontSize: "12px" }}
          />
        </td>
        <td>1,5 - 6 G/L</td>
      </tr>
      <tr>
        <td>Hte</td>
        <td className={`p-0`}>
          <Input {...getActeAttributes("FD01003", false, "number", 0, 100, "", {M:{min:36, max:49}, F:{min:30, max:40}})}
            
            style={{ fontSize: "12px" }}
          />
        </td>
        <td>36 à 49 %</td>
        <td>30 à 40 %</td>
        <td>PE</td>
        <td colSpan={2} className={`p-0`}>
          <Input {...getActeAttributes("PE", false, "number", 0, null, "", {min:0.15, max:0.4})}
            
            style={{ fontSize: "12px" }}
          />
        </td>
        <td>0,15 - 0,4 G/L</td>
      </tr>
      <tr>
        <td>VGM</td>
        <td className={`p-0`}>
          <Input {...getActeAttributes("VGM", false, "number", 0, null, "", {M:{min:80, max:100}, F:{min:80, max:80}})}
            
            
            style={{ fontSize: "12px" }}
          />
        </td>
        <td>80 à 100 fl</td>
        <td>80 à 90 fl</td>
        <td>PB</td>
        <td colSpan={2} className={`p-0`}>
          <Input {...getActeAttributes("PB", false, "number", 0, null, "", {min:0.05, max:0.15})}
            
            style={{ fontSize: "12px" }}
          />
        </td>
        <td>0,05 - 0,15 G/L</td>
      </tr>
      <tr>
        <td>CCMH</td>
        <td className={`p-0`}>
          <Input {...getActeAttributes("CCMH", false, "number", 0, 100, "", {min:30, max:36})}
            
            
            style={{ fontSize: "12px" }}
          />
        </td>
        <td>30 à 36 %</td>
        <td>30 à 36 %</td>
        <td>LY</td>
        <td colSpan={2} className={`p-0`}>
          <Input {...getActeAttributes("LY", false, "number", 0, null, "", {min:1.5, max:4})}
            
            style={{ fontSize: "12px" }}
          />
        </td>
        <td>1,5 - 4 G/L</td>
      </tr>
      <tr>
        <td>TCMH</td>
        <td className={`p-0`}>
          <Input {...getActeAttributes("TCMH", false, "number", 0, null, "", {min:25, max:32})}
            
            
            style={{ fontSize: "12px" }}
          />
        </td>
        <td>25 à 32 pg</td>
        <td>25 à 32 pg</td>
        <td>MO</td>
        <td colSpan={2} className={`p-0`}>
        <Input {...getActeAttributes("MO", false, "number", 0, null, "", {min:0.2, max:0.8})}
            style={{ fontSize: "12px" }}
          />
        </td>
        <td>0,2 - 0,8 G/L</td>
      </tr>
      <tr>
        <td>N. Réticulocytes</td>
        <td className={`p-0`}>
          <Input {...getActeAttributes("FD01015", false, "number", 0, null, "", {min:20, max:80})}
            
            
            style={{ fontSize: "12px" }}
          />
        </td>
        <td>20 à 80 G/l</td>
        <td>20 à 80 G/l</td>
        <td>N. Plaquettes</td>
        <td colSpan={2} className={`p-0`}>
          <Input {...getActeAttributes("FD01007", false, "number", 0, null, "", {min:150, max:400})}
            
            
            style={{ fontSize: "12px" }}
          />
        </td>
        <td>150 - 400 G/L</td>
      </tr>
      <tr>
        <td>VS 1ère heure</td>
        <td className={`p-0`}>
          <Input {...getActeAttributes("FD01016", false, "number", 0, null, "", {M:{min:3, max:7}, F:{min:3, max:14}})}
            
            
            style={{ fontSize: "12px" }}
          />
        </td>
        <td>3 à 7 mm</td>
        <td>3 à 14 mm</td>
        {/*<td rowSpan={2}>GE/DP</td>
        <td rowSpan={2} colSpan={3} className={`p-0`}>
          <Textarea {...getActeAttributes("FE01013", false, null, null, null, "Saisir")}
            style={{ fontSize: "12px", height: "100%" }}
            wrapperClassName="p-0"
            
            
          />
        </td>*/}
      </tr>
      <tr>
        <td colSpan={4} className={`p-0`}>
          <Textarea {...getActeAttributes("comment", true, null, null, null, "Saisir")}
            label="Commentaire :"
            labelClassName="font-weight-bold w-100 text-left px-2 font-90"
            containerClassName="border-0 p-0"
            className="pt-0"
            
            style={{ fontSize: "12px" }}
            rows={3}
          />
        </td>
      </tr>
    </Table>
  );
};

FicheHematologie.defaultProps = {
  onChange: () => { },
};

const Table = styled.table`
  width: 100%;
  border: 0.5px solid #ABABAB;
  border-collapse: collapse;
  th {
    border: 0.5px solid #ABABAB;
    padding: 10px 5px;
    font-weight: bold;
    text-align: center;
    font-size: 12px;
  }
  td {
    padding: 10px;
    text-align: center;
    border: 0.5px solid #ABABAB;
    border-collapse: collapse;
    font-size: 12px;
  }
`;

export default FicheHematologie;