import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { HistoryComponentProps } from './index';
import _ from "lodash";
import { fetchPatientHospitalisations } from "http/crud";
import { KeaLoader, RemoteTable, Button } from "components";
import { shorten } from "helpers";


function HospiHistory(props: HistoryComponentProps) {
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [sizePerPage, setSizePerPage] = useState(8);

  const [data, setData] = useState([]);

  const handleTableChange = (type, { page, sizePerPage }) => {
    setPage(page);
    fetchData(false, page);
  };

  const [loading, setLoading] = useState(false);

  const fetchData = async (verbose = true, searchPage = page) => {
    setLoading(verbose);
    if (props?.patient?.patientId) {
      await fetchPatientHospitalisations(props?.patient?.patientId).then(({ data: response }) => {
        if (response?.success) {
          setData(Array.isArray(response?.data) ? response?.data : []);
          setPage(response?.total);
        } else {
          setData([]);
          setPage(1);
        }
      }).catch(() => {
        setData([]);
        setPage(1);
      }).finally(() => setLoading(false));
    } else {
      setTimeout(() => {
        setData([]);
        setPage(1);
        setLoading(false);
      }, 500);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const [focused, setFocused] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = el => {
    setFocused(el);
    setShowModal(true);
  };
  const handleHideModal = () => {
    setFocused(null);
    setShowModal(false);
  };

  const columns = [
    {
      dataField: "medecin",
      text: "Identité du Médecin",
      classes: "kea-table-row",
      headerClasses: "kea-table-header",
      formatter: (cell, row, rowIndex, extraData) => {
        return `${row?.consultation?.Medecin?.lastName || '-'} ${row?.consultation?.Medecin?.firstName || ''}`
      }
    },
    {
      dataField: "diagnostic",
      text: "Diagnostic de sortie",
      classes: "kea-table-row overflow-hidden",
      headerClasses: "kea-table-header",
      formatter: (cell, row, rowIndex, extraData) => {
        return (
          <div>{row?.consultation?.diagnostic?.statut === true ? (shorten(row?.consultation?.diagnostic?.donnee || "-", 100)) : <span className="text-disabled">Contenu caché</span>}</div>
        );
      }
    },
    {
      dataField: "motif",
      text: "Motif de mise en observation",
      classes: "kea-table-row overflow-hidden",
      headerClasses: "kea-table-header",
      formatter: (cell, row, rowIndex, extraData) => {
        return (
          <div>{row?.consultation?.motif?.statut === true ? (shorten(row?.consultation?.motif?.donnee || "-", 100)) : <span className="text-disabled">Contenu caché</span>}</div>
        );
      }
    },
    {
      dataField: "date",
      text: "Début et fin de mise en observation",
      classes: "kea-table-row overflow-hidden",
      headerClasses: "kea-table-header",
      formatter: (cell, row, rowIndex, extraData) => {
        return `${null || '-'}`
      }
    },
    {
      dataField: "evolution",
      text: "Evolution de la maladie",
      classes: "kea-table-row overflow-hidden",
      headerClasses: "kea-table-header",
      formatter: (cell, row, rowIndex, extraData) => {
        return `${null || '-'}`
      }
    },
    {
      dataField: "actions",
      text: "Ordonnance de sortie",
      align: "center",
      classes: "kea-table-row",
      headerClasses: "kea-table-header",
      formatter: (cell, row, rowIndex, extraData) => {
        return (
          <Button variant="blue" size="sm">
            Voir
          </Button>
        );
      },
    },
  ];

  return (
    <Container {..._.omit(props, ["patient"])}>
      {
        loading ?
          <KeaLoader />
          :
          <RemoteTable
            remote={true}
            keyField="_id"
            data={data}
            page={page}
            sizePerPage={sizePerPage}
            totalSize={total}
            columns={columns}
            onTableChange={handleTableChange}
            bordered={false}
          />
      }
    </Container>
  );
}

export default HospiHistory;

const Container = styled.div``;