import React from "react";
import PropTypes from 'prop-types';

const SwitchText = props => {
  const handleClick = () => {
    props.onChange(!props.checked);
  };

  return (
    <div
      {...props}
      className={`
        shadow-sm
        kea-switch_text
        ${props.checked ? 'active' : ''}
        kea-switch_text-${props.size ? props.size : 'md'}
        kea-switch_text-${props.variant ? props.variant : ''}
        ${props.className ? props.className : ''}
      `}
    >
      <div
        onClick={handleClick}
        className="kea-switch_text-option kea-switch_text-option_left"
      >Non</div>
      <div
        onClick={handleClick}
        className="kea-switch_text-option kea-switch_text-option_right"
      >Oui</div>
    </div>
  );
};

SwitchText.propTypes = {
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  size: PropTypes.string,
  variant: PropTypes.oneOf(["principal", "green", "infos", "red", "blue", "black", "gray"]),
};

SwitchText.defaultProps = {
  onChange: () => console.log("Please attach an onHide function to [SwitchText]"),
  checked: false,
  className: ""
};

export default SwitchText;