import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";
import { BsTrashFill } from "react-icons/bs";
import moment from 'moment';

const FicheTherapeutique = props => {
  const [rowSpan, setRowSpan] = useState(1);

  useEffect(() => {
    setRowSpan(
      !!props?.fiche?.sousProduitFiche && Array.isArray(props.fiche.sousProduitFiche) ?
        1 + props.fiche.sousProduitFiche.length
        :
        1
    );
  }, [props.fiche]);

  return (
    <Container {...props}>
      <tr>
        <td style={{ color: "#EE4305" }}>{props?.fiche?.produit}</td>
        <td>{props?.fiche?.posologie}</td>
        <td rowSpan={String(rowSpan)}>{props?.fiche?.voieAdmission}</td>
        <td rowSpan={String(rowSpan)}>{props?.fiche?.duree}</td>
        <td rowSpan={String(rowSpan)}>
          {
            Array.isArray(props?.fiche?.dateAdmissionFiches) &&
            props?.fiche?.dateAdmissionFiches?.map(date => moment(date).format("DD/MM : HH[h]mm"))?.join(", ")
          }
        </td>
        {props.deletable &&
          <td onClick={props.onDelete} className="bg-disabled text-white cursor-pointer" rowSpan={String(rowSpan)}>
            <BsTrashFill size={15} />
          </td>
        }
      </tr>
      {
        !!props?.fiche?.sousProduitFiche &&
        Array.isArray(props.fiche.sousProduitFiche) &&
        props.fiche.sousProduitFiche?.map((el, k) => (
          <tr key={k}>
            <td>{el?.produit}</td>
            <td>{el?.posologie}</td>
          </tr>
        ))
      }
    </Container>
  )
};

FicheTherapeutique.propTypes = {
  fiche: PropTypes.object,
  deletable: PropTypes.bool,
  onDelete: PropTypes.func
};

FicheTherapeutique.defaultProps = {
  fiche: null,
  deletable: false,
  onDelete: () => console.log("Please attach an onDelete method")
};

const Container = styled.table`
  width: 100%;
  border: 0px solid #EBEBEB;
  border-collapse: collapse;
  box-shadow: 0px 0px 3.7146px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  th {
    border-radius: 3px;
    padding: 10px 5px;
    font-weight: bold;
    color: #9F9F9F;
    text-align: center;
    font-size: 11px;
  }
  td {
    padding: 10px;
    text-align: center;
    border: 0.5px solid #EBEBEB;
    border-collapse: collapse;
    font-size: 11px;
  }
`;

export default FicheTherapeutique;