import React, {useState, useEffect} from "react";
import themeConfig, {themes} from "configs/themes";
import DefaultLayout from "layouts/DefaultLayout";
import VerticalLayout from "layouts/Vertical";
import FullLayout from "layouts/FullLayout";
import {toast, ToastContainer} from "react-toastify";
import {CONNECTED_TO_INTERNET_MSG, NOT_CONNECTED_TO_INTERNET_MSG} from "../../configs";

const AppContext = React.createContext();

const layouts = {
    default: DefaultLayout,
    full: FullLayout,
    vertical: VerticalLayout,
};

function AppLayout({children}) {
    const [state, setState] = useState({
        theme: themes[themeConfig.theme],
        currentTheme: themeConfig.theme,
        activeLayout: "default",
        currentLang: themeConfig.lang,
    });

    const [width, setWindowWidth] = useState(0);
    const [showAgenda, setShowAgenda] = useState(false);
    const [connectedToInternet, setConnectedToInternet] = useState(navigator.onLine);
    const [previousNetworkStatus, setPreviousNetworkStatus] = useState("")
    const NON_CONNECTED_TOAST_ID = "KEA_UNIQUE_INTERNET_OVER"
    const CONNECTED_TOAST_ID = "KEA_UNIQUE_INTERNET_ON"

    const handleNetworkStatusChange = () =>  {
        if(!connectedToInternet){
            toast.warn(NOT_CONNECTED_TO_INTERNET_MSG, {
                toastId:NON_CONNECTED_TOAST_ID,
                position: "bottom-left",
                autoClose: false,
                hideProgressBar: true,
                closeOnClick: false,
                pauseOnHover: true,
                draggable: false,
                progress: undefined,
                theme: "dark",
                closeButton:false
            })
        }
        if(connectedToInternet && /*PREVIOUS NETWORK STATE*/ previousNetworkStatus==="NOT_CONNECTED"){
            toast.dismiss(NON_CONNECTED_TOAST_ID)
            toast.success(CONNECTED_TO_INTERNET_MSG, {
                toastId:CONNECTED_TOAST_ID,
                position: "bottom-left",
                autoClose: 1500,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
            })
        }
        //setPreviousNetworkStatus(navigator.onLine ? "CONNECTED" : "NOT_CONNECTED")
        const handleStatusChange = () => {
            setConnectedToInternet(navigator.onLine);
        };

        // Listen to the online status
        window.addEventListener('online', handleStatusChange);

        // Listen to the offline status
        window.addEventListener('offline', handleStatusChange);

        // Specify how to clean up after this effect for performance improvment
        return () => {
            window.removeEventListener('online', handleStatusChange);
            window.removeEventListener('offline', handleStatusChange);
        };
    }

    useEffect(() => {
        handleNetworkStatusChange()
        setPreviousNetworkStatus(connectedToInternet ? "CONNECTED" : "NOT_CONNECTED")
    }, [connectedToInternet])

    useEffect(() => {
        updateDimensions();
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    const updateDimensions = () => {
        const width = window.innerWidth;
        setWindowWidth(width);
    };

    // Breakpoints
    const isMobile = width < 1023;
    // const isTablet = width < 767 && width > 400;
    const isTablet = width < 861 && width > 760;
    const isDesktop = width > 1023;

    return (
        <>
            <ToastContainer/>
            <AppContext.Provider
                value={{
                    ...state,
                    isMobile,
                    isTablet,
                    isDesktop,
                    showAgenda,
                    setShowAgenda,
                    connectedToInternet,
                    FullLayout: layouts["full"],
                    DefaultLayout: layouts["default"],
                    VerticalLayout: layouts["vertical"],
                    switchLayout: (layout) => setState({activeLayout: layout}),
                    changeLang: (lang) => {
                        localStorage.setItem(process.env.REACT_APP_LANG_KEY, lang);
                        setState({...state, currentLang: lang});
                        window.location.reload();
                    },
                    switchTheme: (theme) =>
                        setState({
                            ...state,
                            currentTheme: theme,
                            theme: themes[theme] || {},
                        }),
                }}
            >
                {children}
            </AppContext.Provider>
        </>
    );
}

export {AppLayout, AppContext};
export default AppLayout;
