import React, {Suspense, lazy, useState} from "react";
import ReactDOM from "react-dom";
import reportWebVitals from "./reportWebVitals";
import { StateProvider } from "context/store/store";

import { AppLayout } from "context/app-context";
import setupAxios from "http/axios";
import axios from "axios";
import fr from "assets/translations/fr.json";
import en from "assets/translations/en.json";
import { IntlProvider } from "react-intl";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "assets/animate.css";

import LogoImg from "assets/logo/logo-bg.png";
import styled from "styled-components";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

const LazyRouter = lazy(() => new Promise(resolve => setTimeout(() => resolve(import("./App")), 2000)));

const txt = { fr, en };
let currentLang = navigator.language.split(/[-_]/)[0];;

import moment from 'moment';

moment.locale('fr', {
  months: 'janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre'.split('_'),
  monthsShort: 'janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.'.split('_'),
  monthsParseExact: true,
  weekdays: 'dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi'.split('_'),
  weekdaysShort: 'dim._lun._mar._mer._jeu._ven._sam.'.split('_'),
  weekdaysMin: 'Di_Lu_Ma_Me_Je_Ve_Sa'.split('_'),
  weekdaysParseExact: true,
  longDateFormat: {
    LT: 'HH:mm',
    LTS: 'HH:mm:ss',
    L: 'DD/MM/YYYY',
    LL: 'D MMMM YYYY',
    LLL: 'D MMMM YYYY HH:mm',
    LLLL: 'dddd D MMMM YYYY HH:mm'
  },
  calendar: {
    sameDay: '[Aujourd’hui à] LT',
    nextDay: '[Demain à] LT',
    nextWeek: 'dddd [à] LT',
    lastDay: '[Hier à] LT',
    lastWeek: 'dddd [dernier à] LT',
    sameElse: 'L'
  },
  relativeTime: {
    future: 'dans %s',
    past: 'il y a %s',
    s: 'quelques secondes',
    m: 'une minute',
    mm: '%d minutes',
    h: 'une heure',
    hh: '%d heures',
    d: 'un jour',
    dd: '%d jours',
    M: 'un mois',
    MM: '%d mois',
    y: 'un an',
    yy: '%d ans'
  },
  dayOfMonthOrdinalParse: /\d{1,2}(er|e)/,
  ordinal: function (number) {
    return number + (number === 1 ? 'er' : 'e');
  },
  meridiemParse: /PD|MD/,
  isPM: function (input) {
    return input.charAt(0) === 'M';
  },
  meridiem: function (hours, minutes, isLower) {
    return hours < 12 ? 'PD' : 'MD';
  },
  week: {
    dow: 1,
    doy: 4
  }
});

setupAxios(axios);

const Loader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  img {
    animation: zoomInDown 1s;
    width: 100px;
    height: 100px;
  }
`;

Sentry.init({
  dsn: "https://9dcd4901d48e4817a1dffb46ce3d52d9@o277243.ingest.sentry.io/4504690413535232",
  integrations: [new BrowserTracing()],
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});
ReactDOM.render(
  <React.StrictMode>
    <Suspense
      fallback={
        <Loader>
          <img src={LogoImg} />
        </Loader>
      }
    >
      <IntlProvider locale={currentLang} messages={txt[currentLang]}>
        <StateProvider>
          <AppLayout>
            <LazyRouter />
          </AppLayout>
        </StateProvider>
      </IntlProvider>
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
