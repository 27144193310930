import React from 'react';
import PropTypes from 'prop-types';
import {Button} from "components"
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@material-ui/core";

const DialogConfirmation = props =>{

    const randomID = Math.random()
    return (
        <Dialog
        open={props?.open}
        onClose={props?.onClose}
        aria-labelledby={`alert-dialog-title-${randomID}`}
        aria-describedby={`alert-dialog-description-${randomID}`}
      >
        <DialogTitle id={`alert-dialog-title-${randomID}`}>
        {props?.title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id={`alert-dialog-description-${randomID}`}>
          {props?.description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props?.onClose}>{props?.noLabel}</Button>
          <Button onClick={props?.trigger} autoFocus>
          {props?.yesLabel}
          </Button>
        </DialogActions>
      </Dialog>
    )
}

DialogConfirmation.propTypes = {
    title: "Confirmation",
    description: "Etes-vous sûr de vouloir effectuer cette action ?",
    noLabel:"Non",
    yesLabel:"Oui",
  };

export default DialogConfirmation