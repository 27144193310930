import React from "react";
import { Modal, Row } from "react-bootstrap";
import { makeStyles } from "@material-ui/core";
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from 'components/button';
import { Card } from 'components/cards';
import Pill from 'components/pill';

import HeaderBgImg from "./header.png";

const OrdonnanceDetails = props => {
  const classes = usetyles();

  return (
    <Modal
      centered
      backdrop="static"
      contentClassName={classes.modalContainer}
      show={props.visible}
      onHide={props.onHide.bind(this, false)}
    >
      <div className="h-auto w-100 d-flex flex-column align-items-center p-0 overflow-hidden">
        <Header className="p-5">
          <div className="d-flex flex-column">
            <h5 className="font-weight-bold">Ordonnance</h5>
          </div>
          <Pill
            value={`Facture #${props?.ordonnance?.id || ""}`}
            style={{ backgroundColor: "#0005" }}
            className="px-4 text-white border-20"
          />
        </Header>
        <div style={{ height: "400px", overflowY: "auto" }} className="w-100 d-flex flex-column align-items-center p-4">
          {
            Array.isArray(props?.ordonnance?.medicaments) && props?.ordonnance?.medicaments?.length > 0 ?
              props?.ordonnance?.medicaments?.map((el, k) => (
                <Card key={k} className="p-4 position-relative w-100 d-flex flex-column h-auto mb-3">
                  <div className="d-flex flex-column w-100">
                    <p className="font-weight-bold mb-0" style={{ color: "#526487" }}>{el?.medicament}</p>
                  </div>
                  <div className="w-100 p-3 bg-light rounded-lg mt-3" style={{ color: "#6E7781" }}>
                    <p style={{ color: "#6E7781" }}>
                      {el?.quantite || ""} {el?.unite || ""} / {el?.posologie || "-"}
                    </p>
                  </div>
                </Card>
              ))
              :
              "Aucun médicament"
          }
        </div>
        <div className="w-100 d-flex align-items-center justify-content-center py-3 bg-white">
          <Button variant="gray" onClick={props.onHide.bind(this, false)} value="Fermer" className="px-5" />
        </div>
      </div>
    </Modal>
  );
};

const usetyles = makeStyles(() => ({
  modalContainer: {
    boxShadow: "0px 8px 30px rgba(0, 0, 0, 0.12)",
    borderRadius: "9px",
    overflow: "hidden",
    borderWidth: 0,
    backgroundColor: "#F6F8FB",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start"
  },
}));

OrdonnanceDetails.propTypes = {
  ordonnance: PropTypes.object,
  visible: PropTypes.bool,
  onHide: PropTypes.func,
};

OrdonnanceDetails.defaultProps = {
  ordonnance: null,
  visible: false,
  onHide: () => console.log("Please attach an onHide function to [OrdonnanceDetails]"),
};

const Header = styled(Row)`
  min-height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  color: white;
  background: ${({ background }) => !!background ? background : `url(${HeaderBgImg}) bottom right no-repeat`};
  background-size: cover;
`;

export default OrdonnanceDetails;