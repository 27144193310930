import React from "react";
import styled from "styled-components";
import { Button } from "components";
import moment from "moment";
import { SoinsType } from "types";

interface CardSyntheseSoinsProps {
  synthese: {
    created_at: string,
    idConsultation: string,
    idExterne: string,
    statut: true,
    type: string,
    updated_at: string,
    _id: string,
    Datas: SoinsType,
  };
  className: string;
}

const CardSyntheseSoins = (props: CardSyntheseSoinsProps) => {
  console.log("props", props.synthese);
  return (
    <Card {...props} className={`p-3 ${props.className}`}>
      <div className="d-flex align-items-center justify-content-between">
        <Button
          className="w-auto h-auto py-1 font-90"
          size="sm"
          value={moment(props?.synthese?.created_at).format("HH:mm")}
          style={{
            background:
              "linear-gradient(54.74deg, #52575C 30.03%, #526487 145.84%)",
          }}
        />
        <div className="d-flex align-items-center justify-content-end">
          <div className="d-flex align-items-center">
            <span className="font-90 text-principal">Action: </span>
            <span className="font-90 ml-2">Soins administrés</span>
          </div>
          <div className="d-flex align-items-center ml-4">
            <span className="font-90 text-principal">Exécuteur: </span>
            <span className="font-90 ml-2">
              {props?.synthese?.Datas?.Executeur?.firstName || "-"}{" "}
              {props?.synthese?.Datas?.Executeur?.lastName || ""}
            </span>
          </div>
        </div>
      </div>
      <Container className="mt-3 p-3">
        <span className="w-100 font-weight-bold font-90">
          {props?.synthese?.Datas?.fiche?.produit || "-"}
        </span>
        <div className="d-flex align-items-center w-100">
          <span className="mr-2 font-90">Dosage:</span>
          <span className="text-principal mr-2 font-90">
            {" "}
            {props?.synthese?.Datas?.fiche?.posologie || ""} -
          </span>
          <span className="mr-2 font-90">Voie d'administration:</span>
          <span className="text-principal mr-2 font-90">
            {" "}
            {props?.synthese?.Datas?.fiche?.voieAdmission || "Non spécifié"} -
          </span>
          <span className="mr-2 font-90">Temps de passage:</span>
          <span className="text-principal mr-2 font-90">
            {" "}
            {props?.synthese?.Datas?.fiche?.duree || ""}
          </span>
        </div>
        {Array.isArray(props?.synthese?.Datas?.fiche?.sous_produit) &&
          props?.synthese?.Datas?.fiche?.sous_produit?.length > 0 &&
          props?.synthese?.Datas?.fiche?.sous_produit?.map((sousProd, key) => (
            <div
              key={key}
              className={`m-0 d-flex align-items-center pl-3 mt-${
                key === 0 ? 3 : 1
              }`}
            >
              <div className="font-90 mr-2">{sousProd?.produit || "-"} - </div>
              <div className="text-principal font-90">
                {" "}
                {sousProd?.posologie || ""}
              </div>
            </div>
          ))}
        <div className="w-100 font-weight-bold font-90 mt-3">Observation :</div>
        <div className="w-100 font-90 mt-2">
          {props?.synthese?.Datas?.observation || "Aucune observation"}
        </div>
      </Container>
    </Card>
  );
};

const Card = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  min-height: 70px;
  width: 100%;
`;

const Container = styled.div`
  background-color: #e3ecf07d;
  width: 100%;
  min-height: 70px;
  border-radius: 10px;
  color: #52575c;
`;

CardSyntheseSoins.defaultProps = {
  className: "",
};

export default CardSyntheseSoins;
