import React, { useState, useEffect } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import { makeStyles } from "@material-ui/core";
import styled from 'styled-components';
import { FaTimes } from 'react-icons/fa';
import { IoPrintSharp } from 'react-icons/io5';
import { BsEye, BsFillLockFill, BsFillSquareFill } from 'react-icons/bs';
import GroupProfileImg from 'assets/images/group_consultation.png';
import UserImg from 'assets/icons/user-placeholder.png';
import {
  CardConstante,
  Card as NativeCard,
  Table,
  Pill,
  SideModal
} from 'components';
import moment from "moment";
import { useHistory } from "react-router-dom";

import { API_BASE_URL, DMP_SUFFIX, PRINT_SUFFIX } from "http/crud";
import { toast } from "react-toastify";
import { CONS_TYPES } from "configs";

const PRINT_CATEGORIES = {
  ORDONNANCE: "ORDONNANCE",
  EXAMEN_LABO: "EXAMEN_LABO",
  IMAGERIE: "IMAGERIE",
  ACTE: "ACTE"
};

interface ModalDetailsConsultationProps {
  consultation: object;
  visible: boolean;
  onHide: () => void;
  medecinName: string;
};


const ModalDetailsConsultation = (props: ModalDetailsConsultationProps) => {
  const classes = useStyles();
  const history = useHistory();

  const printRef = React.useRef(null);

  const [focused, setFocused] = useState("");

  const [consultation, setConsultation] = useState(null);
  const [constantes, setConstantes] = React.useState([]);

  const [lockMotif, setLockMotif] = useState(true);
  const [lockExamens, setLockExamens] = useState(true);
  const [lockTraitement, setLockTraitement] = useState(true);
  const [lockSynthese, setLockSynthese] = useState(true);
  const [lockDiagnostic, setLockDiagnostic] = useState(true);
  const [lockRemarques, setLockRemarques] = useState(true);

  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(100);

  const handleTableChange = (type, { page, sizePerPage }) => {
    const currentIndex = (page - 1) * sizePerPage;
    setPage(page);
  };

  const [examensPhysiques, setExamensPhysiques] = useState([]);
  const examensPhysiquesColumns = [
    {
      dataField: "examens",
      text: "Examens",
      classes: "kea-table-row",
      headerClasses: "kea-table-header font-90",
      formatter: (cell, row, rowIndex, extraData) => {
        return (
          <div className="font-90">{row?.examens?.join(" - ") || "Aucun examen"}</div>
        );
      }
    },
    {
      dataField: "date",
      text: "Date",
      classes: "kea-table-row",
      headerClasses: "kea-table-header font-90",
      formatter: (cell, row, rowIndex, extraData) => {
        return (
          <div className="font-90">{moment(row?.created_at).format("L")}</div>
        );
      }
    },
    {
      dataField: "resultat",
      text: "Résultats",
      classes: "kea-table-row font-90",
      headerClasses: "kea-table-header font-90",
    },
  ];

  const [examensLabos, setExamensLabos] = useState([]);

  const [showLaboResult, setShowLaboResult] = useState(false);

  const examensLabosColumns = [
    {
      dataField: "analyses",
      text: "Analyses",
      classes: "kea-table-row",
      headerClasses: "kea-table-header font-90",
      formatter: (cell, row, rowIndex, extraData) => {
        return (
          <div className="font-90">{row?.analyses?.map((el) => el?.libelle)?.join(" - ") || row?.libelle || "Aucune analyse"}</div>
        );
      }
    },
    {
      dataField: "date",
      text: "Date",
      classes: "kea-table-row",
      headerClasses: "kea-table-header font-90",
      formatter: (cell, row, rowIndex, extraData) => {
        return (
          <div className="font-90">{moment(row?.created_at).format("L")}</div>
        );
      }
    },
    {
      dataField: "motif",
      text: "Renseignement clinique",
      classes: "kea-table-row",
      headerClasses: "kea-table-header font-90",
      formatter: (cell, row, rowIndex, extraData) => {
        return (
          <div className="font-90">{row?.motif || "Aucun"}</div>
        );
      },
    },
    {
      dataField: "resultat",
      text: "Résulats",
      classes: "kea-table-row font-90",
      headerClasses: "kea-table-header font-90",
      formatter: (cell, row, rowIndex, extraData) => {
        if (!!row?.result_url) {
          return (
            <Pill
              variant="blue-bordered"
              className="border-5 font-90 bg-white h-auto w-auto py-2"
              size="sm"
              onClick={() => {
                const url = `${API_BASE_URL}/${DMP_SUFFIX}/${row?.result_url}`;
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('target', `_blank`);
                printRef?.current?.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
              }}
            >
              <IoPrintSharp size={18} />
            </Pill>
          );
        } else if (!!row?.resultat) {
          return (
            <Pill
              variant="blue-bordered"
              className="border-5 font-90 bg-white h-auto w-auto py-2"
              size="sm"
              onClick={() => {
                setShowLaboResult(true);
                setFocused(row?.resultat);
              }}
            >
              <BsEye size={18} />
            </Pill>
          );
        } else {
          return "-";
        }
      },
    },
  ];

  const [examensImageries, setExamensImageries] = useState([]);
  const examensImageriesColumns = [
    {
      dataField: "examens",
      text: "Examens",
      classes: "kea-table-row",
      headerClasses: "kea-table-header",
      formatter: (cell, row, rowIndex, extraData) => {
        return (
          <div className="font-90">{row?.examens?.map(el => el?.libelle)?.join(" - ") || "Aucun examen"}</div>
        );
      }
    },
    {
      dataField: "date",
      text: "Date",
      classes: "kea-table-row",
      headerClasses: "kea-table-header",
      formatter: (cell, row, rowIndex, extraData) => {
        return (
          <div className="font-90">{moment(row?.created_at).format("L")}</div>
        );
      }
    },
    {
      dataField: "motif",
      text: "Renseignement clinique",
      classes: "kea-table-row font-90",
      headerClasses: "kea-table-header",
    },
  ];

  const [ordonnance, setOrdonnance] = useState([]);

  useEffect(() => {
    setConsultation(!!props.consultation ? props.consultation : null);
    const { consultation: consul } = props;
    setConstantes([
      {
        title: "Temperature (°C)",
        color: "#0397FF",
        value: consul?.donneeConstante?.temperature || "-",
      },
      {
        title: "Poids (Kg)",
        color: "#00FF8B",
        value: consul?.donneeConstante?.poids || "-",
      },
      {
        title: "Taille (cm)",
        color: "#FFBE00",
        value: consul?.donneeConstante?.taille || "-",
      },
      {
        title: "IMC (Kg/m²)",
        color: "#224ec7",
        value: consul?.donneeConstante?.imc || "-",
      },
      {
        title: "Pouls (Pls/min)",
        color: "#FFBE00",
        value: consul?.donneeConstante?.pouls || "-",
      },
      {
        title: "Pression Artérielle D (mmHg)",
        color: "#E83578",
        value: consul?.donneeConstante?.icmDroit || "-",
      },
      {
        title: "Pression artérielle G (mmHg)",
        color: "#FF663A",
        value: consul?.donneeConstante?.icmGauche || "-",
      },
      {
        title: "Fréquence respiratoire (Cycles/minute)",
        color: "#B02FC2",
        value: consul?.donneeConstante?.freqResp || "-",
      },
      {
        title: "Saturation en 02 (en %)",
        color: "#703CC6",
        value: consul?.donneeConstante?.satO2 || "-",
      },
      {
        title: "Périmètre brachial (cm)",
        color: "#234f2f",
        value: consul?.donneeConstante?.perimetreBrachial || "-",
      },
      ...(consul?.type?.code === CONS_TYPES.GYNECO ? [
        {
          title: "Leucocyte",
          color: "#FEEBD7",
          value: consul?.donneeConstante?.bandelette?.leucocyte || "-",
        },
        {
          title: "Nitrite",
          color: "#D84885",
          value: consul?.donneeConstante?.bandelette?.nitrite || "-",
        },
        {
          title: "Urobilinogene",
          color: "#FEC7C9",
          value: consul?.donneeConstante?.bandelette?.urobilinogene || "-",
        },
        {
          title: "Proteine",
          color: "#AAE5AE",
          value: consul?.donneeConstante?.bandelette?.proteine || "-",
        },
        {
          title: "pH",
          color: "#FFCF9A",
          value: consul?.donneeConstante?.bandelette?.ph || "-",
        },
        {
          title: "Sang",
          color: "#FBDD92",
          value: consul?.donneeConstante?.bandelette?.sang || "-",
        },
        {
          title: "Densite",
          color: "#386061",
          value: consul?.donneeConstante?.bandelette?.densite || "-",
        },
        {
          title: "Cetone",
          color: "#DFA8B9",
          value: consul?.donneeConstante?.bandelette?.cetone || "-",
        },
        {
          title: "Bilirubine",
          color: "#CCA59E",
          value: consul?.donneeConstante?.bandelette?.bilirubine || "-",
        },
        {
          title: "Glucose",
          color: "#9AA776",
          value: consul?.donneeConstante?.bandelette?.glucose || "-",
        },
      ] : []),
    ]);
    setLockMotif(consul?.motif?.statut === true ? false : true);
    setLockExamens(consul?.examenSomatique?.statut === true ? false : true);
    setLockTraitement(false);
    setLockSynthese(consul?.syntheseHypothese?.statut === true ? false : true);
    setLockDiagnostic(consul?.diagnostic?.statut === true ? false : true);
    setLockRemarques(consul?.remarque?.statut === true ? false : true);
    setExamensPhysiques(
      !!consul?.examenPhysique &&
        Array.isArray(consul.examenPhysique) ?
        consul.examenPhysique
        :
        []
    );
    setExamensLabos(
      !!consul?.examenLabo &&
        Array.isArray(consul?.examenLabo) ?
        consul.examenLabo
        :
        []
    );
    setExamensImageries(
      !!consul?.examenImagerie &&
        Array.isArray(consul.examenImagerie) ?
        consul.examenImagerie
        :
        []
    );
    setOrdonnance(
      !!consul?.ordonnanceMedicaments &&
        Array.isArray(consul?.ordonnanceMedicaments) ?
        consul.ordonnanceMedicaments :
        []
    );
  }, [props.consultation]);

  const ordonnanceRef = React.useRef(null);
  const handlePrintOrdonnance = () => {
    if (Array.isArray(ordonnance) && ordonnance.length > 0) {
      ordonnanceRef.current.click();
    } else {
      toast.warn("Rien à imprimer !");
    }
  };

  const laboRef = React.useRef(null);
  const handlePrintLaboratoire = () => {
    if (Array.isArray(examensLabos) && examensLabos.length > 0) {
      laboRef.current.click();
    } else {
      toast.warn("Rien à imprimer !");
    }
  };

  const imagerieRef = React.useRef(null);
  const handlePrintImagerie = () => {
    if (Array.isArray(examensImageries) && examensImageries.length > 0) {
      imagerieRef.current.click();
    } else {
      toast.warn("Rien à imprimer !");
    }
  };

  return (
    <Modal
      centered
      backdrop="static"
      contentClassName={classes.modalContainer}
      style={{ background: "#0008" }}
      show={props.visible}
      onHide={props.onHide}
    >
      <a
        ref={ordonnanceRef}
        target="_blank"
        href={`${API_BASE_URL}/${PRINT_SUFFIX}/api/imprime/${consultation?.PrintType}/${consultation?._id}/${PRINT_CATEGORIES.ORDONNANCE}`}
        style={{ display: "hidden" }}
      ></a>
      <a
        ref={laboRef}
        target="_blank"
        href={`${API_BASE_URL}/${PRINT_SUFFIX}/api/imprime/${consultation?.PrintType}/${consultation?._id}/${PRINT_CATEGORIES.EXAMEN_LABO}`}
        style={{ display: "hidden" }}
      ></a>
      <a
        ref={imagerieRef}
        target="_blank"
        href={`${API_BASE_URL}/${PRINT_SUFFIX}/api/imprime/${consultation?.PrintType}/${consultation?._id}/${PRINT_CATEGORIES.IMAGERIE}`}
        style={{ display: "hidden" }}
      ></a>
      <SideModal
        visible={showLaboResult}
        onHide={() => {
          setShowLaboResult(false);
          setFocused(null);
        }}
      >
        <div dangerouslySetInnerHTML={{ __html: focused || "" }} />
      </SideModal>
      <Card className="m-0" ref={printRef}>
        <CloseIcon onClick={props.onHide.bind(this, false)} />
        <div className="d-flex align-items-center w-100 pb-2 mb-3" style={{ borderBottom: "0.5px solid #E71A6555" }}>
          <BsFillSquareFill size={5} className="text-principal" />
          <span className="ml-3 d-flex align-items-center text-principal">
            Visualisation de la consultation
          </span>
        </div>
        {
          ![0, false, "0", undefined, null].includes(consultation?.consultationLocked) ?
            <HiddenAll>
              <BsFillLockFill size={40} className="mb-4" />
              Contenu caché par le patient
            </HiddenAll>
            :
            <>
              <Row className="p-0 m-0 mx-auto">
                <Col md={3} xs={12} lg={3} className="m-0 p-0">
                  <div className="d-flex align-items-center">
                    <ProfileContainer>
                      <img src={consultation?.Medecin?.photo ? consultation?.Medecin.photo : UserImg} />
                    </ProfileContainer>
                    <span className="font-weight-bold ml-3 font-90">Dr {consultation?.medecin_full_name || "-"}</span>
                  </div>
                  <div className="mt-4 d-flex flex-column">
                    <UnderlineTitle color="#E83578" className="mb-3">
                      Date de consultation
                      <div></div>
                    </UnderlineTitle>
                    <span className="text-principal font-weight-bold font-90">{moment(consultation?.created_at).format("LL à HH:mm")} </span>
                  </div>
                </Col>
                <Col md={9} xs={12} lg={9} className="pr-0">
                  <UnderlineTitle color="#2AAF97" style={{ color: "#2AAF97" }} className="mb-2">
                    Les paramètres de constante
                    <div></div>
                  </UnderlineTitle>
                  <div className="d-flex p-2 w-100" style={{ overflowX: "auto" }}>
                    {constantes &&
                      constantes.length > 0 &&
                      constantes.map((c, k) => {
                        return (
                          <CardConstante
                            key={k}
                            label={c.title}
                            value={c.value}
                            color={c.color}
                            containerClassName="mr-3"
                            labelClassName="font-80"
                          />
                        );
                      })}
                  </div>
                </Col>
              </Row>
              <Row className="m-0 mt-4 mx-auto">
                <Col md={9} xs={12} lg={9} className="p-0 m-0">
                  <Row className="mx-auto p-0 w-100">
                    <Col md={4} xs={12} lg={4} className="m-0 p-0">
                      <div className="d-flex align-items-center p-1">
                        <UnderlineTitle color="#E71A65">
                          Motif de consultation
                          <div></div>
                        </UnderlineTitle>
                      </div>
                      <HiddenCard className="p-2 rounded-2 mt-2">
                        {
                          lockMotif ?
                            <div className="hide">
                              <BsFillLockFill size={30} />
                            </div>
                            :
                            <div className="content">
                              {consultation?.motif?.donnee || "Aucun motif"}
                            </div>
                        }
                      </HiddenCard>
                    </Col>
                    <Col md={4} xs={12} lg={4}>
                      <div className="d-flex align-items-center p-1">
                        <UnderlineTitle color="#E71A65">
                          Examens somatiques
                          <div></div>
                        </UnderlineTitle>
                      </div>
                      <HiddenCard className="p-2 rounded-2 mt-2">
                        {
                          lockExamens ?
                            <div className="hide">
                              <BsFillLockFill size={30} />
                            </div>
                            :
                            <div className="content">
                              {consultation?.examenSomatique?.donnee || "Aucune donnée"}
                            </div>
                        }
                      </HiddenCard>
                    </Col>
                    <Col md={4} xs={12} lg={4} className="m-0 p-0">
                      <div className="d-flex align-items-center p-1">
                        <UnderlineTitle color="#E71A65">
                          Traitement Antérieur et Déparasitage
                          <div></div>
                        </UnderlineTitle>
                      </div>
                      <HiddenCard className="p-2 rounded-2 mt-2">
                        {
                          lockTraitement ?
                            <div className="hide">
                              <BsFillLockFill size={30} />
                            </div>
                            :
                            <div className="content">
                              {consultation?.traitementAnterieur || "Aucune donnée"}
                            </div>
                        }
                      </HiddenCard>
                    </Col>
                  </Row>
                  <Row className="mt-4 mx-0 w-100">
                    <Col md={6} xs={12} lg={6} className="m-0 p-0">
                      <div style={{ border: "0.81px solid #7B61FF55" }} className="w-100 p-2 border-5 position-relative">
                        <span className="bg-white px-2 position-absolute font-90" style={{ color: "#7B61FF", top: -10 }}>Examens physiques</span>
                        <div className="w-100 d-flex align-items-center justify-content-end mb-2">
                          <PrintButton className="font-90">
                            <IoPrintSharp size={20} className="mr-2" />
                            Imprimer
                          </PrintButton>
                        </div>
                        <div style={{ height: "400px", overflow: "auto" }}>
                          <Table
                            data={examensPhysiques}
                            page={page}
                            sizePerPage={sizePerPage}
                            totalSize={examensPhysiques.length}
                            columns={examensPhysiquesColumns}
                            noPagination
                            bordered={false}
                            onTableChange={handleTableChange}
                            headerClasses={classes.physiquesTableHeader}
                            rowStyle={{
                              boxShadow: "0px 3.95281px 3.95281px rgba(0, 0, 0, 0.05)",
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col md={6} xs={12} lg={6} className="d-flex flex-column">
                      <div className="w-100">
                        <div className="d-flex align-items-center p-1">
                          <UnderlineTitle color="#E71A65">
                            Synthèse et hypothèses diagnostique
                            <div></div>
                          </UnderlineTitle>
                        </div>
                        <HiddenCard className="p-2 rounded-2 mt-2">
                          {
                            lockSynthese ?
                              <div className="hide">
                                <BsFillLockFill size={30} />
                              </div>
                              :
                              <div className="content">
                                {consultation?.syntheseHypothese?.donnee || "Aucune donnée"}
                              </div>
                          }
                        </HiddenCard>
                      </div>
                      <div className="w-100 mt-4">
                        <div className="d-flex align-items-center p-1">
                          <UnderlineTitle color="#E71A65">
                            Diagnostics
                            <div></div>
                          </UnderlineTitle>
                        </div>
                        <HiddenCard className="p-2 rounded-2 mt-2">
                          {
                            lockDiagnostic ?
                              <div className="hide">
                                <BsFillLockFill size={30} />
                              </div>
                              :
                              <div className="content">
                                <div>
                                  {
                                    consultation?.classDiagnostic &&
                                    Array.isArray(consultation?.classDiagnostic) &&
                                    consultation.classDiagnostic.map(el => el?.libelle)?.join(" - ")
                                  }
                                </div>
                                <div>
                                  {consultation?.diagnostic?.donnee || "Aucune donnée"}
                                </div>
                              </div>
                          }
                        </HiddenCard>
                      </div>
                      <div className="w-100 mt-4">
                        <div className="d-flex align-items-center p-1">
                          <UnderlineTitle color="#E71A65">
                            Remarques particulières
                            <div></div>
                          </UnderlineTitle>
                        </div>
                        <HiddenCard className="p-2 rounded-2 mt-2">
                          {
                            lockRemarques ?
                              <div className="hide">
                                <BsFillLockFill size={30} />
                              </div>
                              :
                              <div className="content">
                                {consultation?.remarque?.donnee || "Aucune remarque"}
                              </div>
                          }
                        </HiddenCard>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col md={3} xs={12} lg={3} className="pr-0">
                  <Card
                    style={{
                      background: `linear-gradient(180deg, #E71A65 0%, #AD2154 100%)`,
                      height: "100%",
                      width: "100%",
                      borderRadius: "5px",
                      border: "0px",
                      fontSize: "13px"
                    }}
                    className="p-4"
                  >
                    <UnderlineTitle className="font-weight-bold text-white" color="white">
                      Amanèse
                      <div></div>
                    </UnderlineTitle>
                    <p className="mt-4 line-height-1-5 text-white">
                      {consultation?.anamnese || "Aucune donnée"}
                    </p>
                  </Card>
                </Col>
              </Row>
              <Row className="m-0 mt-4 mx-auto">
                <Col md={6} xs={12} lg={4} className="m-0 pl-0">
                  <div style={{ border: "0.81px solid #36ABFF55" }} className="w-100 p-2 border-5 position-relative">
                    <span className="bg-white px-2 position-absolute font-90" style={{ color: "#36ABFF", top: -10 }}>Examens de laboratoire</span>
                    <div className="w-100 d-flex align-items-center justify-content-end mb-2">
                      <PrintButton onClick={handlePrintLaboratoire} color="#36ABFF" className="font-90">
                        <IoPrintSharp size={20} className="mr-2" />
                        Imprimer
                      </PrintButton>
                    </div>
                    <div style={{ height: "400px", overflow: "auto" }}>
                      <Table
                        data={examensLabos}
                        page={page}
                        sizePerPage={sizePerPage}
                        totalSize={examensLabos.length}
                        columns={examensLabosColumns}
                        onTableChange={handleTableChange}
                        noPagination
                        bordered={false}
                        headerClasses={classes.labosTableHeader}
                        rowStyle={{
                          boxShadow: "0px 3.95281px 3.95281px rgba(0, 0, 0, 0.05)",
                        }}
                      />
                    </div>
                  </div>
                </Col>
                <Col md={6} xs={12} lg={4} className="m-0 pl-0 pr-0">
                  <div style={{ border: "0.81px solid #6AAAAA55" }} className="w-100 p-2 border-5 position-relative">
                    <span className="bg-white px-2 position-absolute font-90" style={{ color: "#6AAAAA", top: -10 }}>Examens d’imagerie</span>
                    <div className="w-100 d-flex align-items-center justify-content-end mb-2">
                      <PrintButton onClick={handlePrintImagerie} color="#6AAAAA" className="font-90">
                        <IoPrintSharp size={20} className="mr-2" />
                        Imprimer
                      </PrintButton>
                    </div>
                    <div style={{ height: "400px", overflow: "auto" }}>
                      <Table
                        data={examensImageries}
                        page={page}
                        sizePerPage={sizePerPage}
                        totalSize={examensImageries.length}
                        columns={examensImageriesColumns}
                        onTableChange={handleTableChange}
                        noPagination
                        bordered={false}
                        headerClasses={classes.imageriesTableHeader}
                        rowStyle={{
                          boxShadow: "0px 3.95281px 3.95281px rgba(0, 0, 0, 0.05)",
                        }}
                      />
                    </div>
                  </div>
                </Col>
                <Col md={6} xs={12} lg={4} className="m-0 pr-0">
                  <div style={{ border: "0.81px solid #00717E55" }} className="w-100 p-2 border-5 position-relative">
                    <span className="bg-white px-2 position-absolute font-90" style={{ color: "#00717E", top: -10 }}>Ordonnance</span>
                    <div className="w-100 d-flex align-items-center justify-content-end mb-2">
                      <PrintButton onClick={handlePrintOrdonnance} color="#6AAAAA" className="font-90">
                        <IoPrintSharp size={20} className="mr-2" />
                        Imprimer
                      </PrintButton>
                    </div>
                    <div className="d-flex flex-column align-items-center p-2" style={{ height: "400px", overflow: "auto" }}>
                      {
                        Array.isArray(ordonnance) && ordonnance.length > 0 ?
                          ordonnance.map((el, k) => (
                            <NativeCard key={k} className="p-2 position-relative w-100 d-flex flex-column h-auto mb-3 shadow-lg">
                              <div className="d-flex flex-column w-100">
                                <p className="font-weight-bold mb-0 font-90" style={{ color: "#526487" }}>{el?.medicament}</p>
                              </div>
                              <div className="w-100 p-3 bg-light rounded-lg mt-3" style={{ color: "#6E7781" }}>
                                <p className="mb-0 font-90">
                                  {el?.quantite || ""} {el?.unite || ""} / {el?.posologie || "-"}
                                </p>
                              </div>
                            </NativeCard>
                          ))
                          :
                          <div className="w-100 text-center pt-5">Aucune donnée</div>
                      }
                    </div>
                  </div>
                </Col>
                {consultation?.type?.code === CONS_TYPES.GYNECO && <Col md={12} xs={12} lg={12} className="m-0 p-0 mt-3">
                  <div style={{ border: "0.81px solid #FF925455" }} className="w-100 p-2 border-5 position-relative">
                    <span className="bg-white px-2 position-absolute font-90" style={{ color: "#FF9254", top: -10 }}>Examens cliniques</span>
                    <Row className="w-100 p-0 m-0 mx-auto">
                      <Col md={3} lg={3} xs={12} className="p-2">
                        <Content className="bg-white border-10 d-flex flex-column h-100">
                          <div className="title">
                            État général
                          </div>
                          <div className="content">
                            <div className="border-5 p-3 bg-light">
                              {consultation?.examenClinique?.etatGeneral || "Aucune donnée"}
                            </div>
                          </div>
                        </Content>
                      </Col>
                      <Col md={3} lg={3} xs={12} className="p-2">
                        <Content className="bg-white border-10 d-flex flex-column h-100">
                          <div className="title">
                            Abdomen
                          </div>
                          <div className="content">
                            <UnderlineTitle color="#E71A65">
                              Seins
                              <div></div>
                            </UnderlineTitle>
                            <div className="border-5 p-3 bg-light mt-2 mb-3">
                              {consultation?.examenClinique?.abdomen?.seins || "Aucune donnée"}
                            </div>
                            <UnderlineTitle color="#E71A65">
                              Circatrice
                              <div></div>
                            </UnderlineTitle>
                            <div className="border-5 p-3 bg-light mt-2 mb-3">
                              {consultation?.examenClinique?.abdomen?.cicatrice || "Aucune donnée"}
                            </div>
                            <UnderlineTitle color="#E71A65">
                              Hauteur utérine
                              <div></div>
                            </UnderlineTitle>
                            <div className="border-5 p-3 bg-light mt-2 mb-3">
                              {consultation?.examenClinique?.abdomen?.hu || "Aucune donnée"}
                            </div>
                            <UnderlineTitle color="#E71A65">
                              Utérus
                              <div></div>
                            </UnderlineTitle>
                            <div className="border-5 p-3 bg-light mt-2 mb-3">
                              {consultation?.examenClinique?.abdomen?.uterus || "Aucune donnée"}
                            </div>
                          </div>
                        </Content>
                      </Col>
                      <Col md={3} lg={3} xs={12} className="p-2">
                        <Content className="bg-white border-10 d-flex flex-column h-100">
                          <div className="title">
                            Touché vaginal
                          </div>
                          <div className="content">
                            <UnderlineTitle color="#E71A65">
                              Col
                              <div></div>
                            </UnderlineTitle>
                            <div className="border-5 p-3 bg-light mt-2 mb-3">
                              {consultation?.examenClinique?.toucheVaginal?.col || "Aucune donnée"}
                            </div>
                            <UnderlineTitle color="#E71A65">
                              Doigtier
                              <div></div>
                            </UnderlineTitle>
                            <div className="border-5 p-3 bg-light mt-2 mb-3">
                              {consultation?.examenClinique?.toucheVaginal?.doigtier || "Aucune donnée"}
                            </div>
                          </div>
                        </Content>
                      </Col>
                      <Col md={3} lg={3} xs={12} className="p-2">
                        <Content className="bg-white border-10 d-flex flex-column h-100">
                          <div className="title">
                            Vulve et Spéculum
                          </div>
                          <div className="content">
                            <UnderlineTitle color="#E71A65">
                              Vulve
                              <div></div>
                            </UnderlineTitle>
                            <div className="border-5 p-3 bg-light mt-2 mb-3">
                              {consultation?.examenClinique?.vulveSpeculum?.vulve || "Aucune donnée"}
                            </div>
                            <UnderlineTitle color="#E71A65">
                              Spéculum
                              <div></div>
                            </UnderlineTitle>
                            <div className="border-5 p-3 bg-light mt-2 mb-3">
                              {consultation?.examenClinique?.vulveSpeculum?.speculum || "Aucune donnée"}
                            </div>
                          </div>
                        </Content>
                      </Col>
                    </Row>
                  </div>
                </Col>}
              </Row>
            </>
        }
      </Card>
    </Modal>
  );
};

const useStyles = makeStyles((theme) => ({
  modalContainer: {
    boxShadow: "0px 8px 30px rgba(0, 0, 0, 0.12)",
    borderRadius: "5px",
    borderWidth: 0,
    backgroundColor: "#E8E8E8",
    minWidth: "90vw",
    position: "relative",
    left: "-28vw",
    maxHeight: "100vh",
    overflow: "hidden",
  },
  physiquesTableHeader: {
    opacity: 1,
    alignItems: "center",
    backgroundColor: "#7B61FF",
    color: "white",
  },
  labosTableHeader: {
    opacity: 1,
    alignItems: "center",
    backgroundColor: "#36ABFF",
    color: "white",
  },
  imageriesTableHeader: {
    opacity: 1,
    alignItems: "center",
    backgroundColor: "#6AAAAA",
    color: "white",
  },
}));

const ProfileContainer = styled.div`
  height: 75px;
  width: 75px;
  background-size: contain;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url(${GroupProfileImg}) no-repeat center;
  img {
    height: 47px;
    border-radius: 100px;
    object-fit: contain;
  }
`;

const CloseIcon = styled(FaTimes)`
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  color: #52575C;
`;

const UnderlineTitle = styled.div`
  font-size: 90%;
  div {
    height: 2px;
    border-radius: 10px;
    width: 60px;
    background-color: ${({ color }) => !!color ? color : "black"};
    margin: 3px 0 0 0;
  }
`;

const Card = styled.div`
  width: 100%;
  height: 90vh;
  overflow: auto;
  padding: 40px;
  background-color: white;
  position: relative;
`;

const HiddenAll = styled.div`
  color: #999999;
  background-color: #EEEEEE;
  height: 95%;
  border-radius: 5px;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const HiddenCard = styled.div`
  background-color: #E5E5E5;
  height: 90px;
  .hide {
    background-color: #C4C4C4;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #52575C;
  }
  .content {
    height: 100%;
    width: 100%;
    font-size: 90%;
    overflow-y: auto;
    overflow-x: hidden;
  }
`;

const PrintButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  color: ${({ color }) => !!color ? color : '#7B61FF'};
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.04);
  .title {
    background: ${({ color }) => color || "#FF9254"};
    color: white;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0 15px;
    border-radius: 5px;
    font-weight: bold;
  }
  .content {
    padding: 15px;
    width: 100%;
    min-height: 200px;
  }
`;

ModalDetailsConsultation.defaultProps = {
  consultation: null,
  visible: false,
  onHide: () => console.log("Please attach an onHide function to [ModalDetailsConsultation]"),
  medecinName: "",
};

export default ModalDetailsConsultation;