export const ignoreAccents = (str: string) => {
  str = str.replace(/[ÀÁÂÃÄÅ]/, "A");
  str = str.replace(/[ÈÉÊË]/, "E");
  str = str.replace(/[Ç]/, "C");
  return str;
};

export const parseJwt = (token) => {
  try {
    return JSON.parse(atob(String(token).split('.')[1]));
  } catch (e) {
    return null;
  }
};

export const toFactureNumber = (nbr = 0, digits = 5) => String(nbr).padStart(digits, '0');