import { searchCommunes, searchQuartiers } from 'http/crud';
import { useEffect, useState } from 'react';

interface UserAddressType {
  communes: {
    id: number;
    name: string;
  }[];
  quartiers: {
    name: string;
  }[];
  fetchQuartiers: (communeId: number | string) => void;
  refreshing: boolean;
};

export const useAddress = (commune: string = ''): UserAddressType => {
  const [loading, setLoading] = useState(false);

  const [communes, setCommunes] = useState([]);

  const [quartiers, setQuartiers] = useState([]);

  const handleFetchCommunes = async () => {
    setLoading(true);
    await searchCommunes().then(({ data: response }) => {
      if (response?.success) {
        setCommunes(Array.isArray(response?.data) ? response?.data : []);
        !!commune && handleFetchQuartiers(response?.data?.find(el => String(el?.name) === commune)?.id);
      } else {
        throw new Error(response?.message);
      }
    }).catch(err => {
      setCommunes([]);
      setQuartiers([]);
    }).finally(() => setLoading(false));
  };

  const handleFetchQuartiers = async (communeId) => {
    if (!!communeId) {
      setLoading(true);
      await searchQuartiers(communeId).then(({ data: response }) => {
        if (response?.success) {
          setQuartiers(Array.isArray(response?.data) ? response?.data : []);
        } else {
          throw new Error(response?.message);
        }
      }).catch(err => {
        setQuartiers([]);
      }).finally(() => setLoading(false));
    } else {
      setQuartiers([]);
    }
  };

  useEffect(() => {
    handleFetchCommunes();
  }, [commune]);

  return {
    communes,
    quartiers,
    fetchQuartiers: handleFetchQuartiers,
    refreshing: loading
  };
};