import React from "react";
import { Button, Spinner } from "react-bootstrap";
import styled from "styled-components";
import PropTypes from 'prop-types';

function ButtonSubmit(props) {
  return (
    <Container onClick={props.onClick} className="mt-4">
        Soumettre
      {props.isLoading && <Spinner className="ml-2" animation="border" variant="info" />}
    </Container>
  );
}

export default ButtonSubmit;

ButtonSubmit.propTypes = {
  onClick: PropTypes.func,
  isLoading: PropTypes.bool
}

const Container = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #008489;
  border: none;
  outline: none;
  &:focus {
    background-color: #008489;
  };
  &:active {
    background-color: #008489;
  };
  &:hover {
    background-color: #008489;
  }
`;
