import React from 'react';
import PropTypes from 'prop-types';
import { FaUserCircle, FaPhoneAlt } from 'react-icons/fa';

const CardInfo = props => {
  return (
    <div className={`kea-card d-flex align-items-center justify-content-between ${props?.className}`}>
      <div className="d-flex align-items-center">
        {!!props?.picture ?
          <img src={props.picture} className="kea-card-infos__img" /> :
          <FaUserCircle color="#ddd" className="kea-card-infos__icon" />
        }
        <div className="d-flex flex-column">
          <span className="font-weight-bold text-principal">{props?.fullname}</span>
          <span className="mt-2"><FaPhoneAlt className="text-principal mr-2" /> {props?.phone}</span>
        </div>
      </div>
      <div onClick={props?.onClick} className="kea-card-infos__btn">Détails patient</div>
    </div>
  );
};

CardInfo.propTypes = {
  className: PropTypes.string,
  picture: PropTypes.any,
  fullname: PropTypes.string,
  phone: PropTypes.string,
  onClick: PropTypes.func
};

CardInfo.defaultProps = {
  picture: "",
  fullname: "USER User",
  phone: "229 XX XX XX XX",
  onClick: () => console.log("Attach an onClick method")
};

export default CardInfo;