import React from 'react';
import "./styles.scss";
import PropTypes from 'prop-types';
import AsyncSelect from "react-select/async";
import _ from 'lodash';

const RemoteSelect = ({
  isDepthOne,
  isMulti,
  name,
  labelName,
  labelFormatter,
  valueName,
  valueFormatter,
  onSearch,
  type,
  label,
  error,
  value,
  onChange,
  className,
  labelClassName,
  errorClassName,
  containerClassName,
  wrapperClassName,
  wrapperStyle,
  noIndicators,
  style,
  inputStyle,
  optionStyle,
  placeholder,
  defaultValue,
  ...rest
}) => {

  const handleChange = (el, ...rest) => {
    if (isMulti) {
      onChange({ target: { value: Array.isArray(el) ? el?.map(item => item?.value) : [], name } }, el);
    } else {
      onChange({ target: { value: el?.value, name } }, el);
    }
  };

  const loadOptions = (search) => {
    return new Promise(async resolve => {
      if (typeof onSearch === 'function') {
        const response = await onSearch(search);

        if (isDepthOne) {
          resolve(
            Array.isArray(response?.data) ?
              response?.data?.map(el => ({
                label: !!labelFormatter && typeof labelFormatter === 'function' ? labelFormatter(el) : (el[labelName] || ""),
                value: !!valueFormatter && typeof valueFormatter === 'function' ? valueFormatter(el) : (el[valueName] || ""),
                data: el,
              }))
              :
              []
              );
            } else {
              resolve(
                Array.isArray(response?.data?.data) ?
                response?.data?.data?.map(el => ({
                  label: !!labelFormatter && typeof labelFormatter === 'function' ? labelFormatter(el) : (el[labelName] || ""),
                  value: !!valueFormatter && typeof valueFormatter === 'function' ? valueFormatter(el) : (el[valueName] || ""),
                  data: el,
              }))
              :
              []
          );
        }
      } else {
        resolve([]);
      }
    });
  };

  return (
    <div style={{ width: "100%", zIndex: 10, ...wrapperStyle }} className={`m-0 p-0 d-flex flex-column align-items-center ${wrapperClassName}`}>
      {!!label && <div className={`w-100 py-2 ${labelClassName}`}>{label}</div>}
      <div className={`kea-input border ${containerClassName}`}>
        <AsyncSelect
          isMulti={isMulti}
          cacheOptions
          defaultOptions
          loadOptions={loadOptions}
          classNamePrefix="select"
          isClearable={true}
          isSearchable={true}
          theme={theme => ({
            ...theme,
            border: "0px",
            borderRadius: 0,
            colors: {
              ...theme.colors,
              primary: "#0000"
            }
          })}
          className={`w-100 ${className || ''}`}
          onChange={handleChange}
          components={{ LoadingIndicator: null }}
          styles={{
            control: (nativeStyles, state) => ({
              ...nativeStyles,
              height: "45px",
              border: "0px",
              ...style,
            }),
            input: (nativeStyles, state) => ({
              ...nativeStyles,
              ...inputStyle,
            }),
						singleValue: (nativeStyles, state) => ({
							...nativeStyles,
							...inputStyle,
						}),
            indicatorsContainer: (nativeStyles, state) => (noIndicators ? {
              ...nativeStyles,
              display: "none",
            } : {
              ...nativeStyles
            }),
            placeholder: (nativeStyles, state) => ({
              ...nativeStyles,
              ...inputStyle,
            }),
            option: (nativeStyles, state) => ({
              ...nativeStyles,
              ...inputStyle,
              ...optionStyle,
            }),
            menuList: styles => ({ ...styles, maxHeight: "200px" }),
          }}
          placeholder={placeholder}
          defaultValue={defaultValue}
          value={value}
          {..._.omit(rest, ["type", "label", "onChange", "error", "className", "labelClassName", "errorClassName", "containerClassName", "value"])}
        />
      </div>
      {!!error && <div className={`kea-input__feedback text-right w-100 text-principal ${errorClassName}`}>{error}</div>}
    </div>
  );
};

RemoteSelect.propTypes = {
  isDepthOne: PropTypes.bool,
  labelName: PropTypes.string,
  valueName: PropTypes.string,
  labelFormatter: PropTypes.func,
  valueFormatter: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.object,
  defaultValue: PropTypes.object,
  options: PropTypes.array,
  label: PropTypes.any,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  labelClassName: PropTypes.string,
  errorClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  placeholder: PropTypes.string,
  onSearch: PropTypes.func,
};

RemoteSelect.defaultProps = {
  isDepthOne: false,
  isMulti: false,
  labelName: 'libelle',
  valueName: 'id',
  name: '',
  defaultValue: undefined,
  options: [],
  onChange: () => console.log('no onChange method attached to RemoteSelect...'),
  onSearch: () => { },
  className: "",
  labelClassName: "",
  errorClassName: "",
  containerClassName: "",
};

export default RemoteSelect;