import React from 'react';
import "./styles.scss";

const Card = ({
  label,
  labelClassName,
  ...props
}) => {
  return (
    <div {...props} className={`kea-card d-flex align-items-center justify-content-center ${props?.className}`}>
      {
        props.children ?
          props.children :
          (
            <span className={`w-100 text-center text-disabled ${labelClassName}`}>{label}</span>
          )
      }
    </div>
  );
};

export default Card;