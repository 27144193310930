import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FaAngleDown, FaArrowRight, FaSearch, FaTrash } from "react-icons/fa";
import { useHistory } from "react-router-dom";
import { BACKEND_DATE_FORMAT, CONS_TYPES } from "configs";
import { Spinner } from "react-bootstrap";
import { toggleServiceRoom } from "context/store";
import { useStore } from "context/store";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as _ from "lodash";
import moment from "moment";
import { Input, DatePicker, Pill } from "components";
import UserImg from "assets/icons/user-placeholder.png";
import {
  API_BASE_URL,
  getPatiensInServiceWaitingRoom,
  PATIENT_SUFFIX,
} from "http/crud";
import routes from "configs/routes";
import Pagination from "react-bootstrap/Pagination";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

function ServiceWaitingRoom({ hasOverlay, ...props }) {
  const history = useHistory();
  const {
    dispatch,
    state: { agent, activeService, displayServiceRoom },
  } = useStore();

  const [loading, setLoading] = useState(false);
  const [patientsList, setPatientsList] = useState([]);
  const [originalPatientList, setOriginalPatientList] = useState([]);

  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [totalSize, setTotalSize] = useState(null);
  const [sizePerPage, setSizePerPage] = useState(8);
  const handlePageChange = (page) => {
    setPage(page);
  };
  const initialPeriodRange = {
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  };
  const [anchorElement, setAnchorElement] = useState(null);
  const [showPop, setShowPop] = useState(Boolean(anchorElement));
  const [periodRange, setPeriodRange] = useState(initialPeriodRange);
  const emptyIntervalDate = {
    dateDebut: null,
    dateFin: null,
  };
  const [intervalDate, setIntervalDate] = useState(emptyIntervalDate);
  const handleOpenPopover = (event) => {
    setAnchorElement(event.currentTarget);
    setShowPop(true);
  };

  const handleClosePopover = () => {
    setAnchorElement(null);
    setShowPop(false);
  };
  const handleChangePeriode = ({ selection }) => {
    if (!!selection?.startDate && !!selection?.endDate) {
      const newIntervalDate = {
        dateDebut: moment(selection?.startDate).format(BACKEND_DATE_FORMAT),
        dateFin: moment(selection?.endDate).format(BACKEND_DATE_FORMAT),
      };
      setIntervalDate(newIntervalDate);
      setPeriodRange(selection);
    }
  };
  const handleDateEmpty = () => {
    setIntervalDate(emptyIntervalDate);
    setPeriodRange(initialPeriodRange);
    setPage(1);
  };
  const fetchPatientsInWaitingRoom = (
    verbose = true,
    searchValue = search,
    targetPage = page,
    limit = sizePerPage,
    dateDebut = intervalDate?.dateDebut || "",
    dateFin = intervalDate?.dateFin || ""
  ) => {
    setLoading(verbose);
    getPatiensInServiceWaitingRoom(
      activeService?.serviceId,
      searchValue,
      targetPage,
      limit,
      dateDebut,
      dateFin
    )
      .then((response) => {
        setLoading(false);
        if (response.data.success) {
          setPatientsList(
            !!response.data?.data?.listeEnAttente
              ? response.data?.data.listeEnAttente
              : []
          );
          setOriginalPatientList(
            !!response.data?.data?.listeEnAttente
              ? response.data?.data.listeEnAttente
              : []
          );
          setTotalSize(response.data.total);
        } else {
          setPatientsList([]);
          setOriginalPatientList([]);
          toast.error("Une erreur est survenue lors de l'opération !", {
            position: "bottom-right",
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        setPatientsList([]);
        setOriginalPatientList([]);
        toast.error("Une erreur est survenue lors de l'opération !", {
          position: "bottom-right",
        });
      });
  };

  const handleToggleRoom = () => {
    dispatch(toggleServiceRoom());
  };

  const handleViewConsultation = (cons) => {
    handleToggleRoom();
    switch (cons?.type?.code) {
      case CONS_TYPES.CPN:
        history.push(routes["MENU.PRECONSULTATION.MATERNITE"], {
          patient: cons?.Patient,
          idSalleAttente: cons?._id,
          typeConsultation: cons?.type,
          dossierMaternite: cons?.dossier_maternite,
        });
        break;
      case CONS_TYPES.VACCINATION:
        history.push(routes["VACCINATION"], {
          patient: cons?.Patient,
          idSalleAttente: cons?._id,
          typeConsultation: cons?.type,
        });
        break;
      case CONS_TYPES.PEDIATRIE:
        history.push(routes["CONSULTATION.PRE.PEDIATRIE"], {
          patient: cons?.Patient,
          idSalleAttente: cons?._id,
          typeConsultation: cons?.type,
          consultationSpecialiste: cons?.ConsultationSpecialiste,
        });
        break;
      default: //PRECONSULTATION
        history.push(routes["PRECONSULTATION"], {
          patient: cons?.Patient,
          idSalleAttente: cons?._id,
          typeConsultation: cons?.type,
          dossierMaternite: cons?.dossier_maternite,
        });
        break;
    }
    //history.push(cons?.type?.code === CONS_TYPES.CPN ? routes["MENU.PRECONSULTATION.MATERNITE"] : routes["PRECONSULTATION"], { patient: cons?.Patient, idSalleAttente: cons?._id, typeConsultation: cons?.type, dossierMaternite: cons?.dossier_maternite });
  };

  const totalPages = Math.ceil(totalSize / sizePerPage);
  const pagesBefore = 2;
  const pagesAfter = 5;
  const pageItems = [];
  let startPage = Math.max(page - pagesBefore, 1);
  let endPage = Math.min(page + pagesAfter, totalPages);

  if (startPage > 1) {
    pageItems.push(
      <Pagination.Prev key="prev" onClick={() => handlePageChange(page - 1)}>
        <FiChevronLeft />
      </Pagination.Prev>
    );
  }
  for (let i = startPage; i <= endPage; i++) {
    if (i === page) {
      pageItems.push(
        <Pagination.Item key={i} active onClick={() => handlePageChange(i)}>
          {i}
        </Pagination.Item>
      );
    } else {
      pageItems.push(
        <Pagination.Item key={i} onClick={() => handlePageChange(i)}>
          {i}
        </Pagination.Item>
      );
    }
  }
  if (endPage < totalPages) {
    pageItems.push(
      <Pagination.Next key="next" onClick={() => handlePageChange(page + 1)}>
        <FiChevronRight />
      </Pagination.Next>
    );
  }
  useEffect(() => {
    if (displayServiceRoom) {
      fetchPatientsInWaitingRoom();
    } else {
      setPatientsList([]);
      setPage(1);
    }
  }, [displayServiceRoom, page, search, intervalDate]);
  return (
    <>
      <Container className={displayServiceRoom ? `active` : `waiting-room`}>
        <ToastContainer />
        <DatePicker
          ranges={[periodRange]}
          visible={showPop}
          anchor={anchorElement}
          onHide={handleClosePopover}
          onChange={handleChangePeriode}
          showMonthAndYearPickers={true}
          maxDate={new Date()}
        />
        <Header>
          {loading ? (
            <span>
              Salle d'attente{" "}
              <Spinner animation="border" size="sm" color="white" />
            </span>
          ) : (
            <span>Salle d'attente ( {totalSize} )</span>
          )}
          <FaArrowRight onClick={handleToggleRoom} className="cursor-pointer" />
        </Header>
        <MainContent>
          <div className="w-100 pb-3">
            <Input
              iconLeft={<FaSearch className="text-disabled" size={15} />}
              containerClassName="border border-5"
              placeholder="Rechercher"
              name="searchFilter"
              onChange={(e) => {
                setSearch(e.target.value);
                setPage(1);
              }}
              value={search}
            />
          </div>
          <div className="w-100 pb-3 position-relative">
            {!!intervalDate.dateDebut && !!intervalDate.dateFin && (
              <DateDeleter
                onClick={() => handleDateEmpty()}
                title="Effacer la date sélectionnée"
              >
                <FaTrash className="text-principal" size={13} />
              </DateDeleter>
            )}
            <Pill
              variant="gray"
              className="border-10"
              style={{
                background: "#F1F4F9",
                color: "#06152B",
                border: "1.56px solid #D9E1E7",
              }}
              onClick={handleOpenPopover}
            >
              {(function (intervalDate, dateFormat = "DD-MMMM") {
                if (!!intervalDate.dateDebut && !!intervalDate.dateFin) {
                  if (intervalDate.dateDebut == intervalDate.dateFin) {
                    return moment(intervalDate.dateDebut).format(dateFormat);
                  }
                  return `${moment(intervalDate.dateDebut).format(
                    dateFormat
                  )}  au  ${moment(intervalDate.dateFin).format(dateFormat)}`;
                }
                return "Sélectionner date";
              })(intervalDate)}
              <FaAngleDown className="ml-3" />
            </Pill>
          </div>

          {patientsList &&
          Array.isArray(patientsList) &&
          patientsList.length > 0 ? (
            <>
              {patientsList.map((patient, ind) => (
                <UserInfosContainer
                  onClick={
                    loading
                      ? () => {}
                      : handleViewConsultation.bind(this, patient)
                  }
                  key={ind}
                >
                  <Avatar
                    alt={"photo"}
                    noBorder={!!patient?.Patient?.photo ? "1" : "0"}
                    src={
                      !!patient?.Patient?.photo
                        ? `${API_BASE_URL}/${PATIENT_SUFFIX}/${patient?.Patient?.photo}`
                        : UserImg
                    }
                  />
                  <div
                    style={{ flex: 1 }}
                    className="justify-content-center d-flex flex-column h-100"
                  >
                    <h6 className="font-weight-bold">{`${
                      patient?.Patient?.firstName || ""
                    } ${patient?.Patient?.lastName || ""}`}</h6>
                    <span>
                      Age :{" "}
                      {!!patient?.Patient?.birthDate
                        ? `${moment().diff(
                            moment(
                              patient?.Patient?.birthDate,
                              BACKEND_DATE_FORMAT
                            ),
                            "years"
                          )} ans`
                        : "-"}
                    </span>
                    <span className="font-70 w-100 text-right">
                      Programmé {moment(patient?.created_at).fromNow()}
                    </span>
                  </div>
                </UserInfosContainer>
              ))}
            </>
          ) : (
            <div className="text-center mt-5"> Aucun patient ! </div>
          )}
        </MainContent>
        {patientsList &&
          Array.isArray(patientsList) &&
          patientsList.length > 0 && (
            <Pagination className="matpaginate">{pageItems}</Pagination>
          )}
      </Container>
      {hasOverlay && (
        <DarkView
          className={displayServiceRoom ? `active` : ``}
          onClick={handleToggleRoom}
        />
      )}
    </>
  );
}

export default ServiceWaitingRoom;

const Container = styled.div`
  height: 100%;
  width: 0px;
  margin: 0;
  padding: 0;
  position: absolute;
  right: -375px;
  z-index: 80;
  overflow-y: scroll;
  background: #ffffff;
  border-radius: 9px;
  transition: all 0.1s ease-in-out;
  box-shadow: -4px 0px 50px 0px #0003;
  opacity: 0;
  &.active {
    width: 375px;
    opacity: 1;
    right: 0;
    transition: all 0.3s ease-in-out;
  }
`;

const DarkView = styled.div`
  height: 100%;
  width: 0px;
  margin: 0;
  padding: 0;
  position: absolute;
  left: 100%;
  z-index: 50;
  background: #0003;
  transition: opacity 0.1s ease-in-out;
  opacity: 1;
  &.active {
    width: 100%;
    opacity: 1;
    left: 0;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;
  }
`;

const Header = styled.div`
  background: linear-gradient(125.83deg, #2aaf97 5.24%, #a3ff5b 136.62%);
  border-radius: 10px 10px 0px 0px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 16px;
  letter-spacing: 0.1px;
  color: #ffffff;
  transition: all 0.6s ease-in-out;
  box-shadow: 6px 0px 18px rgba(0, 0, 0, 0.06);
`;

const MainContent = styled.div`
  height: calc(100% - 120px);
  overflow-y: auto;
  padding: 10px 10px;
`;

const UserInfosContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 15px;
  cursor: pointer;
  color: black;
  &:hover {
    background: #2ac76922;
    border-radius: 10px;
    transition: all 0.2s ease-in-out;
  }
`;

const Avatar = styled.img`
  border-radius: 105px;
  width: 48px;
  height: 48px;
  margin-right: 16px;
  object-fit: cover;
  background: white;
  background: ${({ noBorder }) => (noBorder === "1" ? "#2ac76922" : "white")};
  border: ${({ noBorder }) =>
    noBorder === "1" ? "0px" : "1px solid #2ac76988"};
  overflow: hidden;
`;
const DateDeleter = styled.span`
  position: absolute;
  top: -11px;
  right: -4px;
  cursor: pointer;
`;
