import React from 'react';
import "./styles.scss";
import PropTypes from 'prop-types';

const Input = ({
	type,
	name,
	label,
	value,
	error,
	onChange,
	iconRight,
	iconLeft,
	className,
	labelClassName,
	errorClassName,
	containerClassName,
	containerStyle,
	wrapperClassName,
	wrapperStyle,
	...rest
}) => {
	return (
		<div style={{ width: "100%", ...wrapperStyle }} className={`m-0 p-0 d-flex flex-column align-items-center ${wrapperClassName}`}>
			{!!label && <div className={`w-100 py-2 ${labelClassName}`}>{label}</div>}
			<div style={{ ...containerStyle }} className={`kea-input ${containerClassName}`}>
				{iconLeft &&
					<div className="kea-input__left-icon">
						{iconLeft}
					</div>
				}
				<input
					type={type}
					name={name}
					value={value}
					onChange={onChange}
					{...rest}
					className={`kea-input__field ${className} ${iconLeft ? 'ml-0' : ''} ${iconRight ? 'mr-0' : ''}`}
				/>
				{iconRight &&
					<div className="kea-input__right-icon">
						{iconRight}
					</div>
				}
			</div>
			{!!error && <div className={`kea-input__feedback ${errorClassName}`}>{error}</div>}
		</div>
	);
};

Input.propTypes = {
	type: PropTypes.oneOf(["text", "date", "time", "number", "password", "email", "tel", "datetime-local"]),
	name: PropTypes.string,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	label: PropTypes.any,
	error: PropTypes.string,
	onChange: PropTypes.func,
	iconRight: PropTypes.any,
	iconLeft: PropTypes.any,
	labelClassName: PropTypes.string,
	errorClassName: PropTypes.string,
	containerClassName: PropTypes.string,
	containerStyle: PropTypes.object,
	wrapperClassName: PropTypes.string,
	wrapperStyle: PropTypes.object,
	placeholder: PropTypes.string
};

Input.defaultProps = {
	type: 'text',
	name: '',
	value: '',
	onChange: () => console.log('no onChange method attached to Input...'),
	className: "",
	labelClassName: "",
	errorClassName: "",
	containerClassName: "",
	containerStyle: {},
	wrapperClassName: "",
	wrapperStyle: {}
};

export default Input;