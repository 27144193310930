import axios from "axios";
import {
  getAxiosConfig,
  getConnectedUserAuthorization,
  getConnectedUserToken,
} from "./axios/setupAxios";
/*BASES URLS*/
export const API_BASE_URL = process.env.REACT_APP_API_SIH_BASE_URL;
export const API_GLOBAL_BASE_URL =
  process.env.REACT_APP_API_SIH_GLOBAL_BASE_URL;

/*SUFFIXES*/
export const PATIENT_SUFFIX =
  process.env.REACT_APP_API_SIH_PATIENT_URL_SUFFIX || "mkpatient";
export const DMP_SUFFIX = process.env.REACT_APP_API_SIH_DMP_URL_SUFFIX || "dmp";
export const AGENT_SUFFIX =
  process.env.REACT_APP_API_SIH_AGENTS_AUTH_URL_SUFFIX || "agents";
export const AUTH_SUFFIX =
  process.env.REACT_APP_API_SIH_AUTH_URL_SUFFIX || `kea_agent_auth`;
export const OPERATIONS_SUFFIX =
  process.env.REACT_APP_API_SIH_OPERATIONS_URL_SUFFIX || "operations";
export const CONSULTATIONS_SUFFIX =
  process.env.REACT_APP_API_SIH_CONSULTATION_URL_SUFFIX ||
  "keaconsultation/api";
export const ACTES_STANDARDS_SUFFIX =
  process.env.REACT_APP_API_SIH_ACTES_STANDARDS_URL_SUFFIX ||
  "actes_standard/api";
export const PRINT_SUFFIX =
  process.env.REACT_APP_API_SIH_PRINT_URL_SUFFIX || "keaprint";
export const LABO_INTERNE_SUFFIX =
  process.env.REACT_APP_API_SIH_LABO_INTERNE_URL_SUFFIX || "laboimint";
export const CHAMBRE_HOSPI_SUFFIX =
  process.env.REACT_APP_API_SIH_CHAMBRE_HOSPI_URL_SUFFIX || "chambre_hospis";
export const AGENDA_SUFFIX =
  process.env.REACT_APP_API_SIH_AGENDA_SUFFIX || "agenda";
export const HONORAIRE_SUFFIX =
  process.env.REACT_APP_API_SIH_HONORAIRE_SUFFIX || "honoraire";

/*GLOBAL SERVICES BASE URLS : BASES URL + SERVICES URL*/
export const PATIENT_BASE_URL = `${API_GLOBAL_BASE_URL}/${PATIENT_SUFFIX}`;
export const AGENT_BASE_URL = `${API_GLOBAL_BASE_URL}/${AGENT_SUFFIX}`;
export const AUTH_BASE_URL = `${API_GLOBAL_BASE_URL}/${AUTH_SUFFIX}`;
export const AGENDA_BASE_URL = `${API_GLOBAL_BASE_URL}/${AGENDA_SUFFIX}`;
export const ACTES_STANDARDS_BASE_URL = `${API_GLOBAL_BASE_URL}/${ACTES_STANDARDS_SUFFIX}`;
export const DMP_BASE_URL = `${API_GLOBAL_BASE_URL}/${DMP_SUFFIX}`;

//AUTH
export function authSignIn(credentials) {
  return axios.post(`${AGENT_BASE_URL}/Agents/login`, credentials);
}
export function authSignUp(criteria) {
  return axios.post(`${AGENT_BASE_URL}/Agents/signup`, criteria);
}

export function authResetInit(criteria) {
  return axios.post(`${AUTH_BASE_URL}/users/reset-password/init`, criteria);
}
export function authResetVerifyCode(criteria) {
  return axios.post(
    `${AUTH_BASE_URL}/users/reset-password/check-code`,
    criteria
  );
}
export function authResetUpdatePassword(criteria) {
  return axios.post(`${AUTH_BASE_URL}/users/reset-password/finish`, criteria);
}
export function authChangePassword(criteria) {
  return axios.put(`${AUTH_BASE_URL}/users/changePassword`, criteria);
}

export function updateUser(id, criteria) {
  return axios.patch(`${AGENT_BASE_URL}/Agents/${id}`, criteria);
}

// PATIENTS
export function fetchMe(token) {
  return axios.get(`${PATIENT_BASE_URL}/patients/users/me/${token}`);
}

export function fetchAgentByToken(token) {
  return axios.get(`${AGENT_BASE_URL}/users/me/${token}`);
}

export function fetchPersonToContact(id) {
  return axios.get(`${PATIENT_BASE_URL}/patients/${id}/patient-link`);
}
export function linkPersonToContact(id, criteria) {
  return axios.patch(
    `${PATIENT_BASE_URL}/patients/${id}/patient-link`,
    criteria
  );
}
export function updatePatientInfos(patientId, criteria) {
  return axios.patch(`${PATIENT_BASE_URL}/patients/${patientId}`, criteria);
}
export function fetchAllAntecedentsForCategoryApi(id) {
  return axios.get(
    `${PATIENT_BASE_URL}/disease-categories/${id}/antecedent-patients`
  );
}
export function fetchAllAntecedentsApi() {
  return axios.get(`${PATIENT_BASE_URL}/antecedent-patients`);
}
export function getOnePostApi(id) {
  return axios.get(`${API_BASE_URL}/kaclient/postes/${id}`);
}

export function fetchAllAntecedentsCategoriesApi() {
  return axios.get(`${PATIENT_BASE_URL}/disease-categories`);
}
export function fetchAntecedents(id) {
  return axios.get(`${PATIENT_BASE_URL}/patients/${id}/antecedants`);
}

export function sendMailToPatient(email = "") {
  return axios.post(`${API_BASE_URL}/kaclient/send/mail/${email}`);
}

export function updAntecedents(id, criteria) {
  return axios.post(`${PATIENT_BASE_URL}/patients/${id}/antecedents`, [
    criteria,
  ]);
}

export function fetchPatientConsultations(patientId) {
  return axios.get(`${DMP_BASE_URL}/api/consultation/${patientId}`);
}
export function fetchPatientActesPrescrits(patientId) {
  return axios.get(`${DMP_BASE_URL}/api/actesPrecrit/all/${patientId}`);
}
export function fetchPatientExamenImagerie(patientId) {
  return axios.get(`${DMP_BASE_URL}/api/examensImagerie/all/${patientId}`);
}
export function fetchPatientExamenLabo(patientId) {
  return axios.get(`${DMP_BASE_URL}/api/examensLabo/all/${patientId}`);
}
export function fetchPatientHospitalisations(patientId) {
  return axios.get(
    `${API_BASE_URL}/${CONSULTATIONS_SUFFIX}/gethospiPatient/${patientId}`
  );
}
export function fetchPatientOrdonnances(patientId) {
  return axios.get(`${DMP_BASE_URL}/api/ordonnance/all/${patientId}`);
}

export function fetchPatientActesEnAttente(patientId) {
  return axios.get(
    `${API_BASE_URL}/${OPERATIONS_SUFFIX}/patient/acteEnAttente/${patientId}`
  );
}

//ROLES
export function fetchRolesApi(search = "", page = 1, limit = 10) {
  return axios.get(
    `${AGENT_BASE_URL}/roles?search=${search}&page=${page}&limit=${limit}`
  );
}
export function fetchRoleById(roleId) {
  return axios.get(`${AGENT_BASE_URL}/roles/${roleId}`);
}
export function addRoleApi(criteria) {
  return axios.post(`${AGENT_BASE_URL}/roles/create-role`, criteria);
}
export function fetchAppPages() {
  return axios.get(`${AGENT_BASE_URL}/app/pages`);
}
export function updateRoleApi(roleId, criteria) {
  return axios.patch(`${AGENT_BASE_URL}/roles/${roleId}`, criteria);
}
export function deleteRoleApi(id) {
  return axios.delete(`${AGENT_BASE_URL}/roles/${id}`);
}
// TARIFS
export function getAllTarifs() {
  return axios.get(`${API_BASE_URL}/${OPERATIONS_SUFFIX}/tarifs`);
}
export function addNewTarif(data) {
  return axios.post(`${API_BASE_URL}/${OPERATIONS_SUFFIX}/tarifs`, data);
}
export function removeTarif(id) {
  return axios.delete(`${API_BASE_URL}/${OPERATIONS_SUFFIX}/tarifs/${id}`);
}

// ACTES
export function getActes(
  search = "",
  page = 1,
  limit = 10,
  acteCategorieId = ""
) {
  return axios.get(
    `${API_BASE_URL}/${OPERATIONS_SUFFIX}/actes?search=${search}&page=${page}&limit=${limit}&acteCategorieId=${acteCategorieId}`
  );
}
export function getActeById(acteId) {
  return axios.get(`${API_BASE_URL}/${OPERATIONS_SUFFIX}/actes/${acteId}`);
}
export function getActesBySubCategoryCode(subCategory) {
  return axios.get(
    `${API_BASE_URL}/${OPERATIONS_SUFFIX}/all/actes?codeSousCategorie=${subCategory}`
  );
}
export function getActeSubCategoryDataByCode(subCategoryCode) {
  return axios.get(
    `${API_BASE_URL}/${OPERATIONS_SUFFIX}/acte-sous-categorie/${subCategoryCode}`
  );
}

export function addActe(data) {
  return axios.post(`${API_BASE_URL}/${OPERATIONS_SUFFIX}/actes`, data);
}
export function editActe(id, data) {
  return axios.patch(`${API_BASE_URL}/${OPERATIONS_SUFFIX}/actes/${id}`, data);
}
export function removeActe(id) {
  return axios.delete(`${API_BASE_URL}/${OPERATIONS_SUFFIX}/actes/${id}`);
}
export function importActe(data, onProgress = () => {}) {
  return axios.post(`${API_BASE_URL}/${OPERATIONS_SUFFIX}/actes/import`, data, {
    onUploadProgress: onProgress,
    timeout: null,
  });
}

// ACTE KITS
export function getKits() {
  return axios.get(`${API_BASE_URL}/${OPERATIONS_SUFFIX}/kits`);
}
export function fetchPatientActes(patientId) {
  return axios.get(`${PATIENT_BASE_URL}/patients/byMailOrPhone/${patientId}`);
}

export function addKit(data) {
  return axios.post(`${API_BASE_URL}/${OPERATIONS_SUFFIX}/kits`, data);
}
export function editKit(id, data) {
  return axios.patch(`${API_BASE_URL}/${OPERATIONS_SUFFIX}/kits/${id}`, data);
}
export function removeKit(id) {
  return axios.delete(`${API_BASE_URL}/${OPERATIONS_SUFFIX}/kits/${id}`);
}

// ACTE CATEGORIES
export function getActeCategories(libelle = "") {
  return axios.get(
    `${API_BASE_URL}/${OPERATIONS_SUFFIX}/acte-categories?libelle=${libelle}`
  );
}
export function addActeCategorie(data) {
  return axios.post(
    `${API_BASE_URL}/${OPERATIONS_SUFFIX}/acte-categories`,
    data
  );
}
export function editActeCategorie(id, data) {
  return axios.patch(
    `${API_BASE_URL}/${OPERATIONS_SUFFIX}/acte-categories/${id}`,
    data
  );
}
export function removeActeCategorie(id) {
  return axios.delete(
    `${API_BASE_URL}/${OPERATIONS_SUFFIX}/acte-categories/${id}`
  );
}

// ACTE SOUS CATEGORIES
export function getActeSousCategories() {
  return axios.get(`${API_BASE_URL}/${OPERATIONS_SUFFIX}/acte-sous-categories`);
}
export function addActeSousCategorie(data) {
  return axios.post(
    `${API_BASE_URL}/${OPERATIONS_SUFFIX}/acte-sous-categories`,
    data
  );
}
export function editActeSousCategorie(id, data) {
  return axios.patch(
    `${API_BASE_URL}/${OPERATIONS_SUFFIX}/acte-sous-categories/${id}`,
    data
  );
}
export function removeActeSousCategorie(id) {
  return axios.delete(
    `${API_BASE_URL}/${OPERATIONS_SUFFIX}/acte-sous-categories/${id}`
  );
}

// ACTE CLASSES
export function getActeClasses() {
  return axios.get(`${API_BASE_URL}/${OPERATIONS_SUFFIX}/acte-classes`);
}
export function addActeClasse(data) {
  return axios.post(`${API_BASE_URL}/${OPERATIONS_SUFFIX}/acte-classes`, data);
}
export function editActeClasse(id, data) {
  return axios.patch(
    `${API_BASE_URL}/${OPERATIONS_SUFFIX}/acte-classes/${id}`,
    data
  );
}
export function removeActeClasse(id) {
  return axios.delete(
    `${API_BASE_URL}/${OPERATIONS_SUFFIX}/acte-classes/${id}`
  );
}

// ACTE COTATIONS
export function getActeCotations(typeCotation = "", acteCategorieId = "") {
  return axios.get(
    `${API_BASE_URL}/${OPERATIONS_SUFFIX}/cotations?typeCotation=${typeCotation}&acteCategorieId=${acteCategorieId}`
  );
}
export function addActeCotation(data) {
  return axios.post(`${API_BASE_URL}/${OPERATIONS_SUFFIX}/cotations`, data);
}

// ACTE UNIVERSELLES
export function getUniversalActes() {
  return axios.get(`${API_BASE_URL}/${OPERATIONS_SUFFIX}/universal-actes`);
}

// TYPES CONSULTATIONS
export function getTypeConsultations() {
  return axios.get(`${API_BASE_URL}/${OPERATIONS_SUFFIX}/type-consultations`);
}

// SERVICES
export function getAgentServices() {
  return axios.get(`${AGENT_BASE_URL}/services`);
}
export function addAgentService(data) {
  return axios.post(`${AGENT_BASE_URL}/services`, data);
}
export function editAgentService(id, data) {
  return axios.patch(`${AGENT_BASE_URL}/services/${id}`, data);
}
export function removeAgentService(id) {
  return axios.delete(`${AGENT_BASE_URL}/services/${id}`);
}

// AGENTS
export function getAllAgents(search = "", page = 1, limit = 10) {
  return axios.get(
    `${AGENT_BASE_URL}/Agents?search=${search}&page=${page}&limit=${limit}`
  );
}
export function getAllProfessions() {
  return axios.get(`${AGENT_BASE_URL}/professions`);
}
export function getAllFonctions() {
  return axios.get(`${AGENT_BASE_URL}/fonctions`);
}
export function getAllTypeContrats() {
  return axios.get(`${AGENT_BASE_URL}/type-contrats`);
}
export function getAllGroups() {
  return axios.get(`${AGENT_BASE_URL}/agent-groups`);
}
export function getAllSpecialities() {
  return axios.get(`${AGENT_BASE_URL}/specialities`);
}
export function addAgent(data) {
  return axios.post(`${AGENT_BASE_URL}/Agents`, data);
}
export function editAgent(id, data) {
  return axios.patch(`${AGENT_BASE_URL}/Agents/${id}`, data);
}
export function removeAgent(id) {
  return axios.delete(`${AGENT_BASE_URL}/Agents/${id}`);
}

// CAISSE
export function openCaisse(data) {
  return axios.post(`${API_BASE_URL}/${OPERATIONS_SUFFIX}/caisse/open`, data);
}
export function closeCaisse() {
  return axios.post(`${API_BASE_URL}/${OPERATIONS_SUFFIX}/caisse/close`);
}
export function checkCaisseSession() {
  return axios.get(`${API_BASE_URL}/${OPERATIONS_SUFFIX}/caisse/checkSession`);
}
export function getUserInfos(search = null) {
  return axios.get(
    `${API_BASE_URL}/${OPERATIONS_SUFFIX}/caisse/patient/${search}`
  );
}
export function getEncaissements(
  page = 1,
  limit = 10,
  facture = "",
  dateDebut = "",
  dateFin = ""
) {
  return axios.get(
    `${API_BASE_URL}/${OPERATIONS_SUFFIX}/factures/connectedAgent?page=${page}&limit=${limit}&numeroFacture=${facture}&dateDebut=${dateDebut}&dateFin=${dateFin}`
  );
}
export function searchPatientFromCashier(search = "", type = "phone") {
  return axios.get(
    `${API_BASE_URL}/${OPERATIONS_SUFFIX}/caisse/patient/${search}?type=${type}`
  );
}
export function searchPatientNameFromCashier(search = "", limit = 10) {
  return axios.get(
    `${API_BASE_URL}/${OPERATIONS_SUFFIX}/caisse/patient/searchByName/${search}?limit=${limit}`
  );
}
export function searchTypeDepense() {
  return axios.get(`${API_BASE_URL}/${OPERATIONS_SUFFIX}/type-depenses`);
}
export function getDepenses(
  page = 1,
  month = "",
  year = "",
  typeDepenseId = "",
  limit = 10
) {
  return axios.get(
    `${API_BASE_URL}/${OPERATIONS_SUFFIX}/depenses?page=${page}&limit=${limit}&month=${month}&year=${year}&typeDepenseId=${typeDepenseId}`
  );
}
export function addDepense(criteria) {
  return axios.post(`${API_BASE_URL}/${OPERATIONS_SUFFIX}/depenses`, criteria);
}
export function editDepense(depenseId, criteria) {
  return axios.patch(
    `${API_BASE_URL}/${OPERATIONS_SUFFIX}/depenses/${depenseId}`,
    criteria
  );
}
export function getAffectationsCaisse(page = 1, limit = 10) {
  return axios.get(
    `${API_BASE_URL}/${OPERATIONS_SUFFIX}/config-caisse?page=${page}&limit=${limit}`
  );
}
export function createCaisse(criteria) {
  return axios.post(
    `${API_BASE_URL}/${OPERATIONS_SUFFIX}/config-caisse`,
    criteria
  );
}
export function getCaisseAgents(id, search = "") {
  return axios.get(
    `${API_BASE_URL}/${OPERATIONS_SUFFIX}/config-caisse/${id}?search=${search}`
  );
}
export function removeCashier(caisseId, agentId) {
  return axios.post(
    `${API_BASE_URL}/${OPERATIONS_SUFFIX}/config-caisse/retirer-agent/${caisseId}/${agentId}`
  );
}
export function getAvailableCashiers(search = "") {
  return axios.get(
    `${API_BASE_URL}/${OPERATIONS_SUFFIX}/config-caisse/getCaissiers?search=${search}`
  );
}
export function affectCashier(caisseId, agentId) {
  return axios.post(
    `${API_BASE_URL}/${OPERATIONS_SUFFIX}/config-caisse/affecter-agent/${caisseId}/${agentId}`
  );
}
export function getConnectedCaisse(agentId) {
  return axios.get(
    `${API_BASE_URL}/${OPERATIONS_SUFFIX}/config-caisse/agent/${agentId}`
  );
}

//PATIENTS
export function patientSignUpApi(inputs) {
  return axios.post(`${PATIENT_BASE_URL}/patients`, inputs);
}
export function compteAssocieSignUpApi(inputs) {
  return axios.post(`${PATIENT_BASE_URL}/patient-associations`, inputs);
}

export function paidFactureApi(inputs, salleAttenteCaisseId = "") {
  /*let url = `${API_BASE_URL}/${OPERATIONS_SUFFIX}/caisse/payment`
  if(!!salleAttenteCaisseId){
    url+=`?salleAttenteCaisseId=${salleAttenteCaisseId}`
  }*/
  return axios.post(
    `${API_BASE_URL}/${OPERATIONS_SUFFIX}/caisse/payment${
      !!salleAttenteCaisseId
        ? "?salleAttenteCaisseId=" + salleAttenteCaisseId
        : ""
    }`,
    inputs
  );
}
export function getFactureProforma(inputs) {
  return axios.post(`${API_BASE_URL}/${OPERATIONS_SUFFIX}/proformas`, inputs);
}

export function isPatientNotAlreadyInSalleAttenteCaisse(patientId) {
  return axios.get(
    `${API_BASE_URL}/${OPERATIONS_SUFFIX}/salle-attente-caisses/checkIfPatientIsNotTaken/${patientId}`
  );
}

export function getPatientsApi() {
  return axios.get(`${PATIENT_BASE_URL}/patients`);
}
export function getPatientById(patientId) {
  return axios.get(`${PATIENT_BASE_URL}/patients/${patientId}`);
}
export function getMedecinsApi() {
  return axios.get(`${AGENT_BASE_URL}/Agents/Medecin`);
}
export function getServicesApi() {
  return axios.get(`${AGENT_BASE_URL}/services`);
}

export function searchCommunes() {
  return axios.get(`${ACTES_STANDARDS_BASE_URL}/reference/communes`);
}
export function searchQuartiers(communeId = "") {
  return axios.get(
    `${ACTES_STANDARDS_BASE_URL}/reference/quatiers/${communeId}`
  );
}
// CONCULTATION
export function getInitData() {
  return axios.get(`${API_BASE_URL}/${CONSULTATIONS_SUFFIX}/getInitDatas`);
}
export function getPendingDraft(type, idMedecin, idPatient, consType) {
  return axios.get(
    `${API_BASE_URL}/${CONSULTATIONS_SUFFIX}/getDrafts?type=${type}&idPatient=${idPatient}&idMedecin=${idMedecin}&consType=${consType}`
  );
}
export function updatePendingDraft(criteria) {
  return axios.post(`${API_BASE_URL}/${CONSULTATIONS_SUFFIX}/drafts`, criteria);
}
export function getPatiensInWaitingRoom(
  agentId,
  searchValue = "",
  targetPage = 1,
  limit = 30,
  dateDebut = "",
  dateFin = ""
) {
  return axios.get(
    `${API_BASE_URL}/${CONSULTATIONS_SUFFIX}/RecupererAttenteMedecin/${agentId}?search=${searchValue}&dateDebut=${dateDebut}&dateFin=${dateFin}&limit=${limit}&page=${targetPage}`
  );
}
export function getPatiensInServiceWaitingRoom(
  agentId,
  searchValue = "",
  targetPage = 1,
  limit = 30,
  dateDebut = "",
  dateFin = ""
) {
  return axios.get(
    `${API_BASE_URL}/${CONSULTATIONS_SUFFIX}/RecupererAttenteService/${agentId}?search=${searchValue}&dateDebut=${dateDebut}&dateFin=${dateFin}&limit=${limit}&page=${targetPage}`
  );
}
export function getAllConsultations(
  agentId,
  search = "",
  page = 1,
  limit = 30,
  dateDebut = "",
  dateFin = ""
) {
  return axios.get(
    `${API_BASE_URL}/${CONSULTATIONS_SUFFIX}/Consultations/${agentId}?page=${page}&limit=${limit}&search=${search}&dateDebut=${dateDebut}&dateFin=${dateFin}`
  );
}
export function getAllConsultationsArchive(
  page = 1,
  limit = 30,
  search = "",
  dateDebut = "",
  dateFin = ""
) {
  return axios.get(
    `${API_BASE_URL}/${CONSULTATIONS_SUFFIX}/RecuperConsArchive?page=${page}&limit=${limit}&search=${search}&dateDebut=${dateDebut}&dateFin=${dateFin}`
  );
}

export function searchDiagnostics(search = "", limit = 30) {
  return axios.get(
    `${API_BASE_URL}/${CONSULTATIONS_SUFFIX}/getDiagnostic?search=${search}&limit=${limit}`
  );
}

export function searchUnites(search = "", limit = 30) {
  return axios.get(
    `${API_BASE_URL}/${CONSULTATIONS_SUFFIX}/getUnit?search=${search}&limit=${limit}`
  );
}

export function searchMedocs(search = "", limit = 30) {
  return axios.get(
    `${ACTES_STANDARDS_BASE_URL}/getMedicaments?search=${search}&limit=${limit}`
  );
}

export function searchActesLabo(search = "", limit = 30) {
  return axios.get(
    `${API_BASE_URL}/${OPERATIONS_SUFFIX}/actes/byType/BIOLOGIE?search=${search}&limit=${limit}`
  );
}

export function searchActesImagerie(search = "", limit = 30) {
  return axios.get(
    `${API_BASE_URL}/${OPERATIONS_SUFFIX}/actes/byType/IMAGERIE?search=${search}&limit=${limit}`
  );
}

export function searchActesMedicaux(search = "", limit = 30) {
  return axios.get(
    `${API_BASE_URL}/${OPERATIONS_SUFFIX}/actes/byType/ACTE_MEDICAUX?search=${search}&limit=${limit}`
  );
}

export function addNewConsultation(criteria) {
  return axios.post(
    `${API_BASE_URL}/${CONSULTATIONS_SUFFIX}/SaveConsultation`,
    criteria
  );
}
export function updateSavedConsultation(criteria) {
  return axios.post(
    `${API_BASE_URL}/${CONSULTATIONS_SUFFIX}/updateConsultation`,
    criteria
  );
}
export function uploadCertificatFile(criteria, handleProgress = () => {}) {
  return axios({
    method: "POST",
    url: `${API_BASE_URL}/${CONSULTATIONS_SUFFIX}/sendCertificatFile`,
    data: criteria,
    onUploadProgress: handleProgress,
  });
}
export function getModePaiement() {
  return axios.get(
    `${API_BASE_URL}/centreconfig/centre-config/getModePaiement`
  );
}
export function addSignataireApi(serviceID, agentID) {
  return axios.post(
    `${API_BASE_URL}/centreconfig/centre-config/addSignataireServiceAgent/${serviceID}/${agentID}`
  );
}

export function removeSignataireApi(serviceID, agentID) {
  return axios.post(
    `${API_BASE_URL}/centreconfig/centre-config/removeSignataireServiceAgent/${serviceID}/${agentID}`
  );
}

export function getSignatairesByService(serviceID) {
  return axios.get(
    `${API_BASE_URL}/centreconfig/centre-config/getServiceSignataireAgent/${serviceID}`
  );
}

// SOINS
export function getAllSoins(
  page = 1,
  limit = 30,
  search = "",
  dateDebut = "",
  dateFin = "",
  idAgent = ""
) {
  return axios.get(
    `${API_BASE_URL}/${CONSULTATIONS_SUFFIX}/RecuperConsAmbulatoire?page=${page}&limit=${limit}&search=${search}&dateDebut=${dateDebut}&dateFin=${dateFin}&idAgent=${idAgent}`
  );
}
export function getSoinDossier(dossierId) {
  return axios.get(
    `${API_BASE_URL}/${CONSULTATIONS_SUFFIX}/getCons/${dossierId}`
  );
}
export function addNewControle(criteria) {
  return axios.post(
    `${API_BASE_URL}/${CONSULTATIONS_SUFFIX}/SaveControl`,
    criteria
  );
}
export function addNewVisite(criteria) {
  return axios.post(
    `${API_BASE_URL}/${CONSULTATIONS_SUFFIX}/saveVisite`,
    criteria
  );
}
export function addNewNote(criteria) {
  return axios.post(
    `${API_BASE_URL}/${CONSULTATIONS_SUFFIX}/SaveNote`,
    criteria
  );
}
export function getAllNotes(idConsultation) {
  return axios.get(
    `${API_BASE_URL}/${CONSULTATIONS_SUFFIX}/getNote/${idConsultation}`
  );
}
export function removeNote(criteria) {
  return axios.delete(`${API_BASE_URL}/${CONSULTATIONS_SUFFIX}/deleteNote`, {
    data: criteria,
  });
}
export function addNewConstantes(criteria) {
  return axios.post(
    `${API_BASE_URL}/${CONSULTATIONS_SUFFIX}/saveConstante`,
    criteria
  );
}
export function getAllConstantes(idConsultation) {
  return axios.get(
    `${API_BASE_URL}/${CONSULTATIONS_SUFFIX}/getConstante/${idConsultation}`
  );
}
export function getHistoriqueSynthese(idConsultation) {
  return axios.get(
    `${API_BASE_URL}/${CONSULTATIONS_SUFFIX}/getHistoriqyeSynthese/${idConsultation}`
  );
}
export function addNewPrescription(criteria) {
  return axios.post(
    `${API_BASE_URL}/${CONSULTATIONS_SUFFIX}/saveNewPrescription`,
    criteria
  );
}
export function addFicheTherapeutique(criteria) {
  return axios.post(
    `${API_BASE_URL}/${CONSULTATIONS_SUFFIX}/saveFicheTherapeutique`,
    criteria
  );
}
export function getFichesTherapeutiques(idConsultation = "") {
  return axios.get(
    `${API_BASE_URL}/${CONSULTATIONS_SUFFIX}/getFicheTherapeutique/${idConsultation}`
  );
}
export function stopSoins(criteria) {
  return axios.post(
    `${API_BASE_URL}/${CONSULTATIONS_SUFFIX}/stopSoins`,
    criteria
  );
}
export function executeSoins(criteria) {
  return axios.post(
    `${API_BASE_URL}/${CONSULTATIONS_SUFFIX}/executeSoins`,
    criteria
  );
}
export function addNewPreConsultation(idPatient, criteria) {
  return axios.post(
    `${API_BASE_URL}/${CONSULTATIONS_SUFFIX}/presconsultation/${idPatient}`,
    criteria
  );
}

//LABORATOIRE
export function getAwaitingExamInLabo(
  page = 1,
  limit = 10,
  search = "",
  dateDebut = "",
  dateFin = ""
) {
  return axios.get(
    `${API_BASE_URL}/${LABO_INTERNE_SUFFIX}/api/labo/1?page=${page}&limit=${limit}&search=${search}&dateDebut=${dateDebut}&dateFin=${dateFin}`
  );
}

export function getFinishedExamInLabo(
  page = 1,
  limit = 10,
  search = "",
  dateDebut = "",
  dateFin = ""
) {
  return axios.get(
    `${API_BASE_URL}/laboimint/api/labo/0?page=${page}&limit=${limit}&search=${search}&dateDebut=${dateDebut}&dateFin=${dateFin}`
  );
}

export function updateExamLaboResult(idLabo, criteria) {
  return axios.put(
    `${API_BASE_URL}/${LABO_INTERNE_SUFFIX}/api/updateLabo/${idLabo}`,
    criteria
  );
}

export function getResultByExamLaboID(idLabo) {
  return axios.get(
    `${API_BASE_URL}/${LABO_INTERNE_SUFFIX}/api/labo/resultat/${idLabo}`
  );
}

//IMAGERIE
export function getAwaitingExamInImagerie(
  search = "",
  page = 1,
  limit = 10,
  dateDebut = "",
  dateFin = ""
) {
  return axios.get(
    `${API_BASE_URL}/${LABO_INTERNE_SUFFIX}/api/imagerie/1?page=${page}&limit=${limit}&search=${search}&dateDebut=${dateDebut}&dateFin=${dateFin}`
  );
}

export function getFinishedExamInImagerie(
  search = "",
  page = 1,
  limit = 10,
  dateDebut = "",
  dateFin = ""
) {
  return axios.get(
    `${API_BASE_URL}/${LABO_INTERNE_SUFFIX}/api/imagerie/0?page=${page}&limit=${limit}&search=${search}&dateDebut=${dateDebut}&dateFin=${dateFin}`
  );
}

export function updateExamImagerieResult(idImagerie, criteria) {
  return axios.put(
    `${API_BASE_URL}/${LABO_INTERNE_SUFFIX}/api/updateImg/${idImagerie}`,
    criteria
  );
}

export function getResultByExamImagerieID(idImagerie) {
  return axios.get(
    `${API_BASE_URL}/${LABO_INTERNE_SUFFIX}/api/imagerie/resultat/${idImagerie}`
  );
}

export function getAllPrescriptions(idConsultation) {
  return axios.get(
    `${API_BASE_URL}/${CONSULTATIONS_SUFFIX}/getNewPrescription/${idConsultation}`
  );
}
export function getActesParamedicaux(idConsultation) {
  return axios.get(
    `${API_BASE_URL}/${CONSULTATIONS_SUFFIX}/getActe/${idConsultation}`
  );
}
export function executeActe(criteria) {
  return axios.post(
    `${API_BASE_URL}/${CONSULTATIONS_SUFFIX}/executeActe`,
    criteria
  );
}

export function getAgencyInfos(agencyId) {
  return axios.get(
    `${API_BASE_URL}/keapartenaire/centre-partenaires/${agencyId} `
  );
}

//HOSPITALISATIONS
export function getHospitalisationList(
  page,
  limit,
  search = "",
  dateDebut = "",
  dateFin = "",
  idAgent = ""
) {
  return axios.get(
    `${API_BASE_URL}/${CONSULTATIONS_SUFFIX}/listHospitalisation?page=${page}&limit=${limit}&search=${search}&dateDebut=${dateDebut}&dateFin=${dateFin}&idAgent=${idAgent}`
  );
}
export function getHospitalisationCloturerList(
  page,
  limit,
  search = "",
  dateDebut = "",
  dateFin = ""
) {
  return axios.get(
    `${API_BASE_URL}/${CONSULTATIONS_SUFFIX}/listHospiCloturer?page=${page}&limit=${limit}&search=${search}&dateDebut=${dateDebut}&dateFin=${dateFin}`
  );
}

export function getDossierHopitalisation(idHospitalisation) {
  return axios.get(
    `${API_BASE_URL}/${CONSULTATIONS_SUFFIX}/getHospi/${idHospitalisation}`
  );
}
export function closeHospitalisation(criteria) {
  return axios.post(
    `${API_BASE_URL}/${CONSULTATIONS_SUFFIX}/closeHospi`,
    criteria
  );
}

//ENCAISSEMENT PERIODIQUES

export function getNumeroCaisseApi() {
  return axios.get(`${API_BASE_URL}/${OPERATIONS_SUFFIX}/config-caisse`);
}

export function getEtatsEncaissementsPeriodique(queryParam = "") {
  if (!!queryParam) {
    queryParam = `?${queryParam}`;
  }
  //return axios.get(`http://operations.adonon.bj/facturation/etat-encaissement-periodique${queryParam}`);
  return axios.get(
    `${API_BASE_URL}/${OPERATIONS_SUFFIX}/facturation/etat-encaissement-periodique${queryParam}`
  );
}

export function uploadAssuranceLogo(data, handleProgress = () => {}) {
  return axios.post(
    `${API_BASE_URL}/${OPERATIONS_SUFFIX}/assurances/logo`,
    data,
    {
      onUploadProgress: handleProgress,
      timeout: null,
    }
  );
}
export function uploadSocieteLogo(data, handleProgress = () => {}) {
  return axios.post(
    `${API_BASE_URL}/${OPERATIONS_SUFFIX}/societes/logo`,
    data,
    {
      onUploadProgress: handleProgress,
      timeout: null,
    }
  );
}
export function createAssurance(criteria) {
  return axios.post(
    `${API_BASE_URL}/${OPERATIONS_SUFFIX}/assurances`,
    criteria
  );
}
export function addPatientAssurance(criteria) {
  return axios.post(
    `${API_BASE_URL}/${OPERATIONS_SUFFIX}/patient-assurances`,
    criteria
  );
}
export function getPatientAssurance(id) {
  return axios.get(
    `${API_BASE_URL}/${OPERATIONS_SUFFIX}/patient-assurances/patient/${id}`
  );
}

export function deleteAssurance(id) {
  return axios.delete(`${API_BASE_URL}/${OPERATIONS_SUFFIX}/assurances/${id}`);
}
export function createSociete(criteria) {
  return axios.post(`${API_BASE_URL}/${OPERATIONS_SUFFIX}/societes`, criteria);
}
export function deleteSociete(id) {
  return axios.delete(`${API_BASE_URL}/${OPERATIONS_SUFFIX}/societes/${id}`);
}
export function getTypesAssurances() {
  return axios.get(`${API_BASE_URL}/${OPERATIONS_SUFFIX}/type-assurances`);
}
export function searchAssurances(assuranceName = "", limit = 40) {
  return axios.get(
    `${API_BASE_URL}/${OPERATIONS_SUFFIX}/assurances?assuranceName=${assuranceName}&limit=${limit}`
  );
}
export function getAssurances(assuranceName = "", page = 1, limit = 10) {
  return axios.get(
    `${API_BASE_URL}/${OPERATIONS_SUFFIX}/assurances?assuranceName=${assuranceName}&page=${page}&limit=${limit}`
  );
}
export function getSocietes(societeName = "", page = 1, limit = 10) {
  return axios.get(
    `${API_BASE_URL}/${OPERATIONS_SUFFIX}/societes?societeName=${societeName}&page=${page}&limit=${limit}`
  );
}
export function searchActes(search = "") {
  return axios.get(
    `${API_BASE_URL}/${OPERATIONS_SUFFIX}/actes?search=${search}&limit=${30}`
  );
}
export function getAllActes() {
  return axios.get(
    `${API_BASE_URL}/${OPERATIONS_SUFFIX}/actes/forSelectInput?limit=${1000}`
  );
}
export function getActesById(acteId) {
  return axios.get(`${API_BASE_URL}/${OPERATIONS_SUFFIX}/actes/${acteId}`);
}
export function getAllActesOnce() {
  return axios.get(
    `${API_BASE_URL}/${OPERATIONS_SUFFIX}/actes/forSelectInput?limit=${5000}`
  );
}
export function setPlafond(criteria) {
  return axios.post(`${API_BASE_URL}/${OPERATIONS_SUFFIX}/plafonds`, criteria);
}
export function getPlafonds(page = 1, limit = 10, data) {
  return axios.get(
    `${API_BASE_URL}/${OPERATIONS_SUFFIX}/plafonds?page=${page}&limit=${limit}&assuranceId=${
      data?.assuranceId || ""
    }&societeId=${data?.societeId || ""}`
  );
}
export function deletePlafondConfig(id) {
  return axios.delete(`${API_BASE_URL}/${OPERATIONS_SUFFIX}/plafonds/${id}`);
}

export function demandeAnnulation(idFacture, criteria) {
  return axios.post(
    `${API_BASE_URL}/${OPERATIONS_SUFFIX}/facturation/demande-annulation/${idFacture}`,
    criteria
  );
}

export function getDemandeAnnulation(queryParam = "") {
  if (!!queryParam) {
    queryParam = `?${queryParam}`;
  }
  return axios.get(
    `${API_BASE_URL}/${OPERATIONS_SUFFIX}/facturation/demande-annulation${queryParam}`
  );
}

export function validerAnnulation(criteria) {
  return axios.post(
    `${API_BASE_URL}/${OPERATIONS_SUFFIX}/facturation/valider-annulation`,
    criteria
  );
}

export function rejeterAnnulation(criteria) {
  return axios.post(
    `${API_BASE_URL}/${OPERATIONS_SUFFIX}/facturation/rejeter-annulation`,
    criteria
  );
}

export function visualiserTempsReel(queryParam = "") {
  if (!!queryParam) {
    queryParam = `?${queryParam}`;
  }
  return axios.get(
    `${API_BASE_URL}/${OPERATIONS_SUFFIX}/facturation/encaissement-periodique-temps-reel${queryParam}`
  );
}

export function getAnnulationAcceptee(queryParam = "") {
  if (!!queryParam) {
    queryParam = `?${queryParam}`;
  }
  return axios.get(
    `${API_BASE_URL}/${OPERATIONS_SUFFIX}/facturation/annulation-accepter${queryParam}`
  );
}

export function annulerValidation(criteria) {
  return axios.post(
    `${API_BASE_URL}/${OPERATIONS_SUFFIX}/facturation/annuler-validation`,
    criteria
  );
}

export function getAnnulationRejetee(queryParam = "") {
  if (!!queryParam) {
    queryParam = `?${queryParam}`;
  }
  return axios.get(
    `${API_BASE_URL}/${OPERATIONS_SUFFIX}/facturation/annulation-rejeter${queryParam}`
  );
}

export function annulerRejet(criteria) {
  return axios.post(
    `${API_BASE_URL}/${OPERATIONS_SUFFIX}/facturation/annuler-rejet`,
    criteria
  );
}

export function getListeCaisser() {
  return axios.get(`${AGENT_BASE_URL}/Agents/Caissier`);
}

export function getActeCategoriesList() {
  return axios.get(
    `${API_BASE_URL}/${OPERATIONS_SUFFIX}/acte-categories?libelle=`
  );
}

export function orientToSalleAttenteCaisse(criteria) {
  return axios.post(
    `${API_BASE_URL}/${OPERATIONS_SUFFIX}/salle-attente-caisses`,
    criteria
  );
}

export function getAwaitingPatientsInCaisse() {
  return axios.get(
    `${API_BASE_URL}/${OPERATIONS_SUFFIX}/salle-attente-caisses`
  );
}

export function getSalleAttenteCaisseData(salleAttenteCaisseId) {
  return axios.get(
    `${API_BASE_URL}/${OPERATIONS_SUFFIX}/salle-attente-caisses/${salleAttenteCaisseId}`
  );
}

export function getPaymentModes() {
  return axios.get(
    `${API_BASE_URL}/centreconfig/centre-config/getModePaiement`
  );
}

export function searchActesByName(queryParam = "") {
  if (!!queryParam) {
    queryParam = `?${queryParam}`;
  }
  return axios.get(
    `${API_BASE_URL}/${OPERATIONS_SUFFIX}/actes/forSelectInput${queryParam}`
  );
}
export function searchPatientsByName(queryParam = "") {
  if (!!queryParam) {
    queryParam = `?${queryParam}`;
  }
  return axios.get(
    `${API_BASE_URL}/${OPERATIONS_SUFFIX}/facturation/patients${queryParam}`
  );
}

export function getPatientAntecedentsMedicals(patientId) {
  return axios.get(
    `${PATIENT_BASE_URL}/patients/${patientId}/antecedentMedical`
  );
}
export function setupPatientAntecedent(patientId, criteria) {
  return axios.post(
    `${PATIENT_BASE_URL}/patients/${patientId}/antecedentMedical`,
    criteria
  );
}
export function deletePatientAntecedent(patientId, libelle) {
  return axios.delete(`${PATIENT_BASE_URL}/patients/${patientId}/${libelle}`);
}

//CONFIG
export function getCentreConfigParam() {
  return axios.get(`${API_BASE_URL}/centreconfig/centre-config/getParametres`);
}

export function setCentreConfigParam(criteria) {
  return axios.post(
    `${API_BASE_URL}/centreconfig/centre-config/setParametres`,
    criteria
  );
}

//LABO INTERNE
export function updateLaboResult(criteria) {
  return axios.put(`${API_BASE_URL}/laboimint/api/updateLabo`, criteria);
}

//MATERNITE
export function getDossierPrenatal(idDossier) {
  return axios.get(
    `${API_BASE_URL}/${CONSULTATIONS_SUFFIX}/getdossierPrenatale/${idDossier}`
  );
}
export function updateDossierPrenatal(criteria) {
  return axios.post(
    `${API_BASE_URL}/${CONSULTATIONS_SUFFIX}/updateDossierPrenatale`,
    criteria
  );
}
export function saveDossierMaternite(criteria) {
  return axios.post(
    `${API_BASE_URL}/${CONSULTATIONS_SUFFIX}/saveConsPrenatale`,
    criteria
  );
}

export function saveConsultationPrenatal(patientID, criteria) {
  return axios.post(
    `${API_BASE_URL}/${CONSULTATIONS_SUFFIX}/presconsultation/${patientID}`,
    criteria
  );
}

export function getGrossesseAntecedent(idPatient) {
  return axios.get(
    `${PATIENT_BASE_URL}/patients/${idPatient}/antecedant-grossesses`
  );
}

export function updateGrossesseAntecedent(antecedentId, criteria) {
  return axios.patch(
    `${PATIENT_BASE_URL}/antecedant-grossesses/${antecedentId}`,
    criteria
  );
}
export function saveGrossesseAntecedent(idPatient, criteria) {
  return axios.post(
    `${PATIENT_BASE_URL}/patients/${idPatient}/antecedant-grossesses`,
    criteria
  );
}

export function deleteGrossesseAntecedent(antecedantID) {
  return axios.delete(
    `${PATIENT_BASE_URL}/antecedant-grossesses/${antecedantID}`
  );
}

export function getMedicaments(searchWord = "", limit = 30) {
  return axios.get(
    `${API_BASE_URL}/actes_standard/api/getMedicaments?search=${searchWord}&limit=${limit}`
  );
}

export function getMedicamentsUnits() {
  return axios.get(`${API_BASE_URL}/${CONSULTATIONS_SUFFIX}/getUnit`);
}
export function getPatientsInMaterniteWaitingRoom(
  type,
  page = 1,
  limit = 10,
  search = "",
  dateDebut = "",
  dateFin = ""
) {
  return axios.get(
    `${API_BASE_URL}/${CONSULTATIONS_SUFFIX}/materniteAttente?type=${type}&page=${page}&limit=${limit}&search=${search}&dateDebut=${dateDebut}&dateFin=${dateFin}`
  );
}

export function saveMaterniteNotes(criteria) {
  return axios.post(
    `${API_BASE_URL}/${CONSULTATIONS_SUFFIX}/SaveNote`,
    criteria
  );
}
export function getMaterniteNotes(idDossier) {
  return axios.get(
    `${API_BASE_URL}/${CONSULTATIONS_SUFFIX}/getNoteMaternite/${idDossier}`
  );
}

export function transfertToMaternite(criteria) {
  return axios.post(
    `${API_BASE_URL}/${CONSULTATIONS_SUFFIX}/transfertMaternite`,
    criteria
  );
}

export function getSoinsLists() {
  return axios.get(`${API_BASE_URL}/${CONSULTATIONS_SUFFIX}/getAllsoins`);
}
export function getHospitalisationLists() {
  return axios.get(`${API_BASE_URL}/${CONSULTATIONS_SUFFIX}/getallhospiArch`);
}

export function getCities() {
  return axios.get(`${ACTES_STANDARDS_BASE_URL}/reference/communes`);
}

export function getAreas(communeID) {
  return axios.get(
    `${ACTES_STANDARDS_BASE_URL}/reference/quatiers/${communeID}`
  );
}

//CHAMBRES HOSPI
export function createChambreHospi(criteria) {
  return axios.post(
    `${API_BASE_URL}/${CHAMBRE_HOSPI_SUFFIX}/api/chambre/create`,
    criteria
  );
}

export function getChambres(page = 1, limit = 8) {
  return axios.get(
    `${API_BASE_URL}/${CHAMBRE_HOSPI_SUFFIX}/api/chambre/getAllChambres?page=${page}&limit=${limit}`
  );
}

export function getLitsByChambre(chambreID) {
  return axios.get(
    `${API_BASE_URL}/${CHAMBRE_HOSPI_SUFFIX}/api/lits/getAllLitofChambre/${chambreID}`
  );
}

export function createLitForChambre(criteria) {
  return axios.post(
    `${API_BASE_URL}/${CHAMBRE_HOSPI_SUFFIX}/api/lits/create`,
    criteria
  );
}

export function updateLitForChambre(litID, criteria) {
  return axios.patch(
    `${API_BASE_URL}/${CHAMBRE_HOSPI_SUFFIX}/api/lits/update/${litID}`,
    criteria
  );
}
export function deleteLitForChambre(litID) {
  return axios.delete(
    `${API_BASE_URL}/${CHAMBRE_HOSPI_SUFFIX}/api/lits/delete/${litID}`
  );
}

export function updateChambre(chambreID, criteria) {
  return axios.patch(
    `${API_BASE_URL}/${CHAMBRE_HOSPI_SUFFIX}/api/chambre/update/${chambreID}`,
    criteria
  );
}

export function deleteChambre(chambreID) {
  return axios.delete(
    `${API_BASE_URL}/${CHAMBRE_HOSPI_SUFFIX}/api/chambre/delete/${chambreID}`
  );
}

//AFFECTATION DE CHAMBRES & LITS
export function getDossierAffectationChambreLitList(page, limit, search = "") {
  return axios.get(
    `${API_BASE_URL}/${CONSULTATIONS_SUFFIX}/listHospitalisation/att_aff?page=${page}&limit=${limit}&search=${search}`
  );
}

export function getAllChambresDispo(isAnUpdate = false, existingLitID = "") {
  return axios.get(
    `${API_BASE_URL}/${CHAMBRE_HOSPI_SUFFIX}/api/chambre/getAllChambresDispo${
      !!isAnUpdate ? "/update/" + existingLitID : ""
    }`
  );
}

export function affectSalleHospi(criteria) {
  return axios.post(
    `${API_BASE_URL}/${CONSULTATIONS_SUFFIX}/affectSalleHospi`,
    criteria
  );
}

export function updateAffectSalleHospi(criteria) {
  return axios.post(
    `${API_BASE_URL}/${CONSULTATIONS_SUFFIX}/updateAffectationSalle`,
    criteria
  );
}

//PREANESTHESIE
export function transferToBloc(criteria) {
  return axios.post(
    `${API_BASE_URL}/${CONSULTATIONS_SUFFIX}/transfertBloc`,
    criteria
  );
}

export function searchTypeInterventions(search = "") {
  return axios.get(
    `${API_BASE_URL}/${OPERATIONS_SUFFIX}/actes/chirugie?search=${search}&limit=${30}`
  );
}

export function saveConsultationPreAnesthesie(criteria) {
  return axios.post(
    `${API_BASE_URL}/${CONSULTATIONS_SUFFIX}/saveConsPreAnes`,
    criteria
  );
}
export function updateConsultationPreAnesthesie(criteria) {
  return axios.post(
    `${API_BASE_URL}/${CONSULTATIONS_SUFFIX}/updateConsPreAnes`,
    criteria
  );
}
/*INTERVENTION CHIRURGICALE*/
export function getConsultationPreAnesthesieByAvis(
  avis = "FAVORABLE",
  page = 1,
  limit = 8,
  search = ""
) {
  return axios.get(
    `${API_BASE_URL}/${CONSULTATIONS_SUFFIX}/consPreAnesByAvis?avis=${avis}&page=${page}&limit=${limit}&search=${search}`
  );
}
export function getSoignants(search = "") {
  return axios.get(
    `${AGENT_BASE_URL}/Agents/soignant${!!search ? "search=" + search : ""}`
  );
}

export function saveBlocOperation(criteria) {
  return axios.post(
    `${API_BASE_URL}/${CONSULTATIONS_SUFFIX}/saveBlocOperation`,
    criteria
  );
}

/*SURVEILLANCE POST INTERVENTIONNELLE*/
export function getBlocByOrientation(
  orientation = "REANIMATION",
  page = 1,
  limit = 8,
  search = ""
) {
  return axios.get(
    `${API_BASE_URL}/${CONSULTATIONS_SUFFIX}/getBlocByOrientation?orientation=${orientation}&page=${page}&limit=${limit}&search=${search}`
  );
}

export function getSurveillanceDrainage(idConsultation) {
  return axios.get(
    `${API_BASE_URL}/${CONSULTATIONS_SUFFIX}/getSurveillanceDrainage/${idConsultation}`
  );
}
export function getFicheTherapeutique(idConsultation) {
  return axios.get(
    `${API_BASE_URL}/${CONSULTATIONS_SUFFIX}/getFicheTherapeutique/${idConsultation}`
  );
}

export function saveSurveillanceDrainages(criteria) {
  return axios.post(
    `${API_BASE_URL}/${CONSULTATIONS_SUFFIX}/saveSurveillanceDrainage`,
    criteria
  );
}
export function saveFicheTherapeutique(criteria) {
  return axios.post(
    `${API_BASE_URL}/${CONSULTATIONS_SUFFIX}/saveFicheTherapeutique`,
    criteria
  );
}
export function updateBloc(criteria) {
  return axios.post(
    `${API_BASE_URL}/${CONSULTATIONS_SUFFIX}/updateBloc`,
    criteria
  );
}
export function getSocketUrl() {
  return `${API_BASE_URL}/kea_ws/chat`;
}

//AGENDA
export function getAgenda() {
  return axios.get(`${AGENDA_BASE_URL}/profile`);
}
export function updateAgenda(criteria) {
  return axios.post(`${AGENDA_BASE_URL}/profile`, criteria);
}

export function getAgendaStatus() {
  return axios.get(`${AGENDA_BASE_URL}/profile/status`);
}

export function getEventRDVList(agendaId = "") {
  return axios.get(
    `${AGENDA_BASE_URL}/appointments${!!agendaId ? "/" + agendaId : ""}`
  );
}

export function getAgendasList() {
  return axios.get(`${AGENDA_BASE_URL}/profile/accessed-agenda`);
}

export function getAgentsSpecialities(search = "") {
  return axios.get(`${AGENDA_BASE_URL}/specialities?search=${search}`);
}

//CARDIO
export function uploadCardioFile(data, handleProgress = () => {}) {
  return axios.post(
    `${API_BASE_URL}/${CONSULTATIONS_SUFFIX}/uploadCardioFile`,
    data,
    {
      onUploadProgress: handleProgress,
      timeout: null,
    }
  );
}

//  HONORAIRE
export function addConfigPrestatition(body) {
  return axios.post(
    `${API_BASE_URL}/${HONORAIRE_SUFFIX}/api/config/save`,
    body
  );
}
export function getAgentsWithPrestation() {
  return axios.get(`${API_BASE_URL}/${HONORAIRE_SUFFIX}/api/config/all/agent`);
}

export function deleteConfigPrestation(idMedecin) {
  return axios.delete(
    `${API_BASE_URL}/${HONORAIRE_SUFFIX}/api/config/delete/${idMedecin}`
  );
}

export function getPrestataireConfig(id) {
  return axios.get(
    `${API_BASE_URL}/${HONORAIRE_SUFFIX}/api/config/prestataire/${id}`
  );
}

export function deleteConfig(id) {
  return axios.get(
    `${API_BASE_URL}/${HONORAIRE_SUFFIX}/api/config/delete/${id}`
  );
}

//STATS
export function fetchStatsFile(url) {
  return fetch(url, { headers: getConnectedUserAuthorization() }) // FETCH BLOB FROM IT
    .then((response) => response.blob());
}

//VACCINATION
export function getPatientVaccinationData(idPatientId) {
  return axios.get(
    `${API_BASE_URL}/${CONSULTATIONS_SUFFIX}/getVaccinationCard/${idPatientId}`
  );
}

export function saveVaccination(criteria) {
  return axios.post(
    `${API_BASE_URL}/${CONSULTATIONS_SUFFIX}/saveVaccination`,
    criteria
  );
}

export function getListvaccination(additionalParams) {
  additionalParams = !!additionalParams ? `?${additionalParams}` : "";
  return axios.get(
    `${API_BASE_URL}/${CONSULTATIONS_SUFFIX}/getListvaccination${additionalParams}`
  );
}

export function savePrePediatrieConsultation(patientId, criteria) {
  return axios.post(
    `${API_BASE_URL}/${CONSULTATIONS_SUFFIX}/presconsultation/${patientId}`,
    criteria
  );
}

export function getSoignantsThatWork(search = "") {
  return axios.get(`${AGENT_BASE_URL}/Agents/soignants?search=${search}`);
}

export function getCpnDossierArchives(
  page = 1,
  limit = 10,
  search = "",
  dateDebut = "",
  dateFin = ""
) {
  return axios.get(
    `${API_BASE_URL}/${CONSULTATIONS_SUFFIX}/getDossierAllMaternite?&page=${page}&limit=${limit}&dateDebut=${dateDebut}&dateFin=${dateFin}&search=${search}`
  );
}
// ?search=&dateDebut&dateFin
