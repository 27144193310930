import React from "react";
import styled from "styled-components";
import { Button } from "components";
import { Row, Col, Spinner } from "react-bootstrap";
import { SoinsType } from "types";
import moment from "moment";

interface CardPrescriptionProps {
  className: string;
  striken: boolean;
  soin: SoinsType;
  onExecute: (soin: SoinsType) => void;
  onStop: (soin: SoinsType) => void;
  loading: boolean;
}

const CardPrescription = (props: CardPrescriptionProps) => {
  return (
    <Card
      {...props}
      className={`p-3 ${props.className} d-flex flex-column align-items-start`}
    >
      <div className="d-flex align-items-center justify-content-between w-100">
        <Button
          className="w-auto h-auto py-1 font-90"
          size="sm"
          value={moment(props?.soin?.dateAdmission).format("HH:mm")}
          style={{
            background:
              "linear-gradient(54.74deg, #52575C 30.03%, #526487 145.84%)",
          }}
        />
        <div className="d-flex align-items-center justify-content-end">
          <div className="d-flex align-items-center">
            <span className="font-90 text-principal">Nom du prescripteur</span>
            <span className="font-90 ml-2">
              Dr {props?.soin?.fiche?.Medecin?.firstName}{" "}
              {props?.soin?.fiche?.Medecin?.lastName}
            </span>
          </div>
          <div className="d-flex align-items-center ml-4">
            <span className="font-90 text-principal">Date: </span>
            <span className="font-90 ml-2">
              {moment(props?.soin?.fiche?.created_at).format("LL")}
            </span>
          </div>
        </div>
      </div>
      <Text
        className={`${
          props?.soin?.fiche?.stopped === true ? "striken" : ""
        } w-auto font-90 mt-3 mb-1`}
      >
        {props?.soin?.fiche?.produit || "-"}
      </Text>
      <Text
        className={`${props?.soin?.fiche?.stopped === true ? "striken" : ""}`}
      >
        <span className="text-principal font-90 mr-2">Posologie :</span>
        <span className="font-90 mr-2">
          {" "}
          {props?.soin?.fiche?.posologie || ""} -{" "}
        </span>
        <span className="text-principal font-90 mr-2">
          Voie d'administration:
        </span>
        <span className="font-90 mr-2">
          {" "}
          {props?.soin?.fiche?.voieAdmission || "Non spécifié"} -
        </span>
        <span className="text-principal font-90 mr-2">Temps de passage:</span>
        <span className="font-90 mr-2"> {props?.soin?.fiche?.duree || ""}</span>
      </Text>
      {Array.isArray(props?.soin?.fiche?.sous_produit) &&
        props?.soin?.fiche?.sous_produit?.length > 0 && (
          <Content className="mx-auto mt-3 font-90 p-3">
            {props?.soin?.fiche?.sous_produit?.map((sousProd, key) => (
              <Col
                md={12}
                lg={12}
                xs={12}
                className="m-0 p-1 d-flex align-items-center"
              >
                <Text className={sousProd?.stopped === true ? "striken" : ""}>
                  <div className="font-90 mr-2">
                    {sousProd?.produit || "-"} -
                  </div>
                  <div className="text-principal font-90">
                    Posologie : {sousProd?.posologie || ""}
                  </div>
                  {sousProd?.stopped === true && (
                    <div className="tooltipp font-90">
                      Arreté par Dr {sousProd?.Stoppeur?.nom || "-"}{" "}
                      {sousProd?.Stoppeur?.prenom || "-"} le{" "}
                      {moment(sousProd?.stopped_at).format(
                        "DD MMM YYYY à HH[h]mm [min]"
                      )}
                    </div>
                  )}
                </Text>
              </Col>
            ))}
          </Content>
        )}
      <div
        className={`w-100 d-flex align-items-center justify-content-${
          props.loading ? "between" : "end"
        }`}
      >
        {props?.loading && (
          <Spinner animation="border" size="sm" className="text-principal" />
        )}
        <div className="d-flex align-items-center">
          {!props?.soin?.fiche?.stopped && (
            <Button
              className="font-90 border-5 mt-3"
              size="sm"
              value="Executer"
              onClick={() => props.onExecute(props?.soin)}
            />
          )}
          {!props?.soin?.fiche?.stopped && (
            <Button
              className="font-90 border-5 ml-2 mt-3"
              size="sm"
              value="Arrêter"
              variant="gray"
              onClick={() => props.onStop(props?.soin)}
            />
          )}
          {/* <Button
          className="font-90 border-5 ml-2 text-black"
          size="sm"
          value="Reconduire la prescription"
          variant="infos"
          style={{ backgroundColor: "#FBF3D0" }}
        /> */}
        </div>
      </div>
    </Card>
  );
};

const Card = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  min-height: 70px;
  width: 100%;
`;

const Content = styled(Row)`
  background-color: #e3ecf07d;
  width: 100%;
  min-height: 100px;
  border-radius: 10px;
  color: #52575c;
`;

const Text = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  &.striken::after {
    border-bottom: 2px solid #ffbc1f;
    content: "";
    left: 0;
    margin-top: calc(0.125em / 2 * -1);
    position: absolute;
    right: 0;
    top: 50%;
  }
  .tooltipp {
    opacity: 0;
    position: absolute;
  }
  &:hover .tooltipp {
    opacity: 1;
    width: 400px;
    border-radius: 8px;
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04),
      0px 4px 32px rgba(0, 0, 0, 0.16);
    position: absolute;
    top: 120%;
    padding: 10px;
    z-index: 3;
  }
`;

CardPrescription.defaultProps = {
  loading: false,
  className: "",
  onExecute: () => {},
  onStop: () => {},
};

export default CardPrescription;
