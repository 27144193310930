import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Input, Textarea } from 'components';


interface FicheBiochimieProps {
  data:object;
  toBeSubmittedData:object;
  setToBeSubmittedData:() => void,
  subCategoryCode:String;
};

/* const example = {
  idFacture:{
    BIOCHIMIE:{
      acte_data:{
        NAU:'0.23',
        CLU:'0.43',
      },
      comment:"Blablablabla",
      global_resume:"<p>Okay</p>"
    },
  }
} */



const FicheBiochimie = (props: FicheBiochimieProps) => {
  const isDisabled = (acteCode)=>{
    return !(!! props?.data && !!props?.data[acteCode])
  }

  const isNormalValueField = (value, normalValues={})=>{
    if(!_.isEmpty(normalValues) && !!value && value!==""){
      const convertedValue = parseFloat(value)
      if(convertedValue < normalValues?.min || convertedValue > normalValues?.max){
        return false
      }
    }
    return true
  }

  const getActeAttributes=(acteName, isCommentField=false, min="0", normalValues={})=>{
    const value = isCommentField ?  props?.toBeSubmittedData[acteName] : props?.toBeSubmittedData[acteName]?.value
    const isNormalValue=isNormalValueField(value, normalValues);
    return {
      disabled: !isCommentField && isDisabled(acteName),
      value: value,
      onChange:handleInputChange,
      name:acteName,
      type:isCommentField ? null : "number",
      min:isCommentField ? null : min,
      placeholder: !isCommentField && isDisabled(acteName) ? "": "Saisir",
      className:!isCommentField && isDisabled(acteName) ? "fiche-disabled-input" : "",
      containerClassName:`border-0 ${isNormalValue ? "bg-light" : "fiche-bg-red"}`
    }
  }

  //const [acteData, setActeData] = useState({})

  const handleInputChange = el =>{
    if(!el){
      return
    }
    props?.setToBeSubmittedData(old=>({
      ...old,
      //[el?.target?.name]:el?.target?.name=="comment" ? el?.target?.value : parseFloat(el?.target?.value)
      [el?.target?.name]:el?.target?.name=="comment"? el?.target?.value : {value:el?.target?.value}
    }))
  }
  return (
    <Table>
      <tr>
        <th colSpan={8} className="bg-principal-pills">COMPTE RENDU D'EXAMEN DE BIOCHIMIE</th>
      </tr>
      <tr>
        <td>PARAMETRES</td>
        <td>RESULTATS</td>
        <td colSpan={2}>VALEURS NORMALES</td>
        <td colSpan={2}>PARAMETRES</td>
        <td>RESULTATS</td>
        <td>VALEURS NORMALES</td>
      </tr>
      <tr>
        <td colSpan={4}>SUBSTRATS</td>
        <td colSpan={4}>ENZYMES</td>
      </tr>
      <tr>
        <td>Glycémie à jeun</td>
        <td className="p-0">
          <Input {...getActeAttributes("FC01019", false, 0, {min:0.6, max:1.10})}
            
            style={{ fontSize: "12px" }}
          />
        </td>
        <td colSpan={2}>0,6 - 1,10 g/l</td>
        <td rowSpan={2}>Transaminases</td>
        <td>TGP</td>
        <td className="p-0">
          <Input {...getActeAttributes("FC01051", false, 0, {min:3, max:40})}
            
            style={{ fontSize: "12px" }}
          />
        </td>
        <td>3 - 40 UI/L</td>
      </tr>
      <tr>
        <td>Azotémie</td>
        <td className="p-0">
          <Input {...getActeAttributes("AZO", false, 0, {min:0.15, max:0.45})}
            
            
            style={{ fontSize: "12px" }}
          />
        </td>
        <td colSpan={2}>0,15 - 0,45 g/l</td>
        <td>TGO</td>
        <td className="p-0">
          <Input {...getActeAttributes("FC01050", false, 0, {min:3.12, max:38})}
            
            
            style={{ fontSize: "12px" }}
          />
        </td>
        <td>3,12 - 38 UI/L</td>
      </tr>
      <tr>
        <td>Créatinine</td>
        <td className="p-0">
          <Input {...getActeAttributes("FC01012", false, 0, {min:6, max:14})}
            
            
            style={{ fontSize: "12px" }}
          />
        </td>
        <td colSpan={2}>6 - 14 mg/l</td>
        <td colSpan={2}>Gamma GT</td>
        <td className="p-0">
          <Input {...getActeAttributes("FC01045", false, 0, {min:10, max:40})}
            
            
            style={{ fontSize: "12px" }}
          />
        </td>
        <td>10 - 40 UI/L</td>
      </tr>
      <tr>
        <td>Uricémie</td>
        <td className="p-0">
          <Input {...getActeAttributes("URICEMIE", false, 0, {min:25, max:77})}
            
            
            style={{ fontSize: "12px" }}
          />
        </td>
        <td colSpan={2}>25 - 77 mg/l</td>
        <td colSpan={2}>PAL</td>
        <td className="p-0">
          <Input {...getActeAttributes("FC01047", false, 0, {min:100, max:280})}
            
            
            style={{ fontSize: "12px" }}
          />
        </td>
        <td>100 - 280 UI/L</td>
      </tr>
      <tr>
        <td>Cholestérol total</td>
        <td className="p-0">
          <Input {...getActeAttributes("FC01007", false, 0, {min:1.2, max:2})}
            
            
            style={{ fontSize: "12px" }}
          />
        </td>
        <td colSpan={2}>1,2 - 2 g/l</td>
        <td colSpan={2}>PAC</td>
        <td className="p-0">
          <Input {...getActeAttributes("FC01048", false, 0, {min:0, max:10})}
            
            
            style={{ fontSize: "12px" }}
          />
        </td>
        <td>{"<"} 10 UI/L</td>
      </tr>
      <tr>
        <td>Cholestérol HDL</td>
        <td className="p-0">
          <Input {...getActeAttributes("FC01009", false, 0, {min:0.35, max:0.65})}
            
            
            style={{ fontSize: "12px" }}
          />
        </td>
        <td colSpan={2}>0,35 - 0,65 g/l</td>
        <td colSpan={2}>Amylase</td>
        <td className="p-0">
          <Input {...getActeAttributes("FC02004", false, 0, {min:0, max:85})}
            
            
            style={{ fontSize: "12px" }}
          />
        </td>
        <td>{"<"} 85 UI/L</td>
      </tr>
      <tr>
        <td>Triglycérides</td>
        <td className="p-0">
          <Input {...getActeAttributes("FC01037", false, 0, {min:0.35, max:1.65})}
            
            
            style={{ fontSize: "12px" }}
          />
        </td>
        <td colSpan={2}>0,35 - 1,65 g/l</td>
        <td colSpan={4}>
          IONS
        </td>
      </tr>
      <tr>
        <td>IA</td>
        <td className="p-0">
          <Input {...getActeAttributes("FF00035", false, 0, {min:0, max:3.22})}
            
            
            style={{ fontSize: "12px" }}
          />
        </td>
        <td colSpan={2}>{"<"}3,22</td>
        <td colSpan={2}>Calcium</td>
        <td className="p-0">
          <Input {...getActeAttributes("FC02006", false, 0, {min:86, max:102})}
            
            
            style={{ fontSize: "12px" }}
          />
        </td>
        <td>86 - 102 mg/l</td>
      </tr>
      <tr>
        <td>Cholestérol LDL</td>
        <td className="p-0">
          <Input {...getActeAttributes("CHOLDL", false, 0, {min:1.30, max:1.60})}
            
            
            style={{ fontSize: "12px" }}
          />
        </td>
        <td colSpan={2}>1,30 - 1,60 g/l</td>
        <td colSpan={2}>Magnésium</td>
        <td className="p-0">
          <Input {...getActeAttributes("FC02026", false, 0, {min:16, max:25})}
            
            
            style={{ fontSize: "12px" }}
          />
        </td>
        <td>16 - 25 mg/l</td>
      </tr>
      <tr>
        <td>Bilirubines T</td>
        <td className="p-0">
          <Input {...getActeAttributes("FC02005", false, 0, {min:2, max:11})}
            
            
            style={{ fontSize: "12px" }}
          />
        </td>
        <td colSpan={2}>2 - 11 mg/l</td>
        <td colSpan={2}>Sodium</td>
        <td className="p-0">
          <Input {...getActeAttributes("NAU", false, 0, {min:135, max:145})}
            
            
            style={{ fontSize: "12px" }}
          />
        </td>
        <td>135 - 145 mg/L</td>
      </tr>
      <tr>
        <td>Bilirubines D</td>
        <td className="p-0">
          <Input {...getActeAttributes("FC02005", false, 0, {min:0.60, max:2.5})}
            
            
            style={{ fontSize: "12px" }}
          />
        </td>
        <td colSpan={2}>0,60 - 2,5 mg/L</td>
        <td colSpan={2}>Potassium</td>
        <td className="p-0">
          <Input {...getActeAttributes("KU", false, 0, {min:3.5, max:5})}
            
            
            style={{ fontSize: "12px" }}
          />
        </td>
        <td>3,5 - 5 mEq/L</td>
      </tr>
      <tr>
        <td>Protides totaux</td>
        <td className="p-0">
          <Input {...getActeAttributes("PROTID", false, 0, {min:60, max:80})}
            
            
            style={{ fontSize: "12px" }}
          />
        </td>
        <td colSpan={2}>60 - 80 g/l</td>
        <td colSpan={2}>Chlore</td>
        <td className="p-0">
          <Input {...getActeAttributes("FC03000", false, 0, {min:98, max:108})}
            
            
            style={{ fontSize: "12px" }}
          />
        </td>
        <td>98 - 108 mEq/L</td>
      </tr>
      <tr>
        <td>Protéinuries 24h</td>
        <td className="p-0">
          <Input {...getActeAttributes("PROTEI24", false, 0, {min:0, max:145})}
            
            
            style={{ fontSize: "12px" }}
          />
        </td>
        <td colSpan={2}>{"<"} 145 mg/24h</td>
        <td colSpan={2}>Phosphore</td>
        <td className="p-0">
          <Input {...getActeAttributes("FC02014", false, 0, {min:20, max:50})}
            
            
            style={{ fontSize: "12px" }}
          />
        </td>
        <td>20 - 50 mg/L</td>
      </tr>
      <tr>
        <td>Diurèses</td>
        <td className={`p-0 ${!isNormalValueField(props?.toBeSubmittedData["DIUR"]?.value, {min:1200, max:2000}) ? "fiche-bg-red" : "bg-light"}`} >
          <Input {...getActeAttributes("DIUR", false, 0, {min:1200, max:2000})}
            containerClassName="border-0"
            style={{ fontSize: "12px" }}
          />
        </td>
        <td>1200 ml - 1500 ml/24h</td>
        <td>1500 ml - 2000 ml/24h</td>
        <td colSpan={4} className="p-0 bg-light">
          <Textarea {...getActeAttributes("comment", true)}
            label="Commentaire :"
            labelClassName="font-weight-bold w-100 text-left px-2 font-90"
            containerClassName="border-0 p-0"
            className="pt-0"
            style={{ fontSize: "12px" }}
            rows={3}
          />
        </td>
      </tr>
    </Table>
  );
};

const Table = styled.table`
  width: 100%;
  border: 0.5px solid #ABABAB;
  border-collapse: collapse;
  th {
    border: 0.5px solid #ABABAB;
    padding: 10px 5px;
    font-weight: bold;
    text-align: center;
    font-size: 12px;
  }
  td {
    padding: 10px;
    text-align: center;
    border: 0.5px solid #ABABAB;
    border-collapse: collapse;
    font-size: 12px;
  }
`;

export default FicheBiochimie;