import React from 'react';
import { Popover, makeStyles } from "@material-ui/core";
import styled from 'styled-components';
import PropTypes from "prop-types";


interface OptionPickerProps {
  visible: boolean;
  anchor: any;
  onHide: () => void;
  onChange: (value: any) => void;
  options: {
    label: string;
    value: string;
    icon: React.ReactNode;
  }[];
};

const OptionPicker = (props: OptionPickerProps) => {
  const classes = useStyles();

  return (
    <Popover
      id={props.visible ? "simple-popover" : undefined}
      open={props.visible}
      anchorEl={props.anchor}
      onClose={props.onHide}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      classes={{ paper: classes.paper }}
    >
      <div className="d-flex flex-column w-100">
        {props?.options?.map((el, k) => (
          <Option
            key={k}
            onClick={props.onChange.bind(this, el?.value)}
          >{el?.icon || null}{el?.label || ""}</Option>
        ))}
      </div>
    </Popover>
  );
};

OptionPicker.defaultProps = {
  visible: false,
  options: [],
  onChange: () => console.log("No onChange method given"),
  onHide: () => console.log("No onHide method given"),
};

const useStyles = makeStyles(() => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    width: '200px',
    borderRadius: "10px",
    marginTop: "5px",
    boxShadow: "-2px -1px 10px 2px rgba(192, 197, 223, 0.2)"
  }
}));

const Option = styled.div`
  padding: 15px;
  cursor: pointer;
  &:hover {
    background-color: #ddd8;
  }
`;

export default OptionPicker;