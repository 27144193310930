import React, { createContext, useReducer, useEffect, useContext } from "react";
import reducer from './reducer'
import _ from 'lodash';
import { AgentType, UserType, RoleType, ServiceType, CentreConfigsType, InitDataType, SessionCaisse } from 'types';

const STORAGE_KEY = process.env.REACT_APP_APP_STORAGE_KEY;

interface StoreType {
  dispatch: () => void;
  state: {
    token: string;
    currentUser: UserType;
    agent: AgentType;
    displayRoom: boolean;
    displayServiceRoom: boolean;
    role: RoleType;
    initData: InitDataType;
    activeService: ServiceType;
    configs: CentreConfigsType;
    caisse: SessionCaisse;
  };
};

const INITIAL_STATE = {
  token: null,
  currentUser: null,
  agent: null,
  displayRoom: false,
  displayServiceRoom: false,
  role: null,
  initData: null,
  activeService: null,
  configs: null,
  caisse: null,
};

const WHITE_LIST = ['token', 'activeService']

const store = createContext(INITIAL_STATE);
const { Provider } = store;

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE, (state) => {
    const persistedData = localStorage.getItem(STORAGE_KEY);
    const data = persistedData ? JSON.parse(persistedData) : [];
    return { ...state, ...data };
  });

  useEffect(() => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(_.pick(state, WHITE_LIST)));
  }, [state]);

  useEffect(() => {
    window.addEventListener('storage', () => {
      const persistedData = localStorage.getItem(STORAGE_KEY);
      const newData = persistedData ? (JSON.parse(persistedData)) : null;
      if (newData) {
        dispatch({ type: 'SYNC_REQUEST', payload: newData });
      }
    })
  }, []);

  return (
    <Provider value={{ state, dispatch }}>
      {children}
    </Provider>
  );
};

const useStore = (): StoreType => useContext(store);
export { store, StateProvider, useStore };