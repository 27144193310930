import { ReactComponent as StatIcon } from "assets/icons/stat.svg";
import routes from "configs/routes";

export const PresentationList = [
  {
    value: "Céphalique mobile",
    text: "Céphalique mobile",
  },
  {
    value: "Céphalique amorçée",
    text: "Céphalique amorçée",
  },
  {
    value: "Céphalique engagée",
    text: "Céphalique engagée",
  },
  {
    value: "Transversalle",
    text: "Transversalle",
  },
  {
    value: "Excavation vide",
    text: "Excavation vide",
  },
  {
    value: "Foetus mobile",
    text: "Foetus mobile",
  },
  {
    value: "Ballottement foetal",
    text: "Ballottement foetal",
  },
  {
    value: "Uterus globuleux",
    text: "Uterus globuleux",
  },
  {
    value: "Siège",
    text: "Siège",
  },
  {
    value: "Fixée",
    text: "Fixée",
  },

  {
    value: "Podalique",
    text: "Podalique",
  },
];

export const EtatVulvesList = [
  {
    value: "Propre",
    text: "Propre",
  },
  {
    value: "Souillées de glaire",
    text: "Souillées de glaire",
  },
  {
    value: "Souillée de perte banale",
    text: "Souillée de perte banale",
  },
  {
    value: "Souillée de perte mucosique",
    text: "Souillée de perte mucosique",
  },
  {
    value: "Souillée de perte verdâtre",
    text: " Souillée de perte verdâtre",
  },
  {
    value: "Souillée de Sang",
    text: "Souillée de Sang",
  },
  {
    value: "Souillée de Liquide Amiotique",
    text: "Souillée de Liquide Amiotique",
  },
  {
    value: "Souillée de Liquide Amiotique Clair",
    text: "Souillée de Liquide Amiotique Clair",
  },
  {
    value: "Souillée de Liquide Amiotique purée de pois",
    text: "Souillée de Liquide Amiotique purée de pois",
  },
  {
    value: "Souillée de Liquide Amiotique jaune citrin",
    text: "Souillée de Liquide Amiotique jaune citrin",
  },
  {
    value: "Souillée de Liquide Amiotique hématique",
    text: "Souillée de Liquide Amiotique hématique",
  },
  {
    value: "Souillée de Liquide Amiotique méconial",
    text: "Souillée de Liquide Amiotique méconial",
  },
  {
    value: "Souillée de Liquide Amiotique fétide",
    text: "Souillée de Liquide Amiotique fétide",
  },
];

export const OMIList = [
  {
    value: "Absents",
    text: "Absents",
  },
  {
    value: "Présents",
    text: "Présents",
  },
];

export const MuqueusesList = [
  {
    value: "Colorées",
    text: "Colorées",
  },
  {
    value: "Peu colorées",
    text: "Peu colorées",
  },
  {
    value: "Pâles",
    text: "Pâles",
  },
];

export const dataLabo = [
  {
    ref: 1,
    namePatient: "SOSSOU Edwige",
    datePrescription: "25/02/2021",
    nameMedecin: "DEGBA Yaya",
    datePaie: "30/04/2021",
  },
  {
    ref: 2,
    namePatient: "SOSSOU Edwige",
    datePrescription: "25/02/2021",
    nameMedecin: "DEGBA Yaya",
    datePaie: "30/04/2021",
  },
  {
    ref: 3,
    namePatient: "SOSSOU Edwige",
    datePrescription: "25/02/2021",
    nameMedecin: "DEGBA Yaya",
    datePaie: "30/04/2021",
  },
  {
    ref: 4,
    namePatient: "SOSSOU Edwige",
    datePrescription: "25/02/2021",
    nameMedecin: "DEGBA Yaya",
    datePaie: "30/04/2021",
  },
  {
    ref: 5,
    namePatient: "SOSSOU Edwige",
    datePrescription: "25/02/2021",
    nameMedecin: "DEGBA Yaya",
    datePaie: "30/04/2021",
  },
  {
    ref: 6,
    namePatient: "SOSSOU Edwige",
    datePrescription: "25/02/2021",
    nameMedecin: "DEGBA Yaya",
    datePaie: "30/04/2021",
  },
  {
    ref: 7,
    namePatient: "SOSSOU Edwige",
    datePrescription: "25/02/2021",
    nameMedecin: "DEGBA Yaya",
    datePaie: "30/04/2021",
  },
  {
    ref: 8,
    namePatient: "SOSSOU Edwige",
    datePrescription: "25/02/2021",
    nameMedecin: "DEGBA Yaya",
    datePaie: "30/04/2021",
  },
  {
    ref: 9,
    namePatient: "SOSSOU Edwige",
    datePrescription: "25/02/2021",
    nameMedecin: "DEGBA Yaya",
    datePaie: "30/04/2021",
  },
  {
    ref: 10,
    namePatient: "SOSSOU Edwige",
    datePrescription: "25/02/2021",
    nameMedecin: "DEGBA Yaya",
    datePaie: "30/04/2021",
  },
  {
    ref: 11,
    namePatient: "SOSSOU Edwige",
    datePrescription: "25/02/2021",
    nameMedecin: "DEGBA Yaya",
    datePaie: "30/04/2021",
  },
  {
    ref: 12,
    namePatient: "SOSSOU Edwige",
    datePrescription: "25/02/2021",
    nameMedecin: "DEGBA Yaya",
    datePaie: "30/04/2021",
  },
  {
    ref: 13,
    namePatient: "SOSSOU Edwige",
    datePrescription: "25/02/2021",
    nameMedecin: "DEGBA Yaya",
    datePaie: "30/04/2021",
  },
  {
    ref: 14,
    namePatient: "SOSSOU Edwige",
    datePrescription: "25/02/2021",
    nameMedecin: "DEGBA Yaya",
    datePaie: "30/04/2021",
  },
  {
    ref: 15,
    namePatient: "SOSSOU Edwige",
    datePrescription: "25/02/2021",
    nameMedecin: "DEGBA Yaya",
    datePaie: "30/04/2021",
  },
  {
    ref: 16,
    namePatient: "SOSSOU Edwige",
    datePrescription: "25/02/2021",
    nameMedecin: "DEGBA Yaya",
    datePaie: "30/04/2021",
  },
  {
    ref: 17,
    namePatient: "SOSSOU Edwige",
    datePrescription: "25/02/2021",
    nameMedecin: "DEGBA Yaya",
    datePaie: "30/04/2021",
  },
  {
    ref: 18,
    namePatient: "SOSSOU Edwige",
    datePrescription: "25/02/2021",
    nameMedecin: "DEGBA Yaya",
    datePaie: "30/04/2021",
  },
  {
    ref: 19,
    namePatient: "SOSSOU Edwige",
    datePrescription: "25/02/2021",
    nameMedecin: "DEGBA Yaya",
    datePaie: "30/04/2021",
  },
  {
    ref: 20,
    namePatient: "SOSSOU Edwige",
    datePrescription: "25/02/2021",
    nameMedecin: "DEGBA Yaya",
    datePaie: "30/04/2021",
  },
  {
    ref: 21,
    namePatient: "SOSSOU Edwige",
    datePrescription: "25/02/2021",
    nameMedecin: "DEGBA Yaya",
    datePaie: "30/04/2021",
  },
  {
    ref: 22,
    namePatient: "SOSSOU Edwige",
    datePrescription: "25/02/2021",
    nameMedecin: "DEGBA Yaya",
    datePaie: "30/04/2021",
  },
  {
    ref: 23,
    namePatient: "SOSSOU Edwige",
    datePrescription: "25/02/2021",
    nameMedecin: "DEGBA Yaya",
    datePaie: "30/04/2021",
  },
  {
    ref: 24,
    namePatient: "SOSSOU Edwige",
    datePrescription: "25/02/2021",
    nameMedecin: "DEGBA Yaya",
    datePaie: "30/04/2021",
  },
  {
    ref: 25,
    namePatient: "SOSSOU Edwige",
    datePrescription: "25/02/2021",
    nameMedecin: "DEGBA Yaya",
    datePaie: "30/04/2021",
  },
];
export const antecedants = {
  antecedentPatient: {
    antecedentId: "string",
    antecedantName: "string",
    description: "string",
    antecedentSlug: "string",
    patientId: "string",
    antecedentSpecificDiseases: [
      {
        specificDiseaseId: "string",
        specificDiseaseName: "string",
        antecedentPatientId: "string",
        antecedentPatient: "string",
        additionalProp1: {},
      },
    ],
    patients: ["string"],
  },
  antecedentPatients: [
    {
      antecedentId: "string",
      antecedantName: "string",
      description: "string",
      antecedentSlug: "string",
      patientId: "string",
      antecedentSpecificDiseases: [
        {
          specificDiseaseId: "string",
          specificDiseaseName: "string",
          antecedentPatientId: "string",
          antecedentPatient: "string",
          additionalProp1: {},
        },
      ],
      patients: ["string"],
    },
  ],
};

export const STATS = [
  {
    slug: "A12",
    title: "Statistiques A12",
    icon: <StatIcon />,
    route: routes["STATS.A12"],
    /* func: () => {
          const url = `${API_BASE_URL}/${OPERATIONS_SUFFIX}/stats/rapport_a_12`
          window.open(url, '_blank').focus();
        }, */
  },
  {
    slug: "RSC_B1",
    title: "Registre des soins curatifs B1",
    icon: <StatIcon />,
    route: routes["STATS.PRINT"],
  },
  /* {
     slug: "NCE",
     title: "Nombre de consultations effectuées",
     icon: <StatIcon />,
     route: routes["STATS.CONSULTATIONS"],
   }, */
  {
    slug: "RAPS",
    title: "Rapport d'activité par service",
    icon: <StatIcon />,
    route: routes["STATS.BY.SERVICE"],
  },
  {
    slug: "C6",
    title: "Rapport sur les activités de Maternité C6",
    icon: <StatIcon />,
    route: routes["STATS.C6"],
  },
  {
    slug: "RSMI",
    title: "Rapport sur la santé maternelle et infantile",
    icon: <StatIcon />,
    route: routes["STATS.RSMI"],
  },
  {
    slug: "RE_B5A",
    title: "Relevé épidémiologique mensuel des centres de santé",
    icon: <StatIcon />,
    route: routes["STATS.B5A"],
  },
  {
    slug: "CONS_PERIOD",
    title: "Liste des consultations sur une période",
    icon: <StatIcon />,
    route: routes["STATS.CONS.PERIOD"],
  },
  {
    slug: "HOSPI_PERIOD",
    title: "Liste des hospitalisations en cours sur une période",
    icon: <StatIcon />,
    route: routes["STATS.HOSPI.PERIOD"],
  },
  {
    slug: "HOSPI_CLOT_PERIOD",
    title: "Liste des hospitalisations clôturées sur une période",
    icon: <StatIcon />,
    route: routes["STATS.HOSPI.CLOTURE.PERIOD"],
  },
  {
    slug: "FEMME_ACCOUCHEMENT",
    title: "Liste des femmes en salle d'accouchement",
    icon: <StatIcon />,
    route: routes["STATS.FEMME_ACCOUCHEMENT"],
  },

  /* {
      slug: "RCPN",
      title: "Registre de CPN",
      icon: <StatIcon />,
      route: null,
    },
    {
      slug: "RCPON",
      title: "Registre CPoN",
      icon: <StatIcon />,
      route: null,
    },
    {
      slug: "RSMI",
      title: "Rapport sur la santé maternelle et infantile",
      icon: <StatIcon />,
      route: null,
    },
    {
      slug: "FRMG",
      title: "Fiche de rapport mensuel sur le GATPA",
      icon: <StatIcon />,
      route: null,
    },
    {
      slug: "RDV",
      title: "Registre de vaccination",
      icon: <StatIcon />,
      route: routes["STATS.BY.SERVICE"],
    },
    {
      slug: "RCPS",
      title: "Registre de consultation par service",
      icon: <StatIcon />,
      route: null,
    },*/

  /* {
      slug: "FNDMN",
      title: "Fiche de notification des décès maternels et néonatals",
      icon: <StatIcon />,
      route: null,
    },
    {
      slug: "FNMPE",
      title: "Fiche de notification des MPE",
      icon: <StatIcon />,
      route: null,
    },
    {
      slug: "CIF",
      title: "Carte infantile",
      icon: <StatIcon />,
      route: null,
    },
    {
      slug: "RACC",
      title: "Registre d'accouchement",
      icon: <StatIcon />,
      route: null,
    },
    {
      slug: "RDJSMI",
      title: "Registre de décompte journalier SMI C5",
      icon: <StatIcon />,
      route: null,
    },
    {
      slug: "RPF",
      title: "Registre de PF",
      icon: <StatIcon />,
      route: null,
    },
    {
      slug: "RNTR",
      title: "Registre nutrition",
      icon: <StatIcon />,
      route: null,
    },
    {
      slug: "RMSL",
      title: "Rapport mensuel de surveillance et logistique PNLP 1",
      icon: <StatIcon />,
      route: null,
    }, */
];

export const ANTECEDENTS = [
  {
    title: "Terrain et Maladies chroniques",
    data: [
      "Diabétique",
      "Drépanocytose",
      "Autres maladies chroniques",
      "Hypertension artérielle (HTA)",
      "(AVC)",
      "Ulcère gastrique ou duodénale",
      "Insuffisance rénale",
    ],
  },
  {
    title: "Atopies et alergies",
    data: [
      "Alergies medicamenteuses",
      "Asthme bronchique",
      "Autres type d'allergie",
    ],
  },
  {
    title: "Sérologie",
    data: ["TPHA", "HIV", "VDRL", "Hépatite"],
  },
  {
    title: "Exposition",
    data: [
      "Professionnelle",
      "Alcool",
      "Tabac",
      "Autres drogues et stupéfiants",
    ],
  },
  {
    title: "Antécédents gynecologiques",
    data: [
      "Durée des regles",
      "Age de la puberté",
      "Durée du cycle",
      "Affections gynécologiques",
      "Régularité du cycle",
      "Age de la ménopause",
      "Trouble des règles",
    ],
  },
  {
    title: "Antécédents obstetricales",
    data: [
      "Gestité",
      "Parité",
      "Enfant(s) mort(s)-né(s)",
      "Enfant(s) vivant(s)",
      "Enfant(s) décédé(s)",
      "Fausse couche provoqué",
      "Fausse(s) couche(s) spontanée(s)",
    ],
  },
  {
    title: "Autres",
    data: [
      "Transfusion Antérieure",
      "Antécédents familiaux",
      "Antécédents psychiatriques/psychologiques",
      "Antécédents chirugicaux",
    ],
  },
];

export const WEEKDAYS_LIST = [
  "Lundi",
  "Mardi",
  "Mercredi",
  "Jeudi",
  "Vendredi",
  "Samedi",
  "Dimanche",
];

export const LANGUAGES_LIST = [
  { name: "Albanais" },
  { name: "Allemand" },
  { name: "Afrikaans" },
  { name: "Araona" },
  { name: "Azéri" },
  { name: "Amazigh" },
  { name: "Arménien" },
  { name: "Aymara" },
  { name: "Anglais" },
  { name: "Arabe" },
  { name: "Bengali" },
  { name: "Bulgare" },
  { name: "Bésiro" },
  { name: "Bichelamar" },
  { name: "Biélorusse" },
  { name: "Birman" },
  { name: "Catalan" },
  { name: "Coréen" },
  { name: "Croate" },
  { name: "Cambodgien" },
  { name: "Chinois" },
  { name: "Danois" },
  { name: "Espagnol" },
  { name: "Finnois" },
  { name: "Français" },
  { name: "Japonais" },
  { name: "Portugais" },
  { name: "Russe" },
  { name: "Guarani" },
  { name: "Grec" },
  { name: "Hongrois" },
  { name: "Italien" },
  { name: "Kikongo" },
  { name: "Kiswahili" },
  { name: "Lingala" },
  { name: "Malais" },
  { name: "Mongol" },
  { name: "Néerlandais" },
  { name: "Occitan" },
  { name: "Ourdou" },
  { name: "Persan" },
  { name: "Quechua" },
  { name: "Roumain" },
  { name: "Samoan" },
  { name: "Serbe" },
  { name: "Sesotho" },
  { name: "Slovaque" },
  { name: "Slovène" },
  { name: "Suédois" },
  { name: "Tamoul" },
  { name: "Turc" },
  { name: "Canichana" },
  { name: "Cavineña" },
  { name: "Cayubaba" },
  { name: "Chácobo" },
  { name: "Chichewa" },
  { name: "Chimane" },
  { name: "Créole de Guinée-Bissau" },
  { name: "Créole haïtien" },
  { name: "Créole seychellois" },
  { name: "Divehi" },
  { name: "Dzongkha" },
  { name: "Ese 'ejja" },
  { name: "Estonien" },
  { name: "Éwé" },
  { name: "Fidjien" },
  { name: "Filipino" },
  { name: "Géorgien" },
  { name: "Gilbertin" },
  { name: "Guarasu'we" },
  { name: "Guarayu" },
  { name: "Hébreu" },
  { name: "Hindi" },
  { name: "Hindi des Fidji" },
  { name: "Hiri motu" },
  { name: "Indonésien" },
  { name: "Irlandais" },
  { name: "Islandais" },
  { name: "Itonama" },
  { name: "Kallawaya" },
  { name: "Kabiyè" },
  { name: "Kazakh" },
  { name: "Khmer" },
  { name: "Kirghiz" },
  { name: "Kirundi" },
  { name: "Lao" },
  { name: "Néo-zélandaise" },
  { name: "Papouasienne" },
  { name: "Latin" },
  { name: "Leko" },
  { name: "Letton" },
  { name: "Lituanien" },
  { name: "Luxembourgeois" },
  { name: "Macédonien" },
  { name: "Machineri" },
  { name: "Malgache" },
  { name: "Maltais" },
  { name: "Maori" },
  { name: "Maori des Îles Cook" },
  { name: "Maropa" },
  { name: "Marshallais" },
  { name: "Mirandais" },
  { name: "Mojeño-Trinitario" },
  { name: "Mojeño-Ignaciano" },
  { name: "Monténégrin" },
  { name: "Moré" },
  { name: "Mosetén" },
  { name: "Movima" },
  { name: "Nauruan" },
  { name: "Ndébélé du Transvaal" },
  { name: "Népalais" },
  { name: "Norvégien" },
  { name: "Ouzbek" },
  { name: "Pacahuara" },
  { name: "Pachto" },
  { name: "Paluan" },
  { name: "Polonais" },
  { name: "Puquina" },
  { name: "Sango" },
  { name: "Shikomor" },
  { name: "Shona" },
  { name: "Shuar" },
  { name: "Sindebele" },
  { name: "Singhalais" },
  { name: "Sirionó" },
  { name: "Somali" },
  { name: "Sotho du Nord" },
  { name: "Sotho du Sud" },
  { name: "Tacana" },
  { name: "Tadjik" },
  { name: "Tamazight" },
  { name: "Tapiete" },
  { name: "Tchèque" },
  { name: "Tétoum" },
  { name: "Tigrinya" },
  { name: "Thaï" },
  { name: "Tok pisin" },
  { name: "Tongien" },
  { name: "Toromona" },
  { name: "Turkmène" },
  { name: "Tuvaluan" },
  { name: "Ukrainien" },
  { name: "Uru-Chipaya" },
  { name: "Vietnamien" },
  { name: "Wichi" },
  { name: "Yaminahua" },
  { name: "Yuki" },
  { name: "Yaracaré" },
  { name: "Zamuco" },
  { name: "Zoulou" },
  { name: "Adyguéen" },
  { name: "Agul" },
  { name: "Altaï" },
  { name: "Assamais" },
  { name: "Avar" },
  { name: "Bachkir" },
  { name: "Bas saxon" },
  { name: "Basque" },
  { name: "Bouriate" },
  { name: "Cantonais" },
  { name: "Carolinien" },
  { name: "Chamorro" },
  { name: "Darguine" },
  { name: "Erzya" },
  { name: "Galicien" },
  { name: "Gallois" },
  { name: "Gwich'in" },
  { name: "Hawaïen" },
  { name: "Iakoute" },
  { name: "Ingouche" },
  { name: "Inuinnaqtun" },
  { name: "Inuktitut" },
  { name: "Kabarde" },
  { name: "Kalmouk" },
  { name: "Karatchaï-balkar" },
  { name: "Khakasse" },
  { name: "Komi" },
  { name: "Koumyk" },
  { name: "Lak" },
  { name: "Lezghien" },
  { name: "Mannois" },
  { name: "Mari" },
  { name: "Moksa" },
  { name: "Nogaï" },
  { name: "Occitan (aranais)" },
  { name: "Ossète" },
  { name: "Oudmourte" },
  { name: "Ouïghour" },
  { name: "Romanche" },
  { name: "Routoul" },
  { name: "Ruthène" },
  { name: "Same" },
  { name: "Sorabe" },
  { name: "Swati" },
  { name: "Tabassaran" },
  { name: "Tat" },
  { name: "Tatare" },
  { name: "Tchétchène" },
  { name: "Tchouvache" },
  { name: "Tibétain" },
  { name: "Tobi" },
  { name: "Touvain" },
  { name: "Tsakhur" },
  { name: "Tsonga" },
  { name: "Tswana" },
  { name: "Venda" },
  { name: "Xhosa" },
];

export const vaccinationNutritionalGraphsWithMonthYearLabels = [
  {
    nbrOfMonth: 0,
    label: "0",
  },
  {
    nbrOfMonth: 2,
    label: "2mois",
  },
  {
    nbrOfMonth: 4,
    label: "4mois",
  },
  {
    nbrOfMonth: 6,
    label: "6mois",
  },
  {
    nbrOfMonth: 8,
    label: "8mois",
  },
  {
    nbrOfMonth: 10,
    label: "10mois",
  },
  {
    nbrOfMonth: 12,
    label: "1an",
  },
  {
    nbrOfMonth: 14,
    label: "1an2mois",
  },
  {
    nbrOfMonth: 16,
    label: "1an4mois",
  },
  {
    nbrOfMonth: 18,
    label: "1an6mois",
  },
  {
    nbrOfMonth: 20,
    label: "1an8mois",
  },
  {
    nbrOfMonth: 22,
    label: "1an10mois",
  },
  {
    nbrOfMonth: 24,
    label: "2ans",
  },
  {
    nbrOfMonth: 26,
    label: "2ans2mois",
  },
  {
    nbrOfMonth: 28,
    label: "2ans4mois",
  },
  {
    nbrOfMonth: 30,
    label: "2ans6mois",
  },
  {
    nbrOfMonth: 32,
    label: "2ans8mois",
  },
  {
    nbrOfMonth: 34,
    label: "2ans10mois",
  },
  {
    nbrOfMonth: 36,
    label: "3ans",
  },
  {
    nbrOfMonth: 38,
    label: "3ans2mois",
  },
  {
    nbrOfMonth: 40,
    label: "3ans4mois",
  },
  {
    nbrOfMonth: 42,
    label: "3ans6mois",
  },
  {
    nbrOfMonth: 44,
    label: "3ans8mois",
  },
  {
    nbrOfMonth: 46,
    label: "3ans10mois",
  },
  {
    nbrOfMonth: 48,
    label: "4ans",
  },
  {
    nbrOfMonth: 50,
    label: "4ans2mois",
  },
  {
    nbrOfMonth: 52,
    label: "4ans4mois",
  },
  {
    nbrOfMonth: 54,
    label: "4ans6mois",
  },
  {
    nbrOfMonth: 56,
    label: "4ans8mois",
  },
  {
    nbrOfMonth: 58,
    label: "4ans10mois",
  },
  {
    nbrOfMonth: 60,
    label: "5ans",
  },
];
