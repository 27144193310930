import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Input } from 'components';
import { PatientType, ImagerieExam } from 'types';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { useDebounce } from 'hooks';
import { resetObject, validate } from 'context/swift-inputs';
import moment from 'moment';
import { BACKEND_DATE_FORMAT } from 'configs';
import { stateFromHTML } from 'draft-js-import-html';


interface FieldsType {
  result_date: string;
  medecin_demandeur: string;
  indication: string;
  comment: string;
};

interface FicheEchoDefaultProps {
  exam: ImagerieExam;
  className: string;
  patient: PatientType;
  fields: FieldsType;
  onChange: (fields: FieldsType, isValid: boolean) => void;
  readOnly: boolean;
};

const FicheEchoDefault = (props: FicheEchoDefaultProps) => {
  const [fields, setFields] = useState(props?.fields);

  const [errors, setErrors] = useState({
    medecin_demandeur: "",
    indication: "",
    comment: "",
  });

  useDebounce(() => {
    !props?.readOnly && props?.onChange(fields, checkForm(fields));
  }, 100, [fields]);

  const checkForm = (fieldsData: FieldsType) => {
    const validation = validate(fieldsData, {
      medecin_demandeur: "required|name",
      indication: "required",
      comment: value => ({
        valid: !!value && value !== "<p><br></p>",
        error: (!!value && value !== "<p><br></p>") ? "" : "Veuillez renseigner un commentaire",
      }),
    });

    if (validation?.isFormValid()) {
      setErrors(resetObject(errors));
      return true;
    } else {
      setErrors(old => ({
        ...old,
        medecin_demandeur: validation?.response?.medecin_demandeur?.error,
        indication: validation?.response?.indication?.error,
        comment: validation?.response?.comment?.error,
      }));
      return false;
    }
  };

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [toolbarOptions, setToolbarOptions] = useState({
    options: ['inline', 'blockType', 'fontSize', 'colorPicker', 'link', 'emoji', 'remove', 'history']
  });

  const onEditorStateChange = newState => {
    setEditorState(newState);
    setFields(old => ({
      ...old,
      comment: stateToHTML(newState.getCurrentContent())
    }));
  };

  useEffect(() => {
    setEditorState(EditorState.createWithContent(stateFromHTML(props?.fields?.comment)));
  }, []);

  return (
    <Container className={`w-100 ${props.className || ""}`}>
      <div className="w-100 p-3 border-bottom font-weight-bold bg-principal-pills text-center ">
        {props?.exam?.libelle || "ECHOGRAPHIE"}
      </div>
      <div className="p-3 d-flex flex-column w-100">
        <div className="align-self-end d-flex align-items-center">
          <span className="mr-3">Date :</span>
          <Input
            wrapperStyle={{ width: "120px" }}
            containerClassName="border-left-0 border-top-0 border-right-0"
            className="px-0 py-1 h-auto"
            type="date"
            value={props?.fields?.result_date}
          />
        </div>
        <div className="w-100 d-flex flex-wrap">
          <div className="d-flex align-items-center mr-5 mt-4 w-40">
            <span className="mr-3 white-space-nowrap">Nom et Prénoms :</span>
            <Input
              containerClassName="border-left-0 border-top-0 border-right-0"
              className="px-0 py-1 h-auto"
              readOnly
              value={`${props?.patient?.lastName || '-'}${!!props?.patient?.firstName ? ' ' + props?.patient?.firstName : ''}`}
            />
          </div>
          <div className="d-flex align-items-center mr-5 mt-4">
            <span className="mr-3 white-space-nowrap">Age :</span>
            <Input
              containerClassName="border-left-0 border-top-0 border-right-0"
              className="px-0 py-1 h-auto"
              wrapperStyle={{ width: "150px" }}
              readOnly
              value={`${!!props?.patient?.birthDate ? `${moment().diff(moment(props?.patient?.birthDate, BACKEND_DATE_FORMAT), 'years')} ans` : "-"}`}
            />
          </div>
          <div className="d-flex align-items-center mr-5 mt-4 w-40">
            <span className="mr-3 white-space-nowrap">Demandeur :</span>
            <Input
              containerClassName="border-left-0 border-top-0 border-right-0"
              className={`px-0 py-1 h-auto font-weight-bold ${!!errors?.medecin_demandeur ? 'border-principal-bottom' : ''}`}
              value={fields?.medecin_demandeur}
              onChange={e => setFields(old => ({
                ...old,
                medecin_demandeur: e?.target?.value
              }))}
              readOnly={props?.readOnly}
            />
          </div>
          <div className="d-flex align-items-center mt-4 w-100">
            <span className="mr-3 white-space-nowrap">Indications :</span>
            <Input
              containerClassName="border-left-0 border-top-0 border-right-0"
              className={`px-0 py-1 h-auto font-weight-bold ${!!errors?.indication ? 'border-principal-bottom' : ''}`}
              value={fields?.indication}
              onChange={e => setFields(old => ({
                ...old,
                indication: e?.target?.value
              }))}
              readOnly={props?.readOnly}
            />
          </div>
        </div>
      </div>
      <div className="w-100 p-3 font-weight-bold text-principal text-center mt-4">
        COMMENTAIRE
      </div>
      {errors?.comment &&
        <div className="w-100 font-80 text-principal text-center mt-2"        >
          {errors?.comment}
        </div>
      }
      <div className="p-2 d-flex flex-wrap w-100 border-top mt-3">
        <Editor
          editorState={editorState}
          toolbarClassName="toolbarClassName border-top-0 border-left-0 border-right-0 p-0"
          wrapperClassName="wrapperClassName w-100"
          editorStyle={{ height: "150px" }}
          editorClassName="editorClassName"
          onEditorStateChange={onEditorStateChange}
          toolbar={toolbarOptions}
          readOnly={props?.readOnly}
        />
      </div>
    </Container>
  );
};

const Container = styled.div`
  border: 0.5px solid #ABABAB;
`;

export default FicheEchoDefault;