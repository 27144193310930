import {
  LOGIN_ACTION,
  LOGOUT_ACTION,
  SET_ACTIVE_SERVICE,
  SET_CENTRE_CONFIGS,
  SET_INIT_DATA,
  SET_SESSION_CAISSE,
  SYNC_REQUEST,
  TOGGLE_ROOM_ACTION,
  TOGGLE_SERVICE_ROOM_ACTION,
} from '../actions';

const Reducer = (state, action) => {
  switch (action.type) {
    case LOGIN_ACTION:
      return { ...state, ...action.payload };

    case SYNC_REQUEST:
      return { ...state, ...action.payload };

    case LOGOUT_ACTION:
      return { ...state, currentUser: null, user: null, role: null, token: null, caisse: null };

    case TOGGLE_ROOM_ACTION:
      return { ...state, displayRoom: !state.displayRoom };

    case TOGGLE_SERVICE_ROOM_ACTION:
      return { ...state, displayServiceRoom: !state.displayServiceRoom };

    case SET_INIT_DATA:
      return { ...state, initData: action?.payload };

    case SET_ACTIVE_SERVICE: {
      return {
        ...state,
        activeService: action?.service
      };
    }

    case SET_CENTRE_CONFIGS: {
      return {
        ...state,
        configs: action?.configs
      };
    }

    case SET_SESSION_CAISSE: {
      return {
        ...state,
        caisse: action?.caisse
      };
    }

    default:
      return state;
  }
};

export default Reducer;
