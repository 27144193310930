export const LOGIN_ACTION = 'LOGIN_ACTION';
export const LOGOUT_ACTION = 'LOGOUT_ACTION';
export const SET_INIT_DATA = 'SET_INIT_DATA';
export const SYNC_REQUEST = 'SYNC_REQUEST';

export const SET_ACTIVE_SERVICE = "SET_ACTIVE_SERVICE";
export const setActiveService = service => ({
  type: SET_ACTIVE_SERVICE,
  service
});

export const TOGGLE_ROOM_ACTION = 'TOGGLE_ROOM_ACTION';
export const toggleRoom = (payload = undefined) => ({ type: TOGGLE_ROOM_ACTION, payload });

export const TOGGLE_SERVICE_ROOM_ACTION = 'TOGGLE_SERVICE_ROOM_ACTION';
export const toggleServiceRoom = () => ({ type: TOGGLE_SERVICE_ROOM_ACTION });

export const SET_CENTRE_CONFIGS = 'SET_CENTRE_CONFIGS';
export const setupCentreConfigs = (configs) => ({ type: SET_CENTRE_CONFIGS, configs });

export const SET_SESSION_CAISSE = 'SET_SESSION_CAISSE';
export const setSessionCaisse = (caisse) => ({ type: SET_SESSION_CAISSE, caisse });