export const routes = {
  ROOT: "/",
  MENU: "/menu",
  "ACCESS.DENIED": "/acces-non-autorise",
  "CHANGE.PASSWORD": "/changement-mot-de-passe",
  "AUTH.LOGIN": "/connexion",
  "AUTH.SIGNUP": "/inscription",
  "COMPTE.USER": "/user/compte",
  "AUTH.RESETPASSWORD": "/reset-password",
  "HOME.WORKSPACE": "/espace-de-travail",
  "FACTURATIONS.INDEX": "/facturations",
  "FACTURATIONS.CAISSE": "/facturations/caisse",
  "FACTURATIONS.CAISSE.OUVERTURE": "/facturations/caisse-ouverture",
  "FACTURATIONS.RECOUVREMENT": "/facturations/recouvrement",
  "FACTURATIONS.COMPTABILITE": "/facturations/comptabilite",
  "FACTURATIONS.DEMANDES.ANNULATIONS": "/facturations/demandes-annulations",
  "FACTURATIONS.ENCAISSEMENTS.PERIODIQUES":
    "/facturations/encaissements-periodiques",
  "FACTURATIONS.ENCAISSEMENTS.LIVE": "/facturations/encaissements-live",
  "FACTURATIONS.FACTURES.ASSURANCES": "/facturations/factures-assurances",
  "FACTURATIONS.FACTURES.ARCH": "/facturations/factures-arch",
  "FACTURATIONS.CONFIGS": "/facturations/configuration",
  "FACTURATIONS.ENCAISSEMENTS": "/facturations/encaissements",
  "FACTURATIONS.DEPENSES": "/facturations/depenses",
  "FACTURATIONS.HORAIRES": "/facturations/honoraires",
  "FACTURATIONS.LISTEDETAILLEE":
    "/facturations/liste-detailles-des-prestations",
  "CONFIGURATIONS.INDEX": "/configurations",
  "CONFIGURATIONS.ACTES": "/configurations/actes",
  "CONFIGURATIONS.ACTES.IMPORT": "/configurations/actes/importer",
  "CONFIGURATIONS.ASSURANCES": "/configurations/assurances",
  "CONFIGURATIONS.CHAMBRES": "/configurations/chambres",
  "CONFIGURATIONS.CHAMBRES.VIEW": "/configurations/chambres/view",
  "CONFIGURATIONS.ROLES": "/configurations/roles-acces",
  "CONFIGURATIONS.ROLES.ADD": "/configurations/roles-acces/ajouter",
  "CONFIGURATIONS.ROLES.EDIT": "/configurations/roles-acces/modifier",
  "CONFIGURATIONS.TARIFS": "/configurations/tarifs",
  "CONFIGURATIONS.USERS": "/configurations/utilisateurs",
  "SETTINGS.LOGS": "configurations/parametres/gestion-des-logs",
  "SETTINGS.NOTIFICATIONS": "configurations/parametres/notifications",
  "SETTINGS.CONFIGS": "/configuration/parametres",
  "MENU.CONSULTATION": "/liste-consultations",
  "EDIT.CONSULTATION.GENERALE": "/consultation-cmg/modifier",
  "MENU.CONSULTATION.DETAILS": "/consultations/details",
  "MENU.PRECONSULTATION.MATERNITE": "/preconsultations/maternite",
  "MENU.CONSULTATION.MATERNITE.DOSSIER": "/consultations/dossier-maternite",
  "MENU.CONSULTATION.MATERNITE": "/consultations/maternite",
  "ARCHIVES.INDEX": "/archives",
  "MATERNITE.INDEX": "/maternite/accueil",
  "MATERNITE.ACCOUCHEMENT": "/maternite/accouchement",
  "MATERNITE.ACCOUCHEMENT2": "/maternite/accouchement2", // NEW MATERNITE
  "MATERNITE.DILATATION": "/maternite/dilatation",
  "MATERNITE.CLOTURATION": "/maternite/cloture",
  "MATERNITE.DOSSIERS.CLOTURES": "/maternite/dossiers-clotures",
  "MENU.CONSULTATION.MEDECINE.GENERALE": "/consultations/medecine-generale",
  "MENU.CONSULTATION.GYNECOLOGIE": "/consultations/gynecologie",
  "MENU.CONSULTATION.HISTORIQUE.MEDICALE": "/consultations/historique-medicale",
  "MENU.CONSULTATION.HISTORIQUE.MEDICALE.GYNECOLOGIE":
    "/consultations/historique-medicale-gynecologie",
  "EDIT.CONSULTATION.GYNECO": "/consultation-gyneco/modifier",
  "PHARMACIE.INDEX": "/pharmacie",
  "SOINS.INDEX": "/soins-ambulatoires",
  "SOINS.LIST": "/soins-ambulatoires/liste",
  "DOSSIERS.INDEX": "/dossiers-d-hospitalisation",
  "AFFECTATION.CHAMBRE.INDEX": "/chambre/affectation",
  "DOSSIERS.DETAILS": "/dossiers-d-hospitalisation/contenu",
  "LABORATOIRE.INDEX": "/laboratoire",
  "LABORATOIRE.RESULTS": "/laboratoire/resultats",
  "IMAGERIE.INDEX": "/imagerie",
  "IMAGERIE.ANSWER": "/imagerie/respond",
  "IMAGERIE.RESULTS": "/imagerie/resultats",
  "HOSPITALISATIONS.INDEX": "/mises-en-observations",
  "ARRETS.INDEX": "/arrets-maladies",
  "ORIENTATION.INDEX": "/accueil-orientation",
  "ORIENTATION.CAISSE": "/accueil-orientation/caisse",
  "ORIENTATION.PRESCRIPTIONS": "/orientation-prescriptions",
  PRECONSULTATION: "/preconsultation",
  "STATS.INDEX": "/statistiques",
  "STATS.PRINT": "/statistiques-imprimer",
  "STATS.A12": "/statistiques-a12",
  "STATS.C6": "/statistiques-c6",
  "STATS.RSMI": "/statistiques-rsmi",
  "STATS.B5A": "/statistiques-b5a",
  "STATS.BY.SERVICE": "/statistiques-par-service",
  "STATS.CONSULTATIONS": "/statistiques-consultations",
  "STATS.CONS.PERIOD": "/statistiques-consultations-periode",
  "STATS.HOSPI.PERIOD": "/statistiques-hospitalisations-periode",
  "STATS.HOSPI.CLOTURE.PERIOD": "/statistiques-hospitalisations-clot-periode",
  "STATS.FEMME_ACCOUCHEMENT": "/statistiques-femmes-accouchement",
  PREANESTHESIE: "/consultation/preanesthesie",
  INTERVENTIONCHIRUGICALE: "/interventionChirugicale",
  "INTERVENTIONCHIRUGICALE.DOSSIER": "/interventionChirugicale/dossier",
  "INTERVENTIONCHIRUGICALE.DOSSIERSURVEILLANCE":
    "/interventionChirugicale/dossier-surveillance",
  "INTERVENTIONCHIRUGICALE.FAIRESURVEILLANCE":
    "/interventionChirugicale/faire-surveillance",
  "INTERVENTIONCHIRUGICALE.COMPTERENDU":
    "/interventionChirugicale/compte-rendu-operatoire",
  "CONSULTATION.POSTNATAL": "/consutation/post-natal",
  AGENDA: "/agenda",
  "CONSULTATION.CARDIO": "/consutation/cardiologique",
  "CONSULTATION.PRENATAL2": "/consutation/consultation-pre-natal2",
  "CONFIGURATIONS.HONORAIRES": "/configuration/honoraires",
  VACCINATION: "/vaccination",
  "VACCINATION.MENU": "/vaccination-menu",
  "DETAILS.ENFANT": "/details-enfant",
  "FICHE.PATIENT": "/fiche-patient",
  "CENTRE.PATIENT.HOME": "/centre-patient-acceuil",
  "DOSSIER.PATIENT.HOSPI": "/patient-dossier-hospitalisation",
  "CONSULTATION.PEDIATRIE": "/consultation-pediatrie",
  "DETAILS.PEDIATRIE": "/details-pediatrie",
  "CONSULTATION.PRE.PEDIATRIE": "/consultation-pre-pediatrie",
  "CONSULTATION.NEONATOLOGIE": "/consultation-neonatologie",
  "CONSULTATION.PRE.NEONATOLOGIE": "/consultation-pre-neonatologie",
  "CONSULTATION.STOMATOLOGIE": "/consultation-stomatologie",
  "CONSULTATION.ORL": "/consultation-orl",
};
export default routes;
