import React from 'react';
import PropTypes from 'prop-types';
import "./styles.scss";

const Pill = props => {
  return (
    <div
      {...props}
      className={`
        kea-pill
        kea-pill-${props.size ? props.size : 'md'}
        kea-pill-${props.variant ? props.variant : 'principal'}
        ${props.className}
    `}
    >
      {props?.value ? props.value : props?.children}
    </div>
  );
};

Pill.propTypes = {
  value: PropTypes.string,
  // size: PropTypes.string,
  variant: PropTypes.oneOf([
    'principal', 'principal-bordered',
    'infos', 'infos-bordered',
    'green', 'green-bordered',
    'red', 'red-bordered',
    'black', 'black-bordered',
    'gray', 'gray-bordered',
    'blue', 'blue-bordered',
  ])
};

Pill.propTypes = {
  value: "",
  size: "md",
};

export default Pill;