import React from 'react';
import PropTypes from 'prop-types';
import { FaQuestion } from 'react-icons/fa';
import "./styles.scss";
import ChambreImg from "assets/images/chambreImage.png";
import styled from "styled-components";

const CardChambre = props => {
  return (
    <div {...props} style={{cursor:"pointer"}} className="card-15" >
        <div style={{height:"240px"}} className="d-flex align-items-center flex-column justify-content-center">
            {!!props.icon ?
                <IconImage style={{maxHeight:"240px"}} src={props.icon}/> :
                <IconImage className="w-100 border-top-15" src={ChambreImg}/>
            }
        </div>
        <div   className={`kea-card-wh kea-card-option ${props.className}`}  >
          <span className="my-2 w-100  font-weight-bold">
            {props.title}
          </span>
            {!!props.subtitle &&
            <span style={{overflowY:"auto"}} className="w-100  text-disabled font-90">
              {props.subtitle}
            </span>
            }
        </div>

    </div>
  );
};

CardChambre.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.any
};

CardChambre.defaultProps = {
  title: "",
};

const IconImage = styled.img`
  height: auto !important;
  width: auto !important;
  max-height: 90% !important;
  max-width: 90% !important;
`;

export default CardChambre;