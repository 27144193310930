import React from "react";
import styled from 'styled-components';
import PropTypes from 'prop-types';

const SwiftText = ({
	type,
	name,
	value,
	onChange,
	validator,
	isInvalid,
	iconRight,
	iconLeft,
	inputStyle,
	error,
	errorStyle,
	style,
	containerStyle,
	containerClassName,
	theme,
	...rest
}) => {
	return (
		<div
			className={containerClassName}
			style={{
				margin: "5px 0",
				padding: '5px',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'flex-start',
				justifyContent: 'flex-start',
				...containerStyle
			}}
		>
			<div
				style={{
					flexDirection: 'row',
					alignItems: 'center',
					justifyContent: 'flex-start',
					width: '100%',
					borderWidth: 1,
					...style,
				}}
			>
				{iconLeft ? iconLeft : null}
				<Input
					type={type}
					name={name}
					value={value}
					onChange={onChange}
					style={{
						width: (!!iconRight || !!iconLeft) ? "85%" : "100%",
						color: !!theme && !!theme.text ? theme.text : "black",
						borderColor: !!theme && !!theme.border ? theme.border : "#888",
						backgroundColor: !!theme && !!theme.background ? theme.background : "white",
						...inputStyle
					}}
					{...rest}
				/>
				{iconRight ? iconRight : null}
			</div>
			{!!error && <ErrorFeedback style={{ ...errorStyle }}>{error}</ErrorFeedback>}
		</div>
	);
};

SwiftText.propTypes = {
	type: PropTypes.oneOf(["text", "date", "time", "number", "password", "email"]),
	name: PropTypes.string,
	value: PropTypes.string,
	onChange: PropTypes.func,
	validator: PropTypes.func,
	isInvalid: PropTypes.bool,
	iconRight: PropTypes.any,
	iconLeft: PropTypes.any,
	error: PropTypes.string,
	errorStyle: PropTypes.object,
	style: PropTypes.object,
	inputStyle: PropTypes.object,
	containerStyle: PropTypes.object,
	containerClassName: PropTypes.string,
	placeholder: PropTypes.string,
	theme: PropTypes.object
};

SwiftText.defaultProps = {
	type: 'text',
	name: '',
	value: '',
	onChange: () => console.log('no onChange method attached...'),
	isInvalid: false,
	style: {},
	errorStyle: {},
	containerStyle: {},
	containerClassName: "",
};

const Input = styled.input`
	border: 1px solid #888;
	padding: 10px;
	width: 100%;
	&:focus {
		outline: none;
	}
`;

const ErrorFeedback = styled.span`
	width: 100%;
	margin-top: .25rem;
	font-size: 80%;
	color: red;
	text-align: right;
`;

export default SwiftText;
