import React from 'react';
import PropTypes from 'prop-types';
import "./styles.scss";

const Breadcrumb = props => {
  return (
    <div>
      <div className="kea-breadcrumb">
        {props?.elements?.map((el, ind) => (
          <div key={ind.toString()} onClick={!!el.onClick ? el.onClick : () => { }} className="breadcrumb-cell">
            <div
              className={
                `breadcrumb-link ${ind === (props?.elements?.length - 1) ?
                  'active mr-0' :
                  ''
                }`
              }
            >
              {el.label}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

Breadcrumb.propTypes = {
  elements: PropTypes.array
};

Breadcrumb.defaultProps = {
  elements: [
    { label: "Home", onClick: () => { } },
    { label: "Page 1", onClick: () => { } },
    { label: "Page 2", onClick: () => { } },
  ],
};

export default Breadcrumb;