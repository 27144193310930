export const resetObject = (obj) => {
	if (typeof obj === "object") {
		let output = {};
		for (const key in obj) {
			output = {
				...output,
				[key]: "",
			};
		}
		return output;
	} else {
		return obj;
	}
};

const CURRENTLANG = 'fr';
const requiredMsg = { en: "Champ requis", fr: "Champ requis" };
const stringTypeMsg = {
	en: `Seulement des caractères (A-Z, a-z, symbols -,_)`,
	fr: "Seulement des caractères (A-Z, a-z, symbols -,_)",
};
const nameTypeMsg = {
	en: `Invalid name`,
	fr: "Nom invalide",
};
const strongPasswordTypeMsg = {
	en: `Use at least : 1 uppercase, 1 lowerwase, 1 number, 1 special character (@$!%*?&#^_)`,
	fr: "Utilisez au moins : 1 majuscule, 1 miniscule, 1 chiffre, 1 caractère spécial (@$!%*?&#^_)",
};
const arrayTypeMsg = {
	en: `At element at least`,
	fr: "Un élément au moins",
};

const integerTypeMsg = {
	en: `Seulement des chiffres (0-9)`,
	fr: "Seulement des chiffres (0-9)",
};
const numberTypeMsg = { en: `Only numbers`, fr: "Seulement des nombres" };
const emailTypeMsg = {
	en: `Adresse email invalide`,
	fr: "Adresse email invalide",
};
const minLengthMsg = {
	en: `Au moins {{minLength}} caractères`,
	fr: "Au moins {{minLength}} caractères",
};
const maxLengthMsg = {
	en: `Au plus {{maxLength}} caractères`,
	fr: "Au plus {{maxLength}} caractères",
};
const sizeExacthMsg = {
	en: `Exactement {{size}} caractères requis`,
	fr: "Exactement {{size}} caractères requis",
};
const minValueMsg = {
	en: `Doit être supérieur à {{minValue}}`,
	fr: "Doit être supérieur à {{minValue}}",
};
const maxValueMsg = {
	en: `Doit être less à {{maxValue}}`,
	fr: "Doit être inférieur à {{maxValue}}",
};
const dateMsg = {
	en: `Date invalide`,
	fr: "Date invalide",
};

const required = (value, lang = "fr") => {
	return {
		valid: value !== '' && value !== null && value !== undefined && value !== [] && value !== {},
		error: requiredMsg[lang],
	};
};

const string = (value, lang = CURRENTLANG) => {
	if (required(value).valid) {
		return {
			valid: typeof value === "string",
			error: stringTypeMsg[lang],
		};
	} else {
		return {
			valid: false,
			error: required(value, lang).error,
		};
	}
};

const name = (value, lang = CURRENTLANG) => {
	if (string(value).valid) {
		const reg = /[^0-9]+/;
		return {
			valid: reg.test(value),
			error: nameTypeMsg[lang],
		};
	} else {
		return {
			valid: false,
			error: string(value, lang).error,
		};
	}
};

const strong_password = (value, lang = CURRENTLANG) => {
	if (string(value).valid) {
		const reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#^_])[A-Za-z\d@$!%*?&#^_]{8,}$/;
		return {
			valid: reg.test(value),
			error: strongPasswordTypeMsg[lang],
		};
	} else {
		return {
			valid: false,
			error: string(value, lang).error,
		};
	}
};

const integer = (value, lang = CURRENTLANG) => {
	if (required(value).valid) {
		const reg = new RegExp("^(-?[1-9]+\d*)$|^0$");
		return {
			valid: reg.test(value),
			error: integerTypeMsg[lang],
		};
	} else {
		return {
			valid: false,
			error: required(value, lang).error,
		};
	}
};

const number = (value, lang = CURRENTLANG) => {
	if (required(value).valid) {
		const reg = new RegExp("^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$");
		return {
			valid: reg.test(value),
			error: numberTypeMsg[lang],
		};
	} else {
		return {
			valid: false,
			error: required(value, lang).error,
		};
	}
};

const array = (value, lang = CURRENTLANG) => {
	if (required(value).valid) {
		return {
			valid: Array.isArray(value) && value.length > 0,
			error: arrayTypeMsg[lang],
		};
	} else {
		return {
			valid: false,
			error: required(value, lang).error,
		};
	}
};

const email = (value, lang = CURRENTLANG) => {
	if (string(value).valid) {
		const reg = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
		return {
			valid: reg.test(String(value).toLowerCase()),
			error: emailTypeMsg[lang],
		};
	} else {
		return {
			valid: false,
			error: string(value, lang).error,
		};
	}
};

const min = (value, minLength, lang = CURRENTLANG) => {
	if (string(value).valid) {
		return {
			valid: value.length >= minLength,
			error: minLengthMsg[lang].replace("{{minLength}}", minLength),
		};
	} else {
		return {
			valid: false,
			error: string(value, lang).error,
		};
	}
};

const minVal = (value, minValue, lang = CURRENTLANG) => {
	if (number(value).valid) {
		return {
			valid: parseFloat(value) >= minValue,
			error: minValueMsg[lang].replace("{{minValue}}", minValue),
		};
	} else {
		return {
			valid: false,
			error: string(value, lang).error,
		};
	}
};

const max = (value, maxLength, lang = CURRENTLANG) => {
	if (string(value).valid) {
		return {
			valid: value.length <= maxLength,
			error: maxLengthMsg[lang].replace("{{maxLength}}", maxLength),
		};
	} else {
		return {
			valid: false,
			error: string(value, lang).error,
		};
	}
};

const maxVal = (value, maxValue, lang = CURRENTLANG) => {
	if (number(value).valid) {
		return {
			valid: parseFloat(value) <= maxValue,
			error: maxValueMsg[lang].replace("{{maxValue}}", maxValue),
		};
	} else {
		return {
			valid: false,
			error: string(value, lang).error,
		};
	}
};

const size = (value, size, lang = CURRENTLANG) => {
	if (string(value).valid) {
		return {
			valid: value.length === size,
			error: sizeExacthMsg[lang].replace("{{size}}", size),
		};
	} else {
		return {
			valid: false,
			error: string(value, lang).error,
		};
	}
};

const date = (value, lang = CURRENTLANG) => {
	if (string(value).valid) {
		const reg = new RegExp("^(19|20)[0-9][0-9][- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])$");
		return {
			valid: reg.test(value),
			error: dateMsg[lang],
		};
	} else {
		return {
			valid:
				typeof value === "object" &&
				!!value.getTime &&
				value.getTime() === value.getTime(),
			error: dateMsg[lang],
		};
	}
};

/**
 * Object of validation functions
 */
const funcs = {
	string,
	email,
	integer,
	number,
	min,
	minVal,
	max,
	maxVal,
	size,
	required,
	date,
	name,
	strong_password,
	array
};

interface ValidateResponse {
	isFormValid: () => boolean;
	response: {
		[key: string]: {
			valid: boolean;
			error: string;
		};
	};
};


/**
 * Validate a data on validations rules predefined
 * @param {Object} data Data to validate
 * @param {Object} rules { key : "string|min:3|max:10|required|..." }
 * @returns {Object} { response: Object, isFormValid: func }
 */
const validate = (data, rules, lang = "fr"): ValidateResponse => {
	let validationResponse = {};
	let formIsValid = true;

	for (const validation in rules) {
		let isValid = true;
		let errorMessage = "";
		const key_value = data[validation];

		if (typeof (rules[validation]) === 'string') {
			const key_rules = rules[validation].split("|");

			for (let i = 0; i < key_rules.length; i++) {
				const rule = key_rules[i];
				if (rule.includes(":")) {
					const limit = parseInt(rule.split(":")[1]);
					const short_rule = rule.split(":")[0];
					if (!!funcs[short_rule]) {
						if (funcs[short_rule](key_value, limit, lang).valid) {
							formIsValid = formIsValid && true;
							isValid = isValid && true;
						} else {
							formIsValid = formIsValid && false;
							isValid = isValid && false;
							errorMessage = funcs[short_rule](key_value, limit, lang).error;
							break;
						}
					}
				} else {
					if (!!funcs[rule]) {
						if (funcs[rule](key_value, lang).valid) {
							formIsValid = formIsValid && true;
							isValid = isValid && true;
						} else {
							formIsValid = formIsValid && false;
							isValid = isValid && false;
							errorMessage = funcs[rule](key_value, lang).error;
							break;
						}
					}
				}
			}
		} else if (typeof (rules[validation]) === 'function') {
			const check = rules[validation](key_value);

			if (check.valid) {
				formIsValid = formIsValid && true;
				isValid = isValid && true;
			} else {
				formIsValid = formIsValid && false;
				isValid = isValid && false;
				errorMessage = check.error;
			}
		}

		validationResponse = {
			...validationResponse,
			[validation]: {
				valid: isValid,
				error: errorMessage,
			},
		};
	}

	const isFormValid = () => {
		return formIsValid;
	};

	return { response: validationResponse, isFormValid };
};

export default validate;
