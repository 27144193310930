import React from "react";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import BootstrapTable from "react-bootstrap-table-next";
import styled from "styled-components";
import "./styles.scss";
import { KeaLoader } from "components";

const formatOptions = (props) => {
  const pageButtonRenderer = ({
    page,
    active,
    disable,
    title,
    onPageChange,
  }) => {
    const handleClick = (e) => {
      e.preventDefault();
      onPageChange(page);
    };
    const liStyle = {};
    const aStyle = {};
    if (active) {
      liStyle.color = "#E91E63";
      liStyle.border = "1px solid #E91E63AA";
      aStyle.color = "#E91E63";
    } else {
      liStyle.backgroundColor = "white";
      aStyle.color = "#212B36";
      liStyle.border = "1px solid #DFE3E8";
    }
    if (typeof page === "string") {
      liStyle.backgroundColor = "#919EAB";
      liStyle.border = "0px";
      aStyle.color = "white";
    }
    return (
      <CustomLi style={liStyle} onClick={handleClick}>
        <CustomA href="#" style={aStyle}>
          {page}
        </CustomA>
      </CustomLi>
    );
  };

  return {
    pageButtonRenderer,
    ...props,
  };
};

const Table = ({
  keyField,
  remote,
  data,
  page,
  sizePerPage,
  onTableChange,
  totalSize,
  columns,
  bordered,
  headerClasses,
  headerWrapperClasses,
  rowStyle,
  rowClasses,
  striped,
  noPagination,
  ...rest
}) => {
  const [isLoading, setLoadingState] = React.useState(true);

  React.useEffect(() => {
    setTimeout(() => {
      setLoadingState(false);
    }, 10);
  }, []);

  return (
    <div className="w-100">

      {isLoading ?
        <KeaLoader />
        :
        <PaginationProvider
          pagination={paginationFactory(
            formatOptions({
              custom: true,
              page,
              sizePerPage,
              totalSize,
            })
          )}
        >
          {({ paginationProps, paginationTableProps }) => (
            <div>
              <BootstrapTable
                remote={remote}
                keyField={keyField || "id"}
                bordered={bordered}
                striped={striped}
                data={data}
                columns={columns}
                headerWrapperClasses={`bg-principal-pills shadow-sm border-none ${headerWrapperClasses}`}
                headerClasses={`border-none ${headerClasses}`}
                rowClasses={`kea-table-row font-90 shadow-sm ${rowClasses}`}
                rowStyle={{ overflow: "hidden", ...rowStyle }}
                {...(!!onTableChange ? { onTableChange } : null)}
                {...paginationTableProps}
                {...rest}
              />
              {
                !(data && Array.isArray(data) && data.length > 0) && <div className="text-center w-100 my-4">Aucune donnée !</div>
              }
              {
                !!noPagination ?
                  null :
                  <div className="d-flex justify-content-between mt-4 text-gray">
                    <span>Affichage de {(page - 1) * sizePerPage + 1} à {totalSize < (page * sizePerPage) ? totalSize : page * sizePerPage} sur un total de {totalSize}</span>
                    <PaginationListStandalone {...paginationProps} />
                  </div>
              }
            </div>
          )}
        </PaginationProvider>
      }
    </div>
  );
};

export default Table;

const CustomLi = styled.li`
  border-radius: 4px;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 4px;
  cursor: pointer;
`;

const CustomA = styled.a`
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
`;
