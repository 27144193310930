import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { makeStyles } from "@material-ui/core";
import styled from 'styled-components';
import { Textarea, Button } from "components";
import { FaTimes } from 'react-icons/fa';
import SwiftForm, { SubmitResponse } from "context/swift-inputs";


interface ExecuteFicheModalProps {
  visible: boolean;
  onHide: (status: boolean) => void;
};

const ExecuteFicheModal = (props: ExecuteFicheModalProps) => {
  const classes = usetyles();

  const [confirmed, setConfirmed] = useState(false);

  useEffect(() => {
    if (props.visible) {
      setConfirmed(false);
    }
  }, [props?.visible]);

  const handleSubmit = (validation: SubmitResponse) => {
    if (validation?.isValid) {
      props.onHide(true, validation?.fields?.comment);
      validation?.clearForm();
    }
  };

  return (
    <Modal
      centered
      backdrop="static"
      size="sm"
      contentClassName={classes.modalContainer}
      show={props.visible}
      onHide={props.onHide.bind(this, false)}
    >
      <CloseIcon onClick={props.onHide.bind(this, false)} />
      {!confirmed ?
        <div className="h-auto w-100 d-flex flex-column align-items-center p-3 mt-5 mb-4">
          <p className="w-75 text-center">Voulez-vous vraiment exécuter l'administration de ce produit ?</p>
          <div className="w-100 d-flex align-items-center justify-content-center mt-5">
            <Button
              size="sm"
              value="Oui"
              className="px-3 font-90 mr-5"
              onClick={() => setConfirmed(true)}
            />
            <Button
              size="sm" variant="gray"
              value="Non"
              className="px-3 font-90"
              onClick={() => props.onHide(false)}
            />
          </div>
        </div>
        :
        <SwiftForm
          fields={{ comment: "" }}
          rules={{ comment: "required|max:500" }}
          handleSubmit={handleSubmit}
        >
          {({ fields, errors, handleInputChange, submit }) => (
            <div className="h-auto w-100 d-flex flex-column align-items-center p-3 mt-4 mb-4">
              <Textarea
                label="Observation"
                labelClassName="font-weight-bold"
                placeholder="Entrez du texte"
                containerClassName="bg-light border-0 border-5"
                rows={2}
                name="comment"
                error={errors?.comment}
                value={fields?.comment}
                onChange={handleInputChange}
              />
              <div className="w-100 d-flex align-items-center justify-content-center mt-5">
                <Button
                  size="sm"
                  value="Ajouter"
                  className="px-3 font-90 mr-5"
                  onClick={submit}
                />
                <Button
                  size="sm" variant="gray"
                  value="Fermer"
                  className="px-3 font-90"
                  onClick={() => props.onHide(false)}
                />
              </div>
            </div>
          )}
        </SwiftForm>
      }
    </Modal>
  );
};

const usetyles = makeStyles(() => ({
  modalContainer: {
    boxShadow: "0px 8px 30px rgba(0, 0, 0, 0.12)",
    borderRadius: "5px",
    borderWidth: 0,
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    "@media only screen and (max-width: 768px)": {
      width: "80vw",
      marginLeft: "7vw"
    }
  },
}));

ExecuteFicheModal.defaultProps = {
  visible: false,
  onHide: () => console.log("Please attach an onHide function to [ExecuteFicheModal]"),
};

const CloseIcon = styled(FaTimes)`
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  color: black;
`;

export default ExecuteFicheModal;