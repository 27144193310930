import React from 'react';
import { Popover, makeStyles } from "@material-ui/core";
import styled from 'styled-components';
import _ from 'lodash';
import { TwitterPicker, TwitterPickerProps, BlockPicker, BlockPickerProps } from 'react-color';


interface ColorPickerProps {
  visible?: boolean;
  anchor?: any;
  onHide?: () => void;
};

const ColorPicker = (props: ColorPickerProps & TwitterPickerProps) => {
  const classes = useStyles();

  return (
    <Popover
      id={props.visible ? "simple-popover" : undefined}
      open={props.visible}
      anchorEl={props.anchor}
      onClose={props.onHide}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      classes={{ paper: classes.paper }}
    >
      <TwitterPicker
        {..._.omit(props, ["visible", "anchor", "onHide"])}
      />
    </Popover>
  );
};

ColorPicker.defaultProps = {
  visible: false,
  onHide: () => console.log("No onHide method given"),
};

const useStyles = makeStyles(() => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    width: 'auto',
    borderRadius: "10px",
    marginTop: "5px",
    boxShadow: "-2px -1px 10px 2px rgba(192, 197, 223, 0.2)",
    borderBottom: "3px solid #e91e63"
  }
}));

export default ColorPicker;