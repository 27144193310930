import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { HistoryComponentProps } from './index';
import _ from "lodash";
import { API_BASE_URL, DMP_SUFFIX, fetchPatientExamenLabo } from "http/crud";
import { KeaLoader, SideModal, Pill, Table } from "components";
import moment from "moment";

function ExamensHistory(props: HistoryComponentProps) {
  const printRef = useRef();

  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(8);

  const [examens, setExamens] = useState([]);

  const handleTableChange = (type, { page, sizePerPage }) => {
    setPage(page);
  };

  const [loading, setLoading] = useState(false);

  const fetchExamens = React.useCallback(async (verbose = true) => {
    setLoading(verbose);
    if (props?.patient?.patientId) {
      await fetchPatientExamenLabo(props?.patient?.patientId).then(({ data: response }) => {
        if (response?.success) {
          setExamens(Array.isArray(response?.datas) ? response?.datas : []);
        } else {
          setExamens([]);
        }
      }).catch(() => {
        setExamens([]);
      }).finally(() => setLoading(false));
    } else {
      setTimeout(() => {
        setExamens([]);
        setLoading(false);
      }, 500);
    }
  }, []);

  useEffect(() => {
    fetchExamens();
  }, []);

  const [focused, setFocused] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = el => {
    setFocused(el);
    setShowModal(true);
  };
  const handleHideModal = () => {
    setFocused(null);
    setShowModal(false);
  };

  const columns = [
    {
      dataField: "medecin",
      text: "Médécin",
      classes: "kea-table-row",
      headerClasses: "kea-table-header",
      formatter: (cell, row, rowIndex, extraData) => {
        return `${row?.medecin_demandeur || '-'}`
      }
    },
    {
      dataField: "motif",
      text: "Motif d'admission",
      classes: "kea-table-row overflow-hidden",
      headerClasses: "kea-table-header",
      formatter: (cell, row, rowIndex, extraData) => {
        return row?.motif || '-';
      }
    },
    {
      dataField: "date",
      text: "Date d'admission",
      classes: "kea-table-row overflow-hidden",
      headerClasses: "kea-table-header",
      formatter: (cell, row, rowIndex, extraData) => {
        return `${moment(row?.created_at).format("LL à HH:mm:ss") || '-'}`
      }
    },
    {
      dataField: "analyse",
      text: "Analyse",
      classes: "kea-table-row overflow-hidden",
      headerClasses: "kea-table-header",
      formatter: (cell, row, rowIndex, extraData) => {
        return row?.libelle || '-';
      }
    },
    {
      dataField: "resultat",
      text: "Résultat",
      align: "center",
      classes: "kea-table-row",
      headerClasses: "kea-table-header",
      formatter: (cell, row, rowIndex, extraData) => {
        if (!!row?.result_url) {
          return (
            <Pill
              value="Imprimer"
              variant="principal-bordered"
              className="border-5 font-90 bg-white"
              size="sm"
              onClick={() => {
                const url = `${API_BASE_URL}/${DMP_SUFFIX}/${row?.result_url}`;
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('target', `_blank`);
                printRef?.current?.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
              }}
            />
          );
        } else if (!!row?.resultat) {
          return <div dangerouslySetInnerHTML={{ __html: row?.resultat }} />;
        } else {
          return "-";
        }
      },
    },
  ];

  return (
    <Container ref={printRef} {..._.omit(props, ["patient"])}>
      <SideModal
        size="md"
        visible={showModal}
        onHide={handleHideModal}
      >
        {focused?.resultat || "Aucun résultat"}
      </SideModal>
      {
        loading ?
          <KeaLoader />
          :
          <Table
            data={examens}
            page={page}
            sizePerPage={sizePerPage}
            totalSize={examens.length}
            columns={columns}
            onTableChange={handleTableChange}
            bordered={false}
          />
      }
    </Container>
  );
}

export default ExamensHistory;

const Container = styled.div``;