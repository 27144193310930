import React from 'react';
import PropTypes from 'prop-types';
import Card from '../cards';
import styled from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import { FaPhoneAlt } from 'react-icons/fa';
import { ReactComponent as ArcImg } from './arc.svg';
import ClinicBgImg from './clinic.png';

const CardClinique = props => {
  return (
    <Card
      {...props}
      className={`p-3 px-lg-4 position-relative h-auto align-items-center justify-content-between ${props.className ? props.className : ''}`}
      style={{
        background: `url(${ClinicBgImg}) top center no-repeat`,
        backgroundSize: "cover"
      }}
    >
      <Row className="p-0 m-0 w-100">
        <Col md={6} lg={6} xs={12} className="d-flex flex-lg-row flex-column align-items-center">
          <ImageContainer>
            <ArcImg className="arc-border" />
            {props.picture && <img src={props.picture} className="picture" />}
          </ImageContainer>
          <div className="d-flex flex-column align-items-lg-start align-items-center mt-3 mt-lg-0 ml-3">
            <div className="text-principal font-weight-bold font-125">{props?.name}</div>
            <div className="d-flex align-items-center mt-3">
              <FaPhoneAlt className="text-principal mr-2" />
              <span className="text-white font-90">{props?.phone}</span>
            </div>
          </div>
        </Col>
        <Col md={6} lg={6} xs={12} className="d-flex flex-column align-items-lg-end align-items-center justify-content-center">
          <div className="text-white font-weight-bold font-150 mt-3 mt-lg-0">{props?.clinicName}</div>
          <div className="text-white font-weight-bold font-80 mt-4">{props?.clinicPhone}</div>
        </Col>
      </Row>
    </Card>
  );
};

CardClinique.propTypes = {
  name: PropTypes.string,
  phone: PropTypes.string,
  clinicName: PropTypes.string,
  clinicPhone: PropTypes.string,
  picture: PropTypes.any,
};

CardClinique.defaultProps = {
  name: "",
  phone: "",
  clinicName: "",
  clinicPhone: "",
  picture: null,
};

const ImageContainer = styled.div`
  width: 100px;
  height: 100px;
  overflow: hidden;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  .picture {
    object-fit: contain;
    width: 80%;
    height: 80%;
    border-radius: 50%;
  }
  .arc-border {
    position: absolute;
    left: -25px;
    object-fit: contain;
    width: 100%;
    height: 100%;
    border: 0px;
  }
`;

export default CardClinique;