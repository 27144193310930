import React, { useState } from "react";
import classnames from "classnames";
import { FaSyncAlt } from "react-icons/fa";
import { IoNotifications } from "react-icons/io5";
import Man from "assets/icons/user-placeholder.png";
import Pill from "components/pill";
import styled from "styled-components";
import { LOGOUT_ACTION } from "context/store";
import { useHistory } from "react-router-dom";
import { routesConfigs } from "configs";
import LogoKea from "assets/logo/logo_save.png";
import { useStore } from "context/store";
import routes from "configs/routes";
import "./styles.scss";


const Navbar = props => {
  const [dropDownsState, setDropDownState] = useState(false);
  const history = useHistory();

  const { dispatch, state: { agent, configs, activeService, caisse } } = useStore();

  const logout = () => {
    const STORAGE_KEY = process.env.REACT_APP_APP_STORAGE_KEY
    localStorage.removeItem(STORAGE_KEY);
    dispatch({ type: LOGOUT_ACTION });
  };

  return (
    <header {...props} className="kea-navbar px-3">
      <div className="h-100 d-flex align-items-center">
        <div style={{ fontSize: "20px" }} className="d-flex flex-column align-items-start justify-content-center h-100">
          <a href="/"><LogoImage src={LogoKea} /></a>
          <Pill
            size="sm"
            className="font-50 h-auto py-1 border-10 mt-1"
            variant="blue"
            onClick={() => history.push(routes["MENU"])}
          >
            {activeService?.libelle || ""} <FaSyncAlt size={10} className="ml-2" />
          </Pill>
        </div>
        <div className="font-weight-bold text-uppercase text-principal ml-4 h-75 border-left pl-4 d-flex align-items-center justify-content-center">
          {configs?.centreInfo?.center_name || ""}
        </div>
        {!!caisse &&
          <div className="font-weight-bold text-principal ml-4 h-75 border-left pl-4 d-flex align-items-center justify-content-center">
            Caisse N°
            <Pill
              variant="blue-bordered"
              size="sm"
              className="border-5 ml-2"
              value={caisse?.numero || "-"}
            />
          </div>
        }
      </div>
      <div className="h-100 d-flex align-items-center justify-content-end">
        <span className="p-3 position-relative" onClick={() => { }}>
          <Badge
            style={{
              position: "absolute",
              top: 5,
              right: 10,
              zIndex: 5
            }}
          >
          0
          </Badge>
          <IconNotification size={24} style={{ color: "#CACCCF", cursor: "pointer", transform: "rotate(30deg)" }} />
        </span>
        <HeaderAvatar onClick={() => setDropDownState(!dropDownsState)} className="border">
          <div
            className={classnames("dropdown", { "dropdown--active": dropDownsState }, "p-3 border-10 border-0 d-flex flex-column align-items-center")}
          >
            <UserImage src={Man} />
            <div className="my-3">Hello, {!!agent?.lastName ? agent.lastName : "Mr/Mme"}</div>
            <div className="d-flex align-items-center justify-content-between w-100">
              <div onClick={() => history.push(routesConfigs["COMPTE.USER"])} className="w-45 text-center font-90 cursor-pointer">Mon Compte</div>
              <div className="bg-disabled" style={{ width: "1px", height: "30px" }}></div>
              <div onClick={logout} className="w-45 text-center font-90 text-disabled cursor-pointer">Déconnexion</div>
            </div>
          </div>
        </HeaderAvatar>
      </div>
    </header>
  );
};

Navbar.propTypes = {
  //
};

Navbar.defaultProps = {
  //
};

const IconNotification = styled(IoNotifications)`
  font-size: 20px;
  cursor: pointer;
`;

const Badge = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 10px;
  border: 2px solid #F6F8FB;
  background-color: #e91e63;
  color: #F6F8FB;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  text-align: center;
  font-weight: bold;
`;

const LogoImage = styled.img`
  height: 20px;
  object-fit: contain;
`;

const HeaderAvatar = styled.div`
  margin-left: 15px;
  width: 35px;
  height: 35px;
  cursor: pointer;
  background-image: url(${Man});
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 50%;
`;

const UserImage = styled.img`
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 50%;
`;

export default Navbar;