import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import styled from "styled-components";
import Button from "components/button";
import { Input } from "components/inputs";
import { FaTimes } from "react-icons/fa";
import { BsPlusCircle } from "react-icons/bs";
import { BiMinusCircle } from "react-icons/bi";
import moment from "moment";

const FrequencePicker = (props) => {
  const classes = usetyles();

  const [dates, setDates] = useState([]);

  useEffect(() => {
    setDates(props.dates);
  }, [props.dates]);

  const [value, setValue] = useState(moment()?.format("YYYY-MM-DDTHH:mm"));

  const handleAddDate = () => {
    if (!!value) {
      setDates([...dates, value]);
    }
  };

  const handleRemoveDate = (index) => {
    setDates(dates.filter((el, ind) => ind !== index));
  };

  const handleSubmit = () => {
    props.onHide(true, dates);
    setDates([]);
    setValue(moment()?.format("YYYY-MM-DDTHH:mm"));
  };

  return (
    <Modal
      centered
      backdrop="static"
      size="sm"
      contentClassName={classes.modalContainer}
      show={props.visible}
      onHide={props.onHide.bind(this, false)}
    >
      <CloseIcon onClick={props.onHide.bind(this, false)} />
      <div className="h-auto w-100 d-flex flex-column p-3 mt-5">
        <div className="w-100 p-3 border border-5 d-flex flex-column align-items-center">
          <div className="w-100 d-flex align-items-center justify-content-between mb-2">
            <Input
              wrapperClassName="w-85"
              containerClassName="border-5 border"
              className="font-90 h-auto py-2"
              type="datetime-local"
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
            <BsPlusCircle
              onClick={handleAddDate}
              size={20}
              className="text-principal w-10 cursor-pointer"
            />
          </div>
          {dates.map((el, k) => (
            <div
              key={k}
              className="w-100 d-flex align-items-center justify-content-between mt-3"
            >
              <div className="font-90 text-disabled">
                {moment(el).format("dddd LL HH:mm")}
              </div>
              <BiMinusCircle
                size={20}
                className="text-disabled cursor-pointer"
                onClick={handleRemoveDate.bind(this, k)}
              />
            </div>
          ))}
        </div>
        <div className="w-100 text-center">
          <Button
            value="Sauvegarder"
            onClick={handleSubmit}
            className="mt-3 w-50 font-90"
          />
        </div>
      </div>
    </Modal>
  );
};

const usetyles = makeStyles(() => ({
  modalContainer: {
    boxShadow: "0px 8px 30px rgba(0, 0, 0, 0.12)",
    borderRadius: "5px",
    borderWidth: 0,
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    "@media only screen and (max-width: 768px)": {
      width: "80vw",
      marginLeft: "7vw",
    },
  },
}));

FrequencePicker.propTypes = {
  dates: PropTypes.array,
  visible: PropTypes.bool,
  onHide: PropTypes.func,
};

FrequencePicker.defaultProps = {
  dates: [],
  visible: false,
  onHide: () =>
    console.log("Please attach an onHide function to [FrequencePicker]"),
};

const CloseIcon = styled(FaTimes)`
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  color: black;
`;

export default FrequencePicker;
