import React, { useState } from 'react';
import { Popover, makeStyles } from "@material-ui/core";
import styled from 'styled-components';
import _ from 'lodash';
import { ANTECEDENTS } from 'dataset';
import { Input } from 'components';
import { FaSearch, FaTrash } from 'react-icons/fa';
import { ignoreAccents } from 'helpers';

interface AntecedentsPopoverProps {
  visible?: boolean;
  anchor?: any;
  onHide?: (status: boolean, libelle: string) => void;
};

const AntecedentsPopover = (props: AntecedentsPopoverProps) => {
  const classes = useStyles();

  const [antecedents, setAntecedents] = useState(ANTECEDENTS);

  const [search, setSearch] = useState("");
  const handleSearch = e => {
    setSearch(e?.target?.value);
    setAntecedents(ANTECEDENTS.map(el => {
      const matchIndex = el?.data?.findIndex(cat => {
        const input = ignoreAccents(String(e?.target?.value)?.toUpperCase());
        const current = ignoreAccents(String(cat)?.toUpperCase());
        return String(current).includes(input);
      });
      if (matchIndex !== -1) {
        return {
          ...el,
          data: el?.data?.filter(cat => {
            const input = ignoreAccents(String(e?.target?.value)?.toUpperCase());
            const current = ignoreAccents(String(cat)?.toUpperCase());
            return String(current).includes(input);
          }),
        };
      } else {
        return null;
      }
    }).filter(el => !!el));
  };

  return (
    <Popover
      id={props.visible ? "simple-popover" : undefined}
      open={props.visible}
      anchorEl={props.anchor}
      onClose={props.onHide.bind(this, false)}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      classes={{ paper: classes.paper }}
    >
      <div className="d-flex flex-column w-100">
        <Input
          placeholder="Rechercher"
          iconRight={<FaSearch className="text-disabled mr-3" size={18} />}
          containerClassName="border-top-0 border-left-0 border-right-0"
          containerStyle={{ height: "50px" }}
          wrapperClassName="w-100"
          autoFocus
          value={search}
          onChange={handleSearch}
        />
        <div className="d-flex flex-column w-100" style={{ height: "300px", overflowY: "auto" }}>
          {antecedents?.map((cat, ind) => (
            <div key={ind} className="w-100 d-flex flex-column">
              <Title>{cat?.title}</Title>
              {cat?.data?.map((el, key) => {
                return (
                  <Option
                    key={key}
                    onClick={() => {
                      props.onHide(true, el);
                      setSearch("");
                      setAntecedents(ANTECEDENTS);
                    }}
                  >{el}</Option>
                );
              })}
            </div>
          ))}
          <Option
            onClick={() => {
              props.onHide(true, "");
              setSearch("");
              setAntecedents(ANTECEDENTS);
            }}
            className="px-3 d-flex align-items-center justify-content-between text-red bg-principal-pills"
          >
            Effacer
            <FaTrash className="" />
          </Option>
        </div>
      </div>
    </Popover>
  );
};

AntecedentsPopover.defaultProps = {
  visible: false,
  onHide: () => console.log("No onHide method given"),
};

const useStyles = makeStyles(() => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    width: '400px',
    borderRadius: "10px",
    marginTop: "5px",
    boxShadow: "0px 0px 35px 2px #00000020"
  }
}));

const Title = styled.div`
  padding: 15px;
  color: #E71A65;
  font-size: 110%;
`;

const Option = styled.div`
  padding: 15px;
  padding-left: 40px;
  cursor: pointer;
  &:hover {
    background-color: #ddd8;
  }
`;

export default AntecedentsPopover;