import React from "react";
import styled from "styled-components";

function BorderedCard({ children }) {
  return <Container>{children}</Container>;
}

export default BorderedCard;

const Container = styled.div`
  background: #ffffff !important;
  border: 1px solid #e4e4e4 !important;
  border-radius: 4px !important;
  box-shadow: 0 4px 4px 0 rgb(0 0 0 / 5%) !important;
  width: 100%;
`;
