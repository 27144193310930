import React, {useState, useEffect} from 'react';
import styled from 'styled-components';
import {Input, SelectCreatable, Textarea} from 'components';
import _ from 'lodash';
import {BACKEND_DATE_FORMAT} from 'configs';
import moment from 'moment';
import Select from "react-select";
import {MuqueusesList} from "../../dataset";

import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from "draftjs-to-html"
import htmlToDraft from "html-to-draftjs"
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from "draft-js-import-html";


const FicheSerologie = (props) => {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [editorValue, setEditorValue] = useState('');
    const [toolbarOptions, setToolbarOptions] = useState({
        options: ['inline', 'blockType', 'fontSize', 'colorPicker', 'link', 'emoji', 'remove', 'history', 'textAlign']
    });
    const onEditorStateChange = newState => {
        setEditorState(newState);
        const htmlValue = draftToHtml(convertToRaw(newState.getCurrentContent()))
        //setEditorValue(stateToHTML(newState.getCurrentContent()));
        //const htmlValue = stateToHTML(newState.getCurrentContent())
        handleInputChange({
            target:{
                name:"SDW.conclusion",
                value:htmlValue
            }
        })
    };
    const handleInputChange = el => {
        if (!el) {
            return
        }
        const acteNameSplit = _.split(el?.target?.name, '.')
        if (acteNameSplit.length > 1) {
            const acteName = acteNameSplit[0]
            const subValueFieldLabel = acteNameSplit[1]
            const value = subValueFieldLabel == "exp" ? moment(el?.target?.value).format(BACKEND_DATE_FORMAT) : el?.target?.value
            props?.setToBeSubmittedData(old => ({
                ...old,
                [acteName]: {
                    ...old[acteName],
                    [subValueFieldLabel]: value
                }
            }))
        } else {
            props?.setToBeSubmittedData(old => ({
                ...old,
                [el?.target?.name]: {
                    ...old[el?.target?.name],
                    ['value']: el?.target?.value
                }
            }))
        }
    }

    const getSDWSelectAttributes = (acteName) => {
        let isSubValue = false, subValueFieldLabel = ""
        const finalActeName = acteName
        const acteNameSplit = _.split(acteName, '.')
        let type = null
        if (acteNameSplit.length > 1) {
            acteName = acteNameSplit[0]
            isSubValue = true
            subValueFieldLabel = acteNameSplit[1]
        }
        let value = !!props?.toBeSubmittedData[acteName] ? (isSubValue ? (props?.toBeSubmittedData[acteName])[subValueFieldLabel] : (props?.toBeSubmittedData[acteName])?.value) : ""
        return {
            //options: options,
            onChange: handleInputChange,
            name: finalActeName,
            value : value
        }
    }

    const getActeAttributes = (acteName, placeholder = "Saisir", max = null) => {
        let isSubValue = false, subValueFieldLabel = ""
        const finalActeName = acteName
        const acteNameSplit = _.split(acteName, '.')
        let type = null
        if (acteNameSplit.length > 1) {
            acteName = acteNameSplit[0]
            isSubValue = true
            subValueFieldLabel = acteNameSplit[1]
        }
        let value = !!props?.toBeSubmittedData[acteName] ? (isSubValue ? (props?.toBeSubmittedData[acteName])[subValueFieldLabel] : (props?.toBeSubmittedData[acteName])?.value) : ""
        if (_.endsWith(finalActeName, '.exp')) {
            type = "date"
            //max=moment().format(BACKEND_DATE_FORMAT)
            //value=moment(value).format(BACKEND_DATE_FORMAT)
        }
        return {
            value: value,
            onChange: handleInputChange,
            name: finalActeName,
            placeholder: placeholder,
            type: type,
            max: max
        }
    }

    const handleSDWDataShow = (acteName="SDW")=> {
        //handle SDW conclusion
        //console.log(props)
        const sdwData = props?.toBeSubmittedData[acteName]
        if(!!sdwData){
            //conclusion
            const sdwConclusion = htmlToDraft(sdwData?.conclusion || "");
            const contentState = ContentState.createFromBlockArray(sdwConclusion.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            //setEditorValue(draftToHtml(convertToRaw(editorState.getCurrentContent())))
            setEditorState(editorState)
        }

    }

    const handleSDWTitleChange = (elements, ...rest) => {
        handleInputChange({
            target:{
                name:"SDW.title",
                value:rest[0]?.map((el, k) => el?.value)
            }
        })
    };

    useEffect(()=>{
        handleSDWDataShow()

    }, [])
    return (
        <Table>
            <tr>
                <th colSpan={7} className="bg-principal-pills">COMPTE RENDU D'EXAMEN DE SÉROLOGIE</th>
            </tr>
            <tr>
                <td colSpan={1}>EXAMENS</td>
                <td colSpan={3}>RESULTATS</td>
                <td colSpan={3}>REFERENCE</td>
            </tr>
            <tr>
                <td className="font-weight-bold">
                    Ag HBS
                </td>
                <td colSpan={3} className="p-0 bg-light">
                    <Textarea {...getActeAttributes("FE04042")}
                              containerClassName="border-0 p-0"
                              className="pt-0"
                              placeholder="Saisir"
                              style={{fontSize: "12px"}}
                              rows={3}
                    />
                </td>
                <td colSpan={3} className="p-0">
                    <div className="w-100 d-flex flex-column">
                        <div className="d-flex align-items-center justify-content-between p-2">
              <span className="font-weight-bold mr-2">
                Réactif :
              </span>
                            <Input {...getActeAttributes("FE04042.reactif")}
                                   containerClassName="border-0 bg-light"
                                   placeholder="Saisir"
                                   wrapperStyle={{maxWidth: "350px"}}
                                   style={{fontSize: "12px"}}
                            />
                        </div>
                        <div className="d-flex align-items-center justify-content-between p-2">
              <span className="font-weight-bold mr-2">
                LOT :
              </span>
                            <Input {...getActeAttributes("FE04042.lot")}
                                   containerClassName="border-0 bg-light"
                                   placeholder="Saisir"
                                   wrapperStyle={{maxWidth: "350px"}}
                                   style={{fontSize: "12px"}}
                            />
                        </div>
                        <div className="d-flex align-items-center justify-content-between p-2">
              <span className="font-weight-bold mr-2">
                EXP :
              </span>
                            <Input {...getActeAttributes("FE04042.exp")}
                                   containerClassName="border-0 bg-light"
                                   placeholder="Saisir"
                                   wrapperStyle={{maxWidth: "350px"}}
                                   style={{fontSize: "12px"}}
                            />
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td className="font-weight-bold">
                    HCV Ac
                </td>
                <td colSpan={3} className="p-0 bg-light">
                    <Textarea {...getActeAttributes("FE04049")}
                              containerClassName="border-0 p-0"
                              className="pt-0"
                              placeholder="Saisir"
                              style={{fontSize: "12px"}}
                              rows={3}
                    />
                </td>
                <td colSpan={3} className="p-0">
                    <div className="w-100 d-flex flex-column">
                        <div className="d-flex align-items-center justify-content-between p-2">
              <span className="font-weight-bold mr-2">
                Réactif :
              </span>
                            <Input {...getActeAttributes("FE04049.reactif")}
                                   containerClassName="border-0 bg-light"
                                   placeholder="Saisir"
                                   wrapperStyle={{maxWidth: "350px"}}
                                   style={{fontSize: "12px"}}
                            />
                        </div>
                        <div className="d-flex align-items-center justify-content-between p-2">
              <span className="font-weight-bold mr-2">
                LOT :
              </span>
                            <Input {...getActeAttributes("FE04049.lot")}
                                   containerClassName="border-0 bg-light"
                                   placeholder="Saisir"
                                   wrapperStyle={{maxWidth: "350px"}}
                                   style={{fontSize: "12px"}}
                            />
                        </div>
                        <div className="d-flex align-items-center justify-content-between p-2">
              <span className="font-weight-bold mr-2">
                EXP :
              </span>
                            <Input {...getActeAttributes("FE04049.exp")}
                                   containerClassName="border-0 bg-light"
                                   placeholder="Saisir"
                                   wrapperStyle={{maxWidth: "350px"}}
                                   style={{fontSize: "12px"}}
                            />
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td className="font-weight-bold">
                    SHIV
                </td>
                <td colSpan={3} className="p-0 bg-light">
                    <Textarea {...getActeAttributes("FE04056")}
                              containerClassName="border-0 p-0"
                              className="pt-0"
                              placeholder="Saisir"
                              style={{fontSize: "12px"}}
                              rows={3}
                    />
                </td>
                <td colSpan={3} className="p-0">
                    <div className="w-100 d-flex flex-column">
                        <div className="d-flex align-items-center justify-content-between p-2">
              <span className="font-weight-bold mr-2">
                Réactif :
              </span>
                            <Input {...getActeAttributes("FE04056.reactif")}
                                   containerClassName="border-0 bg-light"
                                   placeholder="Saisir"
                                   wrapperStyle={{maxWidth: "350px"}}
                                   style={{fontSize: "12px"}}
                            />
                        </div>
                        <div className="d-flex align-items-center justify-content-between p-2">
              <span className="font-weight-bold mr-2">
                LOT :
              </span>
                            <Input {...getActeAttributes("FE04056.lot")}
                                   containerClassName="border-0 bg-light"
                                   placeholder="Saisir"
                                   wrapperStyle={{maxWidth: "350px"}}
                                   style={{fontSize: "12px"}}
                            />
                        </div>
                        <div className="d-flex align-items-center justify-content-between p-2">
              <span className="font-weight-bold mr-2">
                EXP :
              </span>
                            <Input {...getActeAttributes("FE04056.exp")}
                                   containerClassName="border-0 bg-light"
                                   placeholder="Saisir"
                                   wrapperStyle={{maxWidth: "350px"}}
                                   style={{fontSize: "12px"}}
                            />
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td className="font-weight-bold">
                    CRP Ultra Sensible
                </td>
                <td colSpan={3} className="p-0 bg-light">
                    <Textarea {...getActeAttributes("CRPUS")}
                              containerClassName="border-0 p-0"
                              className="pt-0"
                              placeholder="Saisir"
                              style={{fontSize: "12px"}}
                              rows={3}
                    />
                </td>
                <td colSpan={3} className="p-0">
                    <div className="w-100 d-flex flex-column">
                        <div className="d-flex align-items-center justify-content-between p-2">
              <span className="font-weight-bold mr-2">
                Réactif :
              </span>
                            <Input {...getActeAttributes("CRPUS.reactif")}
                                   containerClassName="border-0 bg-light"
                                   placeholder="Saisir"
                                   wrapperStyle={{maxWidth: "350px"}}
                                   style={{fontSize: "12px"}}
                            />
                        </div>
                        <div className="d-flex align-items-center justify-content-between p-2">
              <span className="font-weight-bold mr-2">
                LOT :
              </span>
                            <Input {...getActeAttributes("CRPUS.lot")}
                                   containerClassName="border-0 bg-light"
                                   placeholder="Saisir"
                                   wrapperStyle={{maxWidth: "350px"}}
                                   style={{fontSize: "12px"}}
                            />
                        </div>
                        <div className="d-flex align-items-center justify-content-between p-2">
              <span className="font-weight-bold mr-2">
                EXP :
              </span>
                            <Input {...getActeAttributes("CRPUS.exp")}
                                   containerClassName="border-0 bg-light"
                                   placeholder="Saisir"
                                   wrapperStyle={{maxWidth: "350px"}}
                                   style={{fontSize: "12px"}}
                            />
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td className="font-weight-bold">
                    CRP Microméthode
                </td>
                <td colSpan={3} className="p-0 bg-light">
                    <Textarea {...getActeAttributes("CRPBB")}
                              containerClassName="border-0 p-0"
                              className="pt-0"
                              placeholder="Saisir"
                              style={{fontSize: "12px"}}
                              rows={3}
                    />
                </td>
                <td colSpan={3} className="p-0">
                    <div className="w-100 d-flex flex-column">
                        <div className="d-flex align-items-center justify-content-between p-2">
              <span className="font-weight-bold mr-2">
                Réactif :
              </span>
                            <Input {...getActeAttributes("CRPBB.reactif")}
                                   containerClassName="border-0 bg-light"
                                   placeholder="Saisir"
                                   wrapperStyle={{maxWidth: "350px"}}
                                   style={{fontSize: "12px"}}
                            />
                        </div>
                        <div className="d-flex align-items-center justify-content-between p-2">
              <span className="font-weight-bold mr-2">
                LOT :
              </span>
                            <Input {...getActeAttributes("CRPBB.lot")}
                                   containerClassName="border-0 bg-light"
                                   placeholder="Saisir"
                                   wrapperStyle={{maxWidth: "350px"}}
                                   style={{fontSize: "12px"}}
                            />
                        </div>
                        <div className="d-flex align-items-center justify-content-between p-2">
              <span className="font-weight-bold mr-2">
                EXP :
              </span>
                            <Input {...getActeAttributes("CRPBB.exp")}
                                   containerClassName="border-0 bg-light"
                                   placeholder="Saisir"
                                   wrapperStyle={{maxWidth: "350px"}}
                                   style={{fontSize: "12px"}}
                            />
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td className="font-weight-bold">
                    CRP Protéine C Réactive
                </td>
                <td colSpan={3} className="p-0 bg-light">
                    <Textarea {...getActeAttributes("CRPP")}
                              containerClassName="border-0 p-0"
                              className="pt-0"
                              placeholder="Saisir"
                              style={{fontSize: "12px"}}
                              rows={3}
                    />
                </td>
                <td colSpan={3} className="p-0">
                    <div className="w-100 d-flex flex-column">
                        <div className="d-flex align-items-center justify-content-between p-2">
              <span className="font-weight-bold mr-2">
                Réactif :
              </span>
                            <Input {...getActeAttributes("CRPP.reactif")}
                                   containerClassName="border-0 bg-light"
                                   placeholder="Saisir"
                                   wrapperStyle={{maxWidth: "350px"}}
                                   style={{fontSize: "12px"}}
                            />
                        </div>
                        <div className="d-flex align-items-center justify-content-between p-2">
              <span className="font-weight-bold mr-2">
                LOT :
              </span>
                            <Input {...getActeAttributes("CRPP.lot")}
                                   containerClassName="border-0 bg-light"
                                   placeholder="Saisir"
                                   wrapperStyle={{maxWidth: "350px"}}
                                   style={{fontSize: "12px"}}
                            />
                        </div>
                        <div className="d-flex align-items-center justify-content-between p-2">
              <span className="font-weight-bold mr-2">
                EXP :
              </span>
                            <Input {...getActeAttributes("CRPP.exp")}
                                   containerClassName="border-0 bg-light"
                                   placeholder="Saisir"
                                   wrapperStyle={{maxWidth: "350px"}}
                                   style={{fontSize: "12px"}}
                            />
                        </div>
                    </div>
                </td>
            </tr>
            {/*<tr>
        <td className="font-weight-bold">
          ASLO
        </td>
         <td colSpan={3} className="p-0 bg-light">
          <Textarea {...getActeAttributes("FE04003")}
            containerClassName="border-0 p-0"
            className="pt-0"
            placeholder="Saisir"
            style={{ fontSize: "12px" }}
            rows={3}
          />
        </td>
         <td colSpan={3} className="p-0">
          <div className="w-100 d-flex flex-column">
            <div className="d-flex align-items-center justify-content-between p-2">
              <span className="font-weight-bold mr-2">
                Réactif :
              </span>
              <Input {...getActeAttributes("FE04003.reactif")}
                containerClassName="border-0 bg-light"
                placeholder="Saisir"
                wrapperStyle={{ maxWidth: "350px" }}
                style={{ fontSize: "12px" }}
              />
            </div>
            <div className="d-flex align-items-center justify-content-between p-2">
              <span className="font-weight-bold mr-2">
                LOT :
              </span>
              <Input {...getActeAttributes("FE04003.lot")}
                containerClassName="border-0 bg-light"
                placeholder="Saisir"
                wrapperStyle={{ maxWidth: "350px" }}
                style={{ fontSize: "12px" }}
              />
            </div>
            <div className="d-flex align-items-center justify-content-between p-2">
              <span className="font-weight-bold mr-2">
                EXP :
              </span>
              <Input {...getActeAttributes("FE04003.exp")}
                containerClassName="border-0 bg-light"
                placeholder="Saisir"
                wrapperStyle={{ maxWidth: "350px" }}
                style={{ fontSize: "12px" }}
              />
            </div>
          </div>
        </td>
      </tr>*/}
            <tr>
                <td className="font-weight-bold">
                    TPHA qualitatif
                </td>
                <td colSpan={3} className="p-0 bg-light">
                    <Textarea {...getActeAttributes("FE04023")}
                              containerClassName="border-0 p-0"
                              className="pt-0"
                              placeholder="Saisir"
                              style={{fontSize: "12px"}}
                              rows={3}
                    />
                </td>
                <td colSpan={3} className="p-0">
                    <div className="w-100 d-flex flex-column">
                        <div className="d-flex align-items-center justify-content-between p-2">
              <span className="font-weight-bold mr-2">
                Réactif :
              </span>
                            <Input {...getActeAttributes("FE04023.reactif")}
                                   containerClassName="border-0 bg-light"
                                   placeholder="Saisir"
                                   wrapperStyle={{maxWidth: "350px"}}
                                   style={{fontSize: "12px"}}
                            />
                        </div>
                        <div className="d-flex align-items-center justify-content-between p-2">
              <span className="font-weight-bold mr-2">
                LOT :
              </span>
                            <Input {...getActeAttributes("FE04023.lot")}
                                   containerClassName="border-0 bg-light"
                                   placeholder="Saisir"
                                   wrapperStyle={{maxWidth: "350px"}}
                                   style={{fontSize: "12px"}}
                            />
                        </div>
                        <div className="d-flex align-items-center justify-content-between p-2">
              <span className="font-weight-bold mr-2">
                EXP :
              </span>
                            <Input {...getActeAttributes("FE04023.exp")}
                                   containerClassName="border-0 bg-light"
                                   placeholder="Saisir"
                                   wrapperStyle={{maxWidth: "350px"}}
                                   style={{fontSize: "12px"}}
                            />
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td className="font-weight-bold">
                    TPHA quantitatif
                </td>
                <td colSpan={3} className="p-0 bg-light">
                    <Textarea {...getActeAttributes("FE04024")}
                              containerClassName="border-0 p-0"
                              className="pt-0"
                              placeholder="Saisir"
                              style={{fontSize: "12px"}}
                              rows={3}
                    />
                </td>
                <td colSpan={3} className="p-0">
                    <div className="w-100 d-flex flex-column">
                        <div className="d-flex align-items-center justify-content-between p-2">
              <span className="font-weight-bold mr-2">
                Réactif :
              </span>
                            <Input {...getActeAttributes("FE04024.reactif")}
                                   containerClassName="border-0 bg-light"
                                   placeholder="Saisir"
                                   wrapperStyle={{maxWidth: "350px"}}
                                   style={{fontSize: "12px"}}
                            />
                        </div>
                        <div className="d-flex align-items-center justify-content-between p-2">
              <span className="font-weight-bold mr-2">
                LOT :
              </span>
                            <Input {...getActeAttributes("FE04024.lot")}
                                   containerClassName="border-0 bg-light"
                                   placeholder="Saisir"
                                   wrapperStyle={{maxWidth: "350px"}}
                                   style={{fontSize: "12px"}}
                            />
                        </div>
                        <div className="d-flex align-items-center justify-content-between p-2">
              <span className="font-weight-bold mr-2">
                EXP :
              </span>
                            <Input {...getActeAttributes("FE04024.exp")}
                                   containerClassName="border-0 bg-light"
                                   placeholder="Saisir"
                                   wrapperStyle={{maxWidth: "350px"}}
                                   style={{fontSize: "12px"}}
                            />
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td className="font-weight-bold">
                    VDRL qualitatif
                </td>
                <td colSpan={3} className="p-0 bg-light">
                    <Textarea {...getActeAttributes("FE04021")}
                              containerClassName="border-0 p-0"
                              className="pt-0"
                              placeholder="Saisir"
                              style={{fontSize: "12px"}}
                              rows={3}
                    />
                </td>
                <td colSpan={3} className="p-0">
                    <div className="w-100 d-flex flex-column">
                        <div className="d-flex align-items-center justify-content-between p-2">
              <span className="font-weight-bold mr-2">
                Réactif :
              </span>
                            <Input {...getActeAttributes("FE04021.reactif")}
                                   containerClassName="border-0 bg-light"
                                   placeholder="Saisir"
                                   wrapperStyle={{maxWidth: "350px"}}
                                   style={{fontSize: "12px"}}
                            />
                        </div>
                        <div className="d-flex align-items-center justify-content-between p-2">
              <span className="font-weight-bold mr-2">
                LOT :
              </span>
                            <Input {...getActeAttributes("FE04021.lot")}
                                   containerClassName="border-0 bg-light"
                                   placeholder="Saisir"
                                   wrapperStyle={{maxWidth: "350px"}}
                                   style={{fontSize: "12px"}}
                            />
                        </div>
                        <div className="d-flex align-items-center justify-content-between p-2">
              <span className="font-weight-bold mr-2">
                EXP :
              </span>
                            <Input {...getActeAttributes("FE04021.exp")}
                                   containerClassName="border-0 bg-light"
                                   placeholder="Saisir"
                                   wrapperStyle={{maxWidth: "350px"}}
                                   style={{fontSize: "12px"}}
                            />
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td className="font-weight-bold">
                    VDRL quantitatif
                </td>
                <td colSpan={3} className="p-0 bg-light">
                    <Textarea {...getActeAttributes("FE04022")}
                              containerClassName="border-0 p-0"
                              className="pt-0"
                              placeholder="Saisir"
                              style={{fontSize: "12px"}}
                              rows={3}
                    />
                </td>
                <td colSpan={3} className="p-0">
                    <div className="w-100 d-flex flex-column">
                        <div className="d-flex align-items-center justify-content-between p-2">
              <span className="font-weight-bold mr-2">
                Réactif :
              </span>
                            <Input {...getActeAttributes("FE04022.reactif")}
                                   containerClassName="border-0 bg-light"
                                   placeholder="Saisir"
                                   wrapperStyle={{maxWidth: "350px"}}
                                   style={{fontSize: "12px"}}
                            />
                        </div>
                        <div className="d-flex align-items-center justify-content-between p-2">
              <span className="font-weight-bold mr-2">
                LOT :
              </span>
                            <Input {...getActeAttributes("FE04022.lot")}
                                   containerClassName="border-0 bg-light"
                                   placeholder="Saisir"
                                   wrapperStyle={{maxWidth: "350px"}}
                                   style={{fontSize: "12px"}}
                            />
                        </div>
                        <div className="d-flex align-items-center justify-content-between p-2">
              <span className="font-weight-bold mr-2">
                EXP :
              </span>
                            <Input {...getActeAttributes("FE04022.exp")}
                                   containerClassName="border-0 bg-light"
                                   placeholder="Saisir"
                                   wrapperStyle={{maxWidth: "350px"}}
                                   style={{fontSize: "12px"}}
                            />
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td className="font-weight-bold">
                    TGB Sérique
                </td>
                <td colSpan={3} className="p-0 bg-light">
                    <Textarea {...getActeAttributes("FF00003")}
                              containerClassName="border-0 p-0"
                              className="pt-0"
                              placeholder="Saisir"
                              style={{fontSize: "12px"}}
                              rows={3}
                    />
                </td>
                <td colSpan={3} className="p-0">
                    <div className="w-100 d-flex flex-column">
                        <div className="d-flex align-items-center justify-content-between p-2">
              <span className="font-weight-bold mr-2">
                Réactif :
              </span>
                            <Input {...getActeAttributes("FF00003.reactif")}
                                   containerClassName="border-0 bg-light"
                                   placeholder="Saisir"
                                   wrapperStyle={{maxWidth: "350px"}}
                                   style={{fontSize: "12px"}}
                            />
                        </div>
                        <div className="d-flex align-items-center justify-content-between p-2">
              <span className="font-weight-bold mr-2">
                LOT :
              </span>
                            <Input {...getActeAttributes("FF00003.lot")}
                                   containerClassName="border-0 bg-light"
                                   placeholder="Saisir"
                                   wrapperStyle={{maxWidth: "350px"}}
                                   style={{fontSize: "12px"}}
                            />
                        </div>
                        <div className="d-flex align-items-center justify-content-between p-2">
              <span className="font-weight-bold mr-2">
                EXP :
              </span>
                            <Input {...getActeAttributes("FF00003.exp")}
                                   containerClassName="border-0 bg-light"
                                   placeholder="Saisir"
                                   wrapperStyle={{maxWidth: "350px"}}
                                   style={{fontSize: "12px"}}
                            />
                        </div>
                    </div>
                </td>
            </tr>
            <tr>
                <td rowSpan={3} className="font-weight-bold">
                    SDW
                </td>
                <td className="font-weight-bold sdw">
                    <SDWSelector {...getSDWSelectAttributes("SDW.AO")}
                        parentName="AO"
                    />
                </td>
                <td className="font-weight-bold">
                    <SDWSelector {...getSDWSelectAttributes("SDW.BO")}
                                 parentName="BO"
                    />
                </td>
                <td className="font-weight-bold">
                    <SDWSelector {...getSDWSelectAttributes("SDW.CO")}
                                 parentName="CO"
                    />
                </td>
                <td className="font-weight-bold">
                    <SDWSelector {...getSDWSelectAttributes("SDW.TO")}
                                 parentName="TO"
                    />
                </td>
                <td rowSpan={2} colSpan={2} className="font-weight-bold" style={{overflow:"auto"}}>
                    <SelectCreatable
                        name="SDW.title"
                        isMulti
                        options={[]}
                        containerClassName="p-0 border-0"
                        className="p-0 font-90"
                        placeholder="Saisir"
                        onChange={handleSDWTitleChange}
                        value={(props?.toBeSubmittedData["SDW"]?.title || []).map(el => ({ value: el, label: el }))}
                        style={{ borderRadius: "5px" }}
                        containerStyle={{ width: "100%", zIndex: 200 }}
                        label="Titre"
                    />
                </td>
            </tr>
            <tr>

                <td className="font-weight-bold sdw">
                    <SDWSelector {...getSDWSelectAttributes("SDW.AH")}
                                 parentName="AH"
                    />
                </td>
                <td className="font-weight-bold">
                    <SDWSelector {...getSDWSelectAttributes("SDW.BH")}
                                 parentName="BH"
                    />
                </td>
                <td className="font-weight-bold">
                    <SDWSelector {...getSDWSelectAttributes("SDW.CH")}
                                 parentName="CH"
                    />
                </td>
                <td className="font-weight-bold">
                    <SDWSelector {...getSDWSelectAttributes("SDW.TH")}
                                 parentName="TH"
                    />
                </td>
            </tr>
            <tr>

                <td className="font-weight-bold">
                    Conclusion
                </td>
                <td colSpan={5} className="font-weight-bold">
                    <div>
                        <Editor
                            editorState={editorState}
                            toolbarClassName="toolbarClassName border-top-0 border-left-0 border-right-0 p-0"
                            wrapperClassName="wrapperClassName"
                            editorStyle={{ height: "100px" }}
                            editorClassName="editorClassName"
                            onEditorStateChange={onEditorStateChange}
                            toolbar={toolbarOptions}
                            value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
                            //readOnly={inputDisabled}
                        />
                    </div>
                </td>
            </tr>

        </Table>
    );
};

const SDWSelector = ({value, onChange, name, parentName}) => {
    return (
        <div className="w-100 d-flex flex-column">
            <div className="d-flex align-items-center justify-content-center">
              <span className="font-weight-bold">
                {parentName} (
              </span>
                <SDWSelect
                    value={value}
                    onChange={onChange}
                    name={name}
                    className="font-weight-bold "
                    style={{fontSize: "14px", textAlign: "center"}}
                >
                    <option value="">..</option>
                    <option value="+">+</option>
                    <option value="-">-</option>
                </SDWSelect>
                <span className="font-weight-bold">
                )
              </span>
            </div>
        </div>
    )
}
const SDWSelect = styled.select`
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
  padding: 0 1px 0 0;
`;
const Table = styled.table`
  width: 100%;
  table-layout: fixed;
  border: 0.5px solid #ABABAB;
  border-collapse: collapse;

  th {
    border: 0.5px solid #ABABAB;
    padding: 10px 5px;
    font-weight: bold;
    text-align: center;
    font-size: 12px;
  }

  td {
    padding: 10px;
    text-align: center;
    border: 0.5px solid #ABABAB;
    border-collapse: collapse;
    font-size: 12px;

    &.sdw {
      span {
        font-size: 14px;
      }
    }
  }
`;

export default FicheSerologie;