import moment from "moment";
import { parseJwt } from 'helpers';

const STORAGE_KEY = process.env.REACT_APP_APP_STORAGE_KEY;

export const getConnectedUserToken = () => {
    return getAxiosConfig({}, true)
}

export const getConnectedUserAuthorization = () => {
    return {
        Authorization : `Bearer ${getConnectedUserToken()}`
    }
}

export const getAxiosConfig = (config, getOnlyToken = false, ) => {
    const persistedData = localStorage.getItem(STORAGE_KEY);
    const data = persistedData ? JSON.parse(persistedData) : [];
    if(!!getOnlyToken){
        return data.token
    }
    const tokenExpiryDate = moment.unix(parseJwt(data?.token)?.exp);

    config.headers.post["Content-Type"] = "application/json";

    if (!!data.token && tokenExpiryDate.isAfter(moment())) {
        config.headers.Authorization = `Bearer ${data.token}`;
    } else if (!!data?.token && tokenExpiryDate.isBefore(moment())) {
        localStorage.removeItem(STORAGE_KEY);
        window.location.reload();
    }

    return config;
}

export default function setupAxios(axios) {
  axios.interceptors.request.use(
    (config) => getAxiosConfig(config),
    (err) => Promise.reject(err)
  );

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      return Promise.reject(error?.response?.data);
    }
  );
}
