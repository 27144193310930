import React from "react";
import "./styles.scss";
import PropTypes from "prop-types";

const Select = ({
  type,
  name,
  label,
  value,
  error,
  onChange,
  iconLeft,
  iconRight,
  className,
  labelClassName,
  errorClassName,
  containerClassName,
  containerStyle,
  wrapperClassName,
  wrapperStyle,
  children,
  ...rest
}) => {
  return (
    <div
      style={{ width: "100%", ...wrapperStyle }}
      className={`m-0 p-0 d-flex flex-column align-items-center ${wrapperClassName}`}
    >
      {!!label && <div className={`w-100 py-2 ${labelClassName}`}>{label}</div>}
      <div
        style={{ ...containerStyle }}
        className={`kea-input ${containerClassName}`}
      >
        {iconLeft && <div className="kea-input__left-icon">{iconLeft}</div>}
        <select
          name={name}
          value={value}
          onChange={onChange}
          {...rest}
          className={`kea-input__select ${className} ${
            iconLeft ? "ml-0" : ""
          } ${iconRight ? "mr-0" : ""}`}
        >
          {children}
        </select>
        {iconRight && <div className="kea-input__right-icon">{iconRight}</div>}
      </div>
      {!!error && (
        <div className={`kea-input__feedback ${errorClassName}`}>{error}</div>
      )}
    </div>
  );
};

Select.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.any,
  error: PropTypes.string,
  onChange: PropTypes.func,
  iconLeft: PropTypes.any,
  iconRight: PropTypes.any,
  labelClassName: PropTypes.string,
  errorClassName: PropTypes.string,
  containerClassName: PropTypes.string,
  containerStyle: PropTypes.object,
  placeholder: PropTypes.string,
};

Select.defaultProps = {
  name: "",
  value: "",
  onChange: () => console.log("no onChange method attached to Select..."),
  className: "",
  labelClassName: "",
  errorClassName: "",
  containerClassName: "",
  containerStyle: {},
};

export default Select;
