import React from "react";
import styled from 'styled-components';
import PropTypes from 'prop-types';

const SwiftSelect = ({
	name,
	value,
	onChange,
	error,
	errorStyle,
	style,
	containerStyle,
	containerClassName,
	theme,
	children,
	...rest
}) => {
	return (
		<div
			className={containerClassName}
			style={{
				margin: "5px 0",
				padding: '5px',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'flex-start',
				justifyContent: 'flex-start',
				...containerStyle
			}}
		>
			<select
				name={name}
				value={value}
				onChange={onChange}
				style={{
					outline: "none",
					width: '100%',
					padding: "10px",
					borderRadius: "3px",
					color: !!theme && !!theme.text ? theme.text : "black",
					borderColor: !!theme && !!theme.border ? theme.border : "#888",
					backgroundColor: !!theme && !!theme.background ? theme.background : "white",
					...style
				}}
				{...rest}
			>
				{children}
			</select>
			{!!error && <ErrorFeedback style={{ ...errorStyle }}>{error}</ErrorFeedback>}
		</div>
	);
};

SwiftSelect.propTypes = {
	name: PropTypes.string,
	value: PropTypes.string,
	onChange: PropTypes.func,
	error: PropTypes.string,
	errorStyle: PropTypes.object,
	style: PropTypes.object,
	containerStyle: PropTypes.object,
	containerClassName: PropTypes.string,
	placeholder: PropTypes.string,
	theme: PropTypes.object
};

SwiftSelect.defaultProps = {
	name: '',
	value: '',
	onChange: () => console.log('no onChange method attached...'),
	style: {},
	errorStyle: {},
	containerStyle: {},
	containerClassName: "",
};

const ErrorFeedback = styled.span`
	width: 100%;
	margin-top: .25rem;
	font-size: 80%;
	color: red;
	text-align: right;
`;

export default SwiftSelect;
