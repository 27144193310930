import React from "react";
import PropTypes from 'prop-types';
import "./styles.scss";

const ProgressBar = props => {
  return (
    <div
      {...props}
      className={`
        kea-progress-bar
        kea-progress-bar-${props.size ? props.size : 'md'}
        kea-progress-bar-${props.variant ? props.variant : ''}
        ${props.className ? props.className : ''}
      `}
    >
      <div style={{ width: String(props?.value) + "%" }} className="kea-progress-bar-indicator"></div>
    </div>
  );
};

ProgressBar.propTypes = {
  value: PropTypes.number,
  size: PropTypes.string,
  variant: PropTypes.oneOf(["principal", "green", "infos", "red", "blue", "black", "gray"]),
};

ProgressBar.defaultProps = {
  value: 10,
  size: "md",
  variant: "principal",
  className: ""
};

export default ProgressBar;