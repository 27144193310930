import React from "react";
import Header from "./components/header";
import Sidebar from "./components/sidebar";
import Footer from "./components/footer";
import classnames from "classnames";
import "./style.scss";

import { useStore } from "context/store";
import { TOGGLE_ROOM_ACTION } from "context/store";

import { WaitingRoom } from "components";

import Person1 from "assets/images/person-1.jpeg";
import Person2 from "assets/images/person-2.jpeg";
import Person3 from "assets/images/person-3.jpeg";
import Person4 from "assets/images/person-4.jpeg";
import Person5 from "assets/images/person-5.jpeg";
import { useLocation } from "react-router-dom";

export function Vertical(props) {
  const {
    dispatch,
    state: { displayRoom },
  } = useStore();

  const { pathname } = useLocation();

  const [sidenavState, setSidenavState] = React.useState(true);
  const [gridState, setGridState] = React.useState(true);

  React.useEffect(() => {
    if (window !== "undefined") {
      window.addEventListener("resize", addResizeListeners());
    }
  }, []);

  function addResizeListeners() {
    const width = window.innerWidth;
    if (width > 750) {
      setSidenavState(true);
      setGridState(true);
    }
  }

  function toggleSidebar() {
    setSidenavState(!sidenavState);
  }

  function toggleGrid() {
    setGridState(!gridState);
  }

  const childrenWithProps = React.Children.map(props.children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { toggleSidebar });
    }
    return child;
  });

  return (
    <div
      className={classnames("grid", { "grid--noscroll": gridState })}
      style={{ height: "100vh" }}
    >
      <WaitingRoom />
      <Header
        sidenavState={sidenavState}
        gridState={gridState}
        toggleSidebar={toggleSidebar}
        toggleGrid={toggleGrid}
      />
      <Sidebar
        sidenavState={sidenavState}
        gridState={gridState}
        toggleSidebar={toggleSidebar}
        toggleGrid={toggleGrid}
      />
      <main
        style={{ overflowY: "scroll" }}
        className={`main ${
          pathname === "/consutation/consultation-pre-natal2" ? "" : "px-3 pb-3"
        }  pt-0`}
      >
        {childrenWithProps}
      </main>
      <Footer />
    </div>
  );
}

export default Vertical;
