import React from 'react';
import "./styles.scss";
import PropTypes from 'prop-types';

const Textarea = ({
  type,
  name,
  label,
  value,
  rows,
  error,
  onChange, 
  style,
  className,
  labelClassName,
  errorClassName,
  containerClassName,
  containerStyle,
  wrapperClassName,
  wrapperStyle,
  ...rest
}) => {
  return (
    <div style={{ width: "100%", ...wrapperStyle }} className={`m-0 p-0 d-flex flex-column align-items-center ${wrapperClassName}`}>
      {!!label && <div className={`w-100 py-2 ${labelClassName}`}>{label}</div>}
      <div style={containerStyle} className={`kea-input ${containerClassName}`}>
        <textarea
          type={type}
          name={name}
          defaultValue={value}
          value={value}
          rows={rows}
          onChange={onChange}
          style={style}
          {...rest}
          className={`kea-input__textarea ${className}`}
        />
      </div>
      {!!error && <div className={`kea-input__feedback ${errorClassName}`}>{error}</div>}
    </div>
  );
};

Textarea.propTypes = {
  type: PropTypes.oneOf(["text", "date", "time", "number", "password", "email", "tel"]),
  name: PropTypes.string,
  value: PropTypes.string,
  rows: PropTypes.number,
  label: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func,
  labelClassName: PropTypes.string,
  errorClassName: PropTypes.string,
  containerStyle: PropTypes.object,
  containerClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
  wrapperStyle: PropTypes.object,
  placeholder: PropTypes.string
};

Textarea.defaultProps = {
  type: 'text',
  name: '',
  value: '',
  rows: '5',
  onChange: () => console.log('no onChange method attached to Input...'),
  className: "",
  labelClassName: "",
  errorClassName: "",
  containerClassName: "",
  containerStyle: {},
  wrapperClassName: "",
  wrapperStyle: {}
};

export default Textarea;