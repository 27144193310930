import React, {useRef,useState} from 'react';
import "./styles.scss";
import PropTypes from 'prop-types';
import uploadImg from "assets/icons/import-file.png";

const ImportFileInput = ({
                           name,
                           value,
                           onChange,
                           className,
                           showText,
                             onTheFlySrc,
                        ...rest
                       }) => {
    const wrapperRef = useRef(null);

    const onDragEnter = () => wrapperRef.current.classList.add('dragover');

    const onDragLeave = () => wrapperRef.current.classList.remove('dragover');

    const onDrop = () => wrapperRef.current.classList.remove('dragover');

    return (
        <>
            <div
                ref={wrapperRef}
                className={className ? className : "drop-file-input"}
                onDragEnter={onDragEnter}
                onDragLeave={onDragLeave}
                onDrop={onDrop}
            >
                <div className="drop-file-input__label">
                    <img style={{width:"auto", maxWidth : "80%", maxHeight:"200px"}}  src={!!onTheFlySrc ? onTheFlySrc : uploadImg} alt="" />
                    {
                       (showText && !onTheFlySrc) &&
                            <div>
                                <p style={{fontSize : "10px", marginBottom : "0"}}>Cliquez pour télécharger ou faites glisser et déposez</p>
                                <p style={{fontSize : "10px"}}>Taille maximale 1Mo.</p>
                            </div>
                    }
                </div>
                <input type="file" name={name}  value={value} onChange={onChange} {...rest}/>
            </div>
        </>
    );
};

ImportFileInput.propTypes = {
    name: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    onFileChange: PropTypes.func
};

ImportFileInput.defaultProps = {
    name: '',
    value: '',
    onChange: () => console.log('no onChange method attached to Select...'),
};

export default ImportFileInput;