import React from 'react';
import ReactLoading from "react-loading";

export default props => {
  return (
    <div {...props} className="w-100 d-flex justify-content-center align-items-center my-5">
      <ReactLoading
        type="spinningBubbles"
        color={props?.color || "#E71A65"}
        width="50px"
        height="50px"
      />
    </div>
  );
};