import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";
import { Button, ConfirmModal } from "components";
import moment from "moment";
import { Spinner } from 'react-bootstrap';
import { removeNote } from 'http/crud';
import { useStore } from 'context/store';
import { toast } from 'react-toastify';

const CardSyntheseNote = (props) => {
  const { state: { agent } } = useStore();

  const [synthese, setSynthese] = React.useState(null);

  React.useEffect(() => {
    setSynthese(props.synthese);
  }, [props.synthese]);

  const [loading, setLoading] = useState(false);

  const [showConfirm, setShowConfirm] = useState(false);

  const handleHideConfirm = async (status) => {
    setShowConfirm(false);
    if (status === true) {
      setLoading(true);
      await removeNote({
        idNote: props?.synthese?.Datas?._id,
        deletedBy: agent?.agentId
      }).then(response => {
        if (response?.data?.success) {
          props.onReload(false);
          toast.success("Note supprimée !");
        } else {
          toast.error("Une erreur est survenue lors de l'opération !");
        }
      }).catch(error => {
        toast.error("Une erreur est survenue lors de l'opération !");
      }).finally(() => setLoading(false));
    }
  };

  return (
    <Card {...props} className={`p-3 ${props.className}`}>
      <ConfirmModal
        message="Voulez-vous vraiment supprimer cette note ?"
        visible={showConfirm}
        onHide={handleHideConfirm}
      />
      <div className="d-flex align-items-center justify-content-between">
        <Button
          className="w-auto h-auto py-1 font-90"
          size="sm"
          value={moment(synthese?.created_at).format("HH:mm")}
          style={{ background: "linear-gradient(54.74deg, #52575C 30.03%, #526487 145.84%)" }}
        />
        <div className="d-flex align-items-center justify-content-end">
          <div className="d-flex align-items-center">
            <span className="font-90 text-principal">Action: </span>
            <span className="font-90 ml-2">Prise de notes</span>
          </div>
          <div className="d-flex align-items-center ml-4">
            <span className="font-90 text-principal">Auteur: </span>
            <span className="font-90 ml-2">Dr {synthese?.Datas?.Medecin?.lastName || "-"} {synthese?.Datas?.Medecin?.firstName || ""}</span>
          </div>
        </div>
      </div>
      <Text className="mt-3 font-90 p-3">
        {synthese?.Datas?.note || "-"}
      </Text>
      <div className={`w-100 d-flex align-items-center justify-content-${loading ? 'between' : 'end'}`}>
        {loading && <Spinner animation="border" size="sm" className="text-principal" />}
        <Button
          variant={synthese?.Datas?.statut === true ? "principal" : "gray"}
          className="font-90 border-5 mt-3"
          size="sm"
          value={synthese?.Datas?.statut ? "Supprimer note" : "Note suppprimée"}
          disabled={!synthese?.Datas?.statut}
          onClick={() => setShowConfirm(true)}
        />
      </div>
    </Card>
  );
};

const Card = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  min-height: 70px;
  width: 100%;
`;

const Text = styled.div`
  background-color: #E3ECF07D;
  width: 100%;
  min-height: 70px;
  border-radius: 10px;
  color: #52575C;
`;

CardSyntheseNote.propTypes = {
  synthese: PropTypes.object,
  className: PropTypes.string,
  onReload: PropTypes.func,
};

CardSyntheseNote.defaultProps = {
  className: "",
  onReload: () => { },
};

export default CardSyntheseNote;