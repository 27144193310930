import React from 'react';
import "./styles.scss";
import PropTypes from 'prop-types';
import Select from "react-select/creatable";
import _ from 'lodash';

interface SelectSearchableProps {
	options: {
		label: string;
		value: string;
	}[];
	getNewOptionData: (text: string) => { label: string; value: string };
};

const SelectSearchable = ({
	type,
	label,
	error,
	name,
	value,
	onChange,
	className,
	labelClassName,
	errorClassName,
	containerClassName,
	containerStyle,
	wrapperClassName,
	wrapperStyle,
	noIndicators,
	style,
	inputStyle,
	optionStyle,
	menuStyle,
	placeholder,
	defaultValue,
	...rest
}: SelectSearchableProps) => {

	const handleChange = (el, ...rest) => {
		onChange({ target: { value: el?.value, name } }, el);
	};

	return (
		<div style={{ width: "100%", zIndex: 10, ...wrapperStyle }} className={`m-0 p-0 d-flex flex-column align-items-center ${wrapperClassName} `}>
			{!!label && <div className={`w-100 py-2 ${labelClassName} `}>{label}</div>}
			<div style={{ ...containerStyle }} className={`border ${containerClassName} `}>
				<Select
					classNamePrefix="select"
					isClearable={true}
					isSearchable={true}
					theme={theme => ({
						...theme,
						border: "0px",
						borderRadius: 0,
						colors: {
							...theme.colors,
							primary: "#0000"
						}
					})}
					className={`w - 100 ${className || ''} `}
					onChange={handleChange}
					styles={{
						control: (nativeStyles, state) => ({
							...nativeStyles,
							height: "40px",
							border: "0px",
							...style,
						}),
						input: (nativeStyles, state) => ({
							...nativeStyles,
							...inputStyle,
						}),
						singleValue: (nativeStyles, state) => ({
							...nativeStyles,
							...inputStyle,
						}),
						indicatorsContainer: (nativeStyles, state) => (noIndicators ? {
							...nativeStyles,
							display: "none",
						} : {
							...nativeStyles
						}),
						placeholder: (nativeStyles, state) => ({
							...nativeStyles,
							...inputStyle,
						}),
						option: (nativeStyles, state) => ({
							...nativeStyles,
							...inputStyle,
							...optionStyle,
						}),
						menuList: styles => ({ ...styles, ...menuStyle, maxHeight: "200px" }),
					}}
					placeholder={placeholder}
					value={value}
					defaultValue={defaultValue}
					{..._.omit(rest, ["type", "label", "onChange", "error", "className", "labelClassName", "errorClassName", "containerClassName", "value"])}
				/>
			</div>
			{!!error && <div className={`kea-input__feedback ${errorClassName} `}>{error}</div>}
		</div>
	);
};

SelectSearchable.propTypes = {
	name: PropTypes.string,
	value: PropTypes.object,
	defaultValue: PropTypes.object,
	options: PropTypes.array,
	label: PropTypes.any,
	error: PropTypes.string,
	placeholder: PropTypes.string,
	onChange: PropTypes.func,
	labelClassName: PropTypes.string,
	errorClassName: PropTypes.string,
	containerClassName: PropTypes.string,
	containerStyle: PropTypes.object,
	placeholder: PropTypes.string
};

SelectSearchable.defaultProps = {
	name: '',
	defaultValue: undefined,
	options: [],
	onChange: () => console.log('no onChange method attached to SelectSearchable...'),
	className: "",
	labelClassName: "",
	errorClassName: "",
	containerClassName: "",
	containerStyle: {},
};

export default SelectSearchable;