import React from "react";
import { useStore } from "context/store";

export default function checkPermission(pageSlug = "", permissionSlug = "") {
  const { state: { currentUser, role } } = useStore();

  if (!role || !Array.isArray(role.pages)) {
    return false;
  }

  const currentPage = role?.pages.find(page => page?.apppages?.pageSlug == pageSlug);

  if (!!permissionSlug) {
    return currentPage?.permission?.some(perm => perm?.apppermissions?.permissionSlug === permissionSlug);
  } else {
    return !!currentPage?.apppagesId;
  }
}
