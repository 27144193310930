import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FaPlus, FaTrashAlt } from "react-icons/fa";
import { AiOutlinePushpin } from "react-icons/ai";
import { toast } from "react-toastify";
import { validate } from "context/swift-inputs";
import {
  SelectSearchable,
  Input,
  Button,
  FrequencePicker,
  RemoteSelectCreatable,
} from "components";
import { searchMedocs } from "http/crud";

const DEFAULT_CODE = "ENABELPRODPH";

const FicheTherapeutiqueInput = (props) => {
  const { unites, voies } = props;

  const [form, setForm] = useState({
    fields: {
      produit: "",
      code: "",
      posologie: "",
      voieAdmission: "",
      duree: "",
      dateAdmissionFiches: [],
      sousProduitFiche: [],
    },
    errors: {
      produit: "",
      code: "",
      posologie: "",
      voieAdmission: "",
      duree: "",
      dateAdmissionFiches: "",
      sousProduitFiche: "",
    },
  });

  const handleResetForm = () => {
    setForm({
      fields: {
        produit: "",
        code: "",
        posologie: "",
        voieAdmission: "",
        duree: "",
        dateAdmissionFiches: [],
        sousProduitFiche: [],
      },
      errors: {
        produit: "",
        code: "",
        posologie: "",
        voieAdmission: "",
        duree: "",
        dateAdmissionFiches: "",
        sousProduitFiche: "",
      },
    });
  };

  const handleAddSubElement = () => {
    setForm((old) => ({
      fields: {
        ...old.fields,
        sousProduitFiche: [
          ...old.fields.sousProduitFiche,
          { produit: "", code: "", posologie: "" },
        ],
      },
      errors: old.errors,
    }));
  };

  const handleRemoveSubElement = (index) => {
    setForm((old) => ({
      fields: {
        ...old.fields,
        sousProduitFiche: old.fields.sousProduitFiche.filter(
          (el, k) => k !== index
        ),
      },
      errors: old.errors,
    }));
  };

  // HANDLE PERIOD PICKER
  const [showPicker, setShowPicker] = useState(false);

  const handleShowPicker = () => {
    setShowPicker(true);
  };

  const handleHidePicker = (status, dates = []) => {
    setShowPicker(false);
    if (status === true) {
      setForm((old) => ({
        fields: {
          ...old.fields,
          dateAdmissionFiches: dates,
        },
        errors: old.errors,
      }));
    }
  };

  // HANDLE INPUTS
  const handleInputChange = (e) => {
    if (e?.target.name === "produit") {
      setForm((old) => ({
        fields: {
          ...old.fields,
          produit: e?.target?.element?.label || "",
          code: e?.target?.value || "",
        },
        errors: old.errors,
      }));
    } else {
      setForm((old) => ({
        fields: {
          ...old.fields,
          [e?.target.name]: e?.target.value,
        },
        errors: old.errors,
      }));
    }
  };

  // HANDLE SUBS INPUTS
  const handleSubInputChange = (index, e) => {
    let updated = form.fields.sousProduitFiche;
    if (e.target.name === "produit") {
      updated[index]["produit"] = e?.target?.element?.label || "";
      updated[index]["code"] = e?.target?.value || "";
      setForm((old) => ({
        fields: {
          ...old.fields,
          sousProduitFiche: updated,
        },
        errors: old.errors,
      }));
    } else {
      updated[index][e?.target.name] = e?.target.value;
      setForm((old) => ({
        fields: {
          ...old.fields,
          sousProduitFiche: updated,
        },
        errors: old.errors,
      }));
    }
  };

  const handleSubmit = () => {
    const validation = validate(form.fields, {
      produit: "string|required",
      posologie: "string|required",
      voieAdmission: "string|required",
      duree: "string|required",
      dateAdmissionFiches: (data) => ({
        valid: Array.isArray(data) && data.length > 0,
        error: Array.isArray(data) && data.length > 0 ? "" : "Invalide",
      }),
      sousProduitFiche: (data) => {
        if (
          !Array.isArray(data) ||
          (Array.isArray(data) && data.length === 0)
        ) {
          return {
            valid: true,
            error: "",
          };
        } else {
          let good = true;
          for (let i = 0; i < data.length; i++) {
            const el = data[i];
            if (!!el?.produit && !!el?.posologie) {
              good = good && true;
            } else {
              good = good && false;
              break;
            }
          }
          return {
            valid: good,
            error: good ? "" : "Invalide",
          };
        }
      },
    });

    if (validation.isFormValid()) {
      props.onSubmit(form.fields);
      handleResetForm();
    } else {
      setForm({
        fields: form.fields,
        errors: {
          ...form.errors,
          produit: validation?.response?.produit.error,
          posologie: validation?.response?.posologie.error,
          voieAdmission: validation?.response?.voieAdmission.error,
          duree: validation?.response?.duree.error,
          dateAdmissionFiches: validation?.response?.dateAdmissionFiches.error,
          sousProduitFiche: validation?.response?.sousProduitFiche.error,
        },
      });
    }
  };

  return (
    <div
      style={{ minHeight: "300px", overflow: "auto", ...props?.style }}
      className="d-flex flex-column pb-2"
    >
      <FrequencePicker
        dates={form.fields.dateAdmissionFiches}
        visible={showPicker}
        onHide={handleHidePicker}
      />
      <InputsContainer>
        <tr>
          <td className="text-left" style={{ minWidth: "250px" }}>
            <RemoteSelectCreatable
              valueName="code"
              labelName="libelle"
              containerClassName={`border rounded-lg ${
                !!form.errors.produit ? "border-danger" : "border"
              }`}
              placeholder="Choisir"
              name="produit"
              value={
                !!form?.fields?.produit
                  ? { label: form?.fields?.produit, value: form?.fields?.code }
                  : null
              }
              onChange={handleInputChange}
              onSearch={searchMedocs}
              getNewOptionData={(text) => ({
                label: text,
                value: `${DEFAULT_CODE}_${Date.now()}`,
              })}
              inputStyle={{ fontSize: "80%", borderRadius: "5px" }}
              style={{ height: "40px", borderRadius: "5px", zIndex: 100 }}
            />
          </td>
          <td style={{ minWidth: "100px" }}>
            <Input
              containerClassName={`border-5 ${
                !!form.errors.posologie ? "border-danger" : "border"
              }`}
              className="font-80 py-1"
              placeholder="Posologie"
              name="posologie"
              value={form.fields.posologie}
              onChange={handleInputChange}
            />
          </td>
          <td className="text-left" style={{ minWidth: "150px" }}>
            <SelectSearchable
              containerClassName={`border rounded-lg ${
                !!form.errors.voieAdmission ? "border-danger" : "border"
              }`}
              placeholder="Voie d'admission"
              name="voieAdmission"
              value={
                voies.findIndex((el) => el === form.fields.voieAdmission) !== -1
                  ? {
                      value: form.fields.voieAdmission,
                      label: form.fields.voieAdmission,
                    }
                  : null
              }
              onChange={handleInputChange}
              options={voies.map((el) => ({ label: el, value: el }))}
              inputStyle={{ fontSize: "80%" }}
              style={{ borderRadius: "5px" }}
              menuStyle={{ height: "200px" }}
            />
          </td>
          <td style={{ minWidth: "150px" }}>
            <Input
              containerClassName={`border-5 ${
                !!form.errors.duree ? "border-danger" : "border"
              }`}
              className="font-80 py-1"
              placeholder="Durée d’administration"
              name="duree"
              value={form.fields.duree}
              onChange={handleInputChange}
            />
          </td>
          <td>
            <Button
              className={`border-5 py-1`}
              value="Fréquence"
              onClick={handleShowPicker}
            />
          </td>
          <td>
            <FaPlus
              onClick={handleSubmit}
              size={20}
              className="text-principal cursor-pointer"
            />
          </td>
        </tr>
      </InputsContainer>
      {form.fields.sousProduitFiche.length === 0 ? (
        <div className="mt-3">
          <Button
            style={{
              backgroundColor: "#52648726",
              height: "30px",
              width: "30px",
            }}
            className="rounded-circle text-disabled shadow-none p-0"
            onClick={handleAddSubElement}
          >
            <AiOutlinePushpin />
          </Button>
        </div>
      ) : (
        form.fields.sousProduitFiche.map((el, k) => (
          <InputsContainer key={k} className="mt-3">
            <tr>
              <td className="text-left" style={{ minWidth: "230px" }}>
                <RemoteSelectCreatable
                  valueName="code"
                  labelName="libelle"
                  containerClassName={`border rounded-lg`}
                  placeholder="Choisir"
                  name="produit"
                  value={
                    !!el?.produit
                      ? { label: el?.produit, value: el?.code }
                      : null
                  }
                  onChange={(e, element) => handleSubInputChange(k, e, element)}
                  onSearch={searchMedocs}
                  getNewOptionData={(text) => ({
                    label: text,
                    value: `${DEFAULT_CODE}_${Date.now()}`,
                  })}
                  inputStyle={{ fontSize: "80%", borderRadius: "5px" }}
                  style={{ height: "40px", borderRadius: "5px" }}
                />
              </td>
              <td>
                <Input
                  containerClassName="border-5 border"
                  className="font-80 py-1"
                  placeholder="Posologie"
                  name="posologie"
                  value={el?.posologie}
                  onChange={handleSubInputChange.bind(this, k)}
                />
              </td>
              <td>
                <Button
                  style={{
                    backgroundColor: "#52648726",
                    height: "30px",
                    width: "30px",
                  }}
                  className="rounded-circle text-disabled shadow-none p-0"
                  onClick={handleRemoveSubElement.bind(this, k)}
                >
                  <FaTrashAlt size={12} />
                </Button>
              </td>
              {k === form.fields.sousProduitFiche.length - 1 && (
                <td>
                  <Button
                    style={{
                      backgroundColor: "#E8357826",
                      height: "30px",
                      width: "30px",
                    }}
                    className="rounded-circle text-principal shadow-none p-0"
                    onClick={handleAddSubElement}
                  >
                    <AiOutlinePushpin />
                  </Button>
                </td>
              )}
            </tr>
          </InputsContainer>
        ))
      )}
    </div>
  );
};

FicheTherapeutiqueInput.propTypes = {
  onSubmit: PropTypes.func,
  unites: PropTypes.array,
  voies: PropTypes.array,
};

FicheTherapeutiqueInput.defaultProps = {
  onSubmit: () => console.log("Please attach an onSubmit method to Input"),
  unites: [],
  voies: [],
};

const InputsContainer = styled.table`
  border: 0px solid #ebebeb;
  border-collapse: collapse;
  th {
    border-radius: 3px;
    padding: 10px 5px;
    font-weight: bold;
    color: #9f9f9f;
    text-align: center;
    font-size: 11px;
  }
  td {
    padding: 1px;
    text-align: center;
    border: 0px solid #ebebeb;
    border-collapse: collapse;
    font-size: 11px;
  }
`;

export default FicheTherapeutiqueInput;
