export const FRONTEND_DATE_FORMAT = "DD/MM/YYYY";
export const BACKEND_DATE_FORMAT = "YYYY-MM-DD";
export const BACKEND_DATETIME_FORMAT = "YYYY-MM-DD HH:mm";

export const FIXED_ROLE_SUFFIX = "FIXED_ROLE_";
export const FIXED_ROLE_SUPER_USER = "FIXED_ROLE_SUPER_USER";
export const FIXED_ROLE_CAISSIER_RE_ = "FIXED_ROLE_CAISSIER_RE_";
export const FIXED_ROLE_CAISSIER_RE_PRINCIPAL_E_ = "FIXED_ROLE_CAISSIER_RE_PRINCIPAL_E_";

export const YEARS_LIST = () => {
  let output = [];
  for (let year = 2022; year <= (new Date()).getFullYear() + 3; year++) {
    output.push({
      label: String(year),
      value: String(year),
    });
  }
  return output;
};
export const NOT_CONNECTED_TO_INTERNET_MSG = "Connexion impossible. Veuillez vérifier votre réseau!"
export const CONNECTED_TO_INTERNET_MSG = "Connexion au réseau rétablie!"
export const IMAGES_EXTENSIONS = [".png", ".jpg", ".jpeg", ".PNG", ".JPG", ".JPEG"];
export const DOCUMENTS_EXTENSIONS = [".png", ".jpg", ".jpeg", ".PNG", ".JPG", ".JPEG", ".pdf"];
export const IMAGE_MAX_SIZE = 1024*1024; //1Mo
export const MONTHS_LIST = [
  { value: '01', label: "Janvier" },
  { value: '02', label: "Février" },
  { value: '03', label: "Mars" },
  { value: '04', label: "Avril" },
  { value: '05', label: "Mai" },
  { value: '06', label: "Juin" },
  { value: '07', label: "Juillet" },
  { value: '08', label: "Août" },
  { value: '09', label: "Septembre" },
  { value: '10', label: "Octobre" },
  { value: '11', label: "Novembre" },
  { value: '12', label: "Décembre" },
];

export const GROUP_SANGUIN_LIST = [
  { value: 'A+', label: "A+" },
  { value: 'A-', label: "A-" },
  { value: 'O+', label: "O+" },
  { value: 'O-', label: "O-" },
  { value: 'B+', label: "B+" },
  { value: 'B-', label: "B-" },
  { value: 'AB+', label: "AB+" },
  { value: 'AB-', label: "AB-" },
];

export const ELECTROP_LIST = [
  { value: 'AA', label: "AA" },
  { value: 'AS', label: "AS" },
  { value: 'AC', label: "AC" },
  { value: 'SC', label: "SC" },
  { value: 'SS', label: "SS" },
];

export const IMG_ECHO_DEFAULT = "DEFAULT";
export const IMG_ECHO_PELVIENNE = "ECHO_PELV";
export const IMG_ECHO_OBS_1 = "ECHO_OBS_1";
export const IMG_ECHO_OBS_2_3 = "ECHO_OBS_2_3";