import React from "react";
import { AiOutlineCheckSquare, AiFillCheckSquare } from 'react-icons/ai';
import "./styles.scss";


interface CheckboxProps {
  checked: boolean;
  size: number;
  variant: "principal" | "green" | "infos" | "red" | "blue" | "black" | "gray";
  onChange: (status: boolean) => void;
  ActiveIcon: React.ReactNode;
  InactiveIcon: React.ReactNode;
};

const Checkbox = (props: CheckboxProps) => {
  const ActiveIcon = props.ActiveIcon || AiFillCheckSquare;
  const InactiveIcon = props.InactiveIcon || AiOutlineCheckSquare;

  const handleClick = (...rest) => {
    props.onChange(!props.checked, rest);
  };

  return props.checked === true ?
    <ActiveIcon
      style={props.style ? props.style : {}}
      onClick={handleClick}
      size={props.size}
      className={`kea-checkbox-${props.variant ? props.variant : 'principal'} ${props.className ? props.className : ''}`}
    />
    :
    <InactiveIcon
      style={props.style ? props.style : {}}
      onClick={handleClick}
      size={props.size}
      className={`kea-checkbox-${props.variant ? props.variant : 'principal'} disabled ${props.className ? props.className : ''}`}
    />;
};

Checkbox.defaultProps = {
  onChange: () => console.log("Please attach an onChange function to [Checkbox]"),
  checked: false,
  className: ""
};

export default Checkbox;