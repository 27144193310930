import React from "react";
/* import { makeStyles } from "@material-ui/core";

const usetyles = makeStyles((theme) => ({
  footer: {
    gridArea: "footer",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 16px",
    color: "#777",
    backgroundColor: "#fff",
    height: "60px"
  },
  footerCopyright: {
    color: '#1BBAE1'
  },
  footerIcon: {
    color: '#e74c3c'
  },
  footerSignature: {
    color: '#1BBAE1',
    cursor: 'pointer',
    fontWeight: 'bold'
  }
})); */

function Footer() {
  return (
    <footer className="footer">
      
    </footer>
  );
}

export default Footer;
