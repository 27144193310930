import React from 'react';
import "./styles.scss";
import PropTypes from 'prop-types';

const FocusedSelect = ({
  name,
  value,
  error,
  onChange,
  className,
  children,
  ...rest
}) => {
  const [active, setActive] = React.useState(false);

  return (
    <select
      name={name}
      value={value}
      readOnly={!active}
      onChange={onChange}
      onDoubleClick={() => setActive(true)}
      onBlur={() => setActive(false)}
      {...rest}
      className={`kea-input__select h-auto p-1 border-0 ${className ? String(className) : ""}`}
    >{children}</select>
  );
};

FocusedSelect.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string
};

FocusedSelect.defaultProps = {
  name: '',
  value: '',
  onChange: () => console.log('no onChange method attached to Select...'),
};

export default FocusedSelect;