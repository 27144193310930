export const clientTypes = {
  Medecin: "Medecin",
  Infirmier: "Infirmier",
};

export const DEFAULT_LABO_CODE = "ENABEL_LABO_EXAM";
export const DEFAULT_IMAGERIE_CODE = "ENABE_IMAGERIE_EXAM";
export const DEFAULT_ACTE_CODE = "ENABE_ACTE_EXAM";

export const CAISSE_SEARCH_OPTIONS = [
  {
    label: "Rechercher par téléphone", value: "phone",
    rule: "required|min:8|max:20",
  },
  {
    label: "Rechercher par NIP", value: "nip",
    rule: "required|integer|min:12|max:20",
  },
  {
    label: "Rechercher par email", value: "email",
    rule: "required|email"
  },
  {
    label: "Rechercher par nom et prénoms", value: "names",
    rule: "nullable"
  },
];

export const CURRENT_MENU_COLOR = "#E91E63";
export const DEFAULT_MENU_COLOR = "#FFFFFF";

export const APP_PAGES = {
  PAGE_CONFIGURATION_PARAMETRE: "PAGE_CONFIGURATION_PARAMETRE",
  PAGE_TARIF: "PAGE_TARIF",
  PAGE_FACTURATION_DEMANDE_ANNULATION: "PAGE_FACTURATION_DEMANDE_ANNULATION",
  PAGE_FACTURATION_ENCAISSEMENT: "PAGE_FACTURATION_ENCAISSEMENT",
  PAGE_FACTURATION_ARCH: "PAGE_FACTURATION_ARCH",
  PAGE_SALLE_ACOUCHEMENT: "PAGE_SALLE_ACOUCHEMENT",
  PAGE_DOSSIERS_HOSPI: "PAGE_DOSSIERS_HOSPI",
  PAGE_LIST_CONSULTATION: "PAGE_LIST_CONSULTATION",
  PAGE_ACCUEIL_SERVICE: "PAGE_ACCUEIL_SERVICE",
  PAGE_SALLE_DILATATION: "PAGE_SALLE_DILATATION",
  PAGE_LABO: "PAGE_LABO",
  PAGE_PARAMETRE: "PAGE_PARAMETRE",
  PAGE_FACTURATION: "PAGE_FACTURATION",
  PAGE_CAISSE_PRINCIPALE: "PAGE_CAISSE_PRINCIPALE",
  PAGE_FACTURATION_CAISSE: "PAGE_FACTURATION_CAISSE",
  PAGE_FACTURATION_POINT_CAISSE: "PAGE_FACTURATION_POINT_CAISSE",
  PAGE_ROLE_ACCES: "PAGE_ROLE_ACCES",
  PAGE_ACCES_CONFIG_CENTRES: "PAGE_ACCES_CONFIG_CENTRES",
  PAGE_CONFIGURATION_ACTE: "PAGE_CONFIGURATION_ACTE",
  PAGE_SALLE_ATTENTE: "PAGE_SALLE_ATTENTE",
  PAGE_STATISTIQUES: "PAGE_STATISTIQUES",
  PAGE_AGENDA: "PAGE_AGENDA",
  PAGE_DEPENSE: "PAGE_DEPENSE",
  PAGE_SOINS_AMBULATOIRES: "PAGE_SOINS_AMBULATOIRES",
  PAGE_MATERNITE: "PAGE_MATERNITE",
  PAGE_ARCHIVE_CONSULTATION: "PAGE_ARCHIVE_CONSULTATION",
  PAGE_USER_SERVICE: "PAGE_USER_SERVICE",
  PAGE_FACTURATION_ETAT_PERIODIQUE: "PAGE_FACTURATION_ETAT_PERIODIQUE",
  PAGE_ARCHIVE_MATERNITE: "PAGE_ARCHIVE_MATERNITE",
  PAGE_IMAGERIE: "PAGE_IMAGERIE",
  PAGE_ACCEUIL_ORIENTATION:"PAGE_ACCEUIL_ORIENTATION",
  PAGE_AFFECTATION_CHAMBRE:"PAGE_AFFECTATION_CHAMBRE",
  PAGE_PRE_ANESTHESIE:" PAGE_PRE_ANESTHESIE_BLOC"
};

export const PERMISSIONS = {
  PERMISSION_ACTION_ADD_TARIF: "PERMISSION_ACTION_ADD_TARIF",
  PERMISSION_ACTION_UPD_TARIF: "PERMISSION_ACTION_UPD_TARIF",
  PERMISSION_ACTION_DELETE_TARIF: "PERMISSION_ACTION_DELETE_TARIF",
  
  PERMISSION_ACTION_PRESCRIPTION_ANALYSE: "PERMISSION_ACTION_PRESCRIPTION_ANALYSE",
  PERMISSION_ACTION_PRESCRIPTION_IMAGERIE: "PERMISSION_ACTION_PRESCRIPTION_IMAGERIE",
  PERMISSION_ACTION_UPD_ANTECEDENT: "PERMISSION_ACTION_UPD_ANTECEDENT",
  PERMISSION_ACTION_PROGRAMMER_FICHE_THERA: "PERMISSION_ACTION_PROGRAMMER_FICHE_THERA",
  PERMISSION_ACTION_PRESCRIPTION_ORDONNANCE: "PERMISSION_ACTION_PRESCRIPTION_ORDONNANCE",
  PERMISSION_ACTION_CONSULTATION_DIAGNOSTIC: "PERMISSION_ACTION_CONSULTATION_DIAGNOSTIC",
  PERMISSION_ACTION_VISITE: "PERMISSION_ACTION_VISITE",
  PERMISSION_ACTION_VISUALISER_ANTECEDENT: "PERMISSION_ACTION_VISUALISER_ANTECEDENT",
  PERMISSION_ACTION_CLOTURER_DOSSIER_HOSP: "PERMISSION_ACTION_CLOTURER_DOSSIER_HOSP",
  
  PERMISSION_ACTION_UPD_CONSULTATION: "PERMISSION_ACTION_UPD_CONSULTATION",
  
  PERMISSION_ACTION_GIVE_RESULT: "PERMISSION_ACTION_GIVE_RESULT",
  PERMISSION_ACTION_VIEW_RESULT: "PERMISSION_ACTION_VIEW_RESULT",

  PERMISSION_ACTION_GIVE_RESULT_IMG: "PERMISSION_ACTION_GIVE_RESULT_IMG",
  PERMISSION_ACTION_VIEW_RESULT_IMG: "PERMISSION_ACTION_VIEW_RESULT_IMG",

  PERMISSION_ACTION_SIGNATAIRE: "PERMISSION_ACTION_SIGNATAIRE",
  PERMISSION_ACTION_UPD_MODE_PAIEMENT: "PERMISSION_ACTION_UPD_MODE_PAIEMENT",
  PERMISSION_ACTION_UPD_FONCTION: "PERMISSION_ACTION_UPD_FONCTION",
  PERMISSION_ACTION_CAISSE_AFFECTATION: "PERMISSION_ACTION_CAISSE_AFFECTATION",
  PERMISSION_ACTION_UPD_NORMALISATION: "PERMISSION_ACTION_UPD_NORMALISATION",
  PERMISSION_ACTION_UPD_BASE_INFO: "PERMISSION_ACTION_UPD_BASE_INFO",

  PERMISSION_ACTION_ASK_ANNULATION: "PERMISSION_ACTION_ASK_ANNULATION",

  PERMISSION_ACTION_CREATE_ROLE: "PERMISSION_ACTION_CREATE_ROLE",
  PERMISSION_ACTION_UPD_ROLE: "PERMISSION_ACTION_UPD_ROLE",
  PERMISSION_ACTION_DELETE_ROLE: "PERMISSION_ACTION_DELETE_ROLE",

  PERMISSION_ACTION_UPD_KIT: "PERMISSION_ACTION_UPD_KIT",
  PERMISSION_ACTION_VIEW_CAT_ACTES: "PERMISSION_ACTION_VIEW_CAT_ACTES",
  PERMISSION_ACTION_UPD_COTATION_ACTE: "PERMISSION_ACTION_UPD_COTATION_ACTE",
  PERMISSION_ACTION_ADD_ACTE: "PERMISSION_ACTION_ADD_ACTE",
  PERMISSION_ACTION_ADD_KIT_ACTE: "PERMISSION_ACTION_ADD_KIT_ACTE",
  PERMISSION_ACTION_UPD_ACTE: "PERMISSION_ACTION_UPD_ACTE",
  PERMISSION_ACTION_DELETE_PRESTATION: "PERMISSION_ACTION_DELETE_PRESTATION",
  PERMISSION_ACTION_VIEW_CLASSES_ACTES: "PERMISSION_ACTION_VIEW_CLASSES_ACTES",
  PERMISSION_ACTION_UPD_CLASS_ACTES: "PERMISSION_ACTION_UPD_CLASS_ACTES",
  PERMISSION_ACTION_DELETE_COTATION_ACTE: "PERMISSION_ACTION_DELETE_COTATION_ACTE",
  PERMISSION_ACTION_DELETE_CLASS_ACTE: "PERMISSION_ACTION_DELETE_CLASS_ACTE",
  PERMISSION_ACTION_ADD_COTATION_ACTE: "PERMISSION_ACTION_ADD_COTATION_ACTE",
  PERMISSION_ACTION_VIEW_SOUS_CAT_ACTES: "PERMISSION_ACTION_VIEW_SOUS_CAT_ACTES",
  PERMISSION_ACTION_DELETE_KIT: "PERMISSION_ACTION_DELETE_KIT",

  PERMISSION_DONNER_CONSIGNES_SOINS: "PERMISSION_DONNER_CONSIGNES_SOINS",
  PERMISSION_ACTION_CONSULTATION: "PERMISSION_ACTION_CONSULTATION",
  PERMISSION_ACTION_UPD_PATIENT_CONS: "PERMISSION_ACTION_UPD_PATIENT_CONS",
  PERMISSION_ACTION_MATERNITE : "PERMISSION_ACTION_MATERNITE",

  PERMISSION_ACTION_DELETE_DEPENSE: "PERMISSION_ACTION_DELETE_DEPENSE",
  PERMISSION_ACTION_ADD_DEPENSE: "PERMISSION_ACTION_ADD_DEPENSE",
  PERMISSION_ACTION_UPD_DEPENSE: "PERMISSION_ACTION_UPD_DEPENSE",

  PERMISSION_ACTION_CONTROLE: "PERMISSION_ACTION_CONTROLE",
  PERMISSION_ACTION_VOIR_DOSSIER_SOINS_PATIENT: "PERMISSION_ACTION_VOIR_DOSSIER_SOINS_PATIENT",
  
  PERMISSION_ACTION_CREATE_USER: "PERMISSION_ACTION_CREATE_USER",
  PERMISSION_ACTION_UPD_USER: "PERMISSION_ACTION_UPD_USER",
  PERMISSION_ACTION_DELETE_USER: "PERMISSION_ACTION_DELETE_USER",
  PERMISSION_ACTION_UPD_SERVICE: "PERMISSION_ACTION_UPD_SERVICE",
  PERMISSION_ACTION_ADD_SERVICE: "PERMISSION_ACTION_ADD_SERVICE",
  PERMISSION_ACTION_DELETE_SERVICE: "PERMISSION_ACTION_DELETE_SERVICE",

  PERMISSION_ACTION_AFFECTER_CHAMBRE: "PERMISSION_AFFECTER_CHAMBRE",
  PERMISSION_ACTION_AFFECTER_CHAMBRE_UPD: "PERMISSION_ACTION_AFFECTER_CHAMBRE_UPD",
  PERMISSION_ACTION_GET_ARCHIVE_CONSULTATION:"PERMISSION_ACTION_GET_ARCHIVE_CONSULTATION",
  PERMISSION_ACTION_GET_HOSPITALISATION:"PERMISSION_ACTION_GET_HOSPITALISATION",
  PERMISSION_ACTION_GET_ATTENTE_SERVICE:"PERMISSION_ACTION_GET_ATTENTE_SERVICE",
  PERMISSION_ACTION_PRISE_CONSTANTE:"PERMISSION_ACTION_PRISE_CONSTANTE",
  PERMISSION_ACTION_PRISE_NOTE:"PERMISSION_ACTION_PRISE_NOTE",
  PERMISSION_ACTION_CHIRURGIE : "PERMISSION_ACTION_CHIRURGIE",
  PERMISSION_AFFECTER_CHAMBRE:"PERMISSION_AFFECTER_CHAMBRE",

};