import { CONS_TYPES } from "configs";
import { routesConfigs } from "configs";

export default (code) => {
  /*if (code === CONS_TYPES.MED_GEN) {
    return routesConfigs["MENU.CONSULTATION.MEDECINE.GENERALE"];
  } else if (code === CONS_TYPES.GYNECO) {
    return `/consultations/gynecologie`;
  } else if (code === CONS_TYPES.CPN) {
    return routesConfigs["CONSULTATION.PRENATAL2"];
  } else if (code === CONS_TYPES.ANES) {
    return routesConfigs["PREANESTHESIE"];
  } else if (code === CONS_TYPES.POST_NATAL) {
    return routesConfigs["CONSULTATION.POSTNATAL"];
  } else if (code === CONS_TYPES.CARDIO) {
    return routesConfigs["CONSULTATION.CARDIO"];
  } else if (code === CONS_TYPES.CSTM) {
    return routesConfigs["CONSULTATION.STOMATOLOGIE"];
  } else if (code === CONS_TYPES.ORL) {
    return routesConfigs["CONSULTATION.ORL"];
  } else {
    return `/consultations/medecine-generale`;
  }
  */
  switch (code) {
    case CONS_TYPES.MED_GEN:
      return routesConfigs["MENU.CONSULTATION.MEDECINE.GENERALE"];
    case CONS_TYPES.GYNECO:
      return routesConfigs["MENU.CONSULTATION.GYNECOLOGIE"];
    case CONS_TYPES.CPN:
      return routesConfigs["CONSULTATION.PRENATAL2"];
    case CONS_TYPES.ANES:
      return routesConfigs["PREANESTHESIE"];
    case CONS_TYPES.POST_NATAL:
      return routesConfigs["CONSULTATION.POSTNATAL"];
    case CONS_TYPES.CARDIO:
      return routesConfigs["CONSULTATION.CARDIO"];
    case CONS_TYPES.CSTM:
      return routesConfigs["CONSULTATION.STOMATOLOGIE"];
    case CONS_TYPES.PEDIATRIE:
      return routesConfigs["CONSULTATION.PEDIATRIE"];
    case CONS_TYPES.ORL:
      return routesConfigs["CONSULTATION.ORL"];

    default:
      return routesConfigs["MENU.CONSULTATION.MEDECINE.GENERALE"];
  }
};
