import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { makeStyles } from "@material-ui/core";
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from 'components/button';
import { FaTimes } from 'react-icons/fa';

const ConfirmModal = props => {
  const classes = usetyles();

  return (
    <Modal
      centered
      backdrop="static"
      size="sm"
      contentClassName={classes.modalContainer}
      show={props.visible}
      onHide={props.onHide.bind(this, false)}
    >
      <CloseIcon onClick={props.onHide.bind(this, false)} />
      <div className="h-auto w-100 d-flex flex-column p-4 mt-5">
        <Message style={props.messageStyle} className={`text-disabled ${props.messageClassName ? props.messageClassName : ''}`}>
          {props.message}
        </Message>
        <div className="d-flex align-items-center justify-content-between mt-5">
          <Button onClick={props.onHide.bind(this, false)} value={props.cancelLabel} className={`w-40 ${props.cancelClassName ? props.cancelClassName : ''}`} style={{ backgroundColor: "#52575C", ...props.cancelStyle }} />
          <Button onClick={props.onHide.bind(this, true)} value={props.successLabel} className={`w-40 ${props.successClassName ? props.successClassName : ''}`} style={{ ...props.successStyle }} />
        </div>
      </div>
    </Modal>
  );
};

const usetyles = makeStyles(() => ({
  modalContainer: {
    boxShadow: "0px 8px 30px rgba(0, 0, 0, 0.12)",
    borderRadius: "5px",
    borderWidth: 0,
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    "@media only screen and (max-width: 768px)": {
      width: "80vw",
      marginLeft: "7vw"
    }
  },
}));

ConfirmModal.propTypes = {
  successLabel: PropTypes.string,
  successStyle: PropTypes.object,
  successClassName: PropTypes.string,
  cancelStyle: PropTypes.object,
  cancelLabel: PropTypes.string,
  cancelClassName: PropTypes.string,
  message: PropTypes.string,
  messageStyle: PropTypes.object,
  messageClassName: PropTypes.string,
  visible: PropTypes.bool,
  onHide: PropTypes.func,
};

ConfirmModal.defaultProps = {
  visible: false,
  message: "Voulez-vous vraiment continuer ?",
  successLabel: "Continer",
  cancelLabel: "Annuler",
  cancelStyle: {},
  successStyle: {},
  messageStyle: {},
  onHide: () => console.log("Please attach an onHide function to [ConfirmModal]"),
};


const CloseIcon = styled(FaTimes)`
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  color: #52575C;
`;

const Message = styled.div`
  width: 100%;
  text-align: center;
  color: #52575C;
`;

export default ConfirmModal;