import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { makeStyles } from "@material-ui/core";
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from 'components/button';
import HeaderImg from "assets/images/modal_header2.png";

const ListeElements = props => {
  const classes = usetyles();

  return (
    <Modal
      centered
      backdrop="static"
      contentClassName={classes.modalContainer}
      show={props.visible}
      onHide={props.onHide.bind(this, false)}
    >
      <div className={classes.header}>
        <h3 className="text-white font-weight-bold">{props?.headerTitle || ""}</h3>
      </div>
      <div className="w-100 p-3">
        <div className="w-100 d-flex flex-column p-2 border border-10">
          <Content style={{ height: "350px", overflowY: "scroll" }} className="w-100 p-3 pr-2">
            {Array.isArray(props?.data) ?
              props?.data?.map((el, k) => (
                <div key={k} className="mb-4 w-100">
                  <div className="w-100 font-weight-bold">
                    {!!el ? el[props?.titleKey || "libelle"] || "-" : "-"}
                  </div>
                  <div className="w-100 font-90 mt-2">
                    {!!el ? el[props?.texteKey || "motif"] || "-" : "-"}
                  </div>
                </div>
              ))
              :
              "Aucun donnée"
            }
          </Content>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-center mb-4">
        <Button onClick={props.onHide.bind(this, true)} value="Fermer" className="px-5" />
      </div>
    </Modal>
  );
};

const usetyles = makeStyles(() => ({
  modalContainer: {
    boxShadow: "0px 8px 30px rgba(0, 0, 0, 0.12)",
    borderRadius: "10px",
    borderWidth: 0,
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    "@media only screen and (max-width: 768px)": {
      width: "80vw",
      marginLeft: "7vw"
    }
  },
  header: {
    width: "100%",
    height: "100px",
    overflow: "hidden",
    borderRadius: "10px 10px 0 0",
    backgroundSize: "cover",
    background: `url(${HeaderImg}) no-repeat center right`,
    padding: "0 20px",
    display: "flex",
    alignItems: "center",
  },
}));

ListeElements.propTypes = {
  data: PropTypes.array,
  headerTitle: PropTypes.string,
  titleKey: PropTypes.string,
  texteKey: PropTypes.string,
  visible: PropTypes.bool,
  onHide: PropTypes.func,
};

ListeElements.defaultProps = {
  data: [],
  headerTitle: 'Infos',
  titleKey: "libelle",
  texteKey: "motif",
  visible: false,
  onHide: () => console.log("Please attach an onHide function to [ListeElements]"),
};

const Content = styled.div`
  // overflow-y: scroll;
  ::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    border: 0px;
  }
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #ddd;
    border-radius: 0px;
  }
  ::-webkit-scrollbar-thumb {
    background-color: #e91e63;
    border-radius: 0px;
  }
`;

export default ListeElements;