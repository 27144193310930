import React from 'react';
import PropTypes from 'prop-types';
import "./styles.scss";

const CardCentre = props => {
  return (
    <div
      {...props}
      className={`kea-card kea-card-centre ${props?.className}`}
    >
      <div className="kea-card-centre__rayures"></div>
      <div className="kea-card-centre__content w-100 h-100 d-flex flex-column">
        {!!props?.picture ?
          <img src={props?.picture} className="kea-card-centre__img" />
          :
          <div className="kea-card-centre__img"></div>
        }
        <span className="w-60 mt-3 font-weight-bold text-white">
          {props?.title}
        </span>
      </div>
    </div>
  );
};

CardCentre.propTypes = {
  title: PropTypes.string,
  picture: PropTypes.any
};

CardCentre.defaultProps = {
  title: "Titre",
};

export default CardCentre;