import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";
import { FaTimesCircle } from "react-icons/fa";

const OrdonnanceTable = props => {
  const { element } = props;

  return (
    <Container {...props}>
      {props.closable && <CloseIcon onClick={props.onClose} />}
      <tr>
        <th>Nom du produit</th>
        <th>Quantité</th>
        <th>Unité</th>
        <th>Posologie</th>
      </tr>
      <tr>
        <td>{element?.medicament}</td>
        <td>{element?.quantite}</td>
        <td>{element?.unite}</td>
        <td>{element?.posologie}</td>
      </tr>
    </Container>
  )
};

OrdonnanceTable.propTypes = {
  element: PropTypes.object,
  closable: PropTypes.bool,
  onClose: PropTypes.func
};

OrdonnanceTable.defaultProps = {
  closable: false,
  onClose: () => console.log("Attach onClose method")
};

const Container = styled.table`
  width: 100%;
  position: relative;
  border: 0px solid #E8357955;
  border-collapse: collapse;
  box-shadow: 0px 0px 3.7146px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  th {
    border-radius: 3px;
    padding: 10px 5px;
    font-weight: bold;
    color: #9F9F9F;
    text-align: center;
    font-size: 11px;
  }
  td {
    padding: 10px;
    text-align: center;
    border: 0px solid #E8357955;
    border-collapse: collapse;
    font-size: 11px;
  }
`;

const CloseIcon = styled(FaTimesCircle)`
  position: absolute;
  top: -5px;
  right: -5px;
  color: #E71A65;
  cursor: pointer;
`;

export default OrdonnanceTable;