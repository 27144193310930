import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Textarea } from 'components';
import { useDebounce } from 'hooks';
import { resetObject, validate } from 'context/swift-inputs';


/* interface FicheBacteriologieProps {
  fields: { [key: string]: string };
  onChange: (fields: { [key: string]: string }, isValid: boolean) => void;
};

const FicheBacteriologie = (props: FicheBacteriologieProps) => {
  const [fields, setFields] = useState({
    P: "",
    AMP: "",
    AMX: "",
    AMC: "",
    OX: "",
    MET: "",
    TIM: "",
    MZ: "",
    MEC: "",
    CB: "",
    CF: "",
    CN: "",
    CFR: "",
    CFZ: "",
    COX: "",
    CXM: "",
    FOX: "",
    CRO: "",
    FIX: "",
    CEFTEZIDINE: "",
    IMP: "",
    ETP: "",
    ATM: "",
    GEN: "",
    NET: "",
    NIN: "",
    N: "",
    AN: "",
    S: "",
    K: "",
    DBK: "",
    C: "",
    TET: "",
    D: "",
    ME: "",
    E: "",
    SR: "",
    OL: "",
    L: "",
    CC: "",
    SA: "",
    PR: "",
    NA: "",
    PE: "",
    AP: "",
    PEP: "",
    NOR: "",
    OFX: "",
    CIP: "",
    NI: "",
    TEC: "",
    VA: "",
    PM: "",
    SXE: "",
    PM: "",
    CL: "",
    RFA: "",
    FA: "",
    FOSFOMYCINE: "",
    ETAT_FRAIS: "",
    LEUCOCYTE: "",
    GRAM: "",
    BACTERIURIE: "",
    CONCLUSION: "",
  });

  const [errors, setErrors] = useState({
    P: "",
    AMP: "",
    AMX: "",
    AMC: "",
    OX: "",
    MET: "",
    TIM: "",
    MZ: "",
    MEC: "",
    CB: "",
    CF: "",
    CN: "",
    CFR: "",
    CFZ: "",
    COX: "",
    CXM: "",
    FOX: "",
    CRO: "",
    FIX: "",
    CEFTEZIDINE: "",
    IMP: "",
    ETP: "",
    ATM: "",
    GEN: "",
    NET: "",
    NIN: "",
    N: "",
    AN: "",
    S: "",
    K: "",
    DBK: "",
    C: "",
    TET: "",
    D: "",
    ME: "",
    E: "",
    SR: "",
    OL: "",
    L: "",
    CC: "",
    SA: "",
    PR: "",
    NA: "",
    PE: "",
    AP: "",
    PEP: "",
    NOR: "",
    OFX: "",
    CIP: "",
    NI: "",
    TEC: "",
    VA: "",
    PM: "",
    SXE: "",
    PM: "",
    CL: "",
    RFA: "",
    FA: "",
    FOSFOMYCINE: "",
    ETAT_FRAIS: "",
    LEUCOCYTE: "",
    GRAM: "",
    BACTERIURIE: "",
    CONCLUSION: "",
  });

  const handleInputChange = e => {
    setFields(old => ({
      ...old,
      [e?.target?.name]: e?.target?.value,
    }));
  };

  const handleCheck = (value, name) => {
    if (fields[name] === value) {
      setFields(old => ({
        ...old,
        [name]: "",
      }));
    } else {
      setFields(old => ({
        ...old,
        [name]: value,
      }));
    }
  };

  useDebounce(() => {
    const validation = validate(fields, {
      FOSFOMYCINE: "nullable",
      ETAT_FRAIS: "nullable",
      LEUCOCYTE: "nullable",
      GRAM: "nullable",
      BACTERIURIE: "nullable",
      CONCLUSION: "nullable",
    });

    if (!validation.isFormValid()) {
      const { response } = validation;
      setErrors(old => ({
        ...old,
        P: response?.P?.error || "",
        AMP: response?.AMP?.error || "",
        AMX: response?.AMX?.error || "",
        AMC: response?.AMC?.error || "",
        OX: response?.OX?.error || "",
        MET: response?.MET?.error || "",
        TIM: response?.TIM?.error || "",
        MZ: response?.MZ?.error || "",
        MEC: response?.MEC?.error || "",
        CB: response?.CB?.error || "",
        CF: response?.CF?.error || "",
        CN: response?.CN?.error || "",
        CFR: response?.CFR?.error || "",
        CFZ: response?.CFZ?.error || "",
        COX: response?.COX?.error || "",
        CXM: response?.CXM?.error || "",
        FOX: response?.FOX?.error || "",
        CRO: response?.CRO?.error || "",
        FIX: response?.FIX?.error || "",
        CEFTEZIDINE: response?.CEFTEZIDINE?.error || "",
        IMP: response?.IMP?.error || "",
        ETP: response?.ETP?.error || "",
        ATM: response?.ATM?.error || "",
        GEN: response?.GEN?.error || "",
        NET: response?.NET?.error || "",
        NIN: response?.NIN?.error || "",
        N: response?.N?.error || "",
        AN: response?.AN?.error || "",
        S: response?.S?.error || "",
        K: response?.K?.error || "",
        DBK: response?.DBK?.error || "",
        C: response?.C?.error || "",
        TET: response?.TET?.error || "",
        D: response?.D?.error || "",
        ME: response?.ME?.error || "",
        E: response?.E?.error || "",
        SR: response?.SR?.error || "",
        OL: response?.OL?.error || "",
        L: response?.L?.error || "",
        CC: response?.CC?.error || "",
        SA: response?.SA?.error || "",
        PR: response?.PR?.error || "",
        NA: response?.NA?.error || "",
        PE: response?.PE?.error || "",
        AP: response?.AP?.error || "",
        PEP: response?.PEP?.error || "",
        NOR: response?.NOR?.error || "",
        OFX: response?.OFX?.error || "",
        CIP: response?.CIP?.error || "",
        NI: response?.NI?.error || "",
        TEC: response?.TEC?.error || "",
        VA: response?.VA?.error || "",
        PM: response?.PM?.error || "",
        SXE: response?.SXE?.error || "",
        PM: response?.PM?.error || "",
        CL: response?.CL?.error || "",
        RFA: response?.RFA?.error || "",
        FA: response?.FA?.error || "",
        FOSFOMYCINE: response?.FOSFOMYCINE?.error || "",
        ETAT_FRAIS: response?.ETAT_FRAIS?.error || "",
        LEUCOCYTE: response?.LEUCOCYTE?.error || "",
        GRAM: response?.GRAM?.error || "",
        BACTERIURIE: response?.BACTERIURIE?.error || "",
        CONCLUSION: response?.CONCLUSION?.error || "",
      }));
    } else {
      setErrors(resetObject(errors));
    }

    props.onChange(fields, validation.isFormValid() && Object.values(fields).some(el => !!el));
  }, 200, [fields]);

  useEffect(() => {
    setFields(old => ({
      ...old,
      P: props?.fields?.P || "",
      AMP: props?.fields?.AMP || "",
      AMX: props?.fields?.AMX || "",
      AMC: props?.fields?.AMC || "",
      OX: props?.fields?.OX || "",
      MET: props?.fields?.MET || "",
      TIM: props?.fields?.TIM || "",
      MZ: props?.fields?.MZ || "",
      MEC: props?.fields?.MEC || "",
      CB: props?.fields?.CB || "",
      CF: props?.fields?.CF || "",
      CN: props?.fields?.CN || "",
      CFR: props?.fields?.CFR || "",
      CFZ: props?.fields?.CFZ || "",
      COX: props?.fields?.COX || "",
      CXM: props?.fields?.CXM || "",
      FOX: props?.fields?.FOX || "",
      CRO: props?.fields?.CRO || "",
      FIX: props?.fields?.FIX || "",
      CEFTEZIDINE: props?.fields?.CEFTEZIDINE || "",
      IMP: props?.fields?.IMP || "",
      ETP: props?.fields?.ETP || "",
      ATM: props?.fields?.ATM || "",
      GEN: props?.fields?.GEN || "",
      NET: props?.fields?.NET || "",
      NIN: props?.fields?.NIN || "",
      N: props?.fields?.N || "",
      AN: props?.fields?.AN || "",
      S: props?.fields?.S || "",
      K: props?.fields?.K || "",
      DBK: props?.fields?.DBK || "",
      C: props?.fields?.C || "",
      TET: props?.fields?.TET || "",
      D: props?.fields?.D || "",
      ME: props?.fields?.ME || "",
      E: props?.fields?.E || "",
      SR: props?.fields?.SR || "",
      OL: props?.fields?.OL || "",
      L: props?.fields?.L || "",
      CC: props?.fields?.CC || "",
      SA: props?.fields?.SA || "",
      PR: props?.fields?.PR || "",
      NA: props?.fields?.NA || "",
      PE: props?.fields?.PE || "",
      AP: props?.fields?.AP || "",
      PEP: props?.fields?.PEP || "",
      NOR: props?.fields?.NOR || "",
      OFX: props?.fields?.OFX || "",
      CIP: props?.fields?.CIP || "",
      NI: props?.fields?.NI || "",
      TEC: props?.fields?.TEC || "",
      VA: props?.fields?.VA || "",
      PM: props?.fields?.PM || "",
      SXE: props?.fields?.SXE || "",
      PM: props?.fields?.PM || "",
      CL: props?.fields?.CL || "",
      RFA: props?.fields?.RFA || "",
      FA: props?.fields?.FA || "",
      FOSFOMYCINE: props?.fields?.FOSFOMYCINE || "",
      ETAT_FRAIS: props?.fields?.ETAT_FRAIS || "",
      LEUCOCYTE: props?.fields?.LEUCOCYTE || "",
      GRAM: props?.fields?.GRAM || "",
      BACTERIURIE: props?.fields?.BACTERIURIE || "",
      CONCLUSION: props?.fields?.CONCLUSION || "",
    }));
    setErrors(resetObject(errors));
  }, []); */

  interface FicheBacteriologieProps {
    data:object;
    toBeSubmittedData:object;
    setToBeSubmittedData:() => void
  }

  const FicheBacteriologie = (props: FicheBacteriologieProps) => {

    const handleInputChange = el =>{
      if(!el){
        return
      }
      props?.setToBeSubmittedData(old=>({
        ...old,
        //[el?.target?.name]:el?.target?.name=="comment" ? el?.target?.value : parseFloat(el?.target?.value)
        [el?.target?.name]:el?.target?.name=="comment"? el?.target?.value : {value:el?.target?.value}
      }))
    }

    const handleCheck = (value, name) => {
      props?.setToBeSubmittedData(old => ({
        ...old,
        medocs:{
          ...old?.medocs,
          [name]: {value: props?.toBeSubmittedData[name]?.value === value ? "" : value},
        }
        
      }));
    };

  return (
    <Table>
      <tr>
        <th colSpan={15} className="bg-principal-pills">COMPTE RENDU D'EXAMEN DE BACTERIOLOGIE</th>
      </tr>
      <tr>
        <td colSpan={15} className="p-0">
          <Textarea
            label="État frais :"
            labelClassName="font-weight-bold w-100 text-left px-2 font-90"
            containerClassName="border-0 p-0"
            className="pt-0"
            placeholder="Saisir"
            style={{ fontSize: "12px" }}
            rows={3}
            name="ETAT_FRAIS"
            value={props?.toBeSubmittedData?.ETAT_FRAIS?.value}
//            error={errors?.ETAT_FRAIS}
            onChange={handleInputChange}
            //errorClassName="pl-2 pb-2 text-left"
          />
        </td>
      </tr>
      <tr>
        <td colSpan={15} className="p-0">
          <Textarea
            label="Leucocyte :"
            labelClassName="font-weight-bold w-100 text-left px-2 font-90"
            containerClassName="border-0 p-0"
            className="pt-0"
            placeholder="Saisir"
            style={{ fontSize: "12px" }}
            rows={3}
            name="LEUCOCYTE"
            value={props?.toBeSubmittedData?.LEUCOCYTE?.value}
            //error={errors?.LEUCOCYTE}
            onChange={handleInputChange}
            //errorClassName="pl-2 pb-2 text-left"
          />
        </td>
      </tr>
      <tr>
        <td colSpan={15} className="p-0">
          <Textarea
            label="Gram :"
            labelClassName="font-weight-bold w-100 text-left px-2 font-90"
            containerClassName="border-0 p-0"
            className="pt-0"
            placeholder="Saisir"
            style={{ fontSize: "12px" }}
            rows={3}
            name="GRAM"
            value={props?.toBeSubmittedData?.GRAM?.value}
            //error={errors?.GRAM}
            onChange={handleInputChange}
            //errorClassName="pl-2 pb-2 text-left"
          />
        </td>
      </tr>
      <tr>
        <td colSpan={15} className="p-0">
          <Textarea
            label="Bactériurie :"
            labelClassName="font-weight-bold w-100 text-left px-2 font-90"
            containerClassName="border-0 p-0"
            className="pt-0"
            placeholder="Saisir"
            style={{ fontSize: "12px" }}
            rows={3}
            name="BACTERIURIE"
            value={props?.toBeSubmittedData?.BACTERIURIE?.value}
            //error={errors?.BACTERIURIE}
            onChange={handleInputChange}
            //errorClassName="pl-2 pb-2 text-left"
          />
        </td>
      </tr>
      <tr>
        <th colSpan={15}>Culture</th>
      </tr>
      <tr>
        <td colSpan={4} className="font-weight-bold">ANTIBIOTIQUES</td>
        <td colSpan={3} className="font-weight-bold">SENSIBILITE</td>
        <td rowSpan={33} style={{ width: "50px" }} ></td>
        <td colSpan={4} className="font-weight-bold">ANTIBIOTIQUES</td>
        <td colSpan={3} className="font-weight-bold">SENSIBILITE</td>
      </tr>
      <tr>
        <td colSpan={2} className="font-weight-bold bg-blue-pills">FAMILLES</td>
        <td className="font-weight-bold bg-blue-pills">GENERIQUE ou DCI</td>
        <td className="font-weight-bold bg-blue-pills">CODE</td>
        <td className="font-weight-bold bg-blue-pills">S</td>
        <td className="font-weight-bold bg-blue-pills">I</td>
        <td className="font-weight-bold bg-blue-pills">R</td>
        <td colSpan={2} className="font-weight-bold bg-blue-pills">FAMILLES</td>
        <td className="font-weight-bold bg-blue-pills">GENERIQUE ou DCI</td>
        <td className="font-weight-bold bg-blue-pills">CODE</td>
        <td className="font-weight-bold bg-blue-pills">S</td>
        <td className="font-weight-bold bg-blue-pills">I</td>
        <td className="font-weight-bold bg-blue-pills">R</td>
      </tr>
      <tr>
        <td rowSpan={23} className="font-weight-bold">BETA-LACTAMINES</td>
        <td rowSpan={10} className="font-weight-bold">PENICILLINES</td>
        <td>PENICILLINE G</td>
        <td>P</td>
        <Checkbox name="P" type="S" value={props?.toBeSubmittedData?.medocs?.P?.value} onClick={handleCheck} />
        <Checkbox name="P" type="I" value={props?.toBeSubmittedData?.medocs?.P?.value} onClick={handleCheck} />
        <Checkbox name="P" type="R" value={props?.toBeSubmittedData?.medocs?.P?.value} onClick={handleCheck} />
        <td rowSpan={7} className="font-weight-bold">MACROLIDES</td>
        <td rowSpan={5} className="font-weight-bold">VRAIS</td>
        <td>ERYTROMYCINE</td>
        <td>E</td>
        <Checkbox name="E" type="S" value={props?.toBeSubmittedData?.medocs?.E?.value} onClick={handleCheck} />
        <Checkbox name="E" type="I" value={props?.toBeSubmittedData?.medocs?.E?.value} onClick={handleCheck} />
        <Checkbox name="E" type="R" value={props?.toBeSubmittedData?.medocs?.E?.value} onClick={handleCheck} />
      </tr>
      <tr>
        <td>AMPICILINE</td>
        <td>AMP</td>
        <Checkbox name="AMP" type="S" value={props?.toBeSubmittedData?.medocs?.AMP?.value} onClick={handleCheck} />
        <Checkbox name="AMP" type="I" value={props?.toBeSubmittedData?.medocs?.AMP?.value} onClick={handleCheck} />
        <Checkbox name="AMP" type="R" value={props?.toBeSubmittedData?.medocs?.AMP?.value} onClick={handleCheck} />
        <td>SPIRAMYCINE</td>
        <td>SR</td>
        <Checkbox name="SR" type="S" value={props?.toBeSubmittedData?.medocs?.SR?.value} onClick={handleCheck} />
        <Checkbox name="SR" type="I" value={props?.toBeSubmittedData?.medocs?.SR?.value} onClick={handleCheck} />
        <Checkbox name="SR" type="R" value={props?.toBeSubmittedData?.medocs?.SR?.value} onClick={handleCheck} />
      </tr>
      <tr>
        <td>AMOXICILLINE</td>
        <td>AMX</td>
        <Checkbox name="AMX" type="S" value={props?.toBeSubmittedData?.medocs?.AMX?.value} onClick={handleCheck} />
        <Checkbox name="AMX" type="I" value={props?.toBeSubmittedData?.medocs?.AMX?.value} onClick={handleCheck} />
        <Checkbox name="AMX" type="R" value={props?.toBeSubmittedData?.medocs?.AMX?.value} onClick={handleCheck} />
        <td>OLEANDOMYCINE</td>
        <td>OL</td>
        <Checkbox name="OL" type="S" value={props?.toBeSubmittedData?.medocs?.OL?.value} onClick={handleCheck} />
        <Checkbox name="OL" type="I" value={props?.toBeSubmittedData?.medocs?.OL?.value} onClick={handleCheck} />
        <Checkbox name="OL" type="R" value={props?.toBeSubmittedData?.medocs?.OL?.value} onClick={handleCheck} />
      </tr>
      <tr>
        <td>AMOXI+AC CLAVULANIQUE</td>
        <td>AMC</td>
        <Checkbox name="AMC" type="S" value={props?.toBeSubmittedData?.medocs?.AMC?.value} onClick={handleCheck} />
        <Checkbox name="AMC" type="I" value={props?.toBeSubmittedData?.medocs?.AMC?.value} onClick={handleCheck} />
        <Checkbox name="AMC" type="R" value={props?.toBeSubmittedData?.medocs?.AMC?.value} onClick={handleCheck} />
        <td>LINCOMYCINE</td>
        <td>L</td>
        <Checkbox name="L" type="S" value={props?.toBeSubmittedData?.medocs?.L?.value} onClick={handleCheck} />
        <Checkbox name="L" type="I" value={props?.toBeSubmittedData?.medocs?.L?.value} onClick={handleCheck} />
        <Checkbox name="L" type="R" value={props?.toBeSubmittedData?.medocs?.L?.value} onClick={handleCheck} />
      </tr>
      <tr>
        <td>OXACILLINE</td>
        <td>OX</td>
        <Checkbox name="OX" type="S" value={props?.toBeSubmittedData?.medocs?.OX?.value} onClick={handleCheck} />
        <Checkbox name="OX" type="I" value={props?.toBeSubmittedData?.medocs?.OX?.value} onClick={handleCheck} />
        <Checkbox name="OX" type="R" value={props?.toBeSubmittedData?.medocs?.OX?.value} onClick={handleCheck} />
        <td>CLINDAMYCINE</td>
        <td>CC</td>
        <Checkbox name="CC" type="S" value={props?.toBeSubmittedData?.medocs?.CC?.value} onClick={handleCheck} />
        <Checkbox name="CC" type="I" value={props?.toBeSubmittedData?.medocs?.CC?.value} onClick={handleCheck} />
        <Checkbox name="CC" type="R" value={props?.toBeSubmittedData?.medocs?.CC?.value} onClick={handleCheck} />
      </tr>
      <tr>
        <td>METICILINE</td>
        <td>MET</td>
        <Checkbox name="MET" type="S" value={props?.toBeSubmittedData?.medocs?.MET?.value} onClick={handleCheck} />
        <Checkbox name="MET" type="I" value={props?.toBeSubmittedData?.medocs?.MET?.value} onClick={handleCheck} />
        <Checkbox name="MET" type="R" value={props?.toBeSubmittedData?.medocs?.MET?.value} onClick={handleCheck} />
        <td rowSpan={2} className="font-weight-bold">APPA</td>
        <td>VIRGINIAMYCINE</td>
        <td>SA</td>
        <Checkbox name="SA" type="S" value={props?.toBeSubmittedData?.medocs?.SA?.value} onClick={handleCheck} />
        <Checkbox name="SA" type="I" value={props?.toBeSubmittedData?.medocs?.SA?.value} onClick={handleCheck} />
        <Checkbox name="SA" type="R" value={props?.toBeSubmittedData?.medocs?.SA?.value} onClick={handleCheck} />
      </tr>
      <tr>
        <td>TICARCILINNE+AC CLAVULANIQUE</td>
        <td>TIM</td>
        <Checkbox name="TIM" type="S" value={props?.toBeSubmittedData?.medocs?.TIM?.value} onClick={handleCheck} />
        <Checkbox name="TIM" type="I" value={props?.toBeSubmittedData?.medocs?.TIM?.value} onClick={handleCheck} />
        <Checkbox name="TIM" type="R" value={props?.toBeSubmittedData?.medocs?.TIM?.value} onClick={handleCheck} />
        <td>PRISTANAMYCINE</td>
        <td>PR</td>
        <Checkbox name="PR" type="S" value={props?.toBeSubmittedData?.medocs?.PR?.value} onClick={handleCheck} />
        <Checkbox name="PR" type="I" value={props?.toBeSubmittedData?.medocs?.PR?.value} onClick={handleCheck} />
        <Checkbox name="PR" type="R" value={props?.toBeSubmittedData?.medocs?.PR?.value} onClick={handleCheck} />
      </tr>
      <tr>
        <td>MELOCILLINE</td>
        <td>MZ</td>
        <Checkbox name="MZ" type="S" value={props?.toBeSubmittedData?.medocs?.MZ?.value} onClick={handleCheck} />
        <Checkbox name="MZ" type="I" value={props?.toBeSubmittedData?.medocs?.MZ?.value} onClick={handleCheck} />
        <Checkbox name="MZ" type="R" value={props?.toBeSubmittedData?.medocs?.MZ?.value} onClick={handleCheck} />
        <td colSpan={2} rowSpan={8} className="font-weight-bold">QUINOLONES</td>
        <td>AC NALIDICIQUE</td>
        <td>NA</td>
        <Checkbox name="NA" type="S" value={props?.toBeSubmittedData?.medocs?.NA?.value} onClick={handleCheck} />
        <Checkbox name="NA" type="I" value={props?.toBeSubmittedData?.medocs?.NA?.value} onClick={handleCheck} />
        <Checkbox name="NA" type="R" value={props?.toBeSubmittedData?.medocs?.NA?.value} onClick={handleCheck} />
      </tr>
      <tr>
        <td>MECILLINAM</td>
        <td>MEC</td>
        <Checkbox name="MEC" type="S" value={props?.toBeSubmittedData?.medocs?.MEC?.value} onClick={handleCheck} />
        <Checkbox name="MEC" type="I" value={props?.toBeSubmittedData?.medocs?.MEC?.value} onClick={handleCheck} />
        <Checkbox name="MEC" type="R" value={props?.toBeSubmittedData?.medocs?.MEC?.value} onClick={handleCheck} />
        <td>AC PIPEMIDIQUE</td>
        <td>NA</td>
        <Checkbox name="NA1" type="S" value={props?.toBeSubmittedData?.medocs?.NA1?.value} onClick={handleCheck} />
        <Checkbox name="NA1" type="I" value={props?.toBeSubmittedData?.medocs?.NA1?.value} onClick={handleCheck} />
        <Checkbox name="NA1" type="R" value={props?.toBeSubmittedData?.medocs?.NA1?.value} onClick={handleCheck} />
      </tr>
      <tr>
        <td>CARBENICILLINE</td>
        <td>Cb</td>
        <Checkbox name="CB" type="S" value={props?.toBeSubmittedData?.medocs?.CB?.value} onClick={handleCheck} />
        <Checkbox name="CB" type="I" value={props?.toBeSubmittedData?.medocs?.CB?.value} onClick={handleCheck} />
        <Checkbox name="CB" type="R" value={props?.toBeSubmittedData?.medocs?.CB?.value} onClick={handleCheck} />
        <td>FLUMEQUINE</td>
        <td>AP</td>
        <Checkbox name="AP" type="S" value={props?.toBeSubmittedData?.medocs?.AP?.value} onClick={handleCheck} />
        <Checkbox name="AP" type="I" value={props?.toBeSubmittedData?.medocs?.AP?.value} onClick={handleCheck} />
        <Checkbox name="AP" type="R" value={props?.toBeSubmittedData?.medocs?.AP?.value} onClick={handleCheck} />
      </tr>
      <tr>
        <td rowSpan={13} className="font-weight-bold">
          CEPHALOSPORINES / CARBAPENEME / MONOBACTAME
        </td>
        <td>CEFALOTINE</td>
        <td>Cf</td>
        <Checkbox name="CF" type="S" value={props?.toBeSubmittedData?.medocs?.CF?.value} onClick={handleCheck} />
        <Checkbox name="CF" type="I" value={props?.toBeSubmittedData?.medocs?.CF?.value} onClick={handleCheck} />
        <Checkbox name="CF" type="R" value={props?.toBeSubmittedData?.medocs?.CF?.value} onClick={handleCheck} />
        <td>PEFLOXACINE</td>
        <td>PEP</td>
        <Checkbox name="PEP" type="S" value={props?.toBeSubmittedData?.medocs?.PEP?.value} onClick={handleCheck} />
        <Checkbox name="PEP" type="I" value={props?.toBeSubmittedData?.medocs?.PEP?.value} onClick={handleCheck} />
        <Checkbox name="PEP" type="R" value={props?.toBeSubmittedData?.medocs?.PEP?.value} onClick={handleCheck} />
      </tr>
      <tr>
        <td>CEFADROXIL</td>
        <td>CN</td>
        <Checkbox name="CN" type="S" value={props?.toBeSubmittedData?.medocs?.CN?.value} onClick={handleCheck} />
        <Checkbox name="CN" type="I" value={props?.toBeSubmittedData?.medocs?.CN?.value} onClick={handleCheck} />
        <Checkbox name="CN" type="R" value={props?.toBeSubmittedData?.medocs?.CN?.value} onClick={handleCheck} />
        <td>NORFLOXACINE</td>
        <td>NOR</td>
        <Checkbox name="NOR" type="S" value={props?.toBeSubmittedData?.medocs?.NOR?.value} onClick={handleCheck} />
        <Checkbox name="NOR" type="I" value={props?.toBeSubmittedData?.medocs?.NOR?.value} onClick={handleCheck} />
        <Checkbox name="NOR" type="R" value={props?.toBeSubmittedData?.medocs?.NOR?.value} onClick={handleCheck} />
      </tr>
      <tr>
        <td>CEFAPIRINE</td>
        <td>CfR</td>
        <Checkbox name="CFR" type="S" value={props?.toBeSubmittedData?.medocs?.CFR?.value} onClick={handleCheck} />
        <Checkbox name="CFR" type="I" value={props?.toBeSubmittedData?.medocs?.CFR?.value} onClick={handleCheck} />
        <Checkbox name="CFR" type="R" value={props?.toBeSubmittedData?.medocs?.CFR?.value} onClick={handleCheck} />
        <td>OFLOXACINE</td>
        <td>OFX</td>
        <Checkbox name="OFX" type="S" value={props?.toBeSubmittedData?.medocs?.OFX?.value} onClick={handleCheck} />
        <Checkbox name="OFX" type="I" value={props?.toBeSubmittedData?.medocs?.OFX?.value} onClick={handleCheck} />
        <Checkbox name="OFX" type="R" value={props?.toBeSubmittedData?.medocs?.OFX?.value} onClick={handleCheck} />
      </tr>
      <tr>
        <td>CEFALOZINE</td>
        <td>CfZ</td>
        <Checkbox name="CFZ" type="S" value={props?.toBeSubmittedData?.medocs?.CFZ?.value} onClick={handleCheck} />
        <Checkbox name="CFZ" type="I" value={props?.toBeSubmittedData?.medocs?.CFZ?.value} onClick={handleCheck} />
        <Checkbox name="CFZ" type="R" value={props?.toBeSubmittedData?.medocs?.CFZ?.value} onClick={handleCheck} />
        <td>CIPROFLOXACINE</td>
        <td>CIP</td>
        <Checkbox name="CIP" type="S" value={props?.toBeSubmittedData?.medocs?.CIP?.value} onClick={handleCheck} />
        <Checkbox name="CIP" type="I" value={props?.toBeSubmittedData?.medocs?.CIP?.value} onClick={handleCheck} />
        <Checkbox name="CIP" type="R" value={props?.toBeSubmittedData?.medocs?.CIP?.value} onClick={handleCheck} />
      </tr>
      <tr>
        <td>CEFOTAXIME</td>
        <td>COX</td>
        <Checkbox name="COX" type="S" value={props?.toBeSubmittedData?.medocs?.COX?.value} onClick={handleCheck} />
        <Checkbox name="COX" type="I" value={props?.toBeSubmittedData?.medocs?.COX?.value} onClick={handleCheck} />
        <Checkbox name="COX" type="R" value={props?.toBeSubmittedData?.medocs?.COX?.value} onClick={handleCheck} />
        <td>NIFROXOLINE</td>
        <td>NI</td>
        <Checkbox name="NI" type="S" value={props?.toBeSubmittedData?.medocs?.NI?.value} onClick={handleCheck} />
        <Checkbox name="NI" type="I" value={props?.toBeSubmittedData?.medocs?.NI?.value} onClick={handleCheck} />
        <Checkbox name="NI" type="R" value={props?.toBeSubmittedData?.medocs?.NI?.value} onClick={handleCheck} />
      </tr>
      <tr>
        <td>CEFUROINE</td>
        <td>CXM</td>
        <Checkbox name="CXM" type="S" value={props?.toBeSubmittedData?.medocs?.CXM?.value} onClick={handleCheck} />
        <Checkbox name="CXM" type="I" value={props?.toBeSubmittedData?.medocs?.CXM?.value} onClick={handleCheck} />
        <Checkbox name="CXM" type="R" value={props?.toBeSubmittedData?.medocs?.CXM?.value} onClick={handleCheck} />
        <td colSpan={2} rowSpan={2} className="font-weight-bold">GLYCOEPTIDES</td>
        <td>TELCOPLAMINE</td>
        <td>TEC</td>
        <Checkbox name="TEC" type="S" value={props?.toBeSubmittedData?.medocs?.TEC?.value} onClick={handleCheck} />
        <Checkbox name="TEC" type="I" value={props?.toBeSubmittedData?.medocs?.TEC?.value} onClick={handleCheck} />
        <Checkbox name="TEC" type="R" value={props?.toBeSubmittedData?.medocs?.TEC?.value} onClick={handleCheck} />
      </tr>
      <tr>
        <td>CEFOXITINE</td>
        <td>FOX</td>
        <Checkbox name="FOX" type="S" value={props?.toBeSubmittedData?.medocs?.FOX?.value} onClick={handleCheck} />
        <Checkbox name="FOX" type="I" value={props?.toBeSubmittedData?.medocs?.FOX?.value} onClick={handleCheck} />
        <Checkbox name="FOX" type="R" value={props?.toBeSubmittedData?.medocs?.FOX?.value} onClick={handleCheck} />
        <td>VANCOMYCINE</td>
        <td>VA</td>
        <Checkbox name="VA" type="S" value={props?.toBeSubmittedData?.medocs?.VA?.value} onClick={handleCheck} />
        <Checkbox name="VA" type="I" value={props?.toBeSubmittedData?.medocs?.VA?.value} onClick={handleCheck} />
        <Checkbox name="VA" type="R" value={props?.toBeSubmittedData?.medocs?.VA?.value} onClick={handleCheck} />
      </tr>
      <tr>
        <td>CEFTRIAXONE</td>
        <td>CRO</td>
        <Checkbox name="CRO" type="S" value={props?.toBeSubmittedData?.medocs?.CRO?.value} onClick={handleCheck} />
        <Checkbox name="CRO" type="I" value={props?.toBeSubmittedData?.medocs?.CRO?.value} onClick={handleCheck} />
        <Checkbox name="CRO" type="R" value={props?.toBeSubmittedData?.medocs?.CRO?.value} onClick={handleCheck} />
        <td colSpan={2} rowSpan={2} className="font-weight-bold">SULFAMIDES</td>
        <td>FURANES</td>
        <td>PM</td>
        <Checkbox name="PM" type="S" value={props?.toBeSubmittedData?.medocs?.PM?.value} onClick={handleCheck} />
        <Checkbox name="PM" type="I" value={props?.toBeSubmittedData?.medocs?.PM?.value} onClick={handleCheck} />
        <Checkbox name="PM" type="R" value={props?.toBeSubmittedData?.medocs?.PM?.value} onClick={handleCheck} />
      </tr>
      <tr>
        <td>CEFIXIME</td>
        <td>FIX</td>
        <Checkbox name="FIX" type="S" value={props?.toBeSubmittedData?.medocs?.FIX?.value} onClick={handleCheck} />
        <Checkbox name="FIX" type="I" value={props?.toBeSubmittedData?.medocs?.FIX?.value} onClick={handleCheck} />
        <Checkbox name="FIX" type="R" value={props?.toBeSubmittedData?.medocs?.FIX?.value} onClick={handleCheck} />
        <td>COTRIMOXAZOLE SULFLADOXINE TRIMETOPRIME</td>
        <td>SXE</td>
        <Checkbox name="SXE" type="S" value={props?.toBeSubmittedData?.medocs?.SXE?.value} onClick={handleCheck} />
        <Checkbox name="SXE" type="I" value={props?.toBeSubmittedData?.medocs?.SXE?.value} onClick={handleCheck} />
        <Checkbox name="SXE" type="R" value={props?.toBeSubmittedData?.medocs?.SXE?.value} onClick={handleCheck} />
      </tr>
      <tr>
        <td>CEFTEZIDINE</td>
        <td>CRO</td>
        <Checkbox name="CRO1" type="S" value={props?.toBeSubmittedData?.medocs?.CRO1?.value} onClick={handleCheck} />
        <Checkbox name="CRO1" type="I" value={props?.toBeSubmittedData?.medocs?.CRO1?.value} onClick={handleCheck} />
        <Checkbox name="CRO1" type="R" value={props?.toBeSubmittedData?.medocs?.CRO1?.value} onClick={handleCheck} />
        <td colSpan={2} rowSpan={2} className="font-weight-bold">POLYPEPTIDES</td>
        <td>POLYMYCINE</td>
        <td>PM</td>
        <Checkbox name="PM1" type="S" value={props?.toBeSubmittedData?.medocs?.PM1?.value} onClick={handleCheck} />
        <Checkbox name="PM1" type="I" value={props?.toBeSubmittedData?.medocs?.PM1?.value} onClick={handleCheck} />
        <Checkbox name="PM1" type="R" value={props?.toBeSubmittedData?.medocs?.PM1?.value} onClick={handleCheck} />
      </tr>
      <tr>
        <td>IMIPENEM</td>
        <td>IMP</td>
        <Checkbox name="IMP" type="S" value={props?.toBeSubmittedData?.medocs?.IMP?.value} onClick={handleCheck} />
        <Checkbox name="IMP" type="I" value={props?.toBeSubmittedData?.medocs?.IMP?.value} onClick={handleCheck} />
        <Checkbox name="IMP" type="R" value={props?.toBeSubmittedData?.medocs?.IMP?.value} onClick={handleCheck} />
        <td>COLISTINE</td>
        <td>CL</td>
        <Checkbox name="CL" type="S" value={props?.toBeSubmittedData?.medocs?.CL?.value} onClick={handleCheck} />
        <Checkbox name="CL" type="I" value={props?.toBeSubmittedData?.medocs?.CL?.value} onClick={handleCheck} />
        <Checkbox name="CL" type="R" value={props?.toBeSubmittedData?.medocs?.CL?.value} onClick={handleCheck} />
      </tr>
      <tr>
        <td>ERTHAPENEM</td>
        <td>ETP</td>
        <Checkbox name="ETP" type="S" value={props?.toBeSubmittedData?.medocs?.ETP?.value} onClick={handleCheck} />
        <Checkbox name="ETP" type="I" value={props?.toBeSubmittedData?.medocs?.ETP?.value} onClick={handleCheck} />
        <Checkbox name="ETP" type="R" value={props?.toBeSubmittedData?.medocs?.ETP?.value} onClick={handleCheck} />
        <td colSpan={2} className="font-weight-bold">PENICOL</td>
        <td>CHRORAMPHENICOL</td>
        <td>C</td>
        <Checkbox name="C" type="S" value={props?.toBeSubmittedData?.medocs?.C?.value} onClick={handleCheck} />
        <Checkbox name="C" type="I" value={props?.toBeSubmittedData?.medocs?.C?.value} onClick={handleCheck} />
        <Checkbox name="C" type="R" value={props?.toBeSubmittedData?.medocs?.C?.value} onClick={handleCheck} />
      </tr>
      <tr>
        <td>AZTREONAM</td>
        <td>ATM</td>
        <Checkbox name="ATM" type="S" value={props?.toBeSubmittedData?.medocs?.ATM?.value} onClick={handleCheck} />
        <Checkbox name="ATM" type="I" value={props?.toBeSubmittedData?.medocs?.ATM?.value} onClick={handleCheck} />
        <Checkbox name="ATM" type="R" value={props?.toBeSubmittedData?.medocs?.ATM?.value} onClick={handleCheck} />
        <td colSpan={2} rowSpan={3} className="font-weight-bold">TETRACYCLINE</td>
        <td>TETRACYCLINES</td>
        <td>TET</td>
        <Checkbox name="TET" type="S" value={props?.toBeSubmittedData?.medocs?.TET?.value} onClick={handleCheck} />
        <Checkbox name="TET" type="I" value={props?.toBeSubmittedData?.medocs?.TET?.value} onClick={handleCheck} />
        <Checkbox name="TET" type="R" value={props?.toBeSubmittedData?.medocs?.TET?.value} onClick={handleCheck} />
      </tr>
      <tr>
        <td colSpan={2} rowSpan={8} className="font-weight-bold">AMINOSIDES</td>
        <td>GENTAMYCINE</td>
        <td>GEN</td>
        <Checkbox name="GEN" type="S" value={props?.toBeSubmittedData?.medocs?.GEN?.value} onClick={handleCheck} />
        <Checkbox name="GEN" type="I" value={props?.toBeSubmittedData?.medocs?.GEN?.value} onClick={handleCheck} />
        <Checkbox name="GEN" type="R" value={props?.toBeSubmittedData?.medocs?.GEN?.value} onClick={handleCheck} />
        <td>DOXYCICLINE</td>
        <td>D</td>
        <Checkbox name="D" type="S" value={props?.toBeSubmittedData?.medocs?.D?.value} onClick={handleCheck} />
        <Checkbox name="D" type="I" value={props?.toBeSubmittedData?.medocs?.D?.value} onClick={handleCheck} />
        <Checkbox name="D" type="R" value={props?.toBeSubmittedData?.medocs?.D?.value} onClick={handleCheck} />
      </tr>
      <tr>
        <td>NETILMICINE</td>
        <td>NET</td>
        <Checkbox name="NET" type="S" value={props?.toBeSubmittedData?.medocs?.NET?.value} onClick={handleCheck} />
        <Checkbox name="NET" type="I" value={props?.toBeSubmittedData?.medocs?.NET?.value} onClick={handleCheck} />
        <Checkbox name="NET" type="R" value={props?.toBeSubmittedData?.medocs?.NET?.value} onClick={handleCheck} />
        <td>MINOCYCLINE</td>
        <td>ME</td>
        <Checkbox name="ME" type="S" value={props?.toBeSubmittedData?.medocs?.ME?.value} onClick={handleCheck} />
        <Checkbox name="ME" type="I" value={props?.toBeSubmittedData?.medocs?.ME?.value} onClick={handleCheck} />
        <Checkbox name="ME" type="R" value={props?.toBeSubmittedData?.medocs?.ME?.value} onClick={handleCheck} />
      </tr>
      <tr>
        <td>TOERAMYCINE</td>
        <td>NIN</td>
        <Checkbox name="NIN" type="S" value={props?.toBeSubmittedData?.medocs?.NIN?.value} onClick={handleCheck} />
        <Checkbox name="NIN" type="I" value={props?.toBeSubmittedData?.medocs?.NIN?.value} onClick={handleCheck} />
        <Checkbox name="NIN" type="R" value={props?.toBeSubmittedData?.medocs?.NIN?.value} onClick={handleCheck} />
        <td colSpan={2} rowSpan={3} className="font-weight-bold">DIVERS</td>
        <td>RIFAMPICINE</td>
        <td>RFA</td>
        <Checkbox name="RFA" type="S" value={props?.toBeSubmittedData?.medocs?.RFA?.value} onClick={handleCheck} />
        <Checkbox name="RFA" type="I" value={props?.toBeSubmittedData?.medocs?.RFA?.value} onClick={handleCheck} />
        <Checkbox name="RFA" type="R" value={props?.toBeSubmittedData?.medocs?.RFA?.value} onClick={handleCheck} />
      </tr>
      <tr>
        <td>NEOMYCINE</td>
        <td>N</td>
        <Checkbox name="N" type="S" value={props?.toBeSubmittedData?.medocs?.N?.value} onClick={handleCheck} />
        <Checkbox name="N" type="I" value={props?.toBeSubmittedData?.medocs?.N?.value} onClick={handleCheck} />
        <Checkbox name="N" type="R" value={props?.toBeSubmittedData?.medocs?.N?.value} onClick={handleCheck} />
        <td>AC FUCIDIQUE</td>
        <td>FA</td>
        <Checkbox name="FA" type="S" value={props?.toBeSubmittedData?.medocs?.FA?.value} onClick={handleCheck} />
        <Checkbox name="FA" type="I" value={props?.toBeSubmittedData?.medocs?.FA?.value} onClick={handleCheck} />
        <Checkbox name="FA" type="R" value={props?.toBeSubmittedData?.medocs?.FA?.value} onClick={handleCheck} />
      </tr>
      <tr>
        <td>AMIKACINE</td>
        <td>AN</td>
        <Checkbox name="AN" type="S" value={props?.toBeSubmittedData?.medocs?.AN?.value} onClick={handleCheck} />
        <Checkbox name="AN" type="I" value={props?.toBeSubmittedData?.medocs?.AN?.value} onClick={handleCheck} />
        <Checkbox name="AN" type="R" value={props?.toBeSubmittedData?.medocs?.AN?.value} onClick={handleCheck} />
        <td>FOSFOMYCINE</td>
        <td></td>
        <Checkbox name="FOSFOMYCINE" type="S" value={props?.toBeSubmittedData?.medocs?.FOSFOMYCINE?.value} onClick={handleCheck} />
        <Checkbox name="FOSFOMYCINE" type="I" value={props?.toBeSubmittedData?.medocs?.FOSFOMYCINE?.value} onClick={handleCheck} />
        <Checkbox name="FOSFOMYCINE" type="R" value={props?.toBeSubmittedData?.medocs?.FOSFOMYCINE?.value} onClick={handleCheck} />
      </tr>
      <tr>
        <td>STREPTOMYCINE</td>
        <td>S</td>
        <Checkbox name="S" type="S" value={props?.toBeSubmittedData?.medocs?.S?.value} onClick={handleCheck} />
        <Checkbox name="S" type="I" value={props?.toBeSubmittedData?.medocs?.S?.value} onClick={handleCheck} />
        <Checkbox name="S" type="R" value={props?.toBeSubmittedData?.medocs?.S?.value} onClick={handleCheck} />
        <td colSpan={7} rowSpan={3}></td>
      </tr>
      <tr>
        <td>KANAMYCINE</td>
        <td>K</td>
        <Checkbox name="K" type="S" value={props?.toBeSubmittedData?.medocs?.K?.value} onClick={handleCheck} />
        <Checkbox name="K" type="I" value={props?.toBeSubmittedData?.medocs?.K?.value} onClick={handleCheck} />
        <Checkbox name="K" type="R" value={props?.toBeSubmittedData?.medocs?.K?.value} onClick={handleCheck} />
      </tr>
      <tr>
        <td>DIBEKACINE</td>
        <td>DBK</td>
        <Checkbox name="DBK" type="S" value={props?.toBeSubmittedData?.medocs?.DBK?.value} onClick={handleCheck} />
        <Checkbox name="DBK" type="I" value={props?.toBeSubmittedData?.medocs?.DBK?.value} onClick={handleCheck} />
        <Checkbox name="DBK" type="R" value={props?.toBeSubmittedData?.medocs?.DBK?.value} onClick={handleCheck} />
      </tr>
      <tr>
        <td colSpan={15} className="p-0">
          <Textarea
            label="Conclusion :"
            labelClassName="font-weight-bold w-100 text-left px-2 font-90"
            containerClassName="border-0 p-0"
            className="pt-0"
            placeholder="Saisir"
            style={{ fontSize: "12px" }}
            rows={3}
            name="comment"
            value={props?.toBeSubmittedData?.comment}
            //error={errors?.CONCLUSION}
            onChange={handleInputChange}
            //errorClassName="pl-2 pb-2 text-left"
          />
        </td>
      </tr>
    </Table>
  );
};

const Checkbox = ({ type, value, name, onClick }) => <td
  className={`cursor-pointer ${value === type ? 'bg-principal-pills' : ''}`}
  onClick={() => onClick(type, name)}
>
  {value === type ? 'X' : ''}
</td>;

FicheBacteriologie.defaultProps = {
  onChange: () => { },
};

const Table = styled.table`
  width: 100%;
  border: 0.5px solid #ABABAB;
  border-collapse: collapse;
  th {
    border: 0.5px solid #ABABAB;
    padding: 10px 5px;
    font-weight: bold;
    text-align: center;
    font-size: 12px;
  }
  td {
    padding: 10px;
    text-align: center;
    border: 0.5px solid #ABABAB;
    border-collapse: collapse;
    font-size: 12px;
  }
`;

export default FicheBacteriologie;