import React from 'react';
import PropTypes from 'prop-types';
import styled from "styled-components";
import Button from "components/button";
import { Row, Col } from "react-bootstrap";
import moment from "moment";

const CardSyntheseConstante = (props) => {
  const [synthese, setSynthese] = React.useState(null);

  React.useEffect(() => {
    setSynthese(props.synthese);
  }, [props.synthese]);

  return (
    <Card {...props} className={`p-3 ${props.className}`}>
      <div className="d-flex align-items-center justify-content-between">
        <Button
          className="w-auto h-auto py-1 font-90"
          size="sm"
          value={moment(synthese?.created_at).format("HH:mm")}
          style={{ background: "linear-gradient(54.74deg, #52575C 30.03%, #526487 145.84%)" }}
        />
        <div className="d-flex align-items-center justify-content-end">
          <div className="d-flex align-items-center">
            <span className="font-90 text-principal">Action: </span>
            <span className="font-90 ml-2">Prise de constantes</span>
          </div>
          <div className="d-flex align-items-center ml-4">
            <span className="font-90 text-principal">Auteur: </span>
            <span className="font-90 ml-2">Dr {synthese?.Datas?.Medecin?.lastName || "-"}</span>
          </div>
        </div>
      </div>
      <Content className="mx-auto mt-3 font-90 p-3">
        <Col md={4} lg={4} xs={12} className="m-0 p-1 d-flex align-items-center">
          <Dot className="mr-2" />
          <Text className="font-90">Pouls: {synthese?.Datas?.donneeConstante?.pouls || "-"}</Text>
        </Col>
        <Col md={4} lg={4} xs={12} className="m-0 p-1 d-flex align-items-center">
          <Dot className="mr-2" />
          <Text className="font-90">Frequence respiratoire: {synthese?.Datas?.donneeConstante?.freqResp || "-"}</Text>
        </Col>
        <Col md={4} lg={4} xs={12} className="m-0 p-1 d-flex align-items-center">
          <Dot className="mr-2" />
          <Text className="font-90">Pression artérielle droit: {synthese?.Datas?.donneeConstante?.icmDroit || "-"}</Text>
        </Col>
        <Col md={4} lg={4} xs={12} className="m-0 p-1 d-flex align-items-center">
          <Dot className="mr-2" />
          <Text className="font-90">Pression artérielle gauche: {synthese?.Datas?.donneeConstante?.icmGauche || "-"}</Text>
        </Col>
        <Col md={4} lg={4} xs={12} className="m-0 p-1 d-flex align-items-center">
          <Dot className="mr-2" />
          <Text className="font-90">Temperature: {synthese?.Datas?.donneeConstante?.temperature || "-"}</Text>
        </Col>
        <Col md={4} lg={4} xs={12} className="m-0 p-1 d-flex align-items-center">
          <Dot className="mr-2" />
          <Text className="font-90">Diurese: {synthese?.Datas?.donneeConstante?.duriese || "-"}</Text>
        </Col>
        <Col md={4} lg={4} xs={12} className="m-0 p-1 d-flex align-items-center">
          <Dot className="mr-2" />
          <Text className="font-90">Poids: {synthese?.Datas?.donneeConstante?.poids || "-"}</Text>
        </Col>
        <Col md={4} lg={4} xs={12} className="m-0 p-1 d-flex align-items-center">
          <Dot className="mr-2" />
          <Text className="font-90">Saturation en O2: {synthese?.Datas?.donneeConstante?.satO2 || "-"}</Text>
        </Col>
      </Content>
    </Card>
  );
};

const Card = styled.div`
  background: #FFFFFF;
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  min-height: 70px;
  width: 100%;
`;

const Content = styled(Row)`
  background-color: #E3ECF07D;
  width: 100%;
  min-height: 100px;
  border-radius: 10px;
  color: #52575C;
`;

const Dot = styled.div`
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #FFBC1F;
`;

const Text = styled.span`
  color: #52575C;
`;

CardSyntheseConstante.propTypes = {
  synthese: PropTypes.object,
  className: PropTypes.string
};

CardSyntheseConstante.defaultProps = {
  className: ""
};

export default CardSyntheseConstante;