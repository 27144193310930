import React from "react";
import styled from "styled-components";
import PropTypes from 'prop-types';

const CardConstante = (props) => {
  return (
    <Card className={`p-3 ${props.containerClassName}`} style={{ ...props.containerStyle }}>
      <div
        style={{ width: "3px", borderRadius: "5px 0 0 5px", height: "30px", backgroundColor: props.color, position: "absolute", top: 10, left: "-5px" }}
      ></div>
      <div className="d-flex w-100">
        <div
          style={{
            height: "13px",
            width: "13px",
            borderRadius: "10px",
            margin: "2px 5px 0 0",
            backgroundColor: props.color
          }}
        ></div>
        <div className={`${props.labelClassName}`} style={{ width: "75%", color: "#52575C" }}>{props.label}</div>
      </div>
      <div className={`font-weight-bold ${props.valueClassName}`} style={{ color: "#52575C", fontSize: "20px", width: "100%" }}>{props.value}</div>
    </Card>
  );
};

const Card = styled.div`
  height: 110px;
  width: 130px;
  min-width: 130px;
  font-size: 10px;
  box-shadow: 0px 3.76727px 13.1854px rgba(0, 0, 0, 0.09);
  border: 0px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
`;

CardConstante.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  color: PropTypes.string,
  icon: PropTypes.any,
  containerStyle: PropTypes.object,
  containerClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  valueClassName: PropTypes.string,
};

CardConstante.defaultProps = {
  label: "Une constante",
  value: "0",
  icon: null,
  color: "black",
  containerStyle: {},
  containerClassName: "",
  labelClassName: "",
  valueClassName: "",
};

export default CardConstante;