export const DRAFT_CONSULTATION = "CONS";
export const DRAFT_CONTROLE = "CONTROL";
export const DRAFT_VISITE = "VISITE";

export const CONS_TYPES = {
  MED_GEN: "CGE",
  GYNECO: "CG",
  CPN: "CPN",
  ANES: "ANES",
  BLOC: "BLOC",
  POST_NATAL: "CPON",
  CARDIO: "CAR",
  VACCINATION: "VAC",
  PEDIATRIE: "CP",
  CSTM: "CSTM",
  ORL: "ORL",
};

export const ORIENTATION_TYPES = [
  {
    label: "Salle de réveil",
    value: "SALLE_REVEIL",
  },
  {
    label: "Salle de réanimation",
    value: "REANIMATION",
  },
  {
    label: "Hospitalisation",
    value: "UNITE_HOSPI",
  },
];

export const DECISIONS_CODES = {
  RDV: "RDV",
};

export const ANTECEDENTS_TO_SHOW = [
  {
    search: "Diabétique",
    show: "Diabétique",
  },
  {
    search: "Asthme bronchique",
    show: "Asthmatique",
  },
  {
    search: "Insuffisance rénale",
    show: "Insuffisant(e) rénal",
  },
  {
    search: "Hypertension artérielle (HTA)",
    show: "Hypertendu(e)",
  },
  {
    search: "Ulcère gastrique ou duodénale",
    show: "Ulcéreux(se)",
  },
  {
    search: "Drépanocytose",
    show: "Drépanocytaire",
  },
];

export const ETAT_LIST = [
  { label: "Bon", value: "Bon" },
  { label: "Mauvais ", value: "Mauvais" },
  { label: "Autres à préciser ", value: "Autre à préciser" },
];
export const MUQUEUSES_BULBAIRE_LIST = [
  { label: "Ictérique", value: "Ictérique" },
  { label: "Anictérique ", value: "Anictérique" },
];
export const MUQUEUSES_PALPEBRALE_LIST = [
  { label: "Colorées", value: "Colorées" },
  { label: "Pâles ", value: "Pâles" },
];
export const OEDEME_LIST = [
  { label: "Colorées", value: "Colorées" },
  { label: "Pâles ", value: "Pâles" },
];
