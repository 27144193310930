import React from 'react';
import { Popover, makeStyles } from "@material-ui/core";
import styled from 'styled-components';
import { DateRangePicker, Calendar, DateRangePickerProps } from 'react-date-range';
import _ from 'lodash';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { fr } from 'date-fns/locale';

interface DatePickerProps {
  visible?: boolean;
  anchor?: any;
  onHide?: () => void;
};

const DatePicker = (props: DatePickerProps & DateRangePickerProps) => {
  const classes = useStyles();

  return (
    <Popover
      id={props.visible ? "simple-popover" : undefined}
      open={props.visible}
      anchorEl={props.anchor}
      onClose={props.onHide}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      classes={{ paper: classes.paper }}
    >
      {
        props.displayMode === 'date' ?
          <Calendar
            color='#E71A65'
            {..._.omit(props, ["visible", "anchor", "onHide"])}
            locale={fr}
            dateDisplayFormat="dd MMM yyyy"
          />
          :
          <DateRangePicker
            rangeColors={['#E71A65', '#526487', '#0396FF']}
            {..._.omit(props, ["visible", "anchor", "onHide"])}
            locale={fr}
            staticRanges={[]}
            inputRanges={[]}
            dateDisplayFormat="dd MMM yyyy"
          />
      }
    </Popover>
  );
};

DatePicker.defaultProps = {
  visible: false,
  onHide: () => console.log("No onHide method given"),
};

const useStyles = makeStyles(() => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    width: 'auto',
    borderRadius: "10px",
    marginTop: "5px",
    boxShadow: "-2px -1px 10px 2px rgba(192, 197, 223, 0.2)",
    borderBottom: "3px solid #e91e63",
  }
}));

export default DatePicker;