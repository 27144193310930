import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { BsEye } from "react-icons/bs";
import Table from "components/tables";
import { KeaLoader } from 'components';
import { fetchPatientExamenImagerie } from "http/crud";
import moment from "moment";
import { SideModal } from "components/modals";
import { HistoryComponentProps } from './index';
import _ from "lodash";


function ImagerieHistory(props: HistoryComponentProps) {
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(8);

  const [actes, setActes] = useState([]);

  const handleTableChange = (type, { page, sizePerPage }) => {
    const currentIndex = (page - 1) * sizePerPage;
    setPage(page);
  };

  const [loading, setLoading] = useState(false);

  const fetchActes = React.useCallback(async (verbose = true) => {
    setLoading(verbose);
    if (props?.patient?.patientId) {
      await fetchPatientExamenImagerie(props?.patient?.patientId).then(({ data: response }) => {
        if (response?.success) {
          setActes(Array.isArray(response?.datas) ? response?.datas : []);
        } else {
          setActes([]);
        }
      }).catch(() => {
        setActes([]);
      }).finally(() => setLoading(false));
    } else {
      setTimeout(() => {
        setActes([]);
        setLoading(false);
      }, 500);
    }
  }, []);

  useEffect(() => {
    fetchActes();
  }, []);

  const [focused, setFocused] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = el => {
    setFocused(el);
    setShowModal(true);
  };
  const handleHideModal = () => {
    setFocused(null);
    setShowModal(false);
  };

  const columns = [
    {
      dataField: "medecin",
      text: "Médécin",
      classes: "kea-table-row",
      headerClasses: "kea-table-header",
      formatter: (cell, row, rowIndex, extraData) => {
        return `${row?.medecin_demandeur || '-'}`
      }
    },
    {
      dataField: "motif",
      text: "Motif d'admission",
      classes: "kea-table-row overflow-hidden",
      headerClasses: "kea-table-header",
      formatter: (cell, row, rowIndex, extraData) => {
        return row?.motif || '-';
      }
    },
    {
      dataField: "created_at",
      text: "Date d'admission",
      classes: "kea-table-row overflow-hidden",
      headerClasses: "kea-table-header",
      formatter: (cell, row, rowIndex, extraData) => {
        return `${moment(row?.created_at).format("LL à HH:mm:ss") || '-'}`
      }
    },
    {
      dataField: "libelle",
      text: "Analyse",
      classes: "kea-table-row",
      headerClasses: "kea-table-header",
      formatter: (cell, row, rowIndex, extraData) => {
        return row?.libelle || '-';
      }
    },
    {
      dataField: "resultat",
      text: "Résultat",
      align: "center",
      classes: "kea-table-row",
      headerClasses: "kea-table-header",
      formatter: (cell, row, rowIndex, extraData) => {
        return row?.resultat || '-';
      },
    },
  ];

  return (
    <Container {..._.omit(props, ["patient"])}>
      <SideModal
        size="md"
        visible={showModal}
        onHide={handleHideModal}
      >
        {focused?.resultat || "Aucun résultat"}
      </SideModal>
      {
        loading ?
          <KeaLoader />
          :
          <Table
            data={actes}
            page={page}
            sizePerPage={sizePerPage}
            totalSize={actes.length}
            columns={columns}
            onTableChange={handleTableChange}
            bordered={false}
          />
      }
    </Container>
  );
}

export default ImagerieHistory;

const Container = styled.div``;