import React, { useState } from 'react';
import styled from 'styled-components';
import { Input, Textarea, Select } from 'components';
import { PatientType, ResultatImgObsSecondType, ImagerieExam } from 'types';
import { useDebounce } from 'hooks';
import _ from 'lodash';
import { minValOrNullable, resetObject, validate } from 'context/swift-inputs';
import moment from 'moment';
import { BACKEND_DATE_FORMAT } from 'configs';


interface FieldsType {
  result_date: string;
  medecin_demandeur: string;
  ddr: string;
  indication: string;
  resultat: ResultatImgObsSecondType;
  comment: string;
};

interface FicheEchoObsSecondProps {
  exam: ImagerieExam;
  className: string;
  patient: PatientType;
  fields: FieldsType;
  onChange: (fields: FieldsType, isValid: boolean) => void;
  readOnly: boolean;
};

const FicheEchoObsSecond = (props: FicheEchoObsSecondProps) => {
  const [fields, setFields] = useState(props?.fields);

  const [errors, setErrors] = useState({
    medecin_demandeur: "",
    ddr: "",
    indication: "",
    comment: "",

    foetus_nbr: "",
    foetus_presentation: "",
    foetus_orientation: "",
    foetus_activite: "",
    foetus_maf: "",
    foetus_mr: "",

    bip1: "",
    fl1: "",
    ac1: "",
    gesta1_sa: "",
    gesta1_jours: "",
    bip2: "",
    fl2: "",
    ac2: "",
    gesta2_sa: "",
    gesta2_jours: "",

    placenta_situation: "",
    placenta_grade: "",

    liquid_abondance: "",
    liquid_aspect: "",

    morph_thorax: "",
    morph_coeur: "",
    morph_colonne: "",
    morph_cerveau: "",
    morph_paroi: "",
    morph_estomac: "",
    morph_reins: "",
    morph_vessie: "",
    morph_sexe: "",
    morph_dpa: "",
    morph_long: "",
    morph_poids: "",

    aspect_pathologique: "",
  });

  // FOR RESULTATS INPUTS
  const handleInputChange = e => {
    setFields(old => ({
      ...old,
      resultat: {
        ...old?.resultat,
        [e?.target?.name]: e?.target?.value
      }
    }));
  };

  useDebounce(() => {
    !props?.readOnly && props?.onChange(fields, checkForm({
      ..._.omit(fields, 'resultat'),
      ...fields?.resultat
    }));
  }, 100, [fields]);

  const checkForm = (fieldsData: FieldsType) => {
    const validation = validate(fieldsData, {
      medecin_demandeur: "required|name",
      ddr: "required|date",
      indication: "required",
      comment: "required",

      foetus_nbr: minValOrNullable.bind(this, 0),
      foetus_presentation: "nullable",
      foetus_orientation: "nullable",
      foetus_activite: minValOrNullable.bind(this, 0),
      foetus_maf: "nullable",
      foetus_mr: "nullable",

      bip1: minValOrNullable.bind(this, 0),
      fl1: minValOrNullable.bind(this, 0),
      ac1: minValOrNullable.bind(this, 0),
      gesta1_sa: minValOrNullable.bind(this, 0),
      gesta1_jours: minValOrNullable.bind(this, 0),
      bip2: minValOrNullable.bind(this, 0),
      fl2: minValOrNullable.bind(this, 0),
      ac2: minValOrNullable.bind(this, 0),
      gesta2_sa: minValOrNullable.bind(this, 0),
      gesta2_jours: minValOrNullable.bind(this, 0),

      placenta_situation: "nullable",
      placenta_grade: "nullable",

      liquid_abondance: "nullable",
      liquid_aspect: "nullable",

      morph_thorax: "nullable",
      morph_coeur: "nullable",
      morph_colonne: "nullable",
      morph_cerveau: "nullable",
      morph_paroi: "nullable",
      morph_estomac: "nullable",
      morph_reins: "nullable",
      morph_vessie: "nullable",
      morph_sexe: "nullable",
      morph_dpa: "nullable",
      morph_long: "nullable",
      morph_poids: "nullable",

      aspect_pathologique: "nullable",
    });

    if (validation?.isFormValid()) {
      setErrors(resetObject(errors));
      return true;
    } else {
      setErrors(old => ({
        ...old,
        medecin_demandeur: validation?.response?.medecin_demandeur?.error,
        ddr: validation?.response?.ddr?.error,
        indication: validation?.response?.indication?.error,
        comment: validation?.response?.comment?.error,

        foetus_nbr: validation?.response?.foetus_nbr?.error,
        foetus_presentation: validation?.response?.foetus_presentation?.error,
        foetus_orientation: validation?.response?.foetus_orientation?.error,
        foetus_activite: validation?.response?.foetus_activite?.error,
        foetus_maf: validation?.response?.foetus_maf?.error,
        foetus_mr: validation?.response?.foetus_mr?.error,
        bip1: validation?.response?.bip1?.error,
        fl1: validation?.response?.fl1?.error,
        ac1: validation?.response?.ac1?.error,
        gesta1_sa: validation?.response?.gesta1_sa?.error,
        gesta1_jours: validation?.response?.gesta1_jours?.error,
        bip2: validation?.response?.bip2?.error,
        fl2: validation?.response?.fl2?.error,
        ac2: validation?.response?.ac2?.error,
        gesta2_sa: validation?.response?.gesta2_sa?.error,
        gesta2_jours: validation?.response?.gesta2_jours?.error,
        placenta_situation: validation?.response?.placenta_situation?.error,
        placenta_grade: validation?.response?.placenta_grade?.error,
        liquid_abondance: validation?.response?.liquid_abondance?.error,
        liquid_aspect: validation?.response?.liquid_aspect?.error,
        morph_thorax: validation?.response?.morph_thorax?.error,
        morph_coeur: validation?.response?.morph_coeur?.error,
        morph_colonne: validation?.response?.morph_colonne?.error,
        morph_cerveau: validation?.response?.morph_cerveau?.error,
        morph_paroi: validation?.response?.morph_paroi?.error,
        morph_estomac: validation?.response?.morph_estomac?.error,
        morph_reins: validation?.response?.morph_reins?.error,
        morph_vessie: validation?.response?.morph_vessie?.error,
        morph_sexe: validation?.response?.morph_sexe?.error,
        morph_dpa: validation?.response?.morph_dpa?.error,
        morph_long: validation?.response?.morph_long?.error,
        morph_poids: validation?.response?.morph_poids?.error,
        aspect_pathologique: validation?.response?.aspect_pathologique?.error,
      }));
      return false;
    }
  };

  const makeInputClassName = err => {
    return `px-0 py-1 h-auto font-weight-bold ${!!err ? 'border-principal-bottom' : ''}`;
  };

  return (
    <Container className={`w-100 ${props.className || ""}`}>
      <div className="w-100 p-3 border-bottom font-weight-bold bg-principal-pills text-center">
        {props?.exam?.libelle || "ECHOGRAPHIE OBSTETRICAL (2ème et 3ème Trimestre)"}
      </div>
      <div className="p-3 d-flex flex-column w-100">
        <div className="align-self-end d-flex align-items-center">
          <span className="mr-3">Date :</span>
          <Input
            wrapperStyle={{ width: "120px" }}
            containerClassName="border-left-0 border-top-0 border-right-0"
            className="px-0 py-1 h-auto"
            type="date"
            value={props?.fields?.result_date}
          />
        </div>
        <div className="w-100 d-flex flex-wrap">
          <div className="d-flex align-items-center mr-5 mt-4 w-40">
            <span className="mr-3 white-space-nowrap">Nom et Prénoms :</span>
            <Input
              containerClassName="border-left-0 border-top-0 border-right-0"
              className="px-0 py-1 h-auto"
              readOnly
              value={`${props?.patient?.lastName || '-'}${!!props?.patient?.firstName ? ' ' + props?.patient?.firstName : ''}`}
            />
          </div>
          <div className="d-flex align-items-center mr-5 mt-4">
            <span className="mr-3 white-space-nowrap">Age :</span>
            <Input
              containerClassName="border-left-0 border-top-0 border-right-0"
              className="px-0 py-1 h-auto"
              wrapperStyle={{ width: "150px" }}
              readOnly
              value={`${!!props?.patient?.birthDate ? `${moment().diff(moment(props?.patient?.birthDate, BACKEND_DATE_FORMAT), 'years')} ans` : "-"}`}
            />
          </div>
          <div className="d-flex align-items-center mr-5 mt-4 w-40">
            <span className="mr-3 white-space-nowrap">Demandeur :</span>
            <Input
              containerClassName="border-left-0 border-top-0 border-right-0"
              className={`px-0 py-1 h-auto font-weight-bold ${!!errors?.medecin_demandeur ? 'border-principal-bottom' : ''}`}
              value={fields?.medecin_demandeur}
              onChange={e => setFields(old => ({
                ...old,
                medecin_demandeur: e?.target?.value
              }))}
              readOnly={props?.readOnly}
            />
          </div>
          <div className="d-flex align-items-center mr-5 mt-4">
            <span className="mr-3 white-space-nowrap">DDR :</span>
            <Input
              containerClassName="border-left-0 border-top-0 border-right-0"
              className={`px-0 py-1 h-auto font-weight-bold ${!!errors?.ddr ? 'border-principal-bottom' : ''}`}
              type="date"
              wrapperStyle={{ width: "150px" }}
              value={fields?.ddr}
              onChange={e => setFields(old => ({
                ...old,
                ddr: e?.target?.value
              }))}
              max={moment().subtract(2, "weeks").format(BACKEND_DATE_FORMAT)}
              readOnly={props?.readOnly}
            />
          </div>
          <div className="d-flex align-items-center mt-4 w-100">
            <span className="mr-3 white-space-nowrap">Indications :</span>
            <Input
              containerClassName="border-left-0 border-top-0 border-right-0"
              className={`px-0 py-1 h-auto font-weight-bold ${!!errors?.indication ? 'border-principal-bottom' : ''}`}
              value={fields?.indication}
              onChange={e => setFields(old => ({
                ...old,
                indication: e?.target?.value
              }))}
              readOnly={props?.readOnly}
            />
          </div>
        </div>
      </div>
      <div className="w-100 p-3 font-weight-bold text-principal text-center mt-4">
        RÉSULTATS
      </div>
      <div className="p-3 d-flex flex-wrap w-100 border-top">
        <div className="font-weight-bold w-100">Foetus</div>
        <div className="d-flex align-items-center mr-4 mt-3">
          <span className="mr-2">Nombre :</span>
          <Input
            containerClassName="border-left-0 border-top-0 border-right-0"
            wrapperStyle={{ width: "70px" }}
            className={makeInputClassName(errors?.foetus_nbr)}
            name="foetus_nbr"
            value={fields?.resultat?.foetus_nbr}
            onChange={handleInputChange}
            min="0" type="number"
            readOnly={props?.readOnly}
          />
        </div>
        <div className="d-flex align-items-center mr-4 mt-3">
          <span className="mr-2">Présentation :</span>
          <Input
            containerClassName="border-left-0 border-top-0 border-right-0"
            wrapperStyle={{ width: "300px" }}
            className={makeInputClassName(errors?.foetus_presentation)}
            name="foetus_presentation"
            value={fields?.resultat?.foetus_presentation}
            onChange={handleInputChange}
            readOnly={props?.readOnly}
          />
        </div>
        <div className="d-flex align-items-center mr-4 mt-3">
          <span className="mr-2">Orientation du dos :</span>
          <Input
            containerClassName="border-left-0 border-top-0 border-right-0"
            wrapperStyle={{ width: "300px" }}
            className={makeInputClassName(errors?.foetus_orientation)}
            name="foetus_orientation"
            value={fields?.resultat?.foetus_orientation}
            onChange={handleInputChange}
            readOnly={props?.readOnly}
          />
        </div>
        <div className="d-flex align-items-center mr-4 mt-3">
          <span className="mr-2">Activité cardiaque :</span>
          <Input
            containerClassName="border-left-0 border-top-0 border-right-0"
            wrapperStyle={{ width: "100px" }}
            className={makeInputClassName(errors?.foetus_activite)}
            name="foetus_activite"
            value={fields?.resultat?.foetus_activite}
            onChange={handleInputChange}
            min="0" type="number"
            readOnly={props?.readOnly}
          />
          <span className="ml-1">bats/mn</span>
        </div>
        <div className="d-flex align-items-center mr-4 mt-3">
          <span className="mr-2">MAF :</span>
          <Input
            containerClassName="border-left-0 border-top-0 border-right-0"
            wrapperStyle={{ width: "120px" }}
            className={makeInputClassName(errors?.foetus_maf)}
            name="foetus_maf"
            value={fields?.resultat?.foetus_maf}
            onChange={handleInputChange}
            readOnly={props?.readOnly}
          />
        </div>
        <div className="d-flex align-items-center mr-4 mt-3">
          <span className="mr-2">MR :</span>
          <Input
            containerClassName="border-left-0 border-top-0 border-right-0"
            wrapperStyle={{ width: "120px" }}
            className={makeInputClassName(errors?.foetus_mr)}
            name="foetus_mr"
            value={fields?.resultat?.foetus_mr}
            readOnly={props?.readOnly}
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className="p-3 d-flex flex-wrap w-100 border-top mt-3">
        <div className="font-weight-bold w-100">1-</div>
        <div className="d-flex align-items-center mr-4 mt-3">
          <span className="mr-2">BIP1 :</span>
          <Input
            containerClassName="border-left-0 border-top-0 border-right-0"
            wrapperStyle={{ width: "80px" }}
            className={makeInputClassName(errors?.bip1)}
            name="bip1"
            value={fields?.resultat?.bip1}
            onChange={handleInputChange}
            min="0" type="number"
            readOnly={props?.readOnly}
          />
          <span className="ml-1">mm</span>
        </div>
        <div className="d-flex align-items-center mr-4 mt-3">
          <span className="mr-2">FL1 :</span>
          <Input
            containerClassName="border-left-0 border-top-0 border-right-0"
            wrapperStyle={{ width: "80px" }}
            className={makeInputClassName(errors?.fl1)}
            name="fl1"
            value={fields?.resultat?.fl1}
            onChange={handleInputChange}
            min="0" type="number"
            readOnly={props?.readOnly}
          />
          <span className="ml-1">mm</span>
        </div>
        <div className="d-flex align-items-center mr-4 mt-3">
          <span className="mr-2">AC1 :</span>
          <Input
            containerClassName="border-left-0 border-top-0 border-right-0"
            wrapperStyle={{ width: "80px" }}
            className={makeInputClassName(errors?.ac1)}
            name="ac1"
            value={fields?.resultat?.ac1}
            onChange={handleInputChange}
            min="0" type="number"
            readOnly={props?.readOnly}
          />
          <span className="ml-1">mm</span>
        </div>
        <div className="d-flex align-items-center mr-4 mt-3">
          <span className="mr-2">Age gestationnel :</span>
          <Input
            containerClassName="border-left-0 border-top-0 border-right-0"
            wrapperStyle={{ width: "70px" }}
            className={makeInputClassName(errors?.gesta1_sa)}
            name="gesta1_sa"
            value={fields?.resultat?.gesta1_sa}
            onChange={handleInputChange}
            min="0" type="number"
            readOnly={props?.readOnly}
          />
          <span className="ml-1 mr-2">SA +</span>
          <Input
            containerClassName="border-left-0 border-top-0 border-right-0"
            wrapperStyle={{ width: "70px" }}
            className={makeInputClassName(errors?.gesta1_jours)}
            name="gesta1_jours"
            value={fields?.resultat?.gesta1_jours}
            onChange={handleInputChange}
            min="0" type="number"
            readOnly={props?.readOnly}
          />
          <span className="ml-1">Jours</span>
        </div>
      </div>
      <div className="p-3 d-flex flex-wrap w-100 border-top mt-3">
        <div className="font-weight-bold w-100">2-</div>
        <div className="d-flex align-items-center mr-4 mt-3">
          <span className="mr-2">BIP2 :</span>
          <Input
            containerClassName="border-left-0 border-top-0 border-right-0"
            wrapperStyle={{ width: "80px" }}
            className={makeInputClassName(errors?.bip2)}
            name="bip2"
            value={fields?.resultat?.bip2}
            onChange={handleInputChange}
            min="0" type="number"
            readOnly={props?.readOnly}
          />
          <span className="ml-1">mm</span>
        </div>
        <div className="d-flex align-items-center mr-4 mt-3">
          <span className="mr-2">FL2 :</span>
          <Input
            containerClassName="border-left-0 border-top-0 border-right-0"
            wrapperStyle={{ width: "80px" }}
            className={makeInputClassName(errors?.fl2)}
            name="fl2"
            value={fields?.resultat?.fl2}
            onChange={handleInputChange}
            min="0" type="number"
            readOnly={props?.readOnly}
          />
          <span className="ml-1">mm</span>
        </div>
        <div className="d-flex align-items-center mr-4 mt-3">
          <span className="mr-2">AC2 :</span>
          <Input
            containerClassName="border-left-0 border-top-0 border-right-0"
            wrapperStyle={{ width: "80px" }}
            className={makeInputClassName(errors?.ac2)}
            name="ac2"
            value={fields?.resultat?.ac2}
            onChange={handleInputChange}
            min="0" type="number"
            readOnly={props?.readOnly}
          />
          <span className="ml-1">mm</span>
        </div>
        <div className="d-flex align-items-center mr-4 mt-3">
          <span className="mr-2">Age gestationnel :</span>
          <Input
            containerClassName="border-left-0 border-top-0 border-right-0"
            wrapperStyle={{ width: "70px" }}
            className={makeInputClassName(errors?.gesta2_sa)}
            name="gesta2_sa"
            value={fields?.resultat?.gesta2_sa}
            onChange={handleInputChange}
            min="0" type="number"
            readOnly={props?.readOnly}
          />
          <span className="ml-1 mr-2">SA +</span>
          <Input
            containerClassName="border-left-0 border-top-0 border-right-0"
            wrapperStyle={{ width: "70px" }}
            className={makeInputClassName(errors?.gesta2_jours)}
            name="gesta2_jours"
            value={fields?.resultat?.gesta2_jours}
            onChange={handleInputChange}
            min="0" type="number"
            readOnly={props?.readOnly}
          />
          <span className="ml-1">Jours</span>
        </div>
      </div>
      <div className="p-3 d-flex flex-wrap w-100 border-top mt-3">
        <div className="font-weight-bold w-100">3- Placenta</div>
        <div className="d-flex align-items-center mr-5 mt-3 w-40">
          <span className="mr-2 white-space-nowrap">Situation :</span>
          <Input
            containerClassName="border-left-0 border-top-0 border-right-0"
            className={makeInputClassName(errors?.placenta_situation)}
            name="placenta_situation"
            value={fields?.resultat?.placenta_situation}
            onChange={handleInputChange}
            readOnly={props?.readOnly}
          />
        </div>
        <div className="d-flex align-items-center mr-4 mt-3 w-40">
          <span className="mr-2 white-space-nowrap">Grade :</span>
          <Input
            containerClassName="border-left-0 border-top-0 border-right-0"
            className={makeInputClassName(errors?.placenta_grade)}
            name="placenta_grade"
            value={fields?.resultat?.placenta_grade}
            onChange={handleInputChange}
            readOnly={props?.readOnly}
          />
        </div>
      </div>
      <div className="p-3 d-flex flex-wrap w-100 border-top mt-3">
        <div className="font-weight-bold w-100">4- Liquide amniotique</div>
        <div className="d-flex align-items-center mr-5 mt-3 w-40">
          <span className="mr-2 white-space-nowrap">Abondance :</span>
          <Input
            containerClassName="border-left-0 border-top-0 border-right-0"
            className={makeInputClassName(errors?.liquid_abondance)}
            name="liquid_abondance"
            value={fields?.resultat?.liquid_abondance}
            onChange={handleInputChange}
            readOnly={props?.readOnly}
          />
        </div>
        <div className="d-flex align-items-center mr-4 mt-3 w-40">
          <span className="mr-2 white-space-nowrap">Aspect :</span>
          <Input
            containerClassName="border-left-0 border-top-0 border-right-0"
            className={makeInputClassName(errors?.liquid_aspect)}
            name="liquid_aspect"
            value={fields?.resultat?.liquid_aspect}
            onChange={handleInputChange}
            readOnly={props?.readOnly}
          />
        </div>
      </div>
      <div className="p-3 d-flex flex-wrap w-100 border-top mt-3">
        <div className="font-weight-bold w-100">5- Morphologie</div>
        <div className="d-flex align-items-center mr-5 mt-3 w-40">
          <span className="mr-2 white-space-nowrap">Thorax :</span>
          <Input
            containerClassName="border-left-0 border-top-0 border-right-0"
            className={makeInputClassName(errors?.morph_thorax)}
            name="morph_thorax"
            value={fields?.resultat?.morph_thorax}
            onChange={handleInputChange}
            readOnly={props?.readOnly}
          />
        </div>
        <div className="d-flex align-items-center mr-4 mt-3 w-40">
          <span className="mr-2 white-space-nowrap">Cœur :</span>
          <Input
            containerClassName="border-left-0 border-top-0 border-right-0"
            className={makeInputClassName(errors?.morph_coeur)}
            name="morph_coeur"
            value={fields?.resultat?.morph_coeur}
            onChange={handleInputChange}
            readOnly={props?.readOnly}
          />
        </div>
        <div className="d-flex align-items-center mr-5 mt-3 w-40">
          <span className="mr-2 white-space-nowrap">Colonne vertébrale :</span>
          <Input
            containerClassName="border-left-0 border-top-0 border-right-0"
            className={makeInputClassName(errors?.morph_colonne)}
            name="morph_colonne"
            value={fields?.resultat?.morph_colonne}
            onChange={handleInputChange}
            readOnly={props?.readOnly}
          />
        </div>
        <div className="d-flex align-items-center mr-4 mt-3 w-40">
          <span className="mr-2 white-space-nowrap">Cerveau :</span>
          <Input
            containerClassName="border-left-0 border-top-0 border-right-0"
            className={makeInputClassName(errors?.morph_cerveau)}
            name="morph_cerveau"
            value={fields?.resultat?.morph_cerveau}
            onChange={handleInputChange}
            readOnly={props?.readOnly}
          />
        </div>
        <div className="d-flex align-items-center mr-5 mt-3 w-40">
          <span className="mr-2 white-space-nowrap">Paroi abdominale :</span>
          <Input
            containerClassName="border-left-0 border-top-0 border-right-0"
            className={makeInputClassName(errors?.morph_paroi)}
            name="morph_paroi"
            value={fields?.resultat?.morph_paroi}
            onChange={handleInputChange}
            readOnly={props?.readOnly}
          />
        </div>
        <div className="d-flex align-items-center mr-4 mt-3 w-40">
          <span className="mr-2 white-space-nowrap">Estomac :</span>
          <Input
            containerClassName="border-left-0 border-top-0 border-right-0"
            className={makeInputClassName(errors?.morph_estomac)}
            name="morph_estomac"
            value={fields?.resultat?.morph_estomac}
            onChange={handleInputChange}
            readOnly={props?.readOnly}
          />
        </div>
        <div className="d-flex align-items-center mr-5 mt-3 w-40">
          <span className="mr-2 white-space-nowrap">Reins :</span>
          <Input
            containerClassName="border-left-0 border-top-0 border-right-0"
            className={makeInputClassName(errors?.morph_reins)}
            name="morph_reins"
            value={fields?.resultat?.morph_reins}
            onChange={handleInputChange}
            readOnly={props?.readOnly}
          />
        </div>
        <div className="d-flex align-items-center mr-4 mt-3 w-40">
          <span className="mr-2 white-space-nowrap">Vessie :</span>
          <Input
            containerClassName="border-left-0 border-top-0 border-right-0"
            className={makeInputClassName(errors?.morph_vessie)}
            name="morph_vessie"
            value={fields?.resultat?.morph_vessie}
            onChange={handleInputChange}
            readOnly={props?.readOnly}
          />
        </div>
        <div className="d-flex align-items-center mr-5 mt-3 w-40">
          <span className="mr-2 white-space-nowrap">Sexe :</span>
          <Select
            containerClassName="border-left-0 border-top-0 border-right-0"
            className={makeInputClassName(errors?.morph_sexe)}
            name="morph_sexe"
            value={fields?.resultat?.morph_sexe}
            onChange={handleInputChange}
            readOnly={props?.readOnly}
          >
            <option value="">Choisir</option>
            <option value="M">Masculin</option>
            <option value="F">Féminin</option>
          </Select>
        </div>
        <div className="d-flex align-items-center mr-4 mt-3 w-40">
          <span className="mr-2 white-space-nowrap font-weight-bold">DPA :</span>
          <Input
            containerClassName="border-left-0 border-top-0 border-right-0"
            className={makeInputClassName(errors?.morph_dpa)}
            name="morph_dpa"
            value={fields?.resultat?.morph_dpa}
            onChange={handleInputChange}
            readOnly={props?.readOnly}
          />
        </div>
        <div className="d-flex align-items-center mr-5 mt-3 w-40">
          <span className="mr-2 white-space-nowrap">Longueur Cervicale :</span>
          <Input
            containerClassName="border-left-0 border-top-0 border-right-0"
            className={makeInputClassName(errors?.morph_long)}
            name="morph_long"
            value={fields?.resultat?.morph_long}
            onChange={handleInputChange}
            readOnly={props?.readOnly}
          />
        </div>
        <div className="d-flex align-items-center mr-4 mt-3 w-40">
          <span className="mr-2 white-space-nowrap font-weight-bold">Poids :</span>
          <Input
            containerClassName="border-left-0 border-top-0 border-right-0"
            className={makeInputClassName(errors?.morph_poids)}
            name="morph_poids"
            value={fields?.resultat?.morph_poids}
            onChange={handleInputChange}
            readOnly={props?.readOnly}
          />
        </div>
      </div>
      <div className="p-3 d-flex flex-wrap w-100 border-top mt-3">
        <Input
          label="ASPECTS PATHOLOGIOUES"
          labelClassName="font-weight-bold"
          containerClassName="border-0"
          placeholder="Saisir ici"
          className={`px-0 py-1 h-auto ${!!errors?.aspect_pathologique ? 'border-principal-bottom' : ''}`}
          name="aspect_pathologique"
          value={fields?.resultat?.aspect_pathologique}
          onChange={handleInputChange}
          readOnly={props?.readOnly}
        />
      </div>
      <div className="p-3 d-flex flex-wrap w-100 border-top mt-3">
        <Textarea
          label="CONCLUSION"
          labelClassName="font-weight-bold text-principal"
          containerClassName="border-0"
          className={`px-0 py-1 ${!!errors?.comment ? 'border-principal-bottom' : ''}`}
          placeholder="Saisir ici"
          rows={3}
          value={fields?.comment}
          onChange={e => setFields(old => ({
            ...old,
            comment: e?.target?.value
          }))}
          readOnly={props?.readOnly}
        />
      </div>
    </Container>
  );
};

const Container = styled.div`
  border: 0.5px solid #ABABAB;
`;

export default FicheEchoObsSecond;