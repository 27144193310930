import React from 'react';
import "./styles.scss";
import PropTypes from 'prop-types';

const SearchInput = ({
  type,
  name,
  label,
  value,
  error,
  onChange,
  iconRight,
  iconLeft,
  style,
  className,
  labelClassName,
  errorClassName,
  containerClassName,
  containerStyle,
  wrapperClassName,
  wrapperStyle,
  ...rest
}) => {
  return (
    <div style={{ width: "100%", ...wrapperStyle }} className="w-100 m-0 p-0 d-flex flex-column">
      {!!label && <div className={`w-100 py-2 ${labelClassName}`}>{label}</div>}
      <div style={{ ...containerStyle }} className={`kea-input type-search ${containerClassName}`}>
        <div className="d-flex align-items-center w-100 h-100">
          {iconLeft &&
            <div className="kea-input__left-icon">
              {iconLeft}
            </div>
          }
          <input
            type={type}
            name={name}
            value={value}
            onChange={onChange}
            {...rest}
            className={`kea-input__field ${className} ${iconLeft ? 'ml-0' : ''} ${iconRight ? 'mr-0' : ''}`}
          />
          {iconRight &&
            <div className="kea-input__right-icon">
              {iconRight}
            </div>
          }
        </div>
        {!!error && <div className={`kea-input__feedback ${errorClassName}`}>{error}</div>}
      </div>
    </div>
  );
};

SearchInput.propTypes = {
  type: PropTypes.oneOf(["text", "date", "time", "number", "password", "email", "tel"]),
  name: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.string,
  onChange: PropTypes.func,
  iconRight: PropTypes.any,
  iconLeft: PropTypes.any,
  labelClassName: PropTypes.string,
  errorClassName: PropTypes.string,
  containerClassName: PropTypes.string,
	containerStyle: PropTypes.object,
	wrapperClassName: PropTypes.string,
	wrapperStyle: PropTypes.object,
  placeholder: PropTypes.string
};

SearchInput.defaultProps = {
  type: 'text',
  name: '',
  value: '',
  onChange: () => console.log('no onChange method attached to Input...'),
  className: "",
  labelClassName: "",
  errorClassName: "",
  containerClassName: "",
	containerStyle: {},
	wrapperClassName: "",
	wrapperStyle: {}
};

export default SearchInput;