import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { makeStyles } from "@material-ui/core";
import styled from 'styled-components';
import { Button, Checkbox } from "components";
import { FaTimes } from 'react-icons/fa';
import { SoinsType } from 'types';
import { FiCheckSquare, FiSquare } from 'react-icons/fi';


interface StopFicheModalProps {
  soin: SoinsType;
  visible: boolean;
  onHide: (status: boolean, mainChecked: boolean, ids: string[]) => void;
};

const StopFicheModal = (props: StopFicheModalProps) => {
  const classes = usetyles();

  const [selected, setSelected] = useState([]);

  const [isMainChecked, setIsMainChecked] = useState(false);

  useEffect(() => {
    if (props.visible) {
      setIsMainChecked(false);
      setSelected([]);
    }
  }, [props?.visible]);

  const handleToggleSubProduct = (id, status) => {
    if (status) {
      setSelected(old => [...old, id]);
    } else {
      const updated = [...selected?.filter(el => el !== id)];
      setSelected(updated);
      if (updated.length < props?.soin?.fiche?.sous_produit?.filter(el => !el?.stopped)?.length) {
        setIsMainChecked(false);
      }
    }
  };

  const handleSubmit = () => {
    props.onHide(true, isMainChecked, selected);
  };

  return (
    <Modal
      centered
      backdrop="static"
      contentClassName={classes.modalContainer}
      show={props.visible}
      onHide={props.onHide.bind(this, false)}
    >
      <CloseIcon onClick={props.onHide.bind(this, false)} />
      <div className="h-auto w-100 d-flex flex-column align-items-center p-3 mt-5">
        <div className="bg-light border p-3 border-5 w-100 d-flex align-items-center">
          <Checkbox
            ActiveIcon={FiCheckSquare}
            InactiveIcon={FiSquare}
            className="mr-3"
            size={18}
            checked={isMainChecked}
            onChange={status => {
              if (status) {
                setIsMainChecked(true);
                setSelected([...props?.soin?.fiche?.sous_produit?.filter(el => !el?.stopped)?.map(el => el?._id)]);
              } else {
                setIsMainChecked(false);
              }
            }}
          />
          <span className="font-weight-bold w-90 font-90">
            {props?.soin?.fiche?.produit || ""}
          </span>
        </div>
        {Array.isArray(props?.soin?.fiche?.sous_produit) &&
          props?.soin?.fiche?.sous_produit?.filter(el => !el?.stopped)?.map((sousProduit, index) => {
            const isSelected = selected?.includes(sousProduit?._id);

            return (
              <div key={index} className="border p-3 border-5 w-100 d-flex align-items-center mt-3">
                <Checkbox
                  ActiveIcon={FiCheckSquare}
                  InactiveIcon={FiSquare}
                  className="mr-3"
                  size={18}
                  checked={isSelected}
                  onChange={handleToggleSubProduct.bind(this, sousProduit?._id)}
                />
                <div className="d-flex flex-column w-90">
                  <span className="text-principal mb-1 font-90">
                    {sousProduit?.produit || ""}
                  </span>
                  <span className="text-disabled font-90">
                    {sousProduit?.posologie || ""}
                  </span>
                </div>
              </div>
            );
          })
        }
        <div className="w-100 d-flex align-items-center justify-content-center mt-5">
          <Button
            value="Arrêter"
            variant={(isMainChecked || selected?.length > 0) ? "principal" : "gray"}
            disabled={!(isMainChecked || selected?.length > 0)}
            className="px-4 font-90"
            onClick={handleSubmit}
          />
        </div>
      </div>
    </Modal>
  );
};

const usetyles = makeStyles(() => ({
  modalContainer: {
    boxShadow: "0px 8px 30px rgba(0, 0, 0, 0.12)",
    borderRadius: "5px",
    borderWidth: 0,
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    "@media only screen and (max-width: 768px)": {
      width: "80vw",
      marginLeft: "7vw"
    }
  },
}));

StopFicheModal.defaultProps = {
  visible: false,
  onHide: () => console.log("Please attach an onHide function to [StopFicheModal]"),
};

const CloseIcon = styled(FaTimes)`
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  color: black;
`;

export default StopFicheModal;