import { getActeById } from "http/crud";
import { ActeType } from 'types';

export const fetchActe = async (acteId): Promise<ActeType> => {
  try {
    const response = await getActeById(acteId);
    return !!response?.data?.id ? response?.data : null;
  } catch (error) {
    return null;
  }
};