import React, {useState} from "react";
import "./styles.scss";
import PropTypes from "prop-types";
import {DatePicker} from "components";
import moment from "moment";
import {FaCalendar} from "react-icons/fa";
import {FRONTEND_DATE_FORMAT} from "configs";

const DateInput = ({
                       type,
                       name,
                       label,
                       value,
                       error,
                       onChange,
                       iconRight,
                       iconLeft,
                       className,
                       labelClassName,
                       errorClassName,
                       containerClassName,
                       containerStyle,
                       wrapperClassName,
                       wrapperStyle,
                       minDate,
                       maxDate,
                       showMonthAndYearPickers,
                       onClickOverride,
                       ...rest
                   }) => {
    const [date, setDate] = useState(new Date());

    const [anchorElement, setAnchorElement] = useState(null);
    const [showPop, setShowPop] = useState(Boolean(anchorElement));

    const handleOpenPopover = (event) => {
        setAnchorElement(event?.currentTarget);
        setShowPop(true);
    };

    const handleClosePopover = () => {
        setAnchorElement(null);
        setShowPop(false);
    };

    const handleChangeDate = (updated) => {
        setDate(updated);
        onChange({
            target: {
                name,
                value: moment(updated).format(FRONTEND_DATE_FORMAT),
            },
        });
        handleClosePopover();
    };

    return (
        <div
            style={{width: "100%", ...wrapperStyle}}
            className={`m-0 p-0 d-flex flex-column align-items-center ${wrapperClassName}`}
        >
            <DatePicker
                date={date}
                visible={showPop}
                anchor={anchorElement}
                onHide={handleClosePopover}
                onChange={handleChangeDate}
                showMonthAndYearPickers={!!showMonthAndYearPickers}
                displayMode="date"
                minDate={minDate || undefined}
                maxDate={maxDate || undefined}
            />
            {!!label && <div className={`w-100 py-2 ${labelClassName}`}>{label}</div>}
            <div
                style={{...containerStyle}}
                className={`kea-input ${containerClassName}`}
            >
                {iconLeft && <div className="kea-input__left-icon">{iconLeft}</div>}
                <input
                    name={name}
                    value={value}
                    onClick={!!onClickOverride ? (e)=>{
                        onClickOverride()
                    } : handleOpenPopover}
                    readOnly
                    {...rest}
                    className={`kea-input__field cursor-pointer ${className} ${
                        iconLeft ? "ml-0" : ""
                    } ${iconRight ? "mr-0" : ""}`}
                />
                {iconRight && <div className="kea-input__right-icon">{iconRight}</div>}
            </div>
            {!!error && (
                <div className={`kea-input__feedback ${errorClassName}`}>{error}</div>
            )}
        </div>
    );
};

DateInput.propTypes = {
    name: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.any,
    error: PropTypes.string,
    onChange: PropTypes.func,
    iconRight: PropTypes.any,
    iconLeft: PropTypes.any,
    labelClassName: PropTypes.string,
    errorClassName: PropTypes.string,
    containerClassName: PropTypes.string,
    containerStyle: PropTypes.object,
    wrapperClassName: PropTypes.string,
    wrapperStyle: PropTypes.object,
    placeholder: PropTypes.string,
};

DateInput.defaultProps = {
    name: "",
    value: "",
    onChange: () => console.log("no onChange method attached to Input..."),
    className: "",
    labelClassName: "",
    errorClassName: "",
    containerClassName: "",
    containerStyle: {},
    wrapperClassName: "",
    wrapperStyle: {},
    placeholder: "dd/mm/yyyy",
    iconRight: <FaCalendar className="text-disabled" size={13}/>,
};

export default DateInput;
