import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { makeStyles } from "@material-ui/core";
import styled from 'styled-components';
import Button from 'components/button';
import { FaTimes } from 'react-icons/fa';
import { Input } from "components";
import DialogConfirmation from "components/dialog-confirmation";

interface ConfirmModalMotifProps {
  title: string;
  visible: boolean;
  onHide: (status: boolean, motif: string) => void;
}

const ConfirmModalMotif = (props: ConfirmModalMotifProps) => {
  const classes = usetyles();

  const [motif, setMotif] = useState(null)
  const handleInputChange = el => {
    setMotif(el.target.value)
  };

  const handleDialogClose =() =>{
    setDialogIsOpen(false)
  }

  const [dialogIsOpen, setDialogIsOpen] = useState(false);

  const handleDialogValidation = () =>{
    setDialogIsOpen(false)
  }

  return (
    <Modal
      centered
      backdrop="static"
      size="sm"
      contentClassName={classes.modalContainer}
      show={props.visible}
      onHide={props.onHide.bind(this, false)}
    >
      <CloseIcon onClick={props.onHide.bind(this, false)} />
      <div className="h-auto w-100 d-flex align-items-center flex-column p-4 mt-5">
        <Message className="text-disabled text-center font-weight-bold mb-3">
          {props?.title || ""}
        </Message>
        <p>Veuillez entrer le motif d'annulation</p>
        <Input
          containerClassName="border-0 border-5 bg-light"
          containerStyle={{ backgroundColor: "#AAA" }}
          onChange={handleInputChange}
          value={motif}
        />
        {
          !!motif && motif.length>1 && <Button onClick={()=>{setDialogIsOpen(true)}} value="Valider annulation" size="sm" className="w-60 border-5 mt-4" />
        }
        <DialogConfirmation
        open={dialogIsOpen}
        onClose={handleDialogClose}
        title="Confirmation"
        description="Êtes-vous sûr d'envoyer cette demande d'annulation ? Cette demande peut-être validée ou rejetée !"
        trigger={props.onHide.bind(this, true, motif)}
        yesLabel="Oui, je suis sûr !"
        noLabel="Non"
        />
      </div>
    </Modal>
  );
};

const usetyles = makeStyles(() => ({
  modalContainer: {
    boxShadow: "0px 8px 30px rgba(0, 0, 0, 0.12)",
    borderRadius: "5px",
    borderWidth: 0,
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    "@media only screen and (max-width: 768px)": {
      width: "80vw",
      marginLeft: "7vw"
    }
  },
}));

ConfirmModalMotif.defaultProps = {
  title: 'Confirmer la suppression',
  visible: false,
  onHide: () => console.log("Please attach an onHide function to [ConfirmModalMotif]"),
};

const CloseIcon = styled(FaTimes)`
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  color: #52575C;
`;

const Message = styled.div`
  width: 100%;
  text-align: center;
  color: #52575C;
`;

export default ConfirmModalMotif;