import React, { useState } from 'react';
import styled from 'styled-components';
import { Input, Textarea } from 'components';
import { PatientType, ResultatImgObsFirstType, ImagerieExam } from 'types';
import { useDebounce } from 'hooks';
import _ from 'lodash';
import { minValOrNullable, resetObject, validate } from 'context/swift-inputs';
import moment from 'moment';
import { BACKEND_DATE_FORMAT } from 'configs';


interface FieldsType {
  result_date: string;
  medecin_demandeur: string;
  ddr: string;
  indication: string;
  resultat: ResultatImgObsFirstType;
  comment: string;
};

interface FicheEchoObsFirstProps {
  exam: ImagerieExam;
  className: string;
  patient: PatientType;
  fields: FieldsType;
  onChange: (fields: FieldsType, isValid: boolean) => void;
  readOnly: boolean;
};

const FicheEchoObsFirst = (props: FicheEchoObsFirstProps) => {
  const [fields, setFields] = useState(props?.fields);

  const [errors, setErrors] = useState({
    medecin_demandeur: "",
    ddr: "",
    indication: "",
    comment: "",

    sac_nbr: "",
    sac_aspect: "",
    sac_localisation: "",
    sac_dimension: "",
    sac_placenta: "",
    sac_reaction: "",
    sac_vo: "",

    embryon_nbr: "",
    embryon_lcc1: "",
    embryon_ac1: "",
    embryon_maf1: "",
    embryon_gesta1_sa: "",
    embryon_gesta1_jours: "",
    embryon_lcc2: "",
    embryon_ac2: "",
    embryon_maf2: "",
    embryon_gesta2_sa: "",
    embryon_gesta2_jours: "",

    foetus_nbr: "",
    foetus_lcc1: "",
    foetus_bip1: "",
    foetus_ac1: "",
    foetus_fl1: "",
    foetus_maf1: "",
    foetus_gesta1_sa: "",
    foetus_gesta1_jours: "",
    foetus_lcc2: "",
    foetus_bip2: "",
    foetus_ac2: "",
    foetus_fl2: "",
    foetus_maf2: "",
    foetus_gesta2_sa: "",
    foetus_gesta2_jours: "",

    aspect_pathologique: "",
  });

  // FOR RESULTATS INPUTS
  const handleInputChange = e => {
    setFields(old => ({
      ...old,
      resultat: {
        ...old?.resultat,
        [e?.target?.name]: e?.target?.value
      }
    }));
  };

  useDebounce(() => {
    !props?.readOnly && props?.onChange(fields, checkForm({
      ..._.omit(fields, 'resultat'),
      ...fields?.resultat
    }));
  }, 100, [fields]);

  const checkForm = (fieldsData: FieldsType) => {
    const validation = validate(fieldsData, {
      medecin_demandeur: "required|name",
      ddr: "required|date",
      indication: "required",
      comment: "required",

      sac_nbr: minValOrNullable.bind(this, 0),
      sac_aspect: "nullable",
      sac_localisation: "nullable",
      sac_dimension: minValOrNullable.bind(this, 0),
      sac_placenta: "nullable",
      sac_reaction: "nullable",
      sac_vo: "nullable",

      embryon_nbr: minValOrNullable.bind(this, 0),
      embryon_lcc1: minValOrNullable.bind(this, 0),
      embryon_ac1: minValOrNullable.bind(this, 0),
      embryon_maf1: "nullable",
      embryon_gesta1_sa: minValOrNullable.bind(this, 0),
      embryon_gesta1_jours: minValOrNullable.bind(this, 0),
      embryon_lcc2: minValOrNullable.bind(this, 0),
      embryon_ac2: minValOrNullable.bind(this, 0),
      embryon_maf2: "nullable",
      embryon_gesta2_sa: minValOrNullable.bind(this, 0),
      embryon_gesta2_jours: minValOrNullable.bind(this, 0),

      foetus_nbr: minValOrNullable.bind(this, 0),
      foetus_lcc1: minValOrNullable.bind(this, 0),
      foetus_bip1: minValOrNullable.bind(this, 0),
      foetus_ac1: minValOrNullable.bind(this, 0),
      foetus_fl1: minValOrNullable.bind(this, 0),
      foetus_maf1: "nullable",
      foetus_gesta1_sa: minValOrNullable.bind(this, 0),
      foetus_gesta1_jours: minValOrNullable.bind(this, 0),
      foetus_lcc2: minValOrNullable.bind(this, 0),
      foetus_bip2: minValOrNullable.bind(this, 0),
      foetus_ac2: minValOrNullable.bind(this, 0),
      foetus_fl2: minValOrNullable.bind(this, 0),
      foetus_maf2: "nullable",
      foetus_gesta2_sa: minValOrNullable.bind(this, 0),
      foetus_gesta2_jours: minValOrNullable.bind(this, 0),

      aspect_pathologique: "nullable",
    });

    if (validation?.isFormValid()) {
      setErrors(resetObject(errors));
      return true;
    } else {
      setErrors(old => ({
        ...old,
        medecin_demandeur: validation?.response?.medecin_demandeur?.error,
        ddr: validation?.response?.ddr?.error,
        indication: validation?.response?.indication?.error,
        comment: validation?.response?.comment?.error,

        sac_nbr: validation?.response?.sac_nbr?.error,
        sac_aspect: validation?.response?.sac_aspect?.error,
        sac_localisation: validation?.response?.sac_localisation?.error,
        sac_dimension: validation?.response?.sac_dimension?.error,
        sac_placenta: validation?.response?.sac_placenta?.error,
        sac_reaction: validation?.response?.sac_reaction?.error,
        sac_vo: validation?.response?.sac_vo?.error,
        embryon_nbr: validation?.response?.embryon_nbr?.error,
        embryon_lcc1: validation?.response?.embryon_lcc1?.error,
        embryon_ac1: validation?.response?.embryon_ac1?.error,
        embryon_maf1: validation?.response?.embryon_maf1?.error,
        embryon_gesta1_sa: validation?.response?.embryon_gesta1_sa?.error,
        embryon_gesta1_jours: validation?.response?.embryon_gesta1_jours?.error,
        embryon_lcc2: validation?.response?.embryon_lcc2?.error,
        embryon_ac2: validation?.response?.embryon_ac2?.error,
        embryon_maf2: validation?.response?.embryon_maf2?.error,
        embryon_gesta2_sa: validation?.response?.embryon_gesta2_sa?.error,
        embryon_gesta2_jours: validation?.response?.embryon_gesta2_jours?.error,
        foetus_nbr: validation?.response?.foetus_nbr?.error,
        foetus_lcc1: validation?.response?.foetus_lcc1?.error,
        foetus_bip1: validation?.response?.foetus_bip1?.error,
        foetus_ac1: validation?.response?.foetus_ac1?.error,
        foetus_fl1: validation?.response?.foetus_fl1?.error,
        foetus_maf1: validation?.response?.foetus_maf1?.error,
        foetus_gesta1_sa: validation?.response?.foetus_gesta1_sa?.error,
        foetus_gesta1_jours: validation?.response?.foetus_gesta1_jours?.error,
        foetus_lcc2: validation?.response?.foetus_lcc2?.error,
        foetus_bip2: validation?.response?.foetus_bip2?.error,
        foetus_ac2: validation?.response?.foetus_ac2?.error,
        foetus_fl2: validation?.response?.foetus_fl2?.error,
        foetus_maf2: validation?.response?.foetus_maf2?.error,
        foetus_gesta2_sa: validation?.response?.foetus_gesta2_sa?.error,
        foetus_gesta2_jours: validation?.response?.foetus_gesta2_jours?.error,
        aspect_pathologique: validation?.response?.aspect_pathologique?.error,
      }));
      return false;
    }
  };

  const makeInputClassName = err => {
    return `px-0 py-1 h-auto font-weight-bold ${!!err ? 'border-principal-bottom' : ''}`;
  };

  return (
    <Container className={`w-100 ${props.className || ""}`}>
      <div className="w-100 p-3 border-bottom font-weight-bold bg-principal-pills text-center">
        {props?.exam?.libelle || "ECHOGRAPHIE OBSTETRICAL (1er Trimestre)"}
      </div>
      <div className="p-3 d-flex flex-column w-100">
        <div className="align-self-end d-flex align-items-center">
          <span className="mr-3">Date :</span>
          <Input
            wrapperStyle={{ width: "120px" }}
            containerClassName="border-left-0 border-top-0 border-right-0"
            className="px-0 py-1 h-auto"
            type="date"
            value={props?.fields?.result_date}
          />
        </div>
        <div className="w-100 d-flex flex-wrap">
          <div className="d-flex align-items-center mr-5 mt-4 w-40">
            <span className="mr-3 white-space-nowrap">Nom et Prénoms :</span>
            <Input
              containerClassName="border-left-0 border-top-0 border-right-0"
              className="px-0 py-1 h-auto"
              readOnly
              value={`${props?.patient?.lastName || '-'}${!!props?.patient?.firstName ? ' ' + props?.patient?.firstName : ''}`}
            />
          </div>
          <div className="d-flex align-items-center mr-5 mt-4">
            <span className="mr-3 white-space-nowrap">Age :</span>
            <Input
              containerClassName="border-left-0 border-top-0 border-right-0"
              className="px-0 py-1 h-auto"
              wrapperStyle={{ width: "150px" }}
              readOnly
              value={`${!!props?.patient?.birthDate ? `${moment().diff(moment(props?.patient?.birthDate, BACKEND_DATE_FORMAT), 'years')} ans` : "-"}`}
            />
          </div>
          <div className="d-flex align-items-center mr-5 mt-4 w-40">
            <span className="mr-3 white-space-nowrap">Demandeur :</span>
            <Input
              containerClassName="border-left-0 border-top-0 border-right-0"
              className={`px-0 py-1 h-auto font-weight-bold ${!!errors?.medecin_demandeur ? 'border-principal-bottom' : ''}`}
              value={fields?.medecin_demandeur}
              onChange={e => setFields(old => ({
                ...old,
                medecin_demandeur: e?.target?.value
              }))}
              readOnly={props?.readOnly}
            />
          </div>
          <div className="d-flex align-items-center mr-5 mt-4">
            <span className="mr-3 white-space-nowrap">DDR :</span>
            <Input
              containerClassName="border-left-0 border-top-0 border-right-0"
              className={`px-0 py-1 h-auto font-weight-bold ${!!errors?.ddr ? 'border-principal-bottom' : ''}`}
              type="date"
              wrapperStyle={{ width: "150px" }}
              value={fields?.ddr}
              onChange={e => setFields(old => ({
                ...old,
                ddr: e?.target?.value
              }))}
              max={moment().subtract(2, "weeks").format(BACKEND_DATE_FORMAT)}
              readOnly={props?.readOnly}
            />
          </div>
          <div className="d-flex align-items-center mt-4 w-100">
            <span className="mr-3 white-space-nowrap">Indications :</span>
            <Input
              containerClassName="border-left-0 border-top-0 border-right-0"
              className={`px-0 py-1 h-auto font-weight-bold ${!!errors?.indication ? 'border-principal-bottom' : ''}`}
              value={fields?.indication}
              onChange={e => setFields(old => ({
                ...old,
                indication: e?.target?.value
              }))}
              readOnly={props?.readOnly}
            />
          </div>
        </div>
      </div>
      <div className="w-100 p-3 font-weight-bold text-principal text-center mt-4">
        RÉSULTATS
      </div>
      <div className="p-3 d-flex flex-wrap w-100 border-top">
        <div className="font-weight-bold w-100">Sac ovulaire</div>
        <div className="d-flex align-items-center mr-4 mt-3">
          <span className="mr-2">Nombre :</span>
          <Input
            containerClassName="border-left-0 border-top-0 border-right-0"
            wrapperStyle={{ width: "80px" }}
            className={makeInputClassName(errors?.sac_nbr)}
            name="sac_nbr"
            value={fields?.resultat?.sac_nbr}
            onChange={handleInputChange}
            min="0" type="number"
            readOnly={props?.readOnly}
          />
        </div>
        <div className="d-flex align-items-center mr-4 mt-3">
          <span className="mr-2">Aspects :</span>
          <Input
            containerClassName="border-left-0 border-top-0 border-right-0"
            wrapperStyle={{ width: "500px" }}
            className={makeInputClassName(errors?.sac_aspect)}
            name="sac_aspect"
            value={fields?.resultat?.sac_aspect}
            onChange={handleInputChange}
            readOnly={props?.readOnly}
          />
        </div>
        <div className="d-flex align-items-center mr-4 mt-3">
          <span className="mr-2">Localisation :</span>
          <Input
            containerClassName="border-left-0 border-top-0 border-right-0"
            wrapperStyle={{ width: "200px" }}
            className={makeInputClassName(errors?.sac_localisation)}
            name="sac_localisation"
            value={fields?.resultat?.sac_localisation}
            onChange={handleInputChange}
            readOnly={props?.readOnly}
          />
        </div>
        <div className="d-flex align-items-center mr-4 mt-3">
          <span className="mr-2">Dimensions :</span>
          <Input
            containerClassName="border-left-0 border-top-0 border-right-0"
            wrapperStyle={{ width: "80px" }}
            className={makeInputClassName(errors?.sac_dimension)}
            name="sac_dimension"
            value={fields?.resultat?.sac_dimension}
            onChange={handleInputChange}
            min="0" type="number"
            readOnly={props?.readOnly}
          />
          <span className="ml-1">mm</span>
        </div>
        <div className="d-flex align-items-center mr-4 mt-3">
          <span className="mr-2">Placenta :</span>
          <Input
            containerClassName="border-left-0 border-top-0 border-right-0"
            wrapperStyle={{ width: "200px" }}
            className={makeInputClassName(errors?.sac_placenta)}
            name="sac_placenta"
            value={fields?.resultat?.sac_placenta}
            onChange={handleInputChange}
            readOnly={props?.readOnly}
          />
        </div>
        <div className="d-flex align-items-center mr-4 mt-3">
          <span className="mr-2">Réaction trophoblastique :</span>
          <Input
            containerClassName="border-left-0 border-top-0 border-right-0"
            wrapperStyle={{ width: "350px" }}
            className={makeInputClassName(errors?.sac_reaction)}
            name="sac_reaction"
            value={fields?.resultat?.sac_reaction}
            onChange={handleInputChange}
            readOnly={props?.readOnly}
          />
        </div>
        <div className="d-flex align-items-center mr-4 mt-3">
          <span className="mr-2">VO :</span>
          <Input
            containerClassName="border-left-0 border-top-0 border-right-0"
            wrapperStyle={{ width: "200px" }}
            className={makeInputClassName(errors?.sac_vo)}
            name="sac_vo"
            value={fields?.resultat?.sac_vo}
            onChange={handleInputChange}
            readOnly={props?.readOnly}
          />
        </div>
      </div>
      <div className="p-3 d-flex flex-wrap w-100 border-top mt-3">
        <div className="font-weight-bold w-100">Embryon</div>
        <div className="d-flex align-items-center mr-4 mt-3 w-100">
          <span className="mr-2">Nombre :</span>
          <Input
            containerClassName="border-left-0 border-top-0 border-right-0"
            wrapperStyle={{ width: "80px" }}
            className={makeInputClassName(errors?.embryon_nbr)}
            name="embryon_nbr"
            value={fields?.resultat?.embryon_nbr}
            onChange={handleInputChange}
            min="0" type="number"
            readOnly={props?.readOnly}
          />
        </div>
        <div className="d-flex flex-wrap w-100">
          <div className="d-flex align-items-center mr-4 mt-3">
            <span className="mr-2">LCC1 :</span>
            <Input
              containerClassName="border-left-0 border-top-0 border-right-0"
              wrapperStyle={{ width: "80px" }}
              className={makeInputClassName(errors?.embryon_lcc1)}
              name="embryon_lcc1"
              value={fields?.resultat?.embryon_lcc1}
              onChange={handleInputChange}
              min="0" type="number"
              readOnly={props?.readOnly}
            />
            <span className="ml-1">mm</span>
          </div>
          <div className="d-flex align-items-center mr-4 mt-3">
            <span className="mr-2">AC1 :</span>
            <Input
              containerClassName="border-left-0 border-top-0 border-right-0"
              wrapperStyle={{ width: "80px" }}
              className={makeInputClassName(errors?.embryon_ac1)}
              name="embryon_ac1"
              value={fields?.resultat?.embryon_ac1}
              onChange={handleInputChange}
              min="0" type="number"
              readOnly={props?.readOnly}
            />
            <span className="ml-1">bts/mn</span>
          </div>
          <div className="d-flex align-items-center mr-4 mt-3">
            <span className="mr-2">MAF :</span>
            <Input
              containerClassName="border-left-0 border-top-0 border-right-0"
              wrapperStyle={{ width: "120px" }}
              className={makeInputClassName(errors?.embryon_maf1)}
              name="embryon_maf1"
              value={fields?.resultat?.embryon_maf1}
              onChange={handleInputChange}
              readOnly={props?.readOnly}
            />
          </div>
          <div className="d-flex align-items-center mr-4 mt-3">
            <span className="mr-2">Age gestationnel :</span>
            <Input
              containerClassName="border-left-0 border-top-0 border-right-0"
              wrapperStyle={{ width: "80px" }}
              className={makeInputClassName(errors?.embryon_gesta1_sa)}
              name="embryon_gesta1_sa"
              value={fields?.resultat?.embryon_gesta1_sa}
              onChange={handleInputChange}
              min="0" type="number"
              readOnly={props?.readOnly}
            />
            <span className="ml-1 mr-2">SA +</span>
            <Input
              containerClassName="border-left-0 border-top-0 border-right-0"
              wrapperStyle={{ width: "80px" }}
              className={makeInputClassName(errors?.embryon_gesta1_jours)}
              name="embryon_gesta1_jours"
              value={fields?.resultat?.embryon_gesta1_jours}
              onChange={handleInputChange}
              min="0" type="number"
              readOnly={props?.readOnly}
            />
            <span className="ml-1">Jours</span>
          </div>
        </div>
        <div className="d-flex flex-wrap w-100">
          <div className="d-flex align-items-center mr-4 mt-3">
            <span className="mr-2">LCC2 :</span>
            <Input
              containerClassName="border-left-0 border-top-0 border-right-0"
              wrapperStyle={{ width: "80px" }}
              className={makeInputClassName(errors?.embryon_lcc2)}
              name="embryon_lcc2"
              value={fields?.resultat?.embryon_lcc2}
              onChange={handleInputChange}
              min="0" type="number"
              readOnly={props?.readOnly}
            />
            <span className="ml-1">mm</span>
          </div>
          <div className="d-flex align-items-center mr-4 mt-3">
            <span className="mr-2">AC2 :</span>
            <Input
              containerClassName="border-left-0 border-top-0 border-right-0"
              wrapperStyle={{ width: "80px" }}
              className={makeInputClassName(errors?.embryon_ac2)}
              name="embryon_ac2"
              value={fields?.resultat?.embryon_ac2}
              onChange={handleInputChange}
              min="0" type="number"
              readOnly={props?.readOnly}
            />
            <span className="ml-1">bts/mn</span>
          </div>
          <div className="d-flex align-items-center mr-4 mt-3">
            <span className="mr-2">MAF :</span>
            <Input
              containerClassName="border-left-0 border-top-0 border-right-0"
              wrapperStyle={{ width: "120px" }}
              className={makeInputClassName(errors?.embryon_maf2)}
              name="embryon_maf2"
              value={fields?.resultat?.embryon_maf2}
              onChange={handleInputChange}
              readOnly={props?.readOnly}
            />
          </div>
          <div className="d-flex align-items-center mr-4 mt-3">
            <span className="mr-2">Age gestationnel :</span>
            <Input
              containerClassName="border-left-0 border-top-0 border-right-0"
              wrapperStyle={{ width: "80px" }}
              className={makeInputClassName(errors?.embryon_gesta2_sa)}
              name="embryon_gesta2_sa"
              value={fields?.resultat?.embryon_gesta2_sa}
              onChange={handleInputChange}
              min="0" type="number"
              readOnly={props?.readOnly}
            />
            <span className="ml-1 mr-2">SA +</span>
            <Input
              containerClassName="border-left-0 border-top-0 border-right-0"
              wrapperStyle={{ width: "80px" }}
              className={makeInputClassName(errors?.embryon_gesta2_jours)}
              name="embryon_gesta2_jours"
              value={fields?.resultat?.embryon_gesta2_jours}
              onChange={handleInputChange}
              min="0" type="number"
              readOnly={props?.readOnly}
            />
            <span className="ml-1">Jours</span>
          </div>
        </div>
      </div>
      <div className="p-3 d-flex flex-wrap w-100 border-top mt-3">
        <div className="font-weight-bold w-100">Foetus</div>
        <div className="d-flex align-items-center mr-4 mt-3 w-100">
          <span className="mr-2">Nombre :</span>
          <Input
            containerClassName="border-left-0 border-top-0 border-right-0"
            wrapperStyle={{ width: "70px" }}
            className={makeInputClassName(errors?.foetus_nbr)}
            name="foetus_nbr"
            value={fields?.resultat?.foetus_nbr}
            onChange={handleInputChange}
            min="0" type="number"
            readOnly={props?.readOnly}
          />
        </div>
        <div className="d-flex flex-wrap w-100">
          <div className="d-flex align-items-center mr-4 mt-3">
            <span className="mr-2">LCC1 :</span>
            <Input
              containerClassName="border-left-0 border-top-0 border-right-0"
              wrapperStyle={{ width: "70px" }}
              className={makeInputClassName(errors?.foetus_lcc1)}
              name="foetus_lcc1"
              value={fields?.resultat?.foetus_lcc1}
              onChange={handleInputChange}
              min="0" type="number"
              readOnly={props?.readOnly}
            />
            <span className="ml-1">mm</span>
          </div>
          <div className="d-flex align-items-center mr-4 mt-3">
            <span className="mr-2">BIP1 :</span>
            <Input
              containerClassName="border-left-0 border-top-0 border-right-0"
              wrapperStyle={{ width: "70px" }}
              className={makeInputClassName(errors?.foetus_bip1)}
              name="foetus_bip1"
              value={fields?.resultat?.foetus_bip1}
              onChange={handleInputChange}
              min="0" type="number"
              readOnly={props?.readOnly}
            />
            <span className="ml-1">mm</span>
          </div>
          <div className="d-flex align-items-center mr-4 mt-3">
            <span className="mr-2">AC1 :</span>
            <Input
              containerClassName="border-left-0 border-top-0 border-right-0"
              wrapperStyle={{ width: "70px" }}
              className={makeInputClassName(errors?.foetus_ac1)}
              name="foetus_ac1"
              value={fields?.resultat?.foetus_ac1}
              onChange={handleInputChange}
              min="0" type="number"
              readOnly={props?.readOnly}
            />
            <span className="ml-1">bts/mn</span>
          </div>
          <div className="d-flex align-items-center mr-4 mt-3">
            <span className="mr-2">FL1 :</span>
            <Input
              containerClassName="border-left-0 border-top-0 border-right-0"
              wrapperStyle={{ width: "70px" }}
              className={makeInputClassName(errors?.foetus_fl1)}
              name="foetus_fl1"
              value={fields?.resultat?.foetus_fl1}
              onChange={handleInputChange}
              min="0" type="number"
              readOnly={props?.readOnly}
            />
            <span className="ml-1">mm</span>
          </div>
          <div className="d-flex align-items-center mr-4 mt-3">
            <span className="mr-2">MAF :</span>
            <Input
              containerClassName="border-left-0 border-top-0 border-right-0"
              wrapperStyle={{ width: "120px" }}
              className={makeInputClassName(errors?.foetus_maf1)}
              name="foetus_maf1"
              value={fields?.resultat?.foetus_maf1}
              onChange={handleInputChange}
              readOnly={props?.readOnly}
            />
          </div>
          <div className="d-flex align-items-center mr-4 mt-3">
            <span className="mr-2">Age gestationnel :</span>
            <Input
              containerClassName="border-left-0 border-top-0 border-right-0"
              wrapperStyle={{ width: "70px" }}
              className={makeInputClassName(errors?.foetus_gesta1_sa)}
              name="foetus_gesta1_sa"
              value={fields?.resultat?.foetus_gesta1_sa}
              onChange={handleInputChange}
              min="0" type="number"
              readOnly={props?.readOnly}
            />
            <span className="ml-1 mr-2">SA +</span>
            <Input
              containerClassName="border-left-0 border-top-0 border-right-0"
              wrapperStyle={{ width: "70px" }}
              className={makeInputClassName(errors?.foetus_gesta1_jours)}
              name="foetus_gesta1_jours"
              value={fields?.resultat?.foetus_gesta1_jours}
              onChange={handleInputChange}
              min="0" type="number"
              readOnly={props?.readOnly}
            />
            <span className="ml-1">Jours</span>
          </div>
        </div>
        <div className="d-flex flex-wrap w-100">
          <div className="d-flex align-items-center mr-4 mt-3">
            <span className="mr-2">LCC2 :</span>
            <Input
              containerClassName="border-left-0 border-top-0 border-right-0"
              wrapperStyle={{ width: "70px" }}
              className={makeInputClassName(errors?.foetus_lcc2)}
              name="foetus_lcc2"
              value={fields?.resultat?.foetus_lcc2}
              onChange={handleInputChange}
              min="0" type="number"
              readOnly={props?.readOnly}
            />
            <span className="ml-1">mm</span>
          </div>
          <div className="d-flex align-items-center mr-4 mt-3">
            <span className="mr-2">BIP2 :</span>
            <Input
              containerClassName="border-left-0 border-top-0 border-right-0"
              wrapperStyle={{ width: "70px" }}
              className={makeInputClassName(errors?.foetus_bip2)}
              name="foetus_bip2"
              value={fields?.resultat?.foetus_bip2}
              onChange={handleInputChange}
              min="0" type="number"
              readOnly={props?.readOnly}
            />
            <span className="ml-1">mm</span>
          </div>
          <div className="d-flex align-items-center mr-4 mt-3">
            <span className="mr-2">AC2 :</span>
            <Input
              containerClassName="border-left-0 border-top-0 border-right-0"
              wrapperStyle={{ width: "70px" }}
              className={makeInputClassName(errors?.foetus_ac2)}
              name="foetus_ac2"
              value={fields?.resultat?.foetus_ac2}
              onChange={handleInputChange}
              min="0" type="number"
              readOnly={props?.readOnly}
            />
            <span className="ml-1">bts/mn</span>
          </div>
          <div className="d-flex align-items-center mr-4 mt-3">
            <span className="mr-2">FL2 :</span>
            <Input
              containerClassName="border-left-0 border-top-0 border-right-0"
              wrapperStyle={{ width: "70px" }}
              className={makeInputClassName(errors?.foetus_fl2)}
              name="foetus_fl2"
              value={fields?.resultat?.foetus_fl2}
              onChange={handleInputChange}
              min="0" type="number"
              readOnly={props?.readOnly}
            />
            <span className="ml-1">mm</span>
          </div>
          <div className="d-flex align-items-center mr-4 mt-3">
            <span className="mr-2">MAF :</span>
            <Input
              containerClassName="border-left-0 border-top-0 border-right-0"
              wrapperStyle={{ width: "120px" }}
              className={makeInputClassName(errors?.foetus_maf2)}
              name="foetus_maf2"
              value={fields?.resultat?.foetus_maf2}
              onChange={handleInputChange}
              readOnly={props?.readOnly}
            />
          </div>
          <div className="d-flex align-items-center mr-4 mt-3">
            <span className="mr-2">Age gestationnel :</span>
            <Input
              containerClassName="border-left-0 border-top-0 border-right-0"
              wrapperStyle={{ width: "70px" }}
              className={makeInputClassName(errors?.foetus_gesta2_sa)}
              name="foetus_gesta2_sa"
              value={fields?.resultat?.foetus_gesta2_sa}
              onChange={handleInputChange}
              min="0" type="number"
              readOnly={props?.readOnly}
            />
            <span className="ml-1 mr-2">SA +</span>
            <Input
              containerClassName="border-left-0 border-top-0 border-right-0"
              wrapperStyle={{ width: "70px" }}
              className={makeInputClassName(errors?.foetus_gesta2_jours)}
              name="foetus_gesta2_jours"
              value={fields?.resultat?.foetus_gesta2_jours}
              onChange={handleInputChange}
              min="0" type="number"
              readOnly={props?.readOnly}
            />
            <span className="ml-1">Jours</span>
          </div>
        </div>
      </div>
      <div className="p-3 d-flex flex-wrap w-100 border-top mt-3">
        <Input
          label="ASPECTS PATHOLOGIOUES"
          labelClassName="font-weight-bold"
          containerClassName="border-0"
          placeholder="Saisir ici"
          className={`px-0 py-1 h-auto ${!!errors?.aspect_pathologique ? 'border-principal-bottom' : ''}`}
          name="aspect_pathologique"
          value={fields?.resultat?.aspect_pathologique}
          onChange={handleInputChange}
          readOnly={props?.readOnly}
        />
      </div>
      <div className="p-3 d-flex flex-wrap w-100 border-top mt-3">
        <Textarea
          label="CONCLUSION"
          labelClassName="font-weight-bold text-principal"
          containerClassName="border-0"
          className={`px-0 py-1 ${!!errors?.comment ? 'border-principal-bottom' : ''}`}
          placeholder="Saisir ici"
          rows={3}
          value={fields?.comment}
          onChange={e => setFields(old => ({
            ...old,
            comment: e?.target?.value
          }))}
          readOnly={props?.readOnly}
        />
      </div>
    </Container>
  );
};

const Container = styled.div`
  border: 0.5px solid #ABABAB;
`;

export default FicheEchoObsFirst;