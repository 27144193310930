import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { makeStyles } from "@material-ui/core";
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FaTimes } from 'react-icons/fa';
import { AiOutlineCheckCircle } from 'react-icons/ai';

const SuccessModal = props => {
  const classes = usetyles();

  return (
    <Modal
      centered
      size="sm"
      contentClassName={classes.modalContainer}
      show={props.visible}
      onHide={props.onHide.bind(this, false)}
    >
      <CloseIcon onClick={props.onHide.bind(this, false)} />
      <CheckIcon className="mt-5" />
      <div className="h-auto w-100 d-flex flex-column p-4">
        <Message style={props.messageStyle} className={`text-disabled ${props.messageClassName ? props.messageClassName : ''}`}>
          {props.message}
        </Message>
      </div>
    </Modal>
  );
};

const usetyles = makeStyles(() => ({
  modalContainer: {
    boxShadow: "0px 8px 30px rgba(0, 0, 0, 0.12)",
    borderRadius: "5px",
    borderWidth: 0,
    backgroundColor: "white",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    "@media only screen and (max-width: 768px)": {
      width: "80vw",
      marginLeft: "7vw"
    }
  },
}));

SuccessModal.propTypes = {
  message: PropTypes.string,
  messageStyle: PropTypes.object,
  messageClassName: PropTypes.string,
  visible: PropTypes.bool,
  onHide: PropTypes.func,
};

SuccessModal.defaultProps = {
  visible: false,
  message: "Voulez-vous vraiment continuer ?",
  messageClassName: "",
  messageStyle: {},
  onHide: () => console.log("Please attach an onHide function to [SuccessModal]"),
};

const CheckIcon = styled(AiOutlineCheckCircle)`
  color: #5AECA9;
  font-size: 100px;
`;

const CloseIcon = styled(FaTimes)`
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  color: #52575C;
`;

const Message = styled.div`
  width: 100%;
  text-align: center;
  color: #52575C;
`;

export default SuccessModal;