import React from 'react';
import styled from 'styled-components';

interface TableProps {
  component?: React.ReactNode;
  children?: any;
};

const Table = (props: TableProps) => {
  const TableNode = props.component || TableContainer;

  return (
    <Wrapper {...props}>
      <TableNode>
        {props.children}
      </TableNode>
    </Wrapper>
  )
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-x: auto;
`;

const TableContainer = styled.table`
  width: 100%;
  position: relative;
  border: 0px solid #E8357955;
  border-collapse: collapse;
  border-radius: 5px;
  tr {
    &.active {
      background-color: #29D2C3 !important;
      color: white;
    }
    &:hover {
      background-color: #29D2C30A;
    }
  }
  th {
    border-top: 0px solid #0002;
    border-bottom: 1px solid #0002;
    border-radius: 3px;
    padding: 15px 5px;
    font-weight: bold;
    background-color: #EEFFFE;
  }
  td {
    font-weight: 400;
    padding: 15px 5px;
    border: 0px solid #E8357955;
    border-collapse: collapse;
  }
`;

export default Table;