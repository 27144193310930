import React from 'react';
import PropTypes from 'prop-types';
import "./styles.scss";

const Button = props => {
  return (
    <button
      {...props}
      className={`
        kea-btn-${props.size ? props.size : 'md'}
        kea-btn-${props.variant ? props.variant : 'principal'}
        shadow-sm
        ${props.className ? props.className : ''}
      `}
    >
      {props?.value ? props.value : props?.children}
    </button>
  );
};

Button.propTypes = {
  value: PropTypes.string,
  size: PropTypes.string,
  variant: PropTypes.oneOf([
    'principal', 'principal-outline',
    'infos', 'infos-outline',
    'green', 'green-outline',
    'red', 'red-outline',
    'black', 'black-outline',
    'gray', 'gray-outline',
    'white', 'white-outline',
    'blue', , 'blue-outline'
  ]),
  className: PropTypes.string,
  style: PropTypes.object
};

Button.defaultProps = {
  value: "",
  size: "md",
  className: "",
  style: {}
};

export default Button;